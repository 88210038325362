<template>
  <router-link :to="checkoutLink" class="py-2 font-size-xl btn btn-primary">
    <span>{{ $t('goToCheckout') }}</span>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WCCartDrawerCheckoutButton',
  computed: {
    ...mapGetters({
      isScanAndGoMode: 'user/isCustomerModeScan',
    }),
    checkoutLink() {
      return this.isScanAndGoMode ? '/scan/co' : '/co';
    },
  },
};
</script>

<style scoped lang="scss">
.wc-checkout-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-weight: 500;
  }
}
</style>
