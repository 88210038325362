
import { PropType, defineComponent } from 'vue';
import WebAd from '@/models/entities/web-ad';
import ElementSizeMixin from '@/mixins/ElementSizeMixin';

const regexUrlBeginsWithSlashSlashWithOptionalProtocol = /^(?:[a-z+]+:)?\/\//i;

export default defineComponent({
  props: {
    webAd: {
      type: Object as PropType<WebAd>,
    },
  },
  name: 'WCWebAd',
  mixins: [ElementSizeMixin],
  data() {
    return {
      WEB_AD_WITH_STYLE_NAME: '--web-ad-fixed-width',
    };
  },
  mounted() {
    this.addClientWidthToStyles(this.WEB_AD_WITH_STYLE_NAME);

    window.addEventListener('resize', () => {
      this.addClientWidthToStyles(this.WEB_AD_WITH_STYLE_NAME);
    });
  },
  updated() {
    this.addClientWidthToStyles(this.WEB_AD_WITH_STYLE_NAME);
  },
  beforeUnmount() {
    window.removeEventListener('resize', () => {
      this.addClientWidthToStyles(this.WEB_AD_WITH_STYLE_NAME);
    });
  },
  computed: {
    hasMessage() {
      return !!this.webAd.label;
    },
    hasImg() {
      return !!this.webAd.image;
    },
    imageSrc() {
      return this.hasImg ? `${this.$configuration.cdn}/api/img/${this.webAd.image}` : '';
    },
    webAdLink() {
      return this.webAd.link;
    },
    webAdLinkIsRelativeUrl(): boolean {
      return this.webAdLink?.includes(this.$configuration.siteUrl);
    },
    // Does the link for the Web Ad take the user to an external site?
    isExternalLink(): boolean {
      return (
        !this.webAdLinkIsRelativeUrl &&
        regexUrlBeginsWithSlashSlashWithOptionalProtocol.test(this.webAdLink)
      );
    },
  },
});
