/* eslint-disable class-methods-use-this */
import VueGtag, { event, purchase } from 'vue-gtag';
import TrackableEventConstants from '@/constants/TrackableEventConstants';

class AnalyticsService {
  googleAnalyticsId;

  load(configuration, router, app) {
    this.googleAnalyticsId = configuration.googleAnalyticsId;

    if (this.googleAnalyticsId) {
      app.use(
        VueGtag,
        {
          config: {
            id: this.googleAnalyticsId,
            send_page_view: false,
          },
          ecommerce: {
            enabled: true,
            enhanced: true,
          },
          pageTrackerScreenviewEnabled: true,
          appName: 'WebCart',
        },
        router,
      );
    }
  }

  track(trackableEvent, payload) {
    if (this.googleAnalyticsId) {
      switch (trackableEvent) {
        case TrackableEventConstants.Search:
          event(TrackableEventConstants.Search, {
            search_term: payload.query,
            customer_id: payload.customerId,
            items: payload.items?.map(item => this.itemToAnalyticsItem(item)),
          });
          break;

        case TrackableEventConstants.ItemImpression:
          if (payload.items?.length > 0) {
            event(TrackableEventConstants.ItemImpression, {
              customer_id: payload.customerId,
              items: payload.items?.map(item => this.itemToAnalyticsItem(item)),
              item_list_id: payload.listId,
              item_list_name: payload.listName,
            });
          }
          break;

        case TrackableEventConstants.ItemViewed:
          event(TrackableEventConstants.ItemViewed, {
            customer_id: payload.customerId,
            items: [this.itemToAnalyticsItem(payload.item)],
          });
          break;

        case TrackableEventConstants.ItemAddedToCart:
          event(TrackableEventConstants.ItemAddedToCart, {
            customer_id: payload.customerId,
            items: payload.items?.map(item => this.itemToAnalyticsItem(item)),
            action: 'add',
            category: 'Orders',
          });
          break;

        case TrackableEventConstants.ItemRemovedFromCart:
          event(TrackableEventConstants.ItemRemovedFromCart, {
            customer_id: payload.customerId,
            items: payload.items?.map(item => this.itemToAnalyticsItem(item)),
            action: 'remove',
            category: 'Orders',
          });
          break;

        case TrackableEventConstants.ItemAddedToList:
          event(TrackableEventConstants.ItemAddedToList, {
            customer_id: payload.customerId,
            items: payload.items?.map(item => this.itemToAnalyticsItem(item)),
          });
          break;

        case TrackableEventConstants.CheckoutStarted:
          event(TrackableEventConstants.CheckoutStarted, {
            customer_id: payload.customerId,
            items: payload.lineItems?.map(lineItem => this.lineItemToAnalyticsItem(lineItem)),
          });
          break;

        case TrackableEventConstants.CheckoutStageChanged:
          event(TrackableEventConstants.CheckoutStageChanged, {
            checkout_step: payload.stage,
            customer_id: payload.customerId,
            items: payload.lineItems?.map(lineItem => this.lineItemToAnalyticsItem(lineItem)),
          });
          break;

        case TrackableEventConstants.OrderPlaced:
          purchase({
            transaction_id: payload.scanCode,
            value: payload.orderTotal || payload.subtotal,
            currency: 'USD',
            tax: payload.taxTotal,
            shipping: payload.shippingTotal,
            coupon: payload.coupons?.map(coupon => coupon.code).join(','),
            customer_id: payload.customerId,
            items:
              payload.giftCardItems?.length > 0
                ? payload.giftCardItems
                    .filter(giftCardItem => this.lineItemToAnalyticsItem(giftCardItem))
                    .map(giftCardItem => this.lineItemToAnalyticsItem(giftCardItem))
                : payload.lineItems
                    .filter(lineItem => this.lineItemToAnalyticsItem(lineItem))
                    .map(lineItem => this.lineItemToAnalyticsItem(lineItem)),
          });
          break;

        case TrackableEventConstants.Refund:
          purchase({
            transaction_id: payload.scancode,
            value: -payload.total,
            currency: 'USD',
            items: payload.lineItems?.map(lineItem => this.lineItemToAnalyticsItem(lineItem)),
            customer_id: payload.customerId,
          });
          break;

        default:
          event(trackableEvent, { ...payload });
          break;
      }
    }
  }

  itemToAnalyticsItem(item) {
    if (!item) {
      return undefined;
    }

    return {
      id: item.id,
      name: item.name,
      brand: item.brand,
      category: item.department,
      quantity: item.quantity || 1,
      price: item.actualPrice / (item.actualPriceDivider || 1.0),
    };
  }

  lineItemToAnalyticsItem(lineItem) {
    if (!lineItem || (lineItem.type && lineItem.type !== 1 && lineItem.type !== 60)) {
      return undefined;
    }

    return {
      id: lineItem.id || lineItem.item?.id,
      name: lineItem.item?.name || lineItem.detail,
      brand: lineItem.item?.brand,
      category: lineItem.item?.department,
      quantity: lineItem.quantity,
      currency: 'USD',
      price: lineItem.price || lineItem.extended || lineItem.amount,
      value: lineItem.price || lineItem.extended || lineItem.amount,
    };
  }
}

export default new AnalyticsService();
