<template>
  <div class="input-group">
    <div v-if="$slots.left" class="input-group-prepend">
      <slot name="left" />
    </div>
    <slot />
    <div v-if="$slots.right" class="input-group-append">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WCInputGroup',
};
</script>
