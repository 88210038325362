<template>
  <div class="qty-adjustor-btn-grp">
    <div class="btn-group d-flex">
      <!-- START : Minus or Trash Button -->
      <button
        class="btn btn-sm"
        v-if="!hasRemove || canDecrement"
        :disabled="!canDecrement || disabled"
        @click="decrement()"
        :aria-label="$t('decrement')"
      >
        <font-awesome-icon class="text-primary" icon="minus" />
      </button>
      <button
        class="btn btn-sm"
        v-else-if="hasRemove"
        :aria-label="$t('delete')"
        :disabled="disabled"
        @click="remove()"
      >
        <font-awesome-icon class="text-primary" icon="trash" />
      </button>
      <!-- END : Minus or Trash Button -->
      <!-- START : Spinner Qty -->
      <button class="btn btn-sm qty" :disabled="disabled">
        {{ selectedQuantity }}
      </button>
      <!-- END : Spinner Qty -->
      <!-- START : Plus button -->
      <button
        class="btn btn-sm"
        :disabled="!canIncrement || disabled"
        :aria-label="$t('increment')"
        @click="increment()"
      >
        <font-awesome-icon class="text-primary" icon="plus" />
      </button>
      <!-- END : Plus button -->
    </div>
  </div>
</template>
<script>
import { BigNumber } from 'bignumber.js';

export default {
  name: 'WCSimpleQuantityAdjustor',
  props: {
    // Item quantity value
    initialValue: {
      type: BigNumber,
      default() {
        return new BigNumber(1);
      },
    },
    // Boolean to check if the remove option is present
    hasRemove: {
      type: Boolean,
      default: true,
    },
    // Increment step value
    incrementStep: {
      type: BigNumber,
      default() {
        return new BigNumber(1);
      },
    },
    // Maximum Quantity allowed for the item
    maxQty: {
      type: BigNumber,
      default: null,
    },
    // Minimum Quantity allowed for the item
    minQty: {
      type: BigNumber,
      default() {
        return new BigNumber(1);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['increment', 'decrement', 'remove'],
  data() {
    return {
      selectedQuantity: this.initialValue,
    };
  },
  computed: {
    /*
     * Property to check if the item quantity can be incremented based on max value
     */
    canIncrement() {
      return (
        (this.maxQty || 0) <= 0 ||
        this.incrementStep.plus(this.selectedQuantity).comparedTo(this.maxQty) <= 0
      );
    },

    /**
     * Property to check if the item quantity can be decremented based on min value
     */
    canDecrement() {
      return (
        this.selectedQuantity.minus(this.incrementStep).comparedTo(new BigNumber(this.minQty)) >= 0
      );
    },
  },
  methods: {
    /**
     * Method to increment selected quantity and emit the 'increment' event
     */
    increment() {
      this.selectedQuantity = this.selectedQuantity.plus(
        (this.selectedQuantity ? this.incrementStep : this.minQty) || 1,
      );
      this.$emit('increment', this.selectedQuantity);
    },
    /**
     * Method to decrement selected quantity and emit the 'decrement' event
     */
    decrement() {
      this.selectedQuantity = this.selectedQuantity.minus(this.incrementStep);
      this.$emit('decrement', this.selectedQuantity);
    },
    /**
     * Method to emit the 'remove' event
     */
    remove() {
      this.selectedQuantity = new BigNumber(0);
      this.$emit('remove');
    },
  },
  watch: {
    /**
     * Set selectedQuantity as Initial Value on change
     */
    initialValue() {
      this.selectedQuantity = this.initialValue;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

/*----- START : Qty Adjustor -----*/
.qty-adjustor-btn-grp {
  .btn-group {
    display: flex;
    .btn {
      background-color: var(--white, $white);
    }
  }
  /*----- START : Qty Adjustor Mobile-----*/
  @include media-breakpoint-down(md) {
    .btn-group {
      display: block;
      .btn {
        border: 1px solid var(--gray-500, $gray-500);
        &.btn-sm {
          padding: $pad-3 * 0.35 $pad-3 * 0.65;
          font-size: $font-size-base;
        }
        &.qty {
          min-width: rem(60px);
        }
      }
    }
  }
  /*----- END : Qty Adjustor Mobile-----*/
}
/*----- END : Qty Adjustor -----*/
</style>
