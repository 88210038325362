export default {
  mounted() {
    this.setHeaderHeight();
    window.addEventListener('resize', () => {
      this.setHeaderHeight();
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', () => {
      this.setHeaderHeight();
    });
  },
  updated() {
    this.setHeaderHeight();
  },
  methods: {
    setHeaderHeight() {
      const height = this.getHeightOfClass('mobile-header');
      document.documentElement.style.setProperty('--mobile-header-height', `${height}px`);
    },
    getHeightOfClass(className) {
      const element = document.getElementsByClassName(className)[0];
      const positionInfo = element?.getBoundingClientRect();

      return positionInfo?.height;
    },
  },
};
