<template>
  <div class="w-100 container-fluid">
    <div v-if="errorMessage">
      <font-awesome-icon icon="circle-exclamation" size="3x" class="w-100 mb-3 text-danger" />
      <p class="w-100 text-center">{{ errorMessage }}</p>
      <button class="btn btn-primary w-100 mt-3 ok-button" @click="handleErrorAcknowledgement">
        <span>{{ $t('ok') }}</span>
      </button>
    </div>
    <div v-else-if="(ebtCashBalance || ebtSnapBalance) && !hideBalanceDisplay">
      <EbtBalanceDisplay
        :token="token"
        :cashBalance="ebtCashBalance"
        :snapBalance="ebtSnapBalance"
        :maskedCardNum="maskedCardNum"
        :paymentMethod="paymentMethod"
        :showViewEbtItems="showViewEbtItems"
        @done="done"
        @viewEbtItems="viewEbtItems"
        @addToWallet="addToWallet"
      />
    </div>
    <WCEbtPinEntry
      v-else
      class="w-100"
      :submitText="submitText"
      :token="token"
      :pinpadIframeId="`pinpadFrame-${paymentMethod.pmId}`"
      @submit="getBalance"
      @error="handleError"
    />
    <WCInvisibleRecaptcha ref="recaptcha" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import SpinnerService from '@/services/spinner.service';
import WCInvisibleRecaptcha from '@/modules/recaptcha/components/WCInvisibleRecaptcha/WCInvisibleRecaptcha.vue';
import ToastService from '@/modules/toasts/services/ToastService';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import WCEbtPinEntry from '@/modules/ebt/components/WCEbtPinEntry/WCEbtPinEntry.vue';
import { RESPONSE_STATUS_CODE } from '@/constants/ApiConstants';
import EbtBalanceDisplay from './EbtBalanceDisplay.vue';
import WalletMixin from '../Wallet/mixins/WalletMixin';

export default {
  name: 'EbtBalanceCheck',
  emits: ['done', 'viewEbtItems', 'addToWallet', 'pay', 'errorAcknowledgement'],
  components: { EbtBalanceDisplay, WCInvisibleRecaptcha, WCEbtPinEntry },
  mixins: [WalletMixin],
  props: {
    paymentMethod: {
      type: Object,
      required: true,
    },
    token: {
      type: Object,
    },
    submitText: {
      type: String,
      default: 'checkBalance',
    },
    showViewEbtItems: {
      type: Boolean,
      default: true,
    },
    showSnapBalance: {
      type: Boolean,
      default: true,
    },
    showCashBalance: {
      type: Boolean,
      default: true,
    },
    hideBalanceDisplay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      refNum: '',
      card: {},
      maskedCardNum: '',
      ebtCashBalance: null,
      ebtSnapBalance: null,
      errorMessage: '',
    };
  },
  methods: {
    ...mapActions({
      setEbtSnapBalance: 'cart/setEbtSnapBalance',
      setEbtCashBalance: 'cart/setEbtCashBalance',
    }),
    done() {
      this.$emit('done');
    },
    viewEbtItems() {
      this.$emit('viewEbtItems');
    },
    async addToWallet() {
      this.loading = true;
      SpinnerService.open();
      try {
        const ccInfo = {
          card: this.card,
        };
        const payload = {
          ...ccInfo,
          recaptcha: await this.$refs.recaptcha.execute(),
        };
        const response = await axios.post('api/me/wallet/addEbtToken', payload);
        this.handleWalletResponse(response);

        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'success',
            title: 'Add Wallet Card',
            message: 'Your Card has been successfully added',
          },
          timeout: 7000,
        });
        this.$emit('addToWallet', response);
      } catch (error) {
        console.log(error);
        this.errorMessage = error.message;
        if (error.response?.status === RESPONSE_STATUS_CODE.CODE_RATE_LIMIT) {
          ToastService.open(WCSimpleToast, {
            props: {
              variant: 'danger',
              title: 'Too Many EBT Card Changes',
              message: error.message,
            },
            timeout: 10000,
          });
        }
      } finally {
        this.loading = false;
        SpinnerService.close();
      }
    },
    async authorize() {
      this.$emit('pay', {
        method: this.paymentMethod,
        refNum: this.refNum,
      });
    },
    async getBalance(payload) {
      if (payload?.card) {
        this.card = payload.card;
      }

      if (payload?.refNum) {
        this.loading = true;
        SpinnerService.open();

        const { refNum } = payload;

        const response = await axios
          .post(`api/ebt/balance/${refNum}`)
          .catch(error => {
            console.error(error);
            if (error.response?.status === RESPONSE_STATUS_CODE.CODE_RATE_LIMIT) {
              ToastService.open(WCSimpleToast, {
                props: {
                  variant: 'danger',
                  title: 'Too Many EBT Balance Checks',
                  message: error.message,
                },
                timeout: 10000,
              });
            }
            this.done();
          })
          .finally(() => {
            this.loading = false;
            SpinnerService.close();
          });

        this.handleBalanceResponse(response);
      }
    },
    handleError(error) {
      this.errorMessage = error.message;
    },
    handleOk() {
      this.$emit('done');
    },
    handleErrorAcknowledgement() {
      this.$emit('errorAcknowledgement');
    },
    handleBalanceResponse(response) {
      if (this.showSnapBalance) {
        this.ebtSnapBalance = response?.data?.fields?.balance;
      }

      if (this.showCashBalance) {
        this.ebtCashBalance = response?.data?.fields?.secondaryBalance;
      }

      this.maskedCardNum = response?.data?.card?.finalDigits;
      this.errorMessage = response?.data?.message;

      if ((this.ebtSnapBalance || this.ebtSnapBalance === 0) && !this.errorMessage) {
        this.setEbtSnapBalance(this.ebtSnapBalance);
      }
      if ((this.ebtCashBalance || this.ebtCashBalance === 0) && !this.errorMessage) {
        this.setEbtCashBalance(this.ebtCashBalance);
      }

      if (this.hideBalanceDisplay && !this.errorMessage) {
        this.done();
      }
    },
  },
};
</script>

<style scoped lang="scss">
iframe {
  border: 0;
  width: 100%;
  height: 4rem;
}
</style>
