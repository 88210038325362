import emitter from '@/eventBus';

class ModalService {
  constructor() {
    this.emitter = emitter;
  }

  open(component, props = {}) {
    return new Promise((resolve, reject) => {
      this.emitter.$emit('open', { component, props, resolve, reject });
    });
  }

  dismissAll() {
    this.emitter.$emit('dismissAll');
  }
}

export default new ModalService();
