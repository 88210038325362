import emitter from '@/eventBus';
import { SIDE_MENU_EVENTS } from '../../../constants/EventConstants';
/**
 * SideMenu service for open and close of side drawer
 */
class SideMenuService {
  constructor() {
    this.emitter = emitter;
  }

  open() {
    this.emitter.$emit(SIDE_MENU_EVENTS.OPEN);
  }

  close() {
    this.emitter.$emit(SIDE_MENU_EVENTS.CLOSE);
  }

  toggle() {
    this.emitter.$emit(SIDE_MENU_EVENTS.TOGGLE);
  }
}

export default new SideMenuService();
