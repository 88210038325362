import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-809ae338"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "aria-label"]
const _hoisted_2 = { class: "wc-atc-btn__quantity-label px-2" }
const _hoisted_3 = { class: "sr-only" }
const _hoisted_4 = ["disabled", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      class: _normalizeClass(["btn py-0 px-1 border-0", _ctx.isNextWeightedQuantityDecrementInCartValid(_ctx.item) ? 'text-primary' : 'text-muted']),
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.decrementWeightedQuantity(_ctx.item)), ["stop"])),
      disabled: !_ctx.isNextWeightedQuantityDecrementInCartValid(_ctx.item),
      "aria-label": _ctx.$t('decreaseQuantity'),
      ref: "decrementWeightButtonRef",
      "aria-describedby": 'decrement items weighted quantity'
    }, [
      (_ctx.getNextWeightedQuantityDecrementInCart(_ctx.item).isZero())
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            icon: "trash",
            size: "sm"
          }))
        : (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 1,
            icon: "minus",
            size: "sm"
          }))
    ], 10, _hoisted_1),
    _createTextVNode(),
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('quantity')), 1),
      _createTextVNode(_toDisplayString(_ctx.getDisplayWeightedQuantityInCart(_ctx.item, _ctx.variation)), 1)
    ]),
    _createTextVNode(),
    _createElementVNode("button", {
      class: _normalizeClass(["btn py-0 px-1 border-0", _ctx.isNextWeightedQuantityIncrementInCartValid(_ctx.item) ? 'text-primary' : 'text-muted']),
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.incrementWeightedQuantity(_ctx.item, _ctx.variation)), ["stop"])),
      disabled: !_ctx.isNextWeightedQuantityIncrementInCartValid(_ctx.item),
      "aria-label": _ctx.$t('increaseQuantity'),
      ref: "incrementWeightButtonRef",
      "aria-describedby": 'increment items weighted quantity'
    }, [
      _createVNode(_component_font_awesome_icon, {
        icon: "plus",
        size: "sm"
      })
    ], 10, _hoisted_4)
  ]))
}