<template>
  <button
    class="linked-items__name unstyled-btn nav-link"
    @click="linkedItemsSelected()"
    :href="`#${linkedItems.id}`"
    data-toggle="tab"
    role="tab"
  >
    {{ linkedItems.name }}
  </button>
</template>
<script>
export default {
  name: 'WCItemModifierLinkedItems',
  emits: ['linkedItemsSelected'],
  props: {
    linkedItems: {},
  },
  methods: {
    linkedItemsSelected() {
      this.$emit('linkedItemsSelected');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

@include media-breakpoint-down(md) {
  .nav-link {
    padding: $pad-3 $pad-1 $pad-1 $pad-1;
  }
}
</style>
