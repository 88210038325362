<template>
  <WCModal @dismiss="isLoading ? null : dismiss('Dismissed')">
    <WCModalHeader class="d-flex justify-content-between align-items-center">
      <h1 class="mb-0">{{ $t('couponDetails') }}</h1>
      <button @click="dismiss('Dismissed')" class="btn btn-link underline-link-from-center">
        {{ $t('close') }}
      </button>
    </WCModalHeader>

    <!-- START: MODAL Body -->
    <WCModalBody class="coupon-detail-body">
      <div class="d-flex flex-row justify-content-between">
        <div class="d-flex mb-2 justify-content-between mw-50 flex-column">
          <div class="d-flex flex-column align-self-start mb-2 mr-2">
            <h2 class="text-primary font-size-lg">
              {{ couponTitle }}
            </h2>
            <div class="font-size-md">
              {{ coupon.brand }}
            </div>
            <WCCouponDetailModalBody :coupon="coupon"></WCCouponDetailModalBody>
          </div>
          <div class="wc-coupon-detail-date font-size-base">
            <div class="wc-promo-ribbon-wrap-mobile">
              <WCDigitalCouponRibbon
                :offer="coupon"
                :couponFilter="couponFilter"
                :isClipped="evaluateClipStatus"
                :isRedeemed="evaulateRedeemedStatus"
              />
            </div>
            {{ $t('expires') }}: {{ coupon.endDate }}
          </div>
        </div>
        <div
          class="wc-coupon-detail-body__image-button d-flex flex-column mb-2 align-items-center justify-content-between"
        >
          <WCImage
            :imageSrc="coupon.imageUrl || ''"
            :imageAlt="coupon.name"
            imageClass="wc-coupon-detail-image p-1"
            defaultClass="wc-coupon-detail-image-default p-1"
            :defaultTitle="coupon.name"
          />
          <WCCouponClipButton
            class="wc-coupon-detail-clip-button"
            :couponId="coupon.id"
            :availableLabel="$t('clipCoupon')"
            :clippedLabel="$t('clippedCoupon')"
            :redeemedLabel="$t('couponRedeemed')"
            :disabled="false"
            :couponFilter="couponFilter"
            :isClipped="evaluateClipStatus"
            :isRedeemed="evaulateRedeemedStatus"
            :handleClip="clip"
          />
        </div>
      </div>

      <!-- START: Coupon redeem options text -->
      <div class="d-flex flex-column">
        <div class="mx-auto my-2 font-size-sm text-primary">
          {{ $t('couponCanBeAppliedOnlineAndStore') }}
        </div>
      </div>
      <!-- END: Coupon redeem options text -->

      <!-- START: Eligible Products -->
      <h3 class="justify-content-flex-start font-weight-bold mb-2 mt-2 border-bottom">
        {{ $t('shopEligibleProducts') }}
      </h3>

      <div class="d-flex flex-column">
        <WCSpinner v-if="isLoading" class="align-self-center" size="xl" />
        <div
          class="wc-coupon-detail__no-products-found text-center align-self-center"
          aria-level="1"
          v-else-if="!products.length"
          tabindex="-1"
          ref="focusFirst"
        >
          {{ $t('noEligibleProductsFound') }}
        </div>

        <div v-else-if="products.length">
          <WCCouponEligibleItemDetails v-for="item in products" :key="item.id" :item="item" />
        </div>
      </div>
      <!-- END: Eligible Products -->

      <!-- START: Eligible UPCs -->
      <div class="d-flex flex-column pt-2">
        <h3 class="font-weight-bold mb-2 border-bottom">{{ $t('eligibleUPCs') }}:</h3>
        <div class="mb-3">
          {{ listOfUPCs }}
        </div>
      </div>
      <!-- END: Eligible UPCs -->
    </WCModalBody>
    <!-- END: MODAL Body -->
  </WCModal>
</template>

<script>
import { mapGetters } from 'vuex';

import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCCouponDetailModalBody from '@/modules/coupons/WCCouponDetailModalBody/WCCouponDetailModalBody.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCCouponClipButton from '@/components/WCCouponClipButton/WCCouponClipButton.vue';
import WCImage from '@/components/WCImage/WCImage.vue';
import WCDigitalCouponRibbon from '@/components/WCDigitalCouponRibbon/WCDigitalCouponRibbon.vue';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import WCCouponEligibleItemDetails from '@/modules/coupons/WCCouponEligibleItemDetails/WCCouponEligibleItemDetails.vue';

export default {
  name: 'WCCouponDetailModal',
  props: {
    coupon: {
      type: Object,
    },
    couponTitle: {
      type: String,
    },
    listOfUPCs: {
      type: String,
    },
    couponFilter: {
      type: String,
    },
    clip: {
      type: Function,
    },
    products: {
      type: Array,
    },
  },
  mixins: [ModalMixin],
  components: {
    WCModal,
    WCModalHeader,
    WCModalBody,
    WCCouponClipButton,
    WCImage,
    WCSpinner,
    WCCouponEligibleItemDetails,
    WCCouponDetailModalBody,
    WCDigitalCouponRibbon,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      clippedCoupons: 'coupons/getClippedCoupons',
      redeemedCoupons: 'coupons/getRedeemedCoupons',
    }),
    evaluateClipStatus() {
      return this.clippedCoupons?.filter(i => i?.includes(this.coupon.id)).length > 0;
    },
    evaulateRedeemedStatus() {
      return this.redeemedCoupons?.filter(i => i?.includes(this.coupon.id)).length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';
@include media-breakpoint-down(sm) {
  .save-btn {
    width: 100%;
  }
}

.modal-body {
  .coupon-detail-body {
    overflow-y: scroll;
  }
}

.wc-coupon-detail-image {
  width: 100%;
}

.wc-coupon-detail-image-default {
  width: 35%;
  height: 35%;
}

.wc-coupon-detail-body__image-button {
  min-width: 40%;
  max-width: 50%;
}

.wc-coupon-detail__no-products-found {
  font-size: 1.28rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
</style>
