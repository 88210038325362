<template>
  <WCAlert :class="`alert-${variant}`" v-if="showFeeAlert">
    <span class="font-weight-bold">{{ $t('membershipPaymentIsDue') }}</span>
    {{ $t('beforeYouCheckOut') }}
    <router-link to="/me/coop">
      {{ $t('clickHereToPay') }}
    </router-link>
  </WCAlert>
</template>

<script>
import WCAlert from '@/modules/alerts/components/WCAlert/WCAlert.vue';
import AlertMixin from '@/modules/alerts/mixins/AlertMixin';
import { mapGetters } from 'vuex';

export default {
  components: { WCAlert },
  mixins: [AlertMixin],
  computed: {
    ...mapGetters({
      isFeeAlertDisplayed: 'membership/isFeeAlertDisplayed',
    }),
    showFeeAlert() {
      return this.isFeeAlertDisplayed && this.$route.name !== 'Membership Details';
    },
  },
  watch: {
    isFeeAlertDisplayed() {
      if (!this.isFeeAlertDisplayed) {
        this.close('Hiding alert due to state change');
      }
    },
  },
};
</script>
