<template>
  <button
    class="btn"
    @click="toggle()"
    :aria-label="
      isFavorited(item)
        ? $t('removeFavorite', { name: item.name })
        : $t('addAsFavorite', { name: item.name })
    "
    :aria-pressed="isFavorited(item) ? 'true' : 'false'"
  >
    <font-awesome-icon :class="isFavorited(item) ? 'text-danger' : 'text-muted'" :icon="'heart'" />
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ToastService from '@/modules/toasts/services/ToastService';
import ProductDetailMixin from '@/views/ProductDetailPage/mixins/ProductDetailMixin';
import ItemAddToListMixin from '@/modules/lists/mixins/ItemAddToListMixin';
import { defineComponent } from 'vue';
import WCAddToListToast from '../WCAddToListToast/WCAddToListToast.vue';

export default defineComponent({
  name: 'WCFavoriteButton',
  props: {
    item: {
      type: Object,
    },
    isMobile: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      isFavorited: 'lists/isFavorited',
      getItemVariation: 'items/getItemVariation',
      favoritesList: 'lists/getFavoritesList',
    }),
  },
  mixins: [ProductDetailMixin, ItemAddToListMixin],
  methods: {
    ...mapActions({
      removeListItem: 'lists/removeListItem',
      createVariation: 'items/createVariation',
    }),
    /**
     * Get variation from the favorites list by mapping with the item id
     * If there are more than 1 variation for a favorited item, redirect to list page
     * Else return the variation
     */
    getVariation(item) {
      const itemsCount = this.favoritesList?.items.filter(i => i.id === item.id).length;
      if (itemsCount > 1) {
        this.$router.push(`/me/list/${this.favoritesList.id}`);
      } else {
        return this.favoritesList?.items.find(i => i.id === item.id).variation;
      }
      return null;
    },
    async add() {
      try {
        const list = await this.addItemToList({
          list: { name: 'Favorites', type: 'FAVORITES' },
          item: this.item,
          variation: this.getItemVariation || null,
        });
        ToastService.open(WCAddToListToast, {
          props: {
            variant: 'favorite',
            title: 'Item added to Favorites list',
            list,
            close: {},
            dismiss: {},
          },
          timeout: 2000,
        });
      } catch (error) {
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: 'Error',
            message: error.message,
          },
          timeout: 7000,
        });
      }
    },
    async remove() {
      try {
        await this.removeListItem({
          list: { name: 'Favorites', type: 'FAVORITES' },
          item: this.item,
          variation: this.getVariation(this.item) || null,
        });
      } catch (error) {
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: 'Error',
            message: error.message,
          },
          timeout: 7000,
        });
      }
    },
    async toggle() {
      // non MTO item's favorite workflow
      if (!(this.item.hasModifiers || this.item.hasLinkedItems)) {
        if (this.isFavorited(this.item)) this.remove();
        else this.add();
      }
      // MTO item's favorite workflow
      else if (this.item.hasModifiers || this.item.hasLinkedItems) {
        if (this.isFavorited(this.item)) this.remove();
        // customizeItemToAddToList accepts two arguments -isMobile and fromMTOItemFavIcon
        else this.customizeItemToAddToList(this.isMobile, true);
      }
    },
  },
});
</script>
