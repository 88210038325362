<template>
  <div class="wc-carousel" :class="{ 'wc-carousel--snap': snap }" v-if="!isSlider">
    <slot></slot>
  </div>
  <div class="splide-carousel" v-else>
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WCCarousel',
  props: {
    snap: {
      type: Boolean,
      default: true,
    },
    isSlider: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@splidejs/splide/dist/css/themes/splide-default.min.css';
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-carousel {
  // Horizontal scroll
  overflow-x: auto;
  white-space: nowrap;
  // Default start/end padding
  padding-left: $pad-4;
  padding-right: $pad-4;

  // Carousel content is displayed in a row
  * {
    display: inline-block !important;
  }

  // Enable snapping
  &.wc-carousel--snap {
    // Enable horizontal snapping
    scroll-snap-type: x proximity;
    // Default snap padding
    scroll-padding: $pad-4;

    // Assign snap points to left-edges of carousel content
    * {
      scroll-snap-align: start;
    }
    // Assign one snap point to the right-edge of the last element
    :nth-last-child(1) {
      scroll-snap-align: end;
    }
  }

  // Play nice if inside a bootstrap container
  &.wc-carousel--negate-container {
    margin-left: -$container-gutter;
    margin-right: -$container-gutter;
    padding-left: $container-gutter;
    padding-right: $container-gutter;

    &.wc-carousel--snap {
      scroll-padding: $container-gutter;
    }
  }

  // Play nice if inside a bootstrap row
  &.wc-carousel--negate-padding {
    padding-left: $container-gutter;
    padding-right: $container-gutter;
  }
}
.splide-carousel :deep(.splide__pagination__page.is-active) {
  background: var(--primary, $primary);
  border: 0;
}

.splide-carousel :deep(.splide__pagination__page) {
  border: solid 1px var(--primary-lighter-7, get-color('primary', 'lighter-7'));
  background: var(--white);
  opacity: 1;
}

.splide-carousel :deep(.splide__arrow) {
  background: 0;
  fill: var(--primary);
  opacity: 1;
  z-index: 3;
}
</style>
