
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import { MODAL_EVENTS } from '@/constants/EventConstants';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [ModalMixin],
  components: { WCModal, WCModalHeader, WCModalBody, WCModalFooter },
  data() {
    return {
      MODAL_EVENTS,
    };
  },
});
