<template>
  <section class="d-flex flex-column w-75 mx-auto my-0">
    <component
      v-bind="alert.props"
      v-bind:close="alert.close"
      v-bind:dismiss="alert.dismiss"
      v-for="(alert, ind) in AlertService.alerts"
      :key="ind"
      :is="alert.component"
      :data-id="alert.id"
    />
  </section>
</template>

<script>
import AlertService from '../../services/AlertService';

export default {
  data() {
    return {
      AlertService,
    };
  },
};
</script>
