<template>
  <div :class="isLoading && 'spinner-container'" role="alert" aria-live="assertive">
    <WCSpinner position="window-center" size="xl" v-if="isLoading" />
  </div>
</template>

<script>
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import emitter from '@/eventBus';

export default {
  name: 'WCSpinnerRoot',
  components: {
    WCSpinner,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    emitter.$on('loader:open', () => {
      this.open();
    });

    emitter.$on('loader:close', () => {
      this.close();
    });
  },
  methods: {
    open() {
      this.isLoading = true;
    },
    close() {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
}

.spinner {
  display: flex;
  z-index: 10000;
  align-items: center;
  justify-content: center;
}
</style>
