<template>
  <div class="wc-user-greeter">
    <div class="wc-user-greeter__welcome-text text-muted font-size-sm">
      <span>{{ $t('greeting') }} </span>
      <router-link
        :aria-label="$t('name')"
        to="/me/edit"
        class="link-primary underline-link-from-center font-weight-bold font-size-sm ml-1"
        >{{ capitalizeFirstLetter(name) }}</router-link
      >
      <div v-if="isEmployee && user.dispShort">
        {{ $t('forText', { name: user.dispShort }) }}
      </div>
    </div>
    <a
      href="#"
      v-if="isCustomer || isEmployee"
      class="link-primary underline-link-from-center font-weight-bold font-size-sm"
      @click.prevent="$emit('logout')"
      >{{ isCustomer ? $t('signOut') : $t('logOut') }}</a
    >
    <template v-else>
      <router-link
        to="/login"
        class="link-primary underline-link-from-center font-weight-bold font-size-sm "
        >{{ $t('signIn') }}</router-link
      >
      <span
        class="mx-2 text-primary font-weight-bold font-size-sm"
        v-if="$configuration.allowCustomerAdd"
        >|</span
      >
      <router-link
        to="/register"
        class="link-primary underline-link-from-center font-weight-bold font-size-sm"
        v-if="$configuration.allowCustomerAdd"
        >{{ $t('register') }}</router-link
      >
    </template>
  </div>
</template>

<script>
import UserMixin from '@/modules/user/mixins/UserMixin';
import { capitalizeFirstLetter } from '@/utils/formatters';

export default {
  emits: ['logout'],
  mixins: [UserMixin],
  methods: {
    capitalizeFirstLetter,
  },
};
</script>
