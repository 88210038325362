<template>
  <div>
    <div v-if="!isCartsLoading && !isCartLoading" class="d-flex">
      <button
        v-if="!cart.giftCardItems.length && !isGuest"
        class="btn btn-link  wc-small-btn mr-auto"
        @click.stop="openSaveAllItesForLaterModal()"
        data-testid="saveForLaterButton"
      >
        <font-awesome-icon icon="bookmark" />
        {{ $t('saveAllItemsForLater') }}
      </button>
      <button
        class="btn btn-link wc-medium-btn"
        @click.stop="openClearCartModal()"
        data-testid="clearCartButton"
      >
        <font-awesome-icon icon="cart-xmark" />
        {{ $t('clearCart') }}
      </button>
    </div>
    <WCCartDrawerItems v-if="!isCartsLoading && !isCartLoading" :cart="cart" />
    <div v-else class="wc-spinner__div">
      <WCSpinner class=" mx-auto align-self-center" size="xl" position="center" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import ModalService from '@/modules/modals/services/modal.service';
import axios from 'axios';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import WCCartDrawerItems from '@/modules/cart/components/WCCartDrawerItems/WCCartDrawerItems.vue';
import WCClearCartModal from '../../../modules/cart/components/WCClearCartModal/WCClearCartModal.vue';
import WCSaveAllItemsForLaterModal from '../../../modules/cart/components/WCSaveAllItemsForLaterModal/WCSaveAllItemsForLaterModal.vue';

export default {
  components: {
    WCCartDrawerItems,
    WCSpinner,
  },

  props: {
    cart: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  async mounted() {
    await this.setLoadingStatus(true);
    await this.setCart();
    await this.getCarts({ loading: true });
    await this.setLoadingStatus(false);
  },

  computed: {
    ...mapGetters({
      isCartsLoading: 'cart/cartsLoadingStatus',
      isSyncing: 'cart/isSyncing',
      isGuest: 'user/isGuest',
      isGiftCard: 'cart/isGiftCard',
      nonEmptyCarts: 'cart/nonEmptyCarts',
      isCartLoading: 'cart/isLoading',
    }),
  },

  methods: {
    ...mapActions({
      setCurrentCart: 'cart/setCurrentCart',
      setLoadingStatus: 'cart/setCartsLoadingStatus',
      getCarts: 'cart/getCarts',
    }),
    async setCart() {
      const response = await axios.get(`api/ot/${this.cart.orderType}/cart`);
      await this.setCurrentCart(response.data);
    },

    async openClearCartModal() {
      try {
        await ModalService.open(WCClearCartModal, {
          lineItems: this.cart.lineItems,
          isGiftCard: this.isGiftCard,
          isGuest: this.isGuest,
        });
      } catch (error) {
        // NOOP
      } finally {
        const nextCart = this.nonEmptyCarts.find(c => this.cart.orderType !== c.orderType);
        if (nextCart) {
          const response = await axios.get(`api/ot/${nextCart.orderType}/cart`);
          await this.setCurrentCart(response.data);
        }
      }
    },

    async openSaveAllItesForLaterModal() {
      try {
        await ModalService.open(WCSaveAllItemsForLaterModal, {
          lineItems: this.cart.lineItems,
          isGiftCard: this.isGiftCard,
          isGuest: this.isGuest,
        });
      } catch (error) {
        // NOOP
      } finally {
        await this.getCarts();
      }
    },
  },
};
</script>
<style scoped>
.wc-spinner__div {
  height: 250px;
}
</style>
