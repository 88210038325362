<template>
  <div>
    <!-- Header -->
    <div
      class="wc-store-select-header"
      :class="{ 'wc-store-select-border-bottom wc-select-light-background': isMobile }"
    >
      <div
        :class="{ 'wc-store-select-exit': !isMobile, 'wc-store-select-exit-mobile mt-2': isMobile }"
        @click="dismiss(MODAL_EVENTS.DISMISS)"
      >
        <font-awesome-icon class="wc-store-select-exit-inner text-primary" icon="xmark" size="2x" />
      </div>

      <div class="mt-2 text-center">
        <div class="font-weight-bold font-size-xl">{{ $t('storeLocation') }}</div>
        <div class="my-2">{{ $t('youAreShoppingHere') }}</div>
      </div>
    </div>
    <!-- Inner body -->
    <div
      :class="{
        'wc-store-select-body wc-store-select-border-radius': !isMobile,
      }"
    >
      <!-- Logo -->
      <div
        class="text-center"
        v-if="getStoreImageSrc"
        :class="{
          'wc-store-select-logo-outer p-3 wc-store-select-border-bottom': !isMobile,
          'pt-3': isMobile,
        }"
      >
        <WCImage
          class="wc-store-select-logo"
          :imageSrc="getStoreImageSrc"
          :imageAlt="getStoreName"
          imageClass="wc-store-select-image"
          :defaultTitle="getStoreName"
        />
      </div>

      <!-- Store Info -->
      <div class="text-center py-3">
        <div v-if="!getLocationAddress && !getLocationPhone && !getLocationEmail && !getStoreName">
          <p>{{ $t('noContactInfoAvailable') }}</p>
        </div>
        <div v-else>
          <div class="my-1">
            <span class="font-weight-bold">{{ getStoreName }}</span>
          </div>
          <!-- START : Store address details -->
          <div class="my-1" v-if="getLocationAddress">
            <div>
              <div v-if="getLocationAddress.street1 || getLocationAddress.street2">
                <span :aria-label="$t('streetAddress1')" v-if="getLocationAddress.street1">{{
                  getLocationAddress.street1
                }}</span>
                <span :aria-label="$t('streetAddress2')" v-if="getLocationAddress.street2"
                  ><br />{{ getLocationAddress.street2 }}</span
                >
              </div>
              <span v-if="getLocationAddress.city" :aria-label="$t('city')"
                >{{ getLocationAddress.city }}
              </span>
              <span v-if="getLocationAddress.state" :aria-label="$t('state')"
                >{{ getLocationAddress.state }}
              </span>
              <span v-if="getLocationAddress.postal" :aria-label="$t('zipCodePostal')">{{
                getLocationAddress.postal
              }}</span>
            </div>
          </div>
          <!-- END : Store address details -->

          <!-- START : Store phone details -->
          <div class="my-1" v-if="getLocationPhone">
            <div class="store-select store-select__contact-info">
              <font-awesome-icon class="mr-2" icon="phone" :title="$t('phoneNumber')" />
              <span :aria-label="$t('phoneNumber')">{{ getLocationPhone.phone }}</span>
            </div>
          </div>
          <!-- END : Store phone details -->

          <!-- START : Store email details -->
          <div class="my-1" v-if="getLocationEmail">
            <div class="store-select store-select__contact-info">
              <font-awesome-icon class="mr-2" icon="envelope" :title="$t('emailAddress')" />
              <span :aria-label="$t('email')">{{ getLocationEmail.email }}</span>
            </div>
          </div>

          <div class="wc-select-store-fit-content ml-auto mr-auto">
            <div
              v-if="isHomeStore"
              :aria-label="$t('homeStore')"
              class="font-weight-bold text-primary"
            >
              <font-awesome-icon :icon="['fa', 'house']" class="mr-1" :title="$t('homeStore')" />
              {{ $t('homeStore') }}
            </div>
            <div
              v-else
              :aria-label="$t('makeHomeStore')"
              class="underline-link-from-center font-weight-bold text-primary"
              @click="setHomeStore"
              role="button"
            >
              {{ $t('makeHomeStore') }}
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div
        class="wc-store-select-border-top"
        :class="{
          'wc-select-store-buttons-mobile': isMobile,
          'wc-select-store-buttons': !isMobile,
        }"
      >
        <button
          class="btn btn-outline-primary"
          :class="{
            'mb-4': isMobile,
          }"
          :aria-label="$t('shopAnotherStore')"
          @click="$emit('showAllStores')"
        >
          {{ $t('shopAnotherStore') }}
        </button>
        <button
          :aria-label="$t('continueShoppingHere')"
          class="btn btn-primary"
          @click="dismiss(MODAL_EVENTS.DISMISS)"
        >
          {{ $t('continueShoppingHere') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UserMixin from '@/modules/user/mixins/UserMixin';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import { mapGetters } from 'vuex';
import { getLocationEmail, getLocationPhone, getLocationAddress } from '@/utils';
import WCImage from '@/components/WCImage/WCImage.vue';
import { MODAL_EVENTS } from '@/constants/EventConstants';
import { PlatformMixin } from '@/modules/platform';

export default {
  emits: ['showAllStores'],
  mixins: [PlatformMixin, ModalMixin, UserMixin],
  components: { WCImage },
  data() {
    return {
      MODAL_EVENTS,
    };
  },
  methods: {
    async setHomeStore() {
      await this.setUserHomeStore(this.$configuration.store.id);
    },
  },
  computed: {
    ...mapGetters({ getUser: 'user/getUser' }),
    isHomeStore() {
      return this.getUser?.home === this.$configuration.store.id;
    },
    getStoreImageSrc() {
      return `${this.$configuration.cdn}/api/img/${this.$configuration.store.image}`;
    },
    getStoreName() {
      return this.$configuration.store.name;
    },
    getLocationAddress() {
      return getLocationAddress(this.$configuration.store);
    },
    getLocationPhone() {
      return getLocationPhone(this.$configuration.store);
    },
    getLocationEmail() {
      return getLocationEmail(this.$configuration.store);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-store-select-header {
  display: flex;
  flex-direction: column;
}

.wc-select-light-background {
  background-color: var(--light-lighter-4);
}

.wc-store-select-exit {
  position: absolute;
  width: 93%;
}

.wc-store-select-exit-mobile {
  position: absolute;
  width: 98%;
}

.wc-store-select-exit-inner {
  float: right;
  cursor: pointer;
}

.wc-store-select-body {
  border: rem(1px) solid var(--gray-700, $gray-700);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px #00000029;
}

.wc-store-select-border-radius {
  border-radius: 0.4rem;
}

.wc-store-select-logo {
  max-width: 50%;
}

.wc-store-select-border-top {
  border-top: rem(3px) solid var(--primary, $primary);
}

.wc-store-select-border-bottom {
  border-bottom: rem(3px) solid var(--primary, $primary);
}

.wc-select-store-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem;
  background-color: var(--light-lighter-4);
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.wc-select-store-buttons-mobile {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: var(--light-lighter-4);
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.wc-store-select-logo-outer {
  background-color: var(--light-lighter-4);
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.wc-store-select-image {
  max-width: 50%;
  max-height: 3rem;
}

.wc-select-store-fit-content {
  width: fit-content;
}
</style>
