<template>
  <div v-if="visibleWebAds.length" class="wc-web-ads-container px-0" :key="componentKey">
    <template v-if="visibleWebAds.length == 1">
      <WCWebAd v-for="webAd in visibleWebAds" :key="webAd.id" :web-ad="webAd" />
    </template>
    <WCAutoplayCarousel v-else class="px-0 position-relative">
      <SplideSlide v-for="webAd in visibleWebAds" :key="webAd.id">
        <WCWebAd :web-ad="webAd" />
      </SplideSlide>
    </WCAutoplayCarousel>
  </div>
</template>

<script>
import { SplideSlide } from '@splidejs/vue-splide';
import { PlatformMixin } from '@/modules/platform';
import WCAutoplayCarousel from '@/components/WCAutoplayCarousel/WCAutoplayCarousel.vue';
import ElementSizeMixin from '@/mixins/ElementSizeMixin';
import WCWebAd from '../WCWebAd/WCWebAd.vue';

export default {
  name: 'WCWebAds',
  components: { WCWebAd, WCAutoplayCarousel, SplideSlide },
  props: {
    webAds: {
      type: Array,
    },
  },
  data() {
    return {
      WEB_AD_WITH_STYLE_NAME: '--web-ad-width',
      resizeObserver: null,
      componentKey: 0,
      width: 0,
    };
  },
  mounted() {
    this.addClientWidthToStyles(this.WEB_AD_WITH_STYLE_NAME);

    window.addEventListener('resize', () => {
      this.addClientWidthToStyles(this.WEB_AD_WITH_STYLE_NAME);
    });

    this.width = this.getClientWidth();

    this.resizeObserver = new ResizeObserver(() => {
      if (this.width !== this.getClientWidth() && this.componentKey === 0) {
        this.componentKey += 1;
      }
    });

    this.resizeObserver.observe(document.body);
  },
  beforeUnmount() {
    window.removeEventListener('resize', () => {
      this.addClientWidthToStyles(this.WEB_AD_WITH_STYLE_NAME);
    });
    this.resizeObserver.disconnect();
  },
  mixins: [PlatformMixin, ElementSizeMixin],
  computed: {
    /**
     * Show only the first six web ads
     */
    visibleWebAds() {
      if (this.isDesktopOrTablet && this.desktopWebAds?.length > 0) {
        return this.desktopWebAds.slice(0, 6);
      }
      if (this.isMobile && this.mobileWebAds?.length > 0) {
        return this.mobileWebAds.slice(0, 6);
      }

      return this.webAds?.slice(0, 6);
    },
    mobileWebAds() {
      return this.webAds?.filter(webAd => webAd.type === 2);
    },
    desktopWebAds() {
      return this.webAds?.filter(webAd => webAd.type === 1);
    },
    isDesktopOrTablet() {
      return !this.isMobile || this.isTablet;
    },
    isTablet() {
      return this.isMediumDevice;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

@include media-breakpoint-up(lg) {
  .wc-web-ads-container {
    width: 100%;
    height: calc(var(--web-ad-width) / 3.5); // 3.5:1 aspect ratio.
  }
}
</style>
