import { v4 as uuid } from 'uuid';
import { ref } from 'vue';

class AlertService {
  constructor() {
    this.alerts = ref([]);
  }

  show(component, options = { props: {}, timeout: 0 }) {
    return new Promise((resolve, reject) => {
      const id = uuid();
      const { props, timeout } = options;
      const alert = {
        id,
        component,
        props,
        close: value => {
          this.alerts.value = this.alerts.value.filter(a => a.id !== id);
          resolve(value);
        },
        dismiss: reason => {
          this.alerts.value = this.alerts.value.filter(a => a.id !== id);
          reject(reason);
        },
      };

      this.alerts.value.unshift(alert); // Push onto front to reverse order of alerts

      if (timeout) {
        setTimeout(() => alert.dismiss('Timeout'), timeout);
      }
    });
  }
}

export default new AlertService();
