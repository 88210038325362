export default {
  "AcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스캔 후 이동 모드"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정"])},
  "accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 번호"])},
  "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 설정"])},
  "addAsFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["다른 이름으로 즐겨찾기에 ", _interpolate(_named("name")), " 추가"])},
  "addCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 추가"])},
  "addedSugars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설탕 추가됨"])},
  "addItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목 추가"])},
  "addItemsToOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문할 항목 추가"])},
  "addItemToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록에 항목 추가"])},
  "addItemToListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록에 이 항목 추가"])},
  "addNewCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 카드 추가"])},
  "addNewList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 목록 추가"])},
  "addNoteAboutItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 항목에 대한 메모 추가"])},
  "addPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 수단 추가"])},
  "addSelectedToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에 선택 항목 추가"])},
  "addSpecialInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특별 지침 추가"])},
  "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에 추가"])},
  "addToFavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기에 추가"])},
  "addToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록에 추가"])},
  "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림"])},
  "alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별칭"])},
  "allCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 카테고리"])},
  "allOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 주문"])},
  "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["허용"])},
  "allowCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카메라 권한 허용"])},
  "allowSubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대체 허용"])},
  "allRightsReserved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("year")), " WebCart - All rights reserved"])},
  "alreadyVerifiedYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 계정을 인증했습니까?"])},
  "alternateItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대체 항목"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금액"])},
  "amountMustBeAtLeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금액은 다음 값 이상이어야 합니다"])},
  "amountMustBeLessOrEqual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금액은 다음 값보다 작거나 같아야 합니다"])},
  "amountPerServing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1회 제공량"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적용"])},
  "applyChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 승인"])},
  "areYouSureCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경을 취소하시겠습니까?"])},
  "areYouSureCancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문을 취소하시겠습니까?"])},
  "areYouSureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별표(*)로 끝나는 카드를 삭제하시겠습니까?"])},
  "associate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결"])},
  "associateAsCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객으로 연결"])},
  "associationBanner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["고객 연결 모드, WebCart를 ", _interpolate(_named("name")), "(으)로 사용"])},
  "assumesNoLiability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품에 대한 부정확성 또는 허위 진술에 대해 책임 지지 않습니다."])},
  "asYouScanItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록에서 항목을 스캔하면 해당 항목을 확인해 드립니다"])},
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간"])},
  "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가용성"])},
  "availableBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능 잔액"])},
  "averagePerUnitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단가당 평균"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잔액"])},
  "barcodeAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바코드"])},
  "barcodePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록 시 스캔하려면 이 바코드 표시"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생일"])},
  "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["브랜드"])},
  "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["빵 부스러기"])},
  "browseByCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리로 찾아보기"])},
  "browseQueryText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("browseText")), "\""])},
  "browseResultQuery": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("browseResult")), "\""])},
  "buyItAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 구매"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화"])},
  "callForPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격 문의"])},
  "callUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화 문의"])},
  "calories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["칼로리"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
  "cancelEditOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 편집 취소"])},
  "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 취소"])},
  "cancelOrderFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문을 취소하는 중 문제가 발생했습니다. 페이지를 새로고침하고 다시 시도하십시오."])},
  "cancelOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문이 취소되었습니다."])},
  "cancelQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소하시겠습니까?"])},
  "cannotAddItemToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에 항목을 추가할 수 없음"])},
  "cardEnteredInvalidInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력하신 카드 번호가 유효하지 않거나 비활성입니다. 번호를 확인하고 다시 입력하십시오."])},
  "cardholderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카드 소지자 이름"])},
  "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카드 번호"])},
  "cardNumberIsNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카드 번호가 유효하지 않습니다"])},
  "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니"])},
  "cashierApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "cashierApprovalMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
  "categoriesMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 메뉴"])},
  "categoryLimitExceededMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " 카테고리에서 ", _interpolate(_named("limit")), "개 이상 선택할 수 없습니다."])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 변경"])},
  "changeYourMind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생각이 바뀌었습니까?"])},
  "changeYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 변경하십시오"])},
  "chargeAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청구 계정"])},
  "checkCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체크 카드"])},
  "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체크 아웃"])},
  "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체크아웃"])},
  "checkShoppingAtCorrectStore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("storeName")), "에서 쇼핑 중입니다. 주문하려는 스토어인지 확인하십시오."])},
  "checkTheBoxForReceipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일을 통해 인스토어 구매 영수증을 받으려면 확인란을 선택합니다."])},
  "checkTheGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소속되고 싶은 그룹을 체크하십시오."])},
  "checkYourBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잔액 확인"])},
  "choose": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("max")), " 선택"])},
  "chooseACategoryMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 메뉴 선택"])},
  "chooseAList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록 선택"])},
  "chooseAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 선택"])},
  "chooseAtLeast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["최소 ", _interpolate(_named("min")), " 선택"])},
  "chooseBetween": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("min")), " ~ ", _interpolate(_named("max")), "에서 선택"])},
  "chooseDataAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜 및 시간을 선택하십시오"])},
  "chooseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜 선택"])},
  "chooseDateAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜 및 시간 선택"])},
  "chooseLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 선택"])},
  "chooseQuantityFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량 선택 품목"])},
  "chooseTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 선택"])},
  "chooseUpTo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["최대 ", _interpolate(_named("max")), " 선택"])},
  "chooseYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), "을(를) 선택하십시오"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지우기"])},
  "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 지우기"])},
  "clearAllText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영구적으로 제거하시겠습니까?"])},
  "clearCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니 지우기"])},
  "clearCartFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니를 지우지 못했습니다"])},
  "clearCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니가 성공적으로 삭제되었습니다"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 지우기"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 지우기"])},
  "clearOrSaveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중에 사용할 수 있도록 이 항목을 저장하시겠습니까? 아니면 영구적으로 제거하시겠습니까?"])},
  "clickHereToRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록하려면 여기를 클릭하십시오"])},
  "clickHereToReregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 등록하려면 여기를 클릭하십시오"])},
  "clickHereToSendAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 항목을 보내려면 여기를 클릭하십시오"])},
  "clickHereToSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인하려면 여기를 클릭하십시오"])},
  "clickTheVerificationLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 등록을 마치려면 이메일에 있는 인증 링크를 클릭하십시오."])},
  "clickToAddNotesAndSubstitutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메모 및 대체 항목을 추가하려면 클릭하십시오"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닫기"])},
  "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접기"])},
  "commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["명령"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사"])},
  "completeAtCashRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금전 등록기에서 완료"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료됨"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의하기"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계속"])},
  "continueHereAndEndAcceleratedCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "continueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쇼핑 계속"])},
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저작권"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국가"])},
  "couponAndPromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 및 프로모션"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 만들기"])},
  "createANewList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 목록 만들기"])},
  "createAPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 만드십시오"])},
  "createNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호 만들기"])},
  "creditCardInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신용카드 입력"])},
  "creditDebitCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신용/직불 카드"])},
  "currentAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(현재)"])},
  "currentlySelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 선택됨"])},
  "currentSelections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 선택 항목"])},
  "currentSpecials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 특가"])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 정의"])},
  "customAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 정의 금액"])},
  "customerAssociation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 연결"])},
  "customerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 카드"])},
  "customerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 ID"])},
  "customerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 정보"])},
  "customerPrograms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 프로그램"])},
  "customPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 정의 가격"])},
  "datePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜 선택기"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일"])},
  "decreaseQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량 감소"])},
  "decrement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감소"])},
  "defaultToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["빠른 액세스를 위해 이 앱을 홈 화면에 설치하시겠습니까?"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
  "deleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카드 삭제"])},
  "deleteCardSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["마지막 숫자가 ", _interpolate(_named("finalDigits")), "인 카드가 성공적으로 삭제되었습니다."])},
  "deleteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록 삭제"])},
  "deleteWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑 삭제"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배달 주소"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세부정보"])},
  "deviceNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지원되지 않는 장치"])},
  "didntReceiveEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일을 받지 못하셨나요?"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고지 사항"])},
  "disclaimerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["당사는 제품 정보가 정확한지 확인하기 위해 노력하지만 때로는 제조업체에서 성분 목록을 변경할 수 있습니다. 실제 제품 포장 및 재료에는 당사 웹 사이트에 표시된 정보보다 더 많거나 다른 정보가 포함될 수 있습니다. 제공된 정보에만 의존하지 말고 제품을 사용하거나 소비하기 전에 항상 라벨, 경고 및 지침을 읽을 것을 권장 드립니다. 제품에 대한 상세 내용은 제조업체에 문의하십시오. 이 사이트의 콘텐츠는 참조용이며 의사, 약사 또는 기타 유면허 의료 전문가가 제공하는 조언을 대체할 수 없습니다. 이 정보는 자가 진단이나 건강 문제 또는 질병 치료에 사용해서는 안 됩니다. 의료적 문제가 있다고 의심될 경우, 즉시 의료 제공자에게 연락하십시오. 식이 보조제에 관한 정보 및 설명은 식품 의약청에서 평가하지 않았으며 질병이나 건강 상태를 진단, 치료, 진료 또는 예방하기 위한 것이 아닙니다."])},
  "discountAppliedInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에서 할인이 적용되었습니다."])},
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["할인"])},
  "dollarSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])},
  "doNotAllowSubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대체 허용 안 함"])},
  "downloadLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그 다운로드"])},
  "drag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["드래그"])},
  "dragContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["드래그하여 제품 컨테이너 확장"])},
  "eachAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개당"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집"])},
  "editItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목 편집"])},
  "editOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 편집"])},
  "editOrderError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실패! 해당 주문이 편집되지 않았습니다."])},
  "editOrderQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문을 편집하시겠습니까?"])},
  "editOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문을 편집할 준비가 되었습니다. 항목 추가 또는 제거가 완료되면 다시 결제를 진행하십시오."])},
  "editYourNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메모 편집"])},
  "eGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 상품권"])},
  "eGiftCardBuild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 상품권 만들기"])},
  "eGiftCardCartAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 상품권이 장바구니에 추가됨"])},
  "eGiftCardCartAddSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 상품권이 장바구니에 성공적으로 추가되었습니다"])},
  "eGiftCardCartError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목은 장바구니에 있는 전자 상품권과 별도로 구매해야 합니다. 장바구니에 항목을 추가하기 전에 전자 상품권을 제거하거나 구입하십시오."])},
  "eGiftCardFormMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 전자 상품권에 대한 메시지 추가(선택사항)"])},
  "eGiftCardModalAlertShoppingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 상품권을 구매하기 전에 쇼핑을 마치십시오."])},
  "eGiftCardModalAlertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 상품권은 장바구니 항목과 별도로 구매해야 합니다."])},
  "eGiftCardPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 상품권 정책 읽기"])},
  "eGiftCardPolicyHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 상품권 정책"])},
  "eGiftCardPurchaseAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 상품권은 장바구니에 있는 항목과 별도로 구매해야 합니다. 전자 상품권 만들기를 시작하기 전에 해당 항목을 제거하거나 구입하십시오."])},
  "eGiftCardShippingAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 상품권은 전자적으로(이메일 및/또는 문자로) 전달되거나 다운로드(인쇄용)됩니다. 선택하신 방법을 검토하려면 1단계를 참조하십시오."])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 주소"])},
  "emailInUseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["귀하의 이메일 주소는 이미 계정과 연결되었습니다."])},
  "emailInUseHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["귀하의 이메일 주소는 이미 사용 중입니다"])},
  "emailIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일은 필수 입력 항목입니다"])},
  "emailPhoneOrAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일, 전화 또는 계정 번호"])},
  "emailTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받는 사람"])},
  "employeeActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직원 조치"])},
  "employeeDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직원 대시보드"])},
  "employeeLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직원 로그인"])},
  "endingIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 글자"])},
  "enterAcceleratedCheckOutModeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 스캔 후 이동 모드에 있습니다"])},
  "enterInstructionsFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("itemName")), "에 대한 특별 지침을 입력하십시오"])},
  "enterTheWrongEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 주소를 잘못 입력하셨습니까?"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오류"])},
  "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오류(404)"])},
  "errorInAcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스캔 후 이동 모드 오류"])},
  "errorInCancelPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 취소 오류"])},
  "errorInPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 오류"])},
  "errorScanProductNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스캔된 제품 정보를 찾을 수 없습니다. 스토어 관리자에게 문의하십시오"])},
  "exitAcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스캔 후 이동 종료"])},
  "exitAcceleratedCheckOutModeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "exitAcceleratedCheckOutModeModalBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "exitAcceleratedCheckOutModeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스캔 후 이동 모드 종료"])},
  "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확장"])},
  "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만료"])},
  "expirationAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만료일"])},
  "expiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만료 예정"])},
  "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실패"])},
  "failedPasswordResetError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재설정 실패"])},
  "failedVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 실패"])},
  "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기"])},
  "featureNotAvailableForDesktopWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 기능은 데스크톱에서 사용할 수 없습니다"])},
  "featureNotAvailableForMobileWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 기능은 모바일에서 사용할 수 없습니다"])},
  "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["요금"])},
  "fieldIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필수 입력란입니다"])},
  "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터"])},
  "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["찾기"])},
  "findOurStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스토어 찾기"])},
  "findStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스토어를 찾으십시오"])},
  "firefoxAndroidToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["빠른 액세스를 위해 이 앱을 홈 화면에 설치하시겠습니까? - 홈 아이콘을 누르고 홈 화면에 추가를 누릅니다."])},
  "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첫 번째"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
  "firstNameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름은 필수 입력 항목입니다"])},
  "forgotYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 잊어 버리셨습니까?"])},
  "forText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([": ", _interpolate(_named("name"))])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보낸 사람"])},
  "getCodeViaEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일을 통해 코드를 받으십시오."])},
  "giftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상품권"])},
  "giftCardCartAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에 전자 상품권이 있습니다. 전자 상품권을 구입하거나 장바구니에서 제거하십시오."])},
  "giftCardDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 상품권 세부정보"])},
  "giftCardImgAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 상품권"])},
  "giftCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 상품권"])},
  "giftCardWithIdImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["전자 상품권 디자인 ", _interpolate(_named("id"))])},
  "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["돌아가기"])},
  "goGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 영수증으로 환경 보호"])},
  "goLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["왼쪽 이동"])},
  "goodThrough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효 기간"])},
  "goRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오른쪽 이동"])},
  "gotIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알겠습니다"])},
  "goToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체크아웃으로 이동"])},
  "goToHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈 스토어로 이동"])},
  "goToNextMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 달로 이동"])},
  "goToPreviousMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전 달로 이동"])},
  "goWithPaperless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 영수증으로 환경 보호"])},
  "grandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총계"])},
  "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어서 오십시오"])},
  "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정이 있습니까?"])},
  "haveCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰이 있습니까?"])},
  "healthAttributeImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["상태 속성 ", _interpolate(_named("attributeName"))])},
  "hidePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 숨기기"])},
  "homePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈 페이지"])},
  "homeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈 스토어"])},
  "homeStoreAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(홈 스토어)"])},
  "idRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "idRequiredMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "imHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현 고객 위치!"])},
  "importantCartMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에 담긴 상품에 대한 중요 메시지"])},
  "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치"])},
  "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포함"])},
  "increaseQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량 증가"])},
  "increment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["증가"])},
  "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재료"])},
  "ingredientsCap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재료"])},
  "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 중"])},
  "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "inStoreOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인스토어 전용"])},
  "inStoreOnlyAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 품목은 스토어에서만 구매할 수 있습니다"])},
  "invalidField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 필드"])},
  "inYourCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에"])},
  "iosShareMessagePart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["먼저"])},
  "iosShareMessagePart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유 아이콘을 누르고 '홈 화면에 추가'를 선택합니다."])},
  "iosToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 쇼핑하고 싶을 때 빨리 접속할 수 있도록 이 애플리케이션을 홈 화면에 설치하십시오."])},
  "isNowOutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 품절이라 장바구니에서 제거되었습니다."])},
  "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목"])},
  "itemAddedToSaveForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "itemDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목 세부정보"])},
  "itemNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목 메모"])},
  "itemProhibitAlternativeMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제거할 수 없습니다"])},
  "itemProhibited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "itemProhibitedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목"])},
  "itemTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목 총계"])},
  "itemUnavailableAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "은(는) 현재 사용할 수 없습니다"])},
  "itLooksPickedUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 이 주문을 수령하신 것 같습니다. 질문이나 우려 사항이 있으면 당사에 연락 바랍니다"])},
  "joinPromosAndRewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로모션 및 보상 프로그램에 동참하십시오"])},
  "kilometersAway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km 거리"])},
  "knowYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 알고 계십니까?"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
  "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성"])},
  "lastNameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성은 필수 입력 항목입니다"])},
  "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록"])},
  "listIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 목록은 비어 있습니다!"])},
  "listNamesCannotExceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록 이름은 20자를 초과할 수 없습니다"])},
  "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로딩 중"])},
  "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현지"])},
  "locallySourced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 품목은 현지에서 조달되었습니다"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
  "loginFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 실패"])},
  "loginFormSubheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 오신 것을 환영합니다! 더 빠른 결제를 위해 로그인하고 과거 주문 내역을 추적 및 조회할 수 있습니다."])},
  "loginFormSubheadingAcceleratedCheckOut": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ECRS에서 제공하는 ", _interpolate(_named("retailer")), "의 스캔 후 이동에 오신 것을 환영합니다"])},
  "loginId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 ID"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])},
  "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메인"])},
  "makeASelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택하기"])},
  "makeHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이것을 귀하의 홈 스토어로 만드십시오"])},
  "manageSavedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장된 쇼핑 목록 관리"])},
  "manageYourSavedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장된 목록은 여기서 관리하십시오"])},
  "memberID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원 ID"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지"])},
  "messageSentFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지가 전송되지 않았습니다"])},
  "messageSentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지가 성공적으로 전송되었습니다"])},
  "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중간 이름"])},
  "milesAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mi"])},
  "minimumSelectionSatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최소 선택 충족됨"])},
  "mobileAcceleratedCheckOutSessionInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
  "moreTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회 이상."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
  "nameYourList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록 이름 지정"])},
  "needsMinimumSelection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["최소 ", _interpolate(_named("min")), " 선택 필요"])},
  "needToStartOver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 시작해야 합니까?"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음"])},
  "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별칭"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아니요"])},
  "noCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아니요, 취소"])},
  "noCancelDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아니요, 삭제 취소"])},
  "noCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일에 안전하게 토큰화된 카드가 없습니다."])},
  "noContactInfoAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연락처 정보가 없습니다."])},
  "noCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 전자 쿠폰이 없습니다"])},
  "noItemsOnOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 주문에 대한 항목이 없습니다."])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["없음"])},
  "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금은 사용 가능한 주문이 없습니다."])},
  "noProductImageAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 제품 이미지를 사용할 수 없습니다"])},
  "noReservationTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 주문 유형에 대한 예약 시간이 없습니다."])},
  "noSavedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장된 카드 없음"])},
  "noShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쇼핑 목록을 찾을 수 없습니다"])},
  "noStoresFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색된 스토어가 없습니다."])},
  "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 불가"])},
  "notAvailableAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
  "notAvailableOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["온라인에서 사용 불가"])},
  "noteAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메모 추가됨"])},
  "notEntered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["입력된 ", _interpolate(_named("name")), " 없음"])},
  "notOnYourList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록에 없음"])},
  "notSignedUpYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 가입하지 않으셨나요?"])},
  "notYourHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["귀하의 홈 스토어가 아닙니다"])},
  "nutrition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영양"])},
  "nutritionFacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영양 정보"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/"])},
  "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "okayGotIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알겠습니다!"])},
  "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 비밀번호"])},
  "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["앗!"])},
  "operaBlockedVersionWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 앱은 Opera 버전 9 ~ 버전 12에서 지원되지 않습니다. 다른 브라우저를 사용해 주십시오."])},
  "operaMiniToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["빠른 액세스를 위해 이 앱을 홈 화면에 설치하시겠습니까? - 더하기 아이콘을 누르고 홈 화면에 추가를 누릅니다."])},
  "optInToAllowInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예정된 프로모션에 대한 정보를 보내고 쿠폰을 받는 등의 정보를 수신하기를 선택하십시오."])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["또는"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문"])},
  "orderCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 취소됨"])},
  "orderCancelledError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실패! 해당 주문은 취소되지 않았습니다."])},
  "orderCancelledSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["주문 ", _interpolate(_named("orderScanCode")), "이(가) 취소되었습니다."])},
  "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 세부정보"])},
  "orderHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 내역"])},
  "orderItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 항목"])},
  "orderNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 메모"])},
  "orderNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문이 유효하지 않습니다"])},
  "orderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 완료"])},
  "ordersInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 진행 중"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 상태"])},
  "orderTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 총계"])},
  "orderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 유형"])},
  "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["품절"])},
  "outOfStockAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "의 재고가 지금은 없습니다"])},
  "packOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팩:"])},
  "packSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팩 크기"])},
  "pageNavigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["페이지 탐색"])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["페이지"])},
  "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통과"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])},
  "passwordMustInclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호는 다음 글자를 포함해야 합니다"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호는 필수 입력 항목입니다"])},
  "passwordRequirementsNotMet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 요구 사항이 충족되지 않았음"])},
  "passwordResetError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공된 계정 세부정보를 토대로 신원을 확인할 수 없습니다."])},
  "passwordValidationCharLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8자 이상"])},
  "passwordValidationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나 이상의 숫자"])},
  "passwordValidationSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나 이상의 기호"])},
  "passwordValidationUpperCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나 이상의 대문자"])},
  "payLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중 결제"])},
  "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 수단"])},
  "payOnYourPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴대전화로 결제"])},
  "payTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 총액"])},
  "percentDailyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% 일일 섭취량*"])},
  "percentDV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% 일일 섭취량(DV)은 음식 1회분에 포함된 영양소가 일일 2,000칼로리의 일일 식단에 기여하는 정도를 말하며 일반 영양 권장량에 사용됩니다."])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호"])},
  "phoneNumberInUseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 전화번호는 이미 계정과 연결되어 있습니다."])},
  "phoneNumberInUseHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 전화번호는 이미 사용 중입니다."])},
  "phoneNumberIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호는 필수 입력 항목입니다"])},
  "phoneOrAppMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화 또는 앱이 없습니까?"])},
  "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문하기"])},
  "pleaseAllowCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카메라가 바코드를 스캔하도록 허용하십시오"])},
  "pleaseEnterValidAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입찰할 유효한 금액을 입력하십시오"])},
  "pleaseEnterYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["귀하의 이름을 입력하십시오 ", _interpolate(_named("name"))])},
  "pleaseRegisterAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 링크를 받으려면 다시 등록하십시오."])},
  "pleaseScanNextItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 항목을 스캔하십시오"])},
  "pleaseSelectAList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록을 선택하십시오"])},
  "pleaseSelectAtLeastOneDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송 방법을 하나 이상 선택하십시오"])},
  "pleaseSelectDelDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송 날짜와 시간을 선택하십시오"])},
  "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트"])},
  "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접두어"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리보기"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전"])},
  "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인쇄"])},
  "printReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영수증 인쇄"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품"])},
  "productDescImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 제품, 페이지 설명 참조"])},
  "productDetailsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상품 상세 페이지"])},
  "productImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("brandName")), " ", _interpolate(_named("productName")), " 제품"])},
  "productImgGalleryThumbnailAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 제품 갤러리 썸네일"])},
  "productInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품 정보"])},
  "productMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품. 방법"])},
  "productPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품 가격"])},
  "profileAddressSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소 정보"])},
  "profileEmailSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 정보"])},
  "profileOtherSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타 정보"])},
  "profilePersonalSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인 정보"])},
  "profilePhoneSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화 정보"])},
  "profileWorkSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직장 정보"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량"])},
  "quantityAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량"])},
  "quickLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["빠른 링크"])},
  "readyToRedeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용할 준비가 되었습니다!"])},
  "receiptLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영수증 로드 중..."])},
  "recipientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받는 사람 이름"])},
  "recipientsEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받는 사람의 이메일 주소"])},
  "recipientsPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받는 사람의 전화번호"])},
  "recommendComplexPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과거에 사용했던 비밀번호와 다른 복잡한 비밀번호를 만드는 것이 좋습니다."])},
  "redeemAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용처"])},
  "redirecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리디렉션 중..."])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록"])},
  "registerAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 등록"])},
  "remainingTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["남은 총계"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제거"])},
  "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 제거"])},
  "removeFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["즐겨찾기에서 ", _interpolate(_named("name")), " 제거"])},
  "removeFromCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에서 제거"])},
  "removeFromCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제에서 제거"])},
  "removeFromFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터에서 제거"])},
  "removeFromList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록에서 제거"])},
  "removeItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목 제거"])},
  "removeSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택 항목 제거"])},
  "removeTheItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목을 제거하십시오"])},
  "replicateNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 복제"])},
  "resendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 재전송"])},
  "resetMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재설정 방법"])},
  "resetNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 재설정"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재설정"])},
  "resetPasswordAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스토어에서 계정을 만든 경우, 처음 온라인 쇼핑을 한다면 비밀번호를 설정해야 할 수 있습니다."])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결과"])},
  "returnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈 화면으로 되돌아가기"])},
  "reviewYourItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목 검토"])},
  "reviewYourOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 검토"])},
  "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상"])},
  "runMobileAuthenticator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모바일 인증 앱을 실행하여 6자리 코드를 받으십시오"])},
  "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s"])},
  "salePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 가격"])},
  "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안녕하세요"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])},
  "saveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카드 저장"])},
  "savedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장된 카드"])},
  "savedForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중을 위해 저장됨"])},
  "saveForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중에 저장"])},
  "saveItemsForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중을 위해 항목 저장"])},
  "scanAnItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목 스캔"])},
  "scanBarcodeCustomerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 카드를 사용하려면 이 바코드를 스캔하십시오"])},
  "scanCustomerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 카드 스캔(인스토어)"])},
  "scanError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목을 찾을 수 없음"])},
  "scanItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목 스캔"])},
  "scanItemInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록에서 항목을 스캔하면 해당 항목을 확인해 드립니다"])},
  "scanNextItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 항목을 스캔하십시오"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])},
  "searchByZipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우편번호로 검색"])},
  "searchCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 검색..."])},
  "searchFilterCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 항목 검색"])},
  "searchProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로그램 검색"])},
  "searchResultsFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색 결과:"])},
  "securelyTokenizedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안전 토큰화 카드"])},
  "securityCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보안 코드"])},
  "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 보기"])},
  "seePriceAtCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체크아웃 시 가격 보기"])},
  "seePriceInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에 담긴 가격 보기"])},
  "selectADesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["디자인 선택"])},
  "selectAnAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금액 선택"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국가 선택"])},
  "selectDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["요일 선택"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택됨"])},
  "selectedGiftCardImgAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택된 전자 상품권"])},
  "selectedToPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인쇄 항목 선택됨"])},
  "selectHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 선택"])},
  "selectImHereButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스토어 방문 시 '현 고객 위치' 버튼을 선택하시면 주문하신 상품을 가져다 드리겠습니다."])},
  "selectMinutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분 선택"])},
  "selectOneGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소속되고 싶은 그룹을 단 1개만 선택하십시오."])},
  "selectOrderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 유형 선택"])},
  "selectPaymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목에 대한 결제 수단을 선택하십시오."])},
  "selectProductManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수동 제품 선택"])},
  "selectQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량 선택"])},
  "selectShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쇼핑 목록 선택"])},
  "selectState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주 선택"])},
  "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 선택"])},
  "sendDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발송일"])},
  "senderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보낸 사람 이름"])},
  "sendLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중에 보내기"])},
  "sendLaterDatePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중에 보내기 날짜 선택기"])},
  "sendLaterTimePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중에 보내기 시간 선택기"])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문자 보내기"])},
  "sendNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 보내기"])},
  "sendOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보내기 날짜"])},
  "sendUsAMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지 보내기"])},
  "servingSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인분"])},
  "servingsPerContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["컨테이너당 인분"])},
  "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 설정"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송"])},
  "shippingProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송업체"])},
  "shipTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송지"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쇼핑"])},
  "shopByCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리별 쇼핑"])},
  "shoppingAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쇼핑 장소"])},
  "shoppingCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니"])},
  "shoppingCartWithItemsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["장바구니 내 ", _interpolate(_named("count")), "개 품목"])},
  "shoppingLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쇼핑 목록"])},
  "shopStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 스토어에서 쇼핑하기"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시"])},
  "showHidePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 표시/숨기기"])},
  "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시 중"])},
  "showingOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 항목 표시"])},
  "showingResultsFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 결과 표시 중:"])},
  "showPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 표시"])},
  "sideMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사이드 메뉴"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["크기"])},
  "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소셜"])},
  "sorryCouponCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["죄송합니다. 입력하신 쿠폰 코드는 이 거래에 적용할 수 없습니다."])},
  "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정렬"])},
  "sortProductBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품 정렬 기준"])},
  "specialInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특별 지침"])},
  "specialPromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특별 프로모션"])},
  "splitTender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SplitTender"])},
  "stageCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단계 완료"])},
  "stageIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단계 미완료"])},
  "stageInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단계 진행 중"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태"])},
  "storeLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스토어 위치"])},
  "storeLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스토어 위치"])},
  "storeLogoAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스토어 로고"])},
  "storeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스토어 이름"])},
  "streetAddress1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
  "streetAddress2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아파트/스위트(선택사항)"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제출"])},
  "substitutionAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대체 허용됨"])},
  "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소계"])},
  "successOrderInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성공! 감사합니다. 해당 주문은 지금 진행 중입니다."])},
  "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접미어"])},
  "suggestionsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제안 목록"])},
  "suppressReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종이 영수증 끄기"])},
  "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세금"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문자"])},
  "textTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문자 수신처"])},
  "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감사합니다!"])},
  "thankYouForCreatingAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정을 만들어 주셔서 감사합니다! 이렇게 하면 더 빠른 결제 경험, 과거 주문 추적 및 보기 등을 마음껏 이용할 수 있습니다."])},
  "timePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 선택기"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["~"])},
  "toastInstallBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설치"])},
  "toastNotNowBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중에"])},
  "toastOkBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
  "toCap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받는 사람"])},
  "tokenServerUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보안 토큰 서버는 현재 사용할 수 없습니다. 나중에 다시 시도하십시오."])},
  "topResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상위 결과"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총계"])},
  "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추적 중"])},
  "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "twoFactorVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2단계 인증"])},
  "typeListName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형 목록 이름"])},
  "unassociate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 해제"])},
  "unassociateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성공적으로 연결 해제되었습니다"])},
  "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 불가"])},
  "upc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPC"])},
  "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 코드"])},
  "verificationLinkHasExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["죄송합니다. 이 인증 링크는 만료되었습니다"])},
  "verifyingYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 인증 중..."])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 보기"])},
  "viewAllItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 항목 보기"])},
  "viewEGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 상품권 보기"])},
  "viewManageList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록 보기/관리"])},
  "viewManageShoppingList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("shoppingListName")), " 목록 보기 또는 관리"])},
  "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 보기"])},
  "viewNextDateSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 다음 날짜 세트 보기"])},
  "viewNextTimeSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 다음 시간 세트 보기"])},
  "viewPrevDateSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 이전 날짜 세트 보기"])},
  "viewPrevTimeSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 이전 시간 세트 보기"])},
  "viewReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영수증 보기"])},
  "viewStoreLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스토어 위치 보기"])},
  "viewYour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보기:"])},
  "voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바우처"])},
  "waitItemsInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기다려주세요! 장바구니에 항목이 있습니다."])},
  "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑"])},
  "weCouldntFindWhatYoureLookingFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["찾고 계신 항목을 찾을 수 없습니다."])},
  "weHaveBeenNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["귀하가 여기 있다는 알림을 받았습니다."])},
  "weightedItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "weightedItemMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "weJustSentAVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방금 귀하의 이메일로 인증 링크를 보냈습니다"])},
  "weNeedToSendVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["귀하께 인증 코드를 보내야 합니다. 어떻게 받으시겠습니까?"])},
  "wereOnOurWay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보내는 중입니다"])},
  "whenFinishedEditCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 편집을 마치면 계속해서 다시 결제해야 합니다."])},
  "whenYouArrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도착 시"])},
  "willBeSentOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발송 예정일"])},
  "wouldYouLikeToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 항목을 편집하려는 주문에 추가하거나 나중을 위해 저장하시겠습니까?"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["년"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예"])},
  "yesContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예, 계속"])},
  "yesDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예, 삭제"])},
  "yesDeleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예, 별표(*)로 끝나는 카드 삭제"])},
  "youCanEither": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택 가능 작업"])},
  "youCanUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능 기능"])},
  "youCanUsePromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 이 프로모션을 사용하거나 나중을 위해 저장할 수 있습니다."])},
  "youHave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 상태"])},
  "youHaveAlreadyNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["귀하가 이곳에 있음을 이미 성공적으로 알리셨습니다. 비정상적으로 긴 지연이 발생할 경우 당사에 연락 바랍니다"])},
  "yourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 계정"])},
  "yourCartIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니가 비어 있습니다"])},
  "yourCompletedOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료된 주문"])},
  "yourCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["귀하의 전자 쿠폰"])},
  "yourCredentialsMatchMultipleAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 자격 증명이 여러 개의 계정과 일치합니다. 다음 이름으로 로그인할 계정을 선택하십시오"])},
  "yourCustomerDiscounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 할인"])},
  "yourID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 ID"])},
  "yourPunchCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ePunch 카드"])},
  "yourVerificationCodeSentShortly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공된 연락 방법을 사용하여 인증 코드가 곧 발송됩니다."])},
  "youTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우편번호"])},
  "zipCodeInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 우편번호"])},
  "zipCodeValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효한 우편번호"])}
}