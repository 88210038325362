<template>
  <div class="p-1">
    <div
      class="justify-content-between m-2 small"
      v-for="orderType in orderTypes"
      :key="orderType.id"
    >
      <span class="wc-ordertype-label w-100 pr-2">{{ orderType.name }}</span>
      <WCAddToCartQuantityAdjuster
        :item="item"
        :linkButtonType="linkButtonType"
        :describedBy="describedBy"
        :orderType="orderType"
        :showMobileCustomize="showMobileCustomize"
        :isListItem="isListItem"
        :variation="variation"
        :showStoreSelectModal="showStoreSelectModal"
        @addToCart="$emit('addToCart')"
        @decrement="$emit('decrement')"
      />
    </div>
  </div>
</template>

<script>
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import { defineAsyncComponent, defineComponent } from 'vue';

export default defineComponent({
  emits: ['addToCart', 'decrement'],
  name: 'WCOrderTypesAddToCartDropdown',
  props: {
    item: {
      type: Object,
      required: true,
    },
    orderTypes: {
      type: Array,
      required: true,
    },
    describedBy: {
      type: String,
    },
    linkButtonType: {
      type: Boolean,
      default: false,
    },
    showMobileCustomize: {
      type: Boolean,
    },
    variation: {},
    isListItem: {
      type: Boolean,
      default: false,
    },
    showStoreSelectModal: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    WCAddToCartQuantityAdjuster: defineAsyncComponent(() =>
      import(
        '@/modules/cart/components/WCAddToCartQuantityAdjuster/WCAddToCartQuantityAdjuster.vue'
      ),
    ),
  },
  mixins: [OrderTypesMixin],
  computed: {
    eligibleInactiveOrderTypes() {
      return this.eligibleInactiveOrderTypesForItem(this.item);
    },
  },
});
</script>

<style scoped>
.wc-ordertype-label {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
