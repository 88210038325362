
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import ModalService from '@/modules/modals/services/modal.service';
import WCItemModifierRoot from '@/modules/itemModifiers/components/WCItemModifierRoot/WCItemModifierRoot.vue';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { PlatformRenderer },
  name: 'WCCustomizeButton',
  methods: {
    async customize() {
      try {
        await ModalService.open(WCItemModifierRoot, {
          item: this.item,
          editingItem: false,
          orderType: this.orderType,
        });
      } catch (error) {
        console.log(error);
      } finally {
        if (this.$refs.customizeBtnRef) this.$refs.customizeBtnRef.focus();
      }
    },
    goToCustomizePage() {
      this.$router.push({
        name: 'Customize Your Item',
        params: { id: this.item.id, name: this.item.name },
      });
    },
  },
  mixins: [CartControlsMixin],
});
