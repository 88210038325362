<template>
  <div class="prohibit-alternative-msg text-danger text-center">
    <span class="ml-2">*</span>
    <span class="font-size-xs mx-1 text-left">{{ $t('itemProhibitAlternativeMsg') }}</span>
  </div>
</template>
<script>
export default {
  name: 'WCProhibitAlternativeMsg',
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

/*----- START : Prohibit Alternative Msg -----*/
.prohibit-alternative-msg {
  line-height: 1;
}
/*----- END : Prohibit Alternative Msg -----*/
</style>
