<template>
  <div class="w-100">
    <div v-if="!token" class="wc-ebtbalancecheck__cardentry">
      <h3 class="my-3">{{ $t('enterEbtInformation') }}</h3>
      <span class="mt-3">{{ $t('cardNumber') }}</span>
      <ifields
        :type="cardType"
        :account="account"
        :threeDS="threeDS"
        v-on:load="onFrameLoad"
        v-on:token="onTokenReceived"
        :options="options"
        @focus="focus"
        @blur="blur"
        @input="onUpdate"
        ref="ifieldRef"
        class="mt-1"
        id="cardNumberIfield"
      />
    </div>

    <p v-if="!isValid && !token">
      <font-awesome-icon icon="circle-exclamation" size="1x" class="mr-1 text-danger" />
      {{ $t('cardNumberIsNotValid') }}
    </p>

    <template v-if="submitText">
      <div v-if="message" class="my-3 text-center">{{ message }}</div>

      <div class="mx-4 wc-card-entry-text-sm">
        <span class="font-weight-bold">{{ $t('note') }} </span>
        <span data-testid="balanceDisclaimerOne">{{
          $t('balanceDisplayDisclaimerOne', { timeout: this.cachedBalanceTimeout })
        }}</span>
        <span><font-awesome-icon icon="circle-info" size="lg" class=" mx-1 text-primary"/></span>
        <span data-testid="balanceDisclaimerTwo">{{ $t('balanceDisplayDisclaimerTwo') }}</span>
      </div>

      <button
        :disabled="(!isValid || !ifieldToken) && !token"
        class="btn btn-primary w-100 mt-3"
        @click="inititate"
      >
        <span>{{ submitText }}</span>
      </button>
    </template>
  </div>
</template>

<script>
import SpinnerService from '@/services/spinner.service';
import ifields, { CARD_TYPE } from '@cardknox/vue-cardknox-ifields';
import InitiateError from '@/models/entities/initiate-error';
import { isTrimmedEmpty } from '@/utils/string-utils';
import axios from 'axios';
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['cardCaptured', 'error'],
  components: {
    ifields,
  },
  props: {
    submitText: {
      type: String,
    },
    message: {
      type: String,
    },
    token: {
      type: Object,
    },
  },
  data() {
    return {
      account: {
        xKey: this.$configuration.cardknoxEbtIfieldsKey,
        xSoftwareName: 'webcart',
        xSoftwareVersion: '5.6.92', // this needs to be dynamic
      },
      cardType: CARD_TYPE,
      ifieldToken: '',
      threeDS: {
        enable3DS: false,
      },
      options: {
        enableLogging: false,
        placeholder: 'Card Number',
        autoSubmit: true,
        autoFormat: true,
        autoFormatSeparator: ' ',
        iFieldstyle: {
          display: 'block',
          height: '2rem',
          color: '#495057',
          'font-size': '1rem',
          'font-weight': '100',
          'line-height': '1.5rem',
          width: '90%',
          'background-color': '#fff',
          border: '1px solid',
          borderRadius: '.25rem',
          'padding-left': '1rem',
          'margin-left': '0.2rem',
          'margin-top': '0.2rem',
          outline: '0',
        },
      },
      iframeSrc: '',
      CARDKNOX_URL: 'https://cdn.cardknox.com',
      isValid: false,
    };
  },
  created() {
    // Load the pinpad automatically if we have everything we need
    if (this.token && !this.submitText) {
      this.inititate();
    }
  },
  computed: {
    cachedBalanceTimeout() {
      return this.$configuration.ebtBalanceTimeout;
    },
  },
  methods: {
    onTokenReceived(tokenData) {
      this.ifieldToken = tokenData.data?.xToken;
    },
    getToken() {
      this.$refs.ifieldRef?.getToken();
    },
    focus() {
      this.$refs.ifieldRef?.focusIfield();
      this.editStyleOnIfield('cardNumberIfield', {
        'box-shadow': '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
      });
    },
    blur() {
      this.editStyleOnIfield('cardNumberIfield', { 'box-shadow': 'none' });
    },
    clear() {
      this.$refs.ifieldRef?.clearIfield();
    },
    editStyleOnIfield(id, style) {
      document.getElementById(id).contentWindow.postMessage(
        {
          action: 'style',
          data: style,
        },
        this.CARDKNOX_URL,
      );
    },
    onUpdate({ data }) {
      this.isValid = data.isValid;
    },
    onFrameLoad() {},
    async inititate() {
      SpinnerService.open();
      let initiateError = false;
      let initiateErrorMessage = null;
      const response = await axios
        .post(`api/ebt/initiate/`, {
          ebtToken: this.ifieldToken,
        })
        .catch(error => {
          console.error(error);
          this.ifieldToken = '';
          initiateError = true;
          initiateErrorMessage = error.response?.data?.message;
          if (isTrimmedEmpty(initiateErrorMessage)) {
            initiateErrorMessage = this.$t('defaultInitiateErrorMessage');
          }
        })
        .finally(() => {
          SpinnerService.close();
        });

      if (initiateError) {
        this.$emit('error', new InitiateError(initiateErrorMessage));
        return;
      }

      // first call complete, now I have the pin pad url, accuID and ref number
      const refNum = response.data?.primaryCorrelation;
      const accuId = response.data?.secondaryCorrelation;
      const multiUseToken = response.data?.card?.token;
      const iframeSrc = response.data?.fields?.pinPadUrl;
      const returnUrl = `${this.$configuration.siteUrl}/api/ebt/initiate/response/${accuId}`;
      const card = response.data?.card;

      // for testing locally, set the return URL to your address
      // this.returnURL = `http://192.168.254.200:8080/api/ebt/initiate/response/${accuId}`;

      this.$emit('cardCaptured', {
        card,
        accuId,
        returnUrl,
        iframeSrc,
        refNum,
        multiUseToken,
      });

      this.clear();
    },
  },
});
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';
iframe {
  border: 0;
  width: 100%;
  height: 4rem;
}

.wc-card-entry-text-sm {
  font-size: $font-size-sm;
}

.wc-card-entry-text-base {
  font-size: $font-size-base;
}
</style>
