<template>
  <WCCarousel class="wc-web-ads wc-carousel--negate-container position-relative" :isSlider="true">
    <Splide :options="primaryOptions" ref="primary">
      <slot></slot>
    </Splide>
    <Splide
      :options="secondaryOptions"
      class="wc-splide m-auto rounded"
      ref="secondary"
      :has-track="false"
    >
      <SplideTrack>
        <slot> </slot>
      </SplideTrack>
      <div class="splide__arrows">
        <button class="splide__arrow splide__arrow--prev splide-arrows wc-arrow-left-margin">
          <font-awesome-icon icon="arrow-right-long" size="xl" />
        </button>
        <button class="splide__arrow splide__arrow--next splide-arrows wc-arrow-right-margin">
          <font-awesome-icon icon="arrow-right-long" size="xl" />
        </button>
      </div>
      <div class="wc-autoplay-wrapper">
        <button class="splide__toggle wc-autoplay btn text-white" type="button">
          <span class="splide__toggle__pause">
            <font-awesome-icon
              icon="pause"
              data-testid="pauseButton"
              :aria-label="$t('pauseAutoplay')"
            />
          </span>
          <span class="splide__toggle__play">
            <font-awesome-icon
              icon="play"
              data-testid="playButton"
              :aria-label="$t('startAutoplay')"
            />
          </span>
        </button>
      </div>
    </Splide>
  </WCCarousel>
</template>

<script>
import { Splide, SplideTrack } from '@splidejs/vue-splide';
import { PlatformMixin } from '@/modules/platform';
import ElementSizeMixin from '@/mixins/ElementSizeMixin';
import WCCarousel from '../WCCarousel/WCCarousel.vue';

export default {
  name: 'WCAutoplayCarousel',
  components: { WCCarousel, Splide, SplideTrack },
  data() {
    return {
      primaryOptions: {
        perPage: 1,
        arrows: false,
        pauseOnHover: false, // Hover/focus conflict with play/pause button
        pauseOnFocus: false,
        rewind: true,
        preloadPages: 3,
        lazyLoad: 'nearby',
        gap: '0.2rem', // Without a gap, the next and previous image can bleed into the current slide.
        type: 'loop',
        keyboard: 'global',
        pagination: true,
        paginationKeyboard: true,
        paginationDirection: 'ltr',
      },
      secondaryOptions: {
        type: 'slide',
        rewind: true,
        gap: '0.2rem', // Without a gap, the next and previous image can bleed into the current slide.
        pagination: false,
        autoplay: true,
        fixedWidth: 1,
        fixedHeight: 1,
        focus: 'center',
        preloadPages: 3,
        lazyLoad: 'nearby',
        isNavigation: true,
        updateOnMove: true,
        pauseOnHover: false, // Hover/focus conflict with play/pause button
        pauseOnFocus: false,
      },
    };
  },
  mixins: [PlatformMixin, ElementSizeMixin],
  mounted() {
    const primary = this.$refs.primary;
    const secondary = this.$refs.secondary;

    primary.sync(secondary.splide);

    const secondaryTrack = secondary.splide.Components.Elements.track;
    secondaryTrack.classList.add('d-none');

    this.setArrowMargin();
    window.addEventListener('resize', () => {
      this.setArrowMargin();
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', () => {
      this.setArrowMargin();
    });
  },
  methods: {
    setArrowMargin() {
      const buffer = 45; // Buffer for space between arrows and pagination.
      const adWidth = this.getClientWidth();
      const paginationWidth = this.getWidthOfClass('splide__pagination');
      const widthOfArrow = this.getWidthOfClass('splide__arrow');

      const arrowMargin = (adWidth - 2 * widthOfArrow - paginationWidth - buffer) / 2;
      document.documentElement.style.setProperty('--arrow-margin', `${arrowMargin}px`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-splide {
  position: absolute;
  width: 100%;
  bottom: -0.3rem;
}

:deep(.splide__pagination__page) {
  width: rem(10px);
  height: rem(10px);
}

@include media-breakpoint-up(lg) {
  .wc-splide {
    width: 100%;
  }
}

@include media-breakpoint-down(sm) {
  .splide-arrows {
    display: none;
  }
  .wc-autoplay {
    font-size: 2rem !important;
  }
  :deep(.splide__pagination__page) {
    width: rem(15px) !important;
    height: rem(15px) !important;
    margin-left: rem(10px) !important;
    margin-right: rem(10px) !important;
  }
}

.wc-splide--pagination {
  font-size: $font-size-lg;
}

:deep(.splide__pagination) {
  flex-wrap: nowrap;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  bottom: 0.7em !important;
}

.wc-autoplay {
  float: right;
  position: relative;
  padding-right: 0.5rem;
  z-index: 2;
  font-size: 1.5rem;
  outline: 0 !important;
  box-shadow: none !important;
}

.wc-autoplay-wrapper {
  width: 100%;
}

.splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.splide-arrows {
  color: white;
  opacity: 100%;
}

:deep(.splide-arrows) {
  font-size: 1.5rem !important;
}

.wc-arrow-left-margin {
  margin-left: var(--arrow-margin);
}

.wc-arrow-right-margin {
  margin-right: var(--arrow-margin);
}

.splide__toggle__pause {
  padding-right: rem(4.2px);
}

:deep(.splide__pagination__page:focus-visible) {
  outline: none !important;
}

:deep(.splide.is-focus-in .splide__pagination__page:focus) {
  outline: none !important;
}

:deep(.splide__arrow:focus-visible) {
  outline: none !important;
}

:deep(.splide.is-focus-in .splide__arrow:focus) {
  outline: none !important;
}
</style>
