<template>
  <button
    type="button"
    class="close"
    @click="$emit('close')"
    data-dismiss="alert"
    :aria-label="$t('close')"
  >
    <!-- eslint-disable-next-line -->
    <span aria-hidden="true">&times;</span>
  </button>
</template>
<script>
export default {
  emits: ['close'],
};
</script>
