
import { mapGetters } from 'vuex';
import EbtBalanceCheckModal from '@/views/EbtBalanceCheck/EbtBalanceCheckModal.vue';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import WCToolTip from '@/components/WCToolTip/WCToolTip.vue';
import ModalService from '@/modules/modals/services/modal.service';
import ToastService from '@/modules/toasts/services/ToastService';
import { PlatformMixin } from '@/modules/platform';
import EbtBalanceCacheMixin from '@/modules/cart/mixins/EbtBalanceCacheMixin';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';

export default {
  name: 'WCCartDrawerEbtBalance',
  mixins: [EbtBalanceCacheMixin, PlatformMixin],
  components: { WCSpinner, WCToolTip },
  props: {
    walletEbtTokens: {
      type: Array,
    },
    ebtPaymentMethod: {
      type: Object,
    },
    loadingWallet: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loadingBalance: false as Boolean,
      displayBalances: false as Boolean,
    };
  },
  async mounted(): Promise<void> {
    this.displayBalances = this.isEbtCacheValid;
  },
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest',
      getEbtBalanceTimeStamp: 'cart/getEbtBalanceTimeStamp',
    }),
    hasEbtTokenInWallet(): Boolean {
      return this.walletEbtTokens?.length > 0;
    },
    showSpinner(): Boolean {
      return this.loadingBalance || this.loadingWallet;
    },
    iconSize(): String {
      if (this.isMobile) {
        return 'xs';
      }
      return '1x';
    },
    isSnapOrCashUnavaliable() {
      return this.getEbtCashCachedBalance == null || this.getEbtSnapCachedBalance == null;
    },
  },
  methods: {
    closeBalance(): void {
      this.invalidateEbtBalanceCache();
      this.displayBalances = false;
    },
    toggleDisplayBalances(): void {
      this.displayBalances = true;
      if (!this.isEbtCacheValid) {
        this.displayBalanceCheckModal();
      }
    },
    async displayBalanceCheckModal(): Promise<void> {
      try {
        this.loadingBalance = true;
        await this.openBalanceCheckModal();
        this.loadingBalance = false;
      } catch (error) {
        this.loadingBalance = false;
        this.displayBalances = false;
        console.error(error);
        if (error !== 'Dismissed') {
          this.openBalanceErrorToast();
        }
      }
    },
    async openBalanceCheckModal(): Promise<void> {
      const token = this.walletEbtTokens && this.walletEbtTokens[0];
      await ModalService.open(EbtBalanceCheckModal, {
        paymentMethod: this.ebtPaymentMethod,
        token,
        hideBalanceDisplay: true,
      });
    },
    openBalanceErrorToast(): void {
      ToastService.open(WCSimpleToast, {
        props: {
          variant: 'danger',
          title: this.$t('error'),
          message: this.$t('balanceCheckFailure'),
        },
        timeout: 7000,
        actions: undefined,
        skipHash: false,
      });
    },
  },
};
