import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "font-size-xl font-weight-bold" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "font-size-lg" }
const _hoisted_4 = {
  key: 0,
  class: "font-size-md",
  "data-testid": "postSummaryLabel"
}
const _hoisted_5 = {
  class: "font-weight-bold",
  "data-testid": "refundAmountLabel"
}
const _hoisted_6 = {
  class: "font-weight-bold",
  "data-testid": "refundAmount"
}
const _hoisted_7 = { "data-testid": "remainingBalanceLabel" }
const _hoisted_8 = {
  class: "font-weight-bold",
  "data-testid": "remainingBalance"
}
const _hoisted_9 = {
  key: 0,
  class: "mx-3 mt-2"
}
const _hoisted_10 = { class: "font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_WCModalHeader = _resolveComponent("WCModalHeader")!
  const _component_WCModalBody = _resolveComponent("WCModalBody")!
  const _component_WCModalFooter = _resolveComponent("WCModalFooter")!
  const _component_WCModal = _resolveComponent("WCModal")!

  return (_openBlock(), _createBlock(_component_WCModal, {
    position: "center",
    onDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dismiss(_ctx.MODAL_EVENTS.DISMISS)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_WCModalHeader, { class: "align-items-baseline" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.headerIcon)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  class: "mr-2",
                  icon: _ctx.headerIcon,
                  "data-testid": "headerIconLabel"
                }, null, 8, ["icon"]))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
          ]),
          _createTextVNode(),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dismiss(_ctx.MODAL_EVENTS.DISMISS))),
            class: "btn btn-link underline-link-from-center"
          }, _toDisplayString(_ctx.$t('close')), 1)
        ]),
        _: 1
      }),
      _createTextVNode(),
      _createVNode(_component_WCModalBody, null, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_font_awesome_icon, {
                icon: _ctx.summaryIcon,
                size: "3x",
                class: _normalizeClass(["w-100 mb-3", _ctx.iconColor]),
                "data-testid": "summaryIconLabel"
              }, null, 8, ["icon", "class"]),
              _createTextVNode(),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.summary), 1),
              _createTextVNode(),
              (_ctx.postSummary)
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.postSummary), 1))
                : _createCommentVNode("", true),
              _createTextVNode(),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ebtAuthList, (auth, authIndex) => {
                return (_openBlock(), _createElementBlock("div", { key: authIndex }, [
                  _createElementVNode("p", _hoisted_5, [
                    _createTextVNode(_toDisplayString(_ctx.$t('ebtAmountRefundedToCard', { ending: auth.lastFour })) + " ", 1),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$filters.currency(auth.tenderAmount)), 1)
                  ]),
                  _createTextVNode(),
                  _createElementVNode("p", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_ctx.$t('ebtRemainingBalance', { type: _ctx.ebtType(auth) })) + " ", 1),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$filters.currency(auth.remainingBalance)), 1)
                  ])
                ]))
              }), 128))
            ]),
            _createTextVNode(),
            (_ctx.endingNote)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('note')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.endingNote), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createTextVNode(),
      _createVNode(_component_WCModalFooter, null, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close(_ctx.MODAL_EVENTS.CONFIRM))),
            class: "btn btn-primary m-auto w-100"
          }, _toDisplayString(_ctx.$t('ok')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}