<template>
  <select v-bind="$attrs" class="custom-select" :class="classes" v-model="_model" v-on="listeners">
    <slot></slot>
  </select>
</template>

<script>
export default {
  name: 'WCSelect',
  emits: ['update:modelValue'],
  inject: {
    field: { default: undefined },
  },
  props: {
    modelValue: {},
    size: {
      type: String,
      validator: value => ['small', 'default', 'large'].includes(value),
      default: 'default',
    },
    state: {
      type: String,
      validator: value => ['valid', 'invalid'].includes(value),
      default: null,
    },
  },
  computed: {
    listeners() {
      let listeners = {};
      Object.keys(this.$attrs).forEach(key => {
        if (key.startsWith('on') && key !== 'onInput') {
          listeners = { ...listeners, [key]: this.$attrs[key] };
        }
      });
      return listeners;
    },
    _model: {
      get() {
        return this.field ? this.field.$model : this.modelValue;
      },
      set(value) {
        if (this.field) {
          this.field.$model = value;
        }

        this.$emit('update:modelValue', value);
      },
    },
    _state() {
      if (this.field && this.field.$error) {
        return 'invalid';
      }

      return this.state;
    },
    classes() {
      const classes = [];

      switch (this.size) {
        case 'small':
          classes.push('custom-select-sm');
          break;
        case 'large':
          classes.push('custom-select-lg');
          break;
        default:
          break;
      }

      switch (this._state) {
        case 'valid':
          classes.push('is-valid');
          break;
        case 'invalid':
          classes.push('is-invalid');
          break;
        default:
          break;
      }

      return classes;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid var(--dark, $dark);
}
.custom-select:focus {
  border: 1px solid var(--dark, $dark);
}
</style>
