/**
 * PlatformRenderer accepts two named slots: `onMobile` and `onDesktop`.
 * When on a mobile device, onMobile will be rendered.
 * When on a desktop device, onDesktop will be rendered.
 *
 * Note: Vue 3 supports multi-root components, however, this component presently will only render the first root passed into it. This is due
 * to changes from Vue 2 to Vue 3 in relation to parent components passing attrs (and CSS classes) to the child component.
 * We can eventually support multi-root components here, but we will need to somehow define which root should inherit the attrs from the parent.
 */
import { Comment, Text, VNode } from 'vue';

export default {
  name: 'PlatformRenderer',
  render() {
    return this.platformInfo.isMobile ? this.mobileSlots[0] : this.desktopSlots[0];
  },
  computed: {
    mobileSlots() {
      return this.$slots.onMobile()?.filter((vNode: VNode) => this.vNodeHasContent(vNode));
    },
    desktopSlots() {
      return this.$slots.onDesktop()?.filter((vNode: VNode) => this.vNodeHasContent(vNode));
    },
  },
  methods: {
    // Vue 3 puts comments in the template into the array of slots, which we don't want
    vNodeHasContent(vNode: VNode | undefined): boolean {
      if (!vNode) return false;

      if (vNode.type === Comment) return false;

      if (Array.isArray(vNode.children) && !vNode.children.length) return false;

      return (
        vNode.type !== Text || (typeof vNode.children === 'string' && vNode.children.trim() !== '')
      );
    },
  },
  inject: {
    /* Provided by PlatformProvider */
    platformInfo: 'platformInfo',
  },
};
