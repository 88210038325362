<template>
  <button
    class="font-size-xl ml-3 p-0 unstyled-btn"
    @click.stop="showMenu"
    :aria-label="$t('sideMenu')"
    data-testid="side-menu"
  >
    <font-awesome-icon icon="bars" color="var(--primary)" />
  </button>
</template>

<script>
export default {
  emits: ['showMenu'],
  methods: {
    showMenu() {
      this.$emit('showMenu');
    },
  },
};
</script>
