<template>
  <li v-if="!group.children || !group.children.length" class="nav-item" :key="group.id">
    <a class="nav-link h4 text-primary-contrast mb-0" href="#" @click.prevent="select(group.id)">
      {{ group.name }}
    </a>
  </li>
  <li v-else>
    <WCCustomSelect
      :availableOptions="constructedOptions"
      :name="group.name"
      :uniqueIdentifier="group.name"
      :unusableHeight="wcHeaderHeight"
      @selectedOption="onSelectOption"
      btnClass="text-primary-contrast"
      :dropdownLabel="$t('chooseACategoryMenu')"
    />
  </li>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import WCCustomSelect from '@/modules/forms/components/WCCustomSelect/WCCustomSelect.vue';

export default {
  emits: ['selected'],
  components: { WCCustomSelect },
  name: 'WCItemGroupDropdown',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    showViewAll: {
      type: Boolean,
      default: true,
    },
    wcHeaderHeight: {
      required: false,
      type: String,
      default: '0px',
    },
  },
  methods: {
    /**
     * Method invoked on selecting option and based on viewAllOption, the argument of select function varies
     */
    onSelectOption(option) {
      if (option.viewAllOption) {
        this.select(this.group);
      } else {
        this.select(option);
      }
    },
    select(group) {
      // Condition used to set Browse page URL and breadcrumbs correctly
      // This will always work as long as only going down 1 child layer
      if (this.group.id !== group.id) {
        this.$emit('selected', [this.group.id, group.id]);
      } else {
        this.$emit('selected', group.id);
      }
    },
  },
  computed: {
    /**
     * Construct options to be displayed in dropdown
     */
    constructedOptions() {
      if (this.showViewAll)
        return [
          ...this.group.children,
          { id: 'viewAll', name: this.$t('viewAll'), isBold: true, viewAllOption: true },
        ];
      return this.group.children;
    },
  },
};
</script>
