export default {
  "AcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スキャン・アンド・ゴーモード"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント"])},
  "accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント番号"])},
  "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント設定"])},
  "addAsFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "をお気に入りとして追加"])},
  "addCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンを追加する"])},
  "addedSugars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加糖"])},
  "addItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテムを追加する"])},
  "addItemsToOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文にアイテムを追加する"])},
  "addItemToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リストにアイテムを追加する"])},
  "addItemToListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアイテムをリストに追加する"])},
  "addNewCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいカードを追加する"])},
  "addNewList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいリストを追加する"])},
  "addNoteAboutItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアイテムに関するメモを追加する"])},
  "addPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い方法を追加する"])},
  "addSelectedToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択したものをカートに追加する"])},
  "addSelectedToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "addSpecialInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別な指示を追加する"])},
  "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートに追加する"])},
  "addToFavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お気に入りに追加する"])},
  "addToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リストに追加する"])},
  "addWalletCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "addWalletCardFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "addWalletCardSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アラート"])},
  "alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別名"])},
  "allCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのカテゴリ"])},
  "allOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての注文"])},
  "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["許可する"])},
  "allowCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カメラを許可する"])},
  "allowSubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["置換を許可する"])},
  "allRightsReserved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("year")), " WebCart - 無断複製禁止"])},
  "alreadyVerifiedYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すでにアカウントを確認されましたか？"])},
  "alternateItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代替アイテム"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["額"])},
  "amountMustBeAtLeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["額は少なくとも次以下でなければなりません"])},
  "amountMustBeLessOrEqual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["額は次以下でなければなりません"])},
  "amountPerServing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1食分あたりの金額"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用する"])},
  "applyChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を適用する"])},
  "areYouSureCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更をキャンセルしてもよろしいですか？"])},
  "areYouSureCancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文をキャンセルしてもよろしいですか？"])},
  "areYouSureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*で終わるカードを削除してもよろしいですか"])},
  "associate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アソシエート"])},
  "associateAsCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客としてのアソシエート"])},
  "associationBanner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["WebCartを", _interpolate(_named("name")), "として使用するカスタマーアソシエーションモードは、"])},
  "assumesNoLiability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品に関する不正確さや虚偽表示について責任を負いません。"])},
  "asYouScanItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リストからアイテムをスキャンすると、次の場所で"])},
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテムがチェックされます"])},
  "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入手可能"])},
  "availableBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用可能残高"])},
  "averagePerUnitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単価あたりの平均"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残高"])},
  "barcodeAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バーコード"])},
  "barcodePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このバーコードを表示して、レジスタでスキャンします"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日"])},
  "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランド"])},
  "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パン粉"])},
  "browseByCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ別に閲覧する"])},
  "browseQueryText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("browseText")), "\""])},
  "browseResultQuery": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("browseResult")), "\""])},
  "buyItAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もう一度購入する"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
  "callForPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格確認のために電話する"])},
  "callUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お電話ください"])},
  "calories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カロリー"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
  "cancelEditOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文の編集をキャンセルする"])},
  "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文をキャンセルする"])},
  "cancelOrderFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文のキャンセルで問題が発生しました。ページを更新して、もう一度お試しください。"])},
  "cancelOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文はキャンセルされました。"])},
  "cancelQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルしますか？"])},
  "cannotAddItemToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートにアイテムを追加できません"])},
  "cardEnteredInvalidInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したカード番号が無効または非アクティブです。番号を確認して、もう一度入力してください。"])},
  "cardholderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカード名義人氏名"])},
  "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード番号"])},
  "cardNumberIsNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード番号が無効です"])},
  "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カート"])},
  "cashierApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "cashierApprovalMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ"])},
  "categoriesMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリメニュー"])},
  "categoryLimitExceededMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), "カテゴリで", _interpolate(_named("limit")), "を超えるものを選択することはできません。"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを変更する"])},
  "changeYourMind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["気が変わりましたか？"])},
  "changeYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを変更する"])},
  "chargeAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを請求する"])},
  "checkCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デビットカード"])},
  "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックアウト"])},
  "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックアウト"])},
  "checkShoppingAtCorrectStore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["あなたは", _interpolate(_named("storeName")), "でショッピング中です。これが注文したいストアであることを確認してください。"])},
  "checkTheBoxForReceipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックボックスをオンにすると、メールで店舗での購入の領収書を受け取ることができます。"])},
  "checkTheGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所属したいグループを確認してください。"])},
  "checkYourBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残高を確認する"])},
  "choose": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("max")), "を選択する"])},
  "chooseACategoryMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリメニューを選択する"])},
  "chooseAList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リストを選択する"])},
  "chooseAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを選択する"])},
  "chooseAtLeast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["少なくとも", _interpolate(_named("min")), "を選択してください"])},
  "chooseBetween": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("min")), "と", _interpolate(_named("max")), "の間を選択してください"])},
  "chooseDataAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付と時刻を選択する"])},
  "chooseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付を選択する"])},
  "chooseDateAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付と時刻を選択する"])},
  "chooseLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語を選択する"])},
  "chooseQuantityFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の数量を選択してください"])},
  "chooseTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間を選択する"])},
  "chooseUpTo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("max")), "まで選択する"])},
  "chooseYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), "を選択する"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリアする"])},
  "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてをクリアする"])},
  "clearAllText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["それらを完全に削除しますか？"])},
  "clearCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートをクリアする"])},
  "clearCartFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートをクリアできませんでした"])},
  "clearCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートが正常にクリアされました"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターをクリアする"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターをクリアする"])},
  "clearOrSaveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらのアイテムを後で使用するために保存しますか、それとも完全に削除しますか？"])},
  "clickHereToRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録するにはここをクリックしてください"])},
  "clickHereToReregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再登録するには、ここをクリックしてください"])},
  "clickHereToSendAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他に送信するには、ここをクリックしてください"])},
  "clickHereToSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインインするには、ここをクリックしてください"])},
  "clickTheVerificationLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール内の認証リンクをクリックして、アカウントの登録を完了してください。"])},
  "clickToAddNotesAndSubstitutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリックしてメモと置換を追加する"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉じる"])},
  "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折りたたむ"])},
  "commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コマンド"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社"])},
  "completeAtCashRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レジで完了する"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了しました"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続行する"])},
  "continueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ショッピングを続ける"])},
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["著作権"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国"])},
  "couponAndPromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンおよびプロモーション"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを作成する"])},
  "createANewList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいリストを作成する"])},
  "createAPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを作成する"])},
  "createNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードを作成する"])},
  "creditCardInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカード入力"])},
  "creditDebitCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジット/デビットカード"])},
  "currentAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（現在）"])},
  "currentlySelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在選択中"])},
  "currentSelections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在の選択"])},
  "currentSpecials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のスペシャル"])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタム"])},
  "customAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタム金額"])},
  "customerAssociation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーアソシエーション"])},
  "customerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーカード"])},
  "customerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーID"])},
  "customerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマー情報"])},
  "customerPrograms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマープログラム"])},
  "customPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマー価格"])},
  "datePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付ピッカー"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
  "decreaseQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量を減らす"])},
  "decrement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デクリメント"])},
  "defaultToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すばやくアクセスできるように、このアプリをホーム画面にインストールしますか？"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除する"])},
  "deleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カードを削除する"])},
  "deleteCardSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["カード番号が", _interpolate(_named("finalDigits")), "で終わるカードが正常に削除されました"])},
  "deleteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リストを削除する"])},
  "deleteWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォレットを削除する"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送先住所"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])},
  "deviceNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デバイスがサポートされていません"])},
  "didntReceiveEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールが届きませんでしたか？"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免責事項"])},
  "disclaimerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品情報が正しいことを確認するよう努めていますが、メーカーが成分リストを変更する場合があります。実際の商品のパッケージおよび材料には、当社のウェブサイトに表示されている情報よりも多くの情報や異なる情報が含まれている場合があります。提示された情報だけに頼るのではなく、商品を使用または消費する前に、常にラベル、警告、および指示に目を通すことをお勧めします。商品の詳細については、製造元にお問い合わせください。このサイトのコンテンツは参照を目的としたものであり、医師、薬剤師、またはその他の認可された医療専門家によるアドバイスに代替するものではありません。この情報を自己診断として、または健康上の問題や病気の治療に使用しないでください。医学的な問題があると思われる場合は、すぐに医療供給者に問い合わせてください。栄養補助食品に関する情報と声明は、食品医薬品局によって評価されておらず、病気や健康状態を診断、治療、治癒、または予防することを目的としていません。"])},
  "discountAppliedInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートに割引が適用されています。"])},
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引"])},
  "dollarSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
  "doNotAllowSubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["置換を許可しないでください"])},
  "downloadLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログをダウンロードする"])},
  "drag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドラッグ"])},
  "dragContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドラッグして商品コンテナを展開する"])},
  "eachAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集する"])},
  "editItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテムを編集する"])},
  "editOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文を編集する"])},
  "editOrderError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失敗しました！ご注文は編集されていません。"])},
  "editOrderQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文を編集しますか？"])},
  "editOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文は編集の準備ができています。アイテムの追加または削除が完了したら、もう一度チェックアウトに進んでください"])},
  "editYourNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メモを編集する"])},
  "eGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eギフトカード"])},
  "eGiftCardBuild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eギフトカードを作成する"])},
  "eGiftCardCartAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートにEギフトカードが追加されました"])},
  "eGiftCardCartAddSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eギフトカードがカートに正常に追加されました"])},
  "eGiftCardCartError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カート内のEギフトカードとは別に商品を購入する必要があります。カートにアイテムを追加する前に、Eギフトカードを削除または購入してください。"])},
  "eGiftCardFormMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このEギフトカードに関するメッセージを追加する（オプション）"])},
  "eGiftCardModalAlertShoppingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eギフトカードを購入する前にショッピングを終了してください。"])},
  "eGiftCardModalAlertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eギフトカードはカート内のアイテムとは別に購入する必要があります"])},
  "eGiftCardPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eギフトカードポリシーを読む"])},
  "eGiftCardPolicyHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eギフトカードポリシー"])},
  "eGiftCardPurchaseAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eギフトカードは、カート内のアイテムとは別に購入する必要があります。Eギフトカードの作成を開始する前に、これらのアイテムを削除または購入してください。"])},
  "eGiftCardShippingAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eギフトカードは、電子的に配信されるか（電子メールおよび/またはショートメッセージ）ダウンロード（印刷用）可能です。選択した方法を確認するには、ステップ1を参照してください"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメール"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールアドレス"])},
  "emailInUseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのEメールアドレスはすでにアカウントに関連付けられています。"])},
  "emailInUseHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのEメールアドレスはすでに使用されています"])},
  "emailIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールが必要です"])},
  "emailPhoneOrAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメール、電話、またはアカウント番号"])},
  "emailTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信先Eメール"])},
  "employeeActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["従業員の行動"])},
  "employeeDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["従業員ダッシュボード"])},
  "employeeLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["従業員のログイン"])},
  "endingIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["で終わります"])},
  "enterAcceleratedCheckOutModeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在、スキャン・アンド・ゴーモードになっています"])},
  "enterInstructionsFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("itemName")), "のために特別な指示を入力してください"])},
  "enterTheWrongEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["間違ったメールアドレスを入力していますか？"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])},
  "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー(404)"])},
  "errorInAcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スキャン・アンド・ゴーモードでエラーが発生しました"])},
  "errorInCancelPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いのキャンセルの際エラーが発生しました"])},
  "errorInPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いエラー"])},
  "errorScanProductNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スキャンした商品情報が見つかりません。ストアマネージャーにお問い合わせください"])},
  "exitAcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スキャン・アンド・ゴーを終了する"])},
  "exitAcceleratedCheckOutModeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スキャン・アンド・ゴーモードを終了しました"])},
  "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拡大する"])},
  "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限"])},
  "expirationAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限"])},
  "expiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期限切れ"])},
  "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失敗しました"])},
  "failedPasswordResetError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードのリセットに失敗しました"])},
  "failedVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証に失敗しました"])},
  "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お気に入り"])},
  "featureNotAvailableForDesktopWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この機能はデスクトップでは使用できません"])},
  "featureNotAvailableForMobileWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この機能はモバイルでは使用できません"])},
  "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手数料"])},
  "fieldIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィールドは必須項目です"])},
  "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター"])},
  "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探す"])},
  "findOurStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店頭を探す"])},
  "findStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店頭を探す"])},
  "firefoxAndroidToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すばやくアクセスできるように、このアプリをホーム画面にインストールしますか？ -ホームアイコンをタップしてから、ホーム画面に追加します。"])},
  "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
  "firstNameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名が必要です"])},
  "forgotYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをお忘れですか？"])},
  "forText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "用"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["より"])},
  "getCodeViaEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールでコードを取得します。"])},
  "giftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ギフトカード"])},
  "giftCardCartAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートにEギフトカードが入っています。Eギフトカードを購入するか、カートから削除してください。"])},
  "giftCardDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eギフトカードの詳細"])},
  "giftCardImgAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eギフトカード"])},
  "giftCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eギフトカード"])},
  "giftCardWithIdImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eギフトカードのデザイン ", _interpolate(_named("id"))])},
  "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
  "goGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペーパーレスの領収書で環境に配慮"])},
  "goLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左へ"])},
  "goodThrough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限"])},
  "goRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["右へ"])},
  "gotIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解しました"])},
  "goToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックアウトする"])},
  "goToHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホームストアに移動する"])},
  "goToNextMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の月に移動する"])},
  "goToPreviousMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前の月に移動する"])},
  "goWithPaperless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペーパーレスの領収書で環境に配慮"])},
  "grandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総計"])},
  "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いらっしゃいませ"])},
  "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントをお持ちですか？"])},
  "haveCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンをお持ちですか？"])},
  "healthAttributeImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ヘルス属性 ", _interpolate(_named("attributeName"))])},
  "hidePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを隠す"])},
  "homePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホームページ"])},
  "homeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホームストア"])},
  "homeStoreAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ホームストア)"])},
  "idRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "idRequiredMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "imHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここです！"])},
  "importantCartMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カート内のアイテムに関する重要なメッセージ"])},
  "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ"])},
  "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["含む"])},
  "increaseQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量を増やす"])},
  "increment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インクリメント"])},
  "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成分"])},
  "ingredientsCap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成分"])},
  "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中"])},
  "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "inStoreOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店頭のみ"])},
  "inStoreOnlyAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "は店頭でのみ購入できます"])},
  "invalidField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効なフィールド"])},
  "inYourCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カート内"])},
  "iosShareMessagePart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有アイコンをタップし、"])},
  "iosShareMessagePart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「ホーム画面に追加」を選択します。"])},
  "iosToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアプリケーションをホーム画面にインストールすると、もう一度お買い物をされたいときにすばやくアクセスできます。"])},
  "isNowOutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在在庫がなく、カートから削除されています"])},
  "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテム"])},
  "itemDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテムの詳細"])},
  "itemNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテムメモ"])},
  "itemProhibitAlternativeMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これは削除できません"])},
  "itemProhibited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "itemProhibitedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテム"])},
  "itemTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテム合計"])},
  "itemUnavailableAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "は現在ご利用いただけません"])},
  "itLooksPickedUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すでにこの注文を受け取っているようです。ご質問やご不明な点がございましたら、お電話ください"])},
  "joinPromosAndRewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロモーションとリワードプログラムに参加する"])},
  "kilometersAway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km離れています"])},
  "knowYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをご存知ですか？"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
  "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["氏"])},
  "lastNameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["氏が必要です"])},
  "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リスト"])},
  "listIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このリストは空です！"])},
  "listNamesCannotExceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リスト名は20文字を超えることはできません"])},
  "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リスト"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中"])},
  "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ローカル"])},
  "locallySourced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアイテムはローカルで調達されています"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["場所"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
  "loginFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインに失敗しました"])},
  "loginFormSubheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["おかえりなさいませ！サインインしてチェックアウトをスムーズに行い、過去の注文を追跡および表示できるようにします。"])},
  "loginFormSubheadingAcceleratedCheckOut": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ECRSによる、", _interpolate(_named("retailer")), "のスキャン・アンド・ゴーへようこそ"])},
  "loginId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインID"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])},
  "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メイン"])},
  "makeASelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択する"])},
  "makeHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これをあなたのホームストアにしてください"])},
  "manageSavedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存したショッピングリストを管理する"])},
  "manageYourSavedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここで保存したリストを管理します"])},
  "memberID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバーID"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージ"])},
  "messageSentFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージが送信されていません"])},
  "messageSentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージは正常に送信されました"])},
  "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミドルネーム"])},
  "milesAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mi"])},
  "minimumSelectionSatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低限の選択がなされました"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "moreTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複数回。"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
  "nameYourList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リストに名前を付ける"])},
  "needsMinimumSelection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最低でも", _interpolate(_named("min")), "を選択する必要があります"])},
  "needToStartOver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初からやり直す必要がありますか？"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワード"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
  "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネーム"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いいえ"])},
  "noCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いいえ、キャンセルします"])},
  "noCancelDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いいえ、削除をキャンセルします"])},
  "noCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全にトークン化されたカードがファイルにありません。"])},
  "noContactInfoAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先情報はありません。"])},
  "noCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用可能なeクーポンがありません"])},
  "noItemsOnOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この注文にはアイテムがありませんでした。"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["なし"])},
  "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在利用可能な注文はありません。"])},
  "noProductImageAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "の商品画像はありません"])},
  "noReservationTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この注文タイプの予約時間はありません。"])},
  "noSavedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存されたカードはありません"])},
  "noShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ショッピングリストが見つかりません"])},
  "noStoresFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗が見つかりません。"])},
  "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用不可"])},
  "notAvailableAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
  "notAvailableOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンラインでは利用できません"])},
  "noteAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メモを追加"])},
  "notEntered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "が入力されていません"])},
  "notOnYourList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのリストにありません"])},
  "notSignedUpYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだサインアップされていませんか？"])},
  "notYourHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これはあなたのホームストアではありません"])},
  "nutrition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["栄養"])},
  "nutritionFacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["栄養成分表"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["うち"])},
  "okayGotIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい、了解しました！"])},
  "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前のパスワード"])},
  "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["おっと！"])},
  "operaBlockedVersionWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアプリは、Operaバージョン9からバージョン12ではサポートされていません。他のブラウザをご利用ください。"])},
  "operaMiniToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すばやくアクセスできるように、このアプリをホーム画面にインストールしますか？ -プラスアイコンをタップしてから、ホーム画面に追加します。"])},
  "optInToAllowInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプトインすると、今後のプロモーションに関する情報の送信、クーポンの受け取りなどが可能になります。"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["または"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文する"])},
  "orderCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文をキャンセルしました"])},
  "orderCancelledError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失敗しました！ご注文はキャンセルされませんでした。"])},
  "orderCancelledSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ご注文", _interpolate(_named("orderScanCode")), "はキャンセルされました。"])},
  "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文詳細"])},
  "orderHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文履歴"])},
  "orderItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文アイテム"])},
  "orderNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文メモ"])},
  "orderNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文が無効です"])},
  "orderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文済み"])},
  "ordersInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中の注文"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文の状況"])},
  "orderTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文合計"])},
  "orderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文タイプ"])},
  "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在庫切れ"])},
  "outOfStockAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "は現在在庫がありません"])},
  "packOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パック"])},
  "packSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パックサイズ"])},
  "pageNavigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページナビゲーション"])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページ"])},
  "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パス"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
  "passwordMustInclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードには次のものが含まれている必要があります"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードが必要です"])},
  "passwordRequirementsNotMet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード要件が満たされていません"])},
  "passwordResetError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供されたアカウントの詳細に基づいてIDを検証できませんでした。"])},
  "passwordValidationCharLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低8文字"])},
  "passwordValidationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低１数字"])},
  "passwordValidationSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低でも記号1つ"])},
  "passwordValidationUpperCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低でも大文字1つ"])},
  "payAtRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "payLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後払い"])},
  "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払方法"])},
  "payOnYourPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お使いの携帯電話で支払う"])},
  "payTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計を支払う"])},
  "percentDailyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["％1日摂取量*"])},
  "percentDV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["％1日摂取量（DV）は、1食分の栄養素が1日の食事にどの程度貢献しているかを示します。一般的な栄養アドバイスには1日2,000カロリーが使用されます。"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
  "phoneNumberInUseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの電話番号はすでにアカウントに関連付けられています。"])},
  "phoneNumberInUseHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの電話番号はすでに使用されています"])},
  "phoneNumberIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号が必要です"])},
  "phoneOrAppMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話またはアプリがありませんか？"])},
  "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文する"])},
  "pleaseAllowCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カメラがバーコードをスキャンできるようにしてください"])},
  "pleaseEnterValidAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入札に有効な金額を入力してください"])},
  "pleaseEnterYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "を入力してください"])},
  "pleaseRegisterAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいリンクを取得するには、もう一度登録してください。"])},
  "pleaseScanNextItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のアイテムをスキャンしてください"])},
  "pleaseSelectAList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リストを選択してください"])},
  "pleaseSelectAtLeastOneDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送方法を少なくとも1つ選択してください"])},
  "pleaseSelectDelDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配達日時を選択してください"])},
  "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイント"])},
  "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["敬称"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビュー"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前"])},
  "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライマリ"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["印刷"])},
  "printReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領収書を印刷する"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品"])},
  "productDescImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 商品、ページの説明を参照"])},
  "productDetailsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品詳細ページ"])},
  "productImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("brandName")), " ", _interpolate(_named("productName")), " 商品"])},
  "productImgGalleryThumbnailAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 商品ギャラリーのサムネイル"])},
  "productInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品情報"])},
  "productMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品。方法"])},
  "productPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品価格"])},
  "profileAddressSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所情報"])},
  "profileEmailSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメール情報"])},
  "profileOtherSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他の情報"])},
  "profilePersonalSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報"])},
  "profilePhoneSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話情報"])},
  "profileWorkSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["勤務先情報"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
  "quantityAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量・個数"])},
  "quickLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイックリンク"])},
  "readyToRedeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き換える準備ができました！"])},
  "receiptLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領収書を読み込んでいます..."])},
  "recipientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受取人の名前"])},
  "recipientsEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受取人のEメールアドレス"])},
  "recipientsPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受取人の電話番号"])},
  "recommendComplexPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去に使用したパスワードとは異なる複雑なパスワードを作成することをお勧めします。"])},
  "redeemAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き換える場所"])},
  "redirecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リダイレクト..."])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地域"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録する"])},
  "registerAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再度登録する"])},
  "remainingTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残りの合計"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除する"])},
  "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて削除する"])},
  "removeCartAcceleratedCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "removeCartAcceleratedCheckOutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "removeFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["お気に入りから", _interpolate(_named("name")), "を削除する"])},
  "removeFromCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートから削除する"])},
  "removeFromCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックアウトから削除する"])},
  "removeFromFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターから削除する"])},
  "removeFromList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リストから削除する"])},
  "removeGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "removeGiftCardCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "removeItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテムを削除する"])},
  "removeSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択されたものを削除する"])},
  "removeTheItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテムを削除する"])},
  "replicateNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐ複製する"])},
  "resendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールを再び送信する"])},
  "resetMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リセットの方法"])},
  "resetNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐリセットする"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをリセットする"])},
  "resetPasswordAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストアでアカウントを作成した場合、初めてオンラインでショッピングするためにパスワードを設定する必要がある場合があります"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結果"])},
  "returnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホームに戻る"])},
  "reviewYourItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテムを確認する"])},
  "reviewYourOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文を確認する"])},
  "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リワード"])},
  "runMobileAuthenticator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モバイル認証システムアプリを実行して、6桁のコードを取得します"])},
  "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s"])},
  "salePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売価格"])},
  "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["こんにちは"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存する"])},
  "saveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カードを保存する"])},
  "savedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存されたカード"])},
  "savedForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今後のために保存されました"])},
  "saveForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今後のために保存する"])},
  "saveItemsForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後のためにアイテムを保存する"])},
  "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "scanAnItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテムをスキャンする"])},
  "scanBarcodeCustomerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このバーコードをスキャンして、カスタマーカードを使用します"])},
  "scanCustomerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーカードをスキャンする（店頭）"])},
  "scanError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテムが見つかりません"])},
  "scanItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテムをスキャンする"])},
  "scanItemInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リストからアイテムをスキャンすると、次の場所で"])},
  "scanNextItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のアイテムをスキャンしてください"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索する"])},
  "searchByZipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号で検索する"])},
  "searchCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーを検索する..."])},
  "searchFilterCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリアイテムを検索する"])},
  "searchProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索プログラム"])},
  "searchResultsFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索結果"])},
  "securelyTokenizedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全にトークン化されたカード"])},
  "securityCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セキュリティコード"])},
  "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続きを見る"])},
  "seePriceAtCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックアウト時の価格を見る"])},
  "seePriceInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カート内で価格を見る"])},
  "selectADesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザインを選択する"])},
  "selectAnAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量を選択する"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国を選択する"])},
  "selectDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日を選択する"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択済み"])},
  "selectedGiftCardImgAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択したEギフトカード"])},
  "selectedToPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["印刷するように選択されました"])},
  "selectHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間を選択する"])},
  "selectImHereButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お店に到着したら「ここです」ボタンを選択してください。ご注文をお届けします。"])},
  "selectMinutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分を選択する"])},
  "selectOneGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所属するグループを1つだけ選択してください。"])},
  "selectOrderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文タイプを選択する"])},
  "selectPaymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテムの支払い方法を選択する"])},
  "selectProductManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品を手動で選択する"])},
  "selectQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量を選択する"])},
  "selectShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ショッピングリストを選択する"])},
  "selectState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["州を選択する"])},
  "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間を選択する"])},
  "sendDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信日"])},
  "senderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信者名"])},
  "sendLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後で送信する"])},
  "sendLaterDatePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後で日付ピッカーを送信する"])},
  "sendLaterTimePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後でタイムピッカーを送信する"])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージを送信する"])},
  "sendNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐ送信する"])},
  "sendOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信"])},
  "sendUsAMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私たちにメッセージを送ってください"])},
  "servingSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1食分"])},
  "servingsPerContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コンテナあたりの人前"])},
  "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを設定する"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送"])},
  "shippingProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送業者"])},
  "shipTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送先"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店"])},
  "shopByCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ別にショッピングする"])},
  "shoppingAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ショッピングの場所"])},
  "shoppingCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ショッピングカート"])},
  "shoppingCartWithItemsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "個のアイテムが入ったショッピングカート"])},
  "shoppingLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ショッピングリスト"])},
  "shopStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この店舗でお買い物をする"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示する"])},
  "showHidePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの表示/非表示"])},
  "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示"])},
  "showingOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文の表示"])},
  "showingResultsFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索結果"])},
  "showPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを表示する"])},
  "sideMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイドメニュー"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインアウト"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイズ"])},
  "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソーシャル"])},
  "sorryCouponCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し訳ありませんが、入力したクーポンコードはこのトランザクションには適用できません。"])},
  "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソート"])},
  "sortProductBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品を並べ替える"])},
  "specialInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別な指示"])},
  "specialPromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別なプロモーション"])},
  "splitTender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SplitTender"])},
  "stageCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステージが完了しました"])},
  "stageIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステージが完了していません"])},
  "stageInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステージが進行中です"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["州"])},
  "storeLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗の場所"])},
  "storeLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗の場所"])},
  "storeLogoAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗のロゴ"])},
  "storeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗の名前"])},
  "streetAddress1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
  "streetAddress2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アパート番号 / 部屋番号（オプション）"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["件名"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信"])},
  "substitutionAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代替が許可されました"])},
  "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小計"])},
  "successOrderInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功！ありがとうございます。ご注文は現在進行中です。"])},
  "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サフィックス"])},
  "suggestionsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提案リスト"])},
  "suppressReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紙の領収書をオフにする"])},
  "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ショートメッセージ"])},
  "textTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ショートメッセージ宛先"])},
  "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ありがとうございます！"])},
  "thankYouForCreatingAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを作成していただきありがとうございます！そうすることで、より迅速なチェックアウト体験、過去の注文を追跡および表示することができます。"])},
  "timePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイムピッカー"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトル"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["から"])},
  "toastInstallBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インストール"])},
  "toastNotNowBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留"])},
  "toastOkBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "toCap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宛先"])},
  "tokenServerUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セキュアトークンサーバーは現在利用できません。後でもう一度やり直してください。"])},
  "topResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トップ結果"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
  "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追跡"])},
  "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "twoFactorVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二要素認証"])},
  "typeListName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リスト名を入力する"])},
  "unassociate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連付けを解除"])},
  "unassociateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連付けが解除されました"])},
  "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用不可"])},
  "upc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPC"])},
  "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コード"])},
  "verificationLinkHasExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し訳ありませんが、この認証リンクの有効期限が切れています"])},
  "verifyingYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを確認しています..."])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて見る"])},
  "viewAllItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのアイテムを表示する"])},
  "viewEGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eギフトカードを表示する"])},
  "viewItemList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "viewManageList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リストの表示/管理"])},
  "viewManageShoppingList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("shoppingListName")), "リストを表示または管理する"])},
  "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もっと見る"])},
  "viewNextDateSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の利用可能な日付の組み合わせを表示する"])},
  "viewNextTimeSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の利用可能な時間の組み合わせを表示する"])},
  "viewPrevDateSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前の利用可能な日付の組み合わせを表示する"])},
  "viewPrevTimeSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前の利用可能な時間の組み合わせを表示する"])},
  "viewReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領収書を表示する"])},
  "viewStoreLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗の場所を表示する"])},
  "viewYour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示する"])},
  "voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バウチャー"])},
  "waitItemsInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待ってください！カート内にアイテムがあります。"])},
  "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォレット"])},
  "weCouldntFindWhatYoureLookingFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探しているものが見つかりませんでした。"])},
  "weHaveBeenNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたがここにいることが通知されました。"])},
  "weJustSentAVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールへの認証リンクを送信しました"])},
  "weNeedToSendVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードをお送りする必要があります。どのように受け取りたいですか？"])},
  "wereOnOurWay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中です"])},
  "whenFinishedEditCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文の編集が終了したら、再度チェックアウトする必要があります"])},
  "whenYouArrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたが到着した際"])},
  "willBeSentOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信されます"])},
  "wouldYouLikeToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらのアイテムをまさに今編集しようとしている注文に追加しますか、それとも後のために保存しますか？"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい"])},
  "yesContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい、続行します"])},
  "yesDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい、削除します"])},
  "yesDeleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい、*で終わるカードを削除します"])},
  "youCanEither": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは次のどちらかを行うことができます"])},
  "youCanUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは次を使用することができます"])},
  "youCanUsePromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらのプロモーションを今すぐ使用することも、後のために保存することもできます。"])},
  "youHave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは持っています"])},
  "youHaveAlreadyNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたはすでにあなたがここにいることを私たちに首尾よく通知しました。異常に長い遅延が発生している場合は、お電話ください"])},
  "yourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのアカウント"])},
  "yourCartIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのカートは空です"])},
  "yourCompletedOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了したご注文"])},
  "yourCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのeクーポン"])},
  "yourCredentialsMatchMultipleAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのクレデンシャルは複数のアカウントと一致します。ログインするアカウントを選択してください"])},
  "yourCustomerDiscounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのカスタマー割引"])},
  "yourID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのID"])},
  "yourPunchCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのePunchカード"])},
  "yourVerificationCodeSentShortly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認コードは、提供されている連絡方法を使用してまもなく送信されます。"])},
  "youTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
  "zipCodeInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効な郵便番号"])},
  "zipCodeValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効な郵便番号"])}
}