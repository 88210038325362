<template>
  <div class="dropdown nav-item" v-click-outside="hideDropdown">
    <!-- START : Selected Dropdown Qty Value Display -->
    <span class="sr-only" :id="`${itemName}-quantity-label`">
      {{ $t('chooseQuantityFor') }} {{ name }}
    </span>
    <button
      class="btn btn-outline-primary btn-sm btn-block nav-link dropdown-toggle dropdownMenuButton"
      data-toggle="dropdown"
      :disabled="disabled"
      :id="`${itemName}-dropdown`"
      @click="toggleDropdown"
      @keydown.esc.exact.stop="hideDropdown"
      @keydown.shift.tab="hideDropdown"
      @keydown.up.exact.prevent="initialFocusWithArrowKeys"
      @keydown.down.exact.prevent="initialFocusWithArrowKeys"
      @keydown.tab.exact="focusedIndex = 0"
      aria-haspopup="listbox"
      :aria-labelledby="`${itemName}-quantity-label ${itemName}-dropdown`"
      :aria-expanded="showDropdown ? 'true' : 'false'"
    >
      {{ isSelected ? currentSelectedQty && currentSelectedQty.name : $t('none') }}
    </button>
    <!-- END : Selected Dropdown Qty Value Display -->
    <!-- START : Available Quantity Option  -->
    <platform-renderer>
      <template v-slot:onDesktop>
        <div
          ref="dropdownMenus"
          class="dropdown-menu"
          v-if="showDropdown"
          :class="{ show: showDropdown }"
          role="listbox"
          :aria-labelledby="`${itemName}-quantity-label`"
          :aria-activedescendant="focusedIndex >= 0 ? 'selected-quantity-option' : null"
        >
          <button
            v-for="(quantity, index) in availableQuantities"
            :key="quantity.id"
            :value="currentSelectedQty"
            :class="{ selected: currentSelectedQty && currentSelectedQty.id == quantity.id }"
            :id="focusedIndex === index ? 'selected-quantity-option' : null"
            :aria-selected="true && currentSelectedQty && currentSelectedQty.id == quantity.id"
            class="dropdown-item btn-sm"
            @click="setSelectedQuantity(quantity)"
            @keydown.esc.exact.stop="hideDropdown"
            @keydown.tab.exact="
              index === availableQuantities.length - 1 ? hideDropdown() : focusNext(false)
            "
            @keydown.shift.tab.exact="index === 0 ? (focusedIndex = -1) : focusPrevious(false)"
            @keydown.down.exact.prevent="
              index === availableQuantities.length - 1 ? '' : focusNext(true)
            "
            @keydown.up.exact.prevent="index === 0 ? '' : focusPrevious(true)"
            role="option"
            :data-testid="`${quantity.id}-dropdownButton`"
          >
            {{ quantity.name }}
            {{ quantity.quantity > 1 ? 'x ' + quantity.quantity : '' }}
            <span> ({{ $filters.currency(quantity.price) }})</span>
          </button>
        </div>
      </template>
      <template v-slot:onMobile>
        <div
          ref="dropdownMenus"
          class="dropdown-menu"
          v-if="showDropdown"
          :class="{ show: showDropdown }"
          role="listbox"
          :aria-labelledby="`${itemName}-quantity-label`"
          :aria-activedescendant="focusedIndex !== 0 ? 'selected-quantity-option' : null"
        >
          <!-- START : None Option -->
          <button
            :class="{ selected: !isSelected }"
            class="dropdown-item btn-sm"
            @click="deselectItem()"
            @keydown.up.exact.prevent=""
            @keydown.tab.exact="focusNext(false)"
            @keydown.down.exact.prevent="focusNext(true)"
            @keydown.esc.exact="hideDropdown"
          >
            {{ customOptionsTop[0] }}
          </button>
          <!-- END : None Option -->
          <!-- START : Available Dropdown Options of the Item -->
          <button
            v-for="(quantity, index) in availableQuantities"
            :key="quantity.id"
            :value="quantity"
            :class="{
              selected: isSelected && currentSelectedQty && currentSelectedQty.id == quantity.id,
            }"
            :id="focusedIndex === index ? 'selected-quantity-option' : null"
            :aria-selected="true && currentSelectedQty && currentSelectedQty.id == quantity.id"
            class="dropdown-item btn-sm"
            @click="setSelectedQuantity(quantity)"
            @keydown.esc.exact.stop="hideDropdown"
            @keydown.tab.exact="
              focusedIndex === dropdownMenusLength ? hideDropdown() : focusNext(false)
            "
            @keydown.shift.tab.exact="focusPrevious(false)"
            @keydown.down.exact.prevent="
              focusedIndex === dropdownMenusLength ? '' : focusNext(true)
            "
            @keydown.up.exact.prevent="
              index === 0 - customOptionsTop.length ? '' : focusPrevious(true)
            "
            role="option"
            :data-testid="`${quantity.id}-dropdownButton`"
          >
            {{ quantity.name }} {{ quantity.quantity > 1 ? 'x ' + quantity.quantity : '' }}
            <span> ({{ $filters.currency(quantity.price) }})</span>
          </button>
          <!-- END : Available Dropdown Options of the Item -->
        </div>
      </template>
    </platform-renderer>
    <!-- END : Available Quantities Dropdown  -->
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';

export default {
  name: 'WCItemModifierQuantityDropdown',
  components: {
    PlatformRenderer,
  },
  props: {
    currentSelectedQty: {
      type: Object,
      default: null,
    },
    availableQuantities: {
      type: Array,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
  },
  emits: ['deselectItem', 'selectQty'],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showDropdown: false,
      focusedIndex: -1,
      customOptionsTop: [this.$t('none')],
    };
  },
  computed: {
    /**
     * Item name property
     */
    itemName() {
      return this.name.replace(/\s/g, '-').toLowerCase();
    },
    /**
     * Length of item quantity dropdown for mobile
     */
    dropdownMenusLength() {
      return this.availableQuantities.length + this.customOptionsTop.length - 1;
    },
  },
  methods: {
    /**
     * Method to toggle dropdown options menu
     */
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    /**
     * Method to hide dropdown options menu
     */
    hideDropdown() {
      this.focusedIndex = -1;
      this.showDropdown = false;
    },

    /**
     * Method to deselect the item and emit the state to parent
     */
    deselectItem() {
      this.$emit('deselectItem');
      this.hideDropdown();
    },

    /**
     * Method to set selected quantity for the item and emit the state to parent
     */
    setSelectedQuantity(quantity) {
      this.$emit('selectQty', quantity);
      this.hideDropdown();
    },

    /**
     * Method to start initial focus with arrow keys for keyboard accessibility
     */
    initialFocusWithArrowKeys() {
      if (
        this.showDropdown &&
        this.$refs.dropdownMenus?.children &&
        this.$refs.dropdownMenus?.children.length
      ) {
        this.focusedIndex = 0;
        this.$refs.dropdownMenus.children[0].focus();
      }
    },

    /**
     * Method to focus previous item with up or shift+tab keys
     */
    focusPrevious(needFocus) {
      this.focusedIndex -= 1;
      if (needFocus) this.focusItem();
    },

    /**
     * Method to focus next item with down or tab key
     */
    focusNext(needFocus) {
      this.focusedIndex += 1;
      if (needFocus) this.focusItem();
    },

    /**
     * Method to focus item
     */
    focusItem() {
      if (this.$refs.dropdownMenus?.children && this.$refs.dropdownMenus?.children.length)
        this.$refs.dropdownMenus.children[this.focusedIndex].focus();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

/*----- START : Btn Styles -----*/
.btn {
  background-color: var(--white, $white);
}
.btn-outline-primary:hover {
  background-color: var(--primary, $primary);
}
/*----- END : Btn Styles -----*/

/*----- START : Dropdown Item -----*/
.dropdown-item.selected,
.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--secondary-lighter-7, get-color('secondary', 'lighter-7'));
  color: var(--secondary-contrast, get-color-contrast('secondary'));
}
.dropdown-item.selected {
  background-color: var(--secondary, get-color('secondary'));
}
/*----- END : Dropdown Item -----*/
</style>
