<template>
  <WCSpinner v-if="loadingWallet" />
  <div v-else class="wc-cartdrawer__totalwrapper">
    <span class="wc-cartdrawer__subtotal font-weight-bold">
      {{ $t('subtotal') }}:
      <div
        v-if="totalSavings"
        :ref="`totalSavings__${cart.orderType}`"
        class="wc-cartdrawer__discountSubtotal"
      >
        <s class="font-weight-normal">{{ $filters.currency(cart.rawSubTotal) }} </s>
        <span>
          {{ $filters.currency(cart.rawSubTotal + totalSavings) }}
        </span>
        <WCToolTip :target="`totalSavings__${cart.orderType}`" position="bottom">
          {{ getToolTipText }}
        </WCToolTip>
        <font-awesome-icon icon="circle-question" />
      </div>
      <template v-else>
        {{ $filters.currency(cart.rawSubTotal) }}
      </template>
    </span>

    <span
      v-if="displayableEbtSnapEligibleTotal"
      class="wc-cartdrawer__ebtsnaptotal"
      :class="{ 'wc-cart-drawer__singleebt': !displayableEbtTanfEligibleTotal }"
    >
      {{ $t('ebtSnap') }} {{ $t('total') }}:
      <div>{{ $filters.currency(displayableEbtSnapEligibleTotal) }}</div>
    </span>
    <span
      v-if="displayableEbtTanfEligibleTotal"
      class="wc-cartdrawer__ebtcashtotal"
      :class="{ 'wc-cart-drawer__singleebt': !displayableEbtSnapEligibleTotal }"
    >
      {{ $t('ebtCash') }} {{ $t('total') }}:
      <div>{{ $filters.currency(displayableEbtTanfEligibleTotal) }}</div>
    </span>
  </div>
</template>

<script>
import WCToolTip from '@/components/WCToolTip/WCToolTip.vue';
import CartTotalMixin from '@/modules/cart/mixins/CartTotalMixin';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import { mapGetters } from 'vuex';
import BigNumber from 'bignumber.js';

export default {
  components: {
    WCToolTip,
    WCSpinner,
  },
  props: {
    cart: {
      type: Object,
    },
    walletEbtTokens: {
      type: Array,
    },
    loadingWallet: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [CartTotalMixin],
  computed: {
    ...mapGetters({
      paymentMethods: 'cart/getPaymentMethods',
      isGuest: 'user/isGuest',
    }),
    getToolTipText() {
      return this.$t('discountIncludedAbove');
    },
    lineItems() {
      return this.cart?.lineItems;
    },
    hasEbtTokenInWallet() {
      return this.walletEbtTokens?.length;
    },
    displayableEbtSnapEligibleTotal() {
      if (!this.hasEbtTokenInWallet) {
        return 0;
      }

      return this.cart.ebtSnapEligibleTotal;
    },
    displayableEbtTanfEligibleTotal() {
      if (!this.hasEbtTokenInWallet) {
        return 0;
      }

      return BigNumber.min(this.cart.rawSubTotal, this.cart.ebtTanfEligibleTotal)?.toNumber();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

@include media-breakpoint-up(lg) {
  .wc-cartdrawer__totalwrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .wc-cartdrawer__subtotal {
    display: flex;
  }

  .wc-cartdrawer__ebtsnaptotal {
    display: flex;
  }

  .wc-cartdrawer__ebtcashtotal {
    display: flex;
  }
}

@include media-breakpoint-down(md) {
  .wc-cartdrawer__totalwrapper {
    display: grid;
    grid-column: span 2;
    text-align: center;
  }

  .wc-cartdrawer--ordertype {
    display: grid;
    row-gap: 0.5rem;
  }

  .wc-cartdrawer--ordertype__checkout {
    grid-column: span 2;
    width: 100%;
  }

  .wc-cartdrawer__subtotal {
    grid-column: span 2;
  }

  .wc-cart-drawer__singleebt {
    grid-column: span 2;
  }
}
</style>
