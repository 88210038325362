export default class CardToken {
  authFk: string;

  brand: string;

  defaultToken: boolean;

  expiration: string;

  finalDigits: string;

  tenderType: number;
}
