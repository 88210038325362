import axios from 'axios';
import debounce from 'lodash/debounce';
import { v4 as uuid } from 'uuid';
import AnalyticsService from '@/services/analytics.service';
import TrackableEventConstants from '@/constants/TrackableEventConstants';
import ModalService from '@/modules/modals/services/modal.service';
import ToastService from '@/modules/toasts/services/ToastService';
import CartDrawerService from '@/modules/cart/services/CartDrawerService';
import WCEditExpiredModal from '@/modules/cart/components/WCEditExpiredModal/WCEditExpiredModal.vue';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import { isTipOptionIdCustomOrNone } from '@/modules/checkout/components/WCTipSelect/WCTipSelect.vue';
import { ALERT_CODE } from '@/constants/ApiConstants';
import WCEbtRefundsModal, {
  REFUND_VARIANT,
} from '@/components/WCEbtOrderCancelModal/WCEbtRefundsModal.vue';

const CART_DEBOUNCE_TIMER = 500;
const TLI_PAIDIN_TYPE = 28;

const fetchCarts = async () => {
  const carts = await axios.get('api/ot/carts');
  if (carts && carts.data) {
    return carts.data;
  }

  return null;
};

const getTipItemsDto = cart => {
  if (cart.lineItems) {
    return cart.lineItems
      .filter(li => li.type === TLI_PAIDIN_TYPE && li.tipProfileId)
      .map(li => {
        return {
          ...li,
          tipOptionId: isTipOptionIdCustomOrNone(li.tipOptionId) ? null : li.tipOptionId,
          tipAmount: li.tipAmount ? li.tipAmount : 0,
        };
      });
  }
  return null;
};

const getRoundUpItemDto = cart => {
  if (cart.roundUpTotal) {
    const roundUpLineItems = cart.lineItems.filter(
      li => li.type === TLI_PAIDIN_TYPE && li.roundUpId,
    );
    return { ...roundUpLineItems[0] };
  }
  return null;
};

const cartDtoFromCart = (cart, checkout) => {
  if (!cart) return null;

  const items = cart.lineItems
    ? cart.lineItems
        .filter(lineItem => lineItem.item)
        .map(lineItem => {
          return {
            embeddedPrice: lineItem.embeddedPrice,
            originalPrice: lineItem.originalPrice,
            embeddedDiscount: lineItem.embeddedDiscount,
            expiration: lineItem.expiration,
            id: lineItem.id,
            item: lineItem.item.id,
            variation: lineItem.variation,
            quantity: lineItem.quantity,
            substitutable: lineItem.substitutable,
            instructions: lineItem.instructions,
            weightedItemQuantity: lineItem.weightedItemQuantity,
          };
        })
    : [];
  if (getTipItemsDto(cart)) {
    items.push(...getTipItemsDto(cart));
  }
  if (cart?.roundUpTotal > 0) {
    items.push(getRoundUpItemDto(cart));
  }

  const giftCardItems = cart.giftCardItems
    ? cart.giftCardItems.map(lineItem => ({ ...lineItem, design: lineItem.design.id }))
    : [];

  const specialOrderItems = cart.specialOrderItems;

  return {
    transaction: cart.id,
    instructions: cart.instructions,
    items,
    coupons: cart.coupons,
    giftCardItems,
    deferrableCombos: cart.deferrableCombos,
    inStore: cart.inStore,
    specialOrderItems,
    checkout: checkout || cart.checkout,
    orderTypeId: cart.orderType,
    deliveryTotal: cart.deliveryTotal,
  };
};

const setCartInCarts = (state, cart) => {
  if (!cart || !cart.orderType) {
    return;
  }
  const targetOrderType = cart.orderType;
  state.carts = [...state.carts.filter(c => c.orderType !== targetOrderType), cart];
  state.nonEmptyCarts = state.carts?.filter(c => c.lineItems?.length || cart.giftCardItems?.length);
};

const updateLineItemQuantityInCart = (cart, itemLineItem) => {
  const foundItem = cart.lineItems.find(({ id }) => itemLineItem.id && id === itemLineItem.id);
  if (foundItem) {
    Object.assign(foundItem, itemLineItem);
  } else {
    cart.lineItems.push(itemLineItem);
  }
};

const updateItemLineItemQuantity = (state, itemLineItem, quantity, orderType) => {
  if (orderType) {
    // Find the matching order type cart and create an identical cart with the updated item quantity
    const orderTypeCart = state.carts.find(cart => cart.orderType === orderType.id);
    updateLineItemQuantityInCart(orderTypeCart, itemLineItem);

    setCartInCarts(state, orderTypeCart);

    // If the current cart matches, update it too
    if (state.cart?.orderType === orderType.id) {
      state.cart.lineItems = orderTypeCart.lineItems;
    }
  } else {
    updateLineItemQuantityInCart(state.cart, itemLineItem);
  }
};

const getAlertsFromCartResponse = cartResponse => {
  return cartResponse.data.alerts;
};

const getShippingMethodNameFromCartResponse = cartResponse => {
  return cartResponse.data.checkout?.shippingMethod?.name;
};

const getEditShippingIsInvalidErrorTitle = shippingMethodName => {
  if (shippingMethodName) {
    return `Oops! Your order is no longer eligible for ${shippingMethodName}!`;
  }
  return 'Oops! Your order is no longer eligible for checkout!';
};

const showShippingIsInvalidErrorToast = (shippingMethodName, message) => {
  const title = getEditShippingIsInvalidErrorTitle(shippingMethodName);
  ToastService.open(WCSimpleToast, {
    props: {
      variant: 'danger',
      title,
      message,
    },
    timeout: 5000,
  });
};

const showErrorToastIfEditShippingIsInvalid = cartResponse => {
  const alerts = getAlertsFromCartResponse(cartResponse);
  if (!alerts) {
    return;
  }
  const shippingInvalidCode = ALERT_CODE.SHIPPING.INVALID;
  alerts.forEach(alert => {
    if (!shippingInvalidCode.validate(alert)) {
      return;
    }
    const shippingMethodName = getShippingMethodNameFromCartResponse(cartResponse);
    const message = shippingInvalidCode.getReason(alert);
    showShippingIsInvalidErrorToast(shippingMethodName, message);
  });
};

const showEditExpiredModal = () => {
  ModalService.open(WCEditExpiredModal);
};

const showModalIfEditExpired = cartResponse => {
  const alerts = getAlertsFromCartResponse(cartResponse);
  if (!alerts) {
    return;
  }
  const editExpiredCode = ALERT_CODE.EDIT.EXPIRED;
  alerts.forEach(alert => {
    if (!editExpiredCode.validate(alert)) {
      return;
    }
    const message = editExpiredCode.getMessage(alert);
    console.error('Edit Expired: ', message);
    showEditExpiredModal();
  });
};

const showModalIfEbtVoided = cartResponse => {
  const alerts = getAlertsFromCartResponse(cartResponse);
  if (!alerts) {
    return;
  }
  const ebtVoidedCode = ALERT_CODE.EBT.VOIDED;
  alerts.forEach(alert => {
    if (!ebtVoidedCode.validate(alert)) {
      return;
    }
    ModalService.open(WCEbtRefundsModal, {
      ebtAuthList: ebtVoidedCode.getTenders(alert),
      variant: REFUND_VARIANT.ORDER_CHANGED_AFTER_TENDER,
    });
  });
};

const showAlertIfMemberOwesFees = (cartResponse, dispatch) => {
  const alerts = getAlertsFromCartResponse(cartResponse);
  if (!alerts?.length) {
    dispatch('membership/hideFeeAlert', null, { root: true });
    return;
  }
  const memberOwesFeesCode = ALERT_CODE.CMS.PAYMENT.DUE;
  alerts.forEach(alert => {
    if (!memberOwesFeesCode.validate(alert)) {
      dispatch('membership/hideFeeAlert', null, { root: true });
      return;
    }

    dispatch('membership/displayFeeAlert', null, { root: true });
  });
};

const handleSyncAlerts = (cartResponse, dispatch) => {
  showErrorToastIfEditShippingIsInvalid(cartResponse);
  showModalIfEditExpired(cartResponse);
  showModalIfEbtVoided(cartResponse);
  showAlertIfMemberOwesFees(cartResponse, dispatch);
};

const getAllowOutOfStockItemsInCart = rootGetters => {
  return rootGetters['user/isCustomerModeScan'];
};

const state = {
  reference: '',
  fetched: false,
  syncing: false,
  loadingStatus: false,
  cart: {},
  carts: [],
  nonEmptyCarts: [],
  selectedTips: [],
  selectedRoundUp: {},
  orderTypes: [],
  cartsLoadingStatus: false,
  checkout: {
    stage: -1,
  },
  outOfStockLineItems: [],
  deliveryOptions: { dropoffInstructions: '', contactless: false, sendNotifications: null },
  ebtSnapBalance: null,
  ebtCashBalance: null,
  ebtBalanceTimeStamp: null,
  ebtEstimatedSnapBalanceEnabled: false,
};

const getters = {
  isFetched(state) {
    return state.fetched;
  },
  isSyncing(state) {
    return state.syncing;
  },
  getCart(state) {
    return state.cart;
  },
  getSyncReference(state) {
    return state.reference;
  },
  getTotals(state) {
    if (!state.cart) {
      return {};
    }
    return {
      sub: state.cart.rawSubTotal,
      discountSub: state.cart.subTotal,
      tax: state.cart.taxTotal,
      due: state.cart.dueTotal,
      grand: state.cart.grandTotal,
      shipping: state.cart.shippingTotal,
      tips: state.cart.tipTotal,
      ebtSnap: state.cart.ebtSnapEligibleTotal,
      ebtCash: state.cart.ebtTanfEligibleTotal,
      roundUp: state.cart.roundUpTotal,
    };
  },
  getLineItems(state) {
    return state.cart && state.cart.lineItems ? state.cart.lineItems : [];
  },
  getCartSyncDto(state) {
    const cart = state.cart;
    const checkout = state.checkout;

    return cartDtoFromCart(cart, checkout);
  },
  getCartSyncDtoByCart: () => (cart, checkout) => {
    return cartDtoFromCart(cart, checkout);
  },
  getCartsSyncDto(state, getters) {
    if (state.carts) {
      const cartsSync = state.carts.map(cart => getters.getCartSyncDtoByCart(cart, cart.checkout));
      return cartsSync;
    }

    return this.getCartSyncDto;
  },
  getBilling(state) {
    return state.checkout.billing;
  },
  getShipping(state) {
    return state.checkout.shipping;
  },
  getCheckoutStage(state) {
    return state.checkout.stage;
  },
  getMaxStage(state) {
    return state.checkout.maxStage;
  },
  getMaxStageReached(state) {
    return state.checkout.maxStageReached;
  },
  getShippingMethods(state) {
    return state.checkout.shippingMethods;
  },
  getShippingMethod(state) {
    return state.checkout.shippingMethod;
  },
  getReservationDate(state) {
    return state.checkout.reservationDate;
  },
  getReservationTime(state) {
    return state.checkout.reservationTime;
  },
  getPaymentMethods(state) {
    return state.checkout.paymentMethods;
  },
  getAvailableRoundUps(state) {
    return state.checkout.availableRoundups;
  },
  getOrderInstructions(state) {
    return state.cart.instructions;
  },
  getInStore(state) {
    return state.cart.inStore;
  },
  getCoupons(state) {
    return state.cart.coupons;
  },
  getPayLater(state) {
    return state.cart.payLater;
  },
  getAddedCouponValid(state) {
    return state.cart.addedCouponValid;
  },
  getCouponAlerts(state) {
    return state.cart.alerts?.filter(i => i.code === 'invalid.coupon');
  },
  getExternalCouponAlerts(state) {
    return state.cart.alerts?.filter(i => i.code === 'valid.coupon');
  },
  getDeferrableCombo(state) {
    return id => {
      return getters.getDeferrableCombos(state).find(promo => {
        return promo.id === id;
      });
    };
  },
  getDeferrableCombos(state) {
    return state.cart.deferrableCombos;
  },
  getGiftCardItems(state) {
    return state.cart && state.cart.giftCardItems ? state.cart.giftCardItems : [];
  },
  getGiftCardTotals(state) {
    return getters.getGiftCardItems(state).reduce((a, b) => a + b.amount, 0);
  },
  getSelectedTips(state) {
    return state.cart.lineItems?.filter(li => li.type === TLI_PAIDIN_TYPE && li.tipProfileId);
  },
  getSelectedRoundUp(state) {
    const roundUps = state.cart.lineItems?.filter(
      lineItem => lineItem.type === TLI_PAIDIN_TYPE && lineItem.roundUpId,
    );
    return roundUps.length > 0 ? roundUps[0] : {};
  },
  getSpecialOrderItems(state) {
    return state.cart && state.cart.specialOrderItems ? state.cart.specialOrderItems : [];
  },
  isGiftCard(state) {
    return getters.getGiftCardItems(state).length > 0;
  },
  getOutOfStockLineItems(state) {
    return state.outOfStockLineItems;
  },
  getOutOfStockLineItemsOfType(state) {
    return type => {
      return getters.getOutOfStockLineItems(state)?.filter(lineItem => lineItem.type === type);
    };
  },
  getOutOfStockItemLineItem(state) {
    return (item, variation = null) => {
      if (!item) {
        return null;
      }

      return getters
        .getOutOfStockLineItemsOfType(state)(1)
        .find(lineItem => {
          return (
            lineItem.item.id === item.id &&
            ((!lineItem.variation && !variation) || lineItem.variation === variation)
          );
        });
    };
  },
  isLoading(state) {
    return state.loadingStatus;
  },
  // fetch web email from the checkout.emails
  getCheckoutWebEmail(state) {
    return (
      (!!state.checkout.emails &&
        state.checkout.emails.find(emailObj => emailObj.name === 'Web')?.email) ||
      ''
    );
  },
  // fetch web phone number from the checkout.phones
  getCheckoutWebPhone(state) {
    return (
      (!!state.checkout.phones &&
        state.checkout.phones.find(phoneObj => phoneObj.name === 'Web')?.phone) ||
      ''
    );
  },
  getCartOrderType(state) {
    return state.cart?.orderType;
  },
  carts(state) {
    return state.carts ? state.carts : null;
  },
  nonEmptyCarts(state) {
    return state.nonEmptyCarts ? state.nonEmptyCarts : null;
  },
  getCartsCount(state) {
    return state.nonEmptyCarts ? state.nonEmptyCarts.length : 0;
  },
  cartsLoadingStatus(state) {
    return state.cartsLoadingStatus;
  },
  getDeliveryTotal(state) {
    return state.cart.deliveryTotal;
  },
  getDeliveryDropoffInstructions(state) {
    return state.deliveryOptions.dropoffInstructions;
  },
  getDeliveryContactless(state) {
    return state.deliveryOptions.contactless;
  },
  getSendNotifications(state, _, rootState) {
    if (state.deliveryOptions.sendNotifications === null) {
      state.deliveryOptions.sendNotifications = !rootState.config.deliveryNotificationsDefaultToOff;
    }
    return state.deliveryOptions.sendNotifications;
  },
  getEbtSnapBalance(state) {
    return state.ebtSnapBalance;
  },
  getEbtCashBalance(state) {
    return state.ebtCashBalance;
  },
  getEbtBalanceTimeStamp(state) {
    return state.ebtBalanceTimeStamp;
  },
  getEbtSnapEstimatedBalanceEnabled(state) {
    return state.ebtEstimatedSnapBalanceEnabled;
  },
};

export const SET_SYNCING = 'SET_SYNCING';
export const SET_FETCHED = 'SET_FETCHED';
export const SET_SYNC_REFERENCE = 'SET_SYNC_REFERENCE';
export const SET_CART = 'SET_CART';
export const SET_CHECKOUT = 'SET_CHECKOUT';
export const SET_ITEM_LINEITEM_QUANTITY = 'SET_ITEM_LINEITEM_QUANTITY';
export const SET_ALL_CART_ITEMS_QUANTITY = 'SET_ALL_CART_ITEMS_QUANTITY';
export const SET_OUTOFSTOCK_LINEITEM_QUANTITY = 'SET_OUTOFSTOCK_LINEITEM_QUANTITY';
export const SET_OUTOFSTOCK_LINEITEMS = 'SET_OUTOFSTOCK_LINEITEMS';
export const ADD_GIFT_CARD = 'ADD_GIFT_CARD';
export const REMOVE_GIFT_CARD = 'REMOVE_GIFT_CARD';
export const REMOVE_ALL_GIFT_CARDS = 'REMOVE_ALL_GIFT_CARDS';
export const SET_ITEM_LINEITEM_INSTRUCTIONS = 'SET_ITEM_LINEITEM_INSTRUCTIONS';
export const SET_ORDER_INSTRUCTIONS = 'SET_ORDER_INSTRUCTIONS';
export const SET_ITEM_LINEITEM_SUBSTITUTABLE = 'SET_ITEM_LINEITEM_SUBSTITUTABLE';
export const SET_BILLING = 'SET_BILLING';
export const SET_SHIPPING = 'SET_SHIPPING';
export const SET_CHECKOUT_STAGE = 'SET_CHECKOUT_STAGE';
export const SET_MAX_STAGE = 'SET_MAX_STAGE';
export const SET_MAX_STAGE_REACHED = 'SET_MAX_STAGE_REACHED';
export const SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD';
export const SET_SHIPPING_METHODS = 'SET_SHIPPING_METHODS';
export const SET_RESERVATION_DATE = 'SET_RESERVATION_DATE';
export const SET_RESERVATION_TIME = 'SET_RESERVATION_TIME';
export const SET_DEFERRABLE_COMBOS = 'SET_DEFERRABLE_COMBOS';
export const SET_MTO_ITEM = 'SET_MTO_ITEM';
export const SET_ITEM_INSTRUCTIONS = 'SET_ITEM_INSTRUCTIONS';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const SET_IN_STORE = 'SET_IN_STORE';
export const SET_SELECTED_TIP = 'SET_SELECTED_TIP';
export const REMOVE_SELECTED_TIP = 'REMOVE_SELECTED_TIP';
export const ADD_SPECIAL_ORDER_ITEM = 'ADD_SPECIAL_ORDER_ITEM';
export const UPDATE_SPECIAL_ORDER_ITEM = 'UPDATE_SPECIAL_ORDER_ITEM';
export const REMOVE_SPECIAL_ORDER_ITEM = 'REMOVE_SPECIAL_ORDER_ITEM';
export const SET_CARTS = 'SET_CARTS';
export const SET_CART_IN_CARTS = 'SET_CART_IN_CARTS';
export const SET_ORDER_TYPES = 'SET_ORDER_TYPES';
export const SET_CARTS_LOADING_STATUS = 'SET_CARTS_LOADING_STATUS';
export const SET_DELIVERY_TOTAL = 'SET_DELIVERY_TOTAL';
export const SET_DELIVERY_INSTRUCTIONS = 'SET_DELIVERY_INSTRUCTIONS';
export const SET_DELIVERY_CONTACTLESS = 'SET_DELIVERY_CONTACTLESS';
export const SET_DELIVERY_NOTIFICATIONS = 'SET_DELIVERY_NOTIFICATIONS';
export const SET_SELECTED_ROUNDUP = 'SET_SELECTED_ROUNDUP';
export const REMOVE_SELECTED_ROUNDUP = 'REMOVE_SELECTED_ROUNDUP';
export const SET_EBT_BALANCE_TIMESTAMP = 'SET_EBT_BALANCE_TIMESTAMP';
export const SET_EBT_SNAP_BALANCE = 'SET_EBT_SNAP_BALANCE';
export const SET_EBT_CASH_BALANCE = 'SET_EBT_CASH_BALANCE';
export const SET_EBT_SNAP_ESTIMATED_BALANCE_ENABLED = 'SET_EBT_SNAP_ESTIMATED_BALANCE_ENABLED';
export const SET_CART_REPLICATION_STATUS = 'SET_CART_REPLICATION_STATUS';

const mutations = {
  [SET_SYNCING](state, syncing) {
    state.syncing = syncing;
  },
  [SET_FETCHED](state, fetched) {
    state.fetched = fetched;
  },
  [SET_SYNC_REFERENCE](state, reference) {
    state.reference = reference;
  },
  [SET_CART](state, cart) {
    state.cart = cart;
    state.carts = [...state.carts.filter(otCart => otCart.orderType !== cart.orderType), cart];
  },
  [SET_CHECKOUT](state, checkout) {
    state.checkout = checkout;

    if (state.checkout?.billing) {
      // Set billing.email value from checkout.email when billing.email is null/undefined/empty
      state.checkout.billing.email =
        state.checkout.billing.email || getters.getCheckoutWebEmail(state);

      // Set billing.phone value from checkout.phones when billing.phone is null/undefined/empty
      state.checkout.billing.phone =
        state.checkout.billing.phone || getters.getCheckoutWebPhone(state);
    }
  },
  [SET_ITEM_LINEITEM_QUANTITY](state, payload) {
    let { itemLineItem } = payload;
    const {
      item,
      variation,
      weightedItemQuantity,
      quantity = 1,
      priceEmbeddedLineItem,
      orderType,
    } = payload;

    if (!itemLineItem && !priceEmbeddedLineItem) {
      itemLineItem = {
        type: 1,
        item,
        variation,
        quantity,
        weightedItemQuantity,
      };
    } else if (!itemLineItem && priceEmbeddedLineItem) {
      itemLineItem = {
        type: 1,
        item,
        variation,
        quantity,
        weightedItemQuantity,
        ...priceEmbeddedLineItem,
      };
    }

    itemLineItem.quantity = quantity;
    itemLineItem.weightedItemQuantity = weightedItemQuantity;

    updateItemLineItemQuantity(state, itemLineItem, quantity, orderType);
  },

  [SET_ALL_CART_ITEMS_QUANTITY](state, payload) {
    const { quantity } = payload;
    state.cart.lineItems.forEach(lineItem => {
      // eslint-disable-next-line no-param-reassign
      lineItem.quantity = quantity;
    });
  },

  [SET_MTO_ITEM](state, payload) {
    let { mtoLineItem } = payload;
    const {
      lineItem,
      item,
      variation,
      amount,
      orderType,
      instructions = '',
      quantity,
      weightedItemQuantity,
      customerId,
    } = payload;
    // If there is an identical lineItem to one being added, remove it and update the cart
    if (mtoLineItem && lineItem) {
      mtoLineItem = {
        type: 1,
        item: lineItem.item,
        variation: lineItem.variation,
        quantity: 0,
        amount: lineItem.amount,
        instructions,
      };

      updateItemLineItemQuantity(state, mtoLineItem, 0, orderType);
    }

    // Update the line item and add it to the cart
    mtoLineItem = {
      type: 1,
      item,
      variation,
      quantity,
      weightedItemQuantity,
      amount,
      instructions,
    };
    updateItemLineItemQuantity(state, mtoLineItem, mtoLineItem.quantity, orderType);

    AnalyticsService.track(TrackableEventConstants.ItemAddedToCart, {
      customerId,
      items: [item],
    });
  },

  [SET_ITEM_LINEITEM_INSTRUCTIONS](state, payload) {
    const { lineItem, instructions = '' } = payload;

    if (!lineItem) {
      return;
    }

    lineItem.instructions = instructions;
  },

  [SET_ORDER_INSTRUCTIONS](state, instructions) {
    state.cart.instructions = instructions;
  },
  [SET_ITEM_LINEITEM_SUBSTITUTABLE](state, payload) {
    const { lineItem, substitutable = false } = payload;

    if (!lineItem) {
      return;
    }

    lineItem.substitutable = substitutable;
  },

  [SET_BILLING](state, payload) {
    const { firstName, lastName, email, phone, street1, street2, city, postal, country } = payload;
    state.checkout.billing = {
      firstName,
      lastName,
      email,
      phone,
      street1,
      street2,
      city,
      postal,
      state: payload.state,
      country,
    };
  },
  [SET_SHIPPING](state, payload) {
    const { firstName, lastName, email, phone } = state.checkout.billing;
    const { street1, street2, city, postal, country, latitude, longitude } = payload;
    // For now, the billing and shipping info will be combined. This will change
    state.checkout.shipping = {
      firstName,
      lastName,
      email,
      phone,
      street1,
      street2,
      city,
      postal,
      state: payload.state,
      country,
      latitude,
      longitude,
    };
  },
  [SET_CHECKOUT_STAGE](state, stage) {
    state.checkout.stage = stage;
  },
  [SET_IN_STORE](state, payload) {
    state.cart.inStore = payload;
  },
  [SET_MAX_STAGE](state, stage) {
    state.checkout.maxStage = stage;
  },
  [SET_MAX_STAGE_REACHED](state, stage) {
    state.checkout.maxStageReached = stage;
  },
  [SET_SHIPPING_METHOD](state, method) {
    state.checkout.shippingMethod = method;
  },
  [SET_SHIPPING_METHODS](state, methods) {
    state.checkout.shippingMethods = [...methods];
  },
  [SET_RESERVATION_DATE](state, date) {
    state.checkout.reservationDate = date;
  },
  [SET_RESERVATION_TIME](state, time) {
    state.checkout.reservationTime = time;
  },
  [SET_DEFERRABLE_COMBOS](state, payload) {
    const { id, defer } = payload;
    const promo = getters.getDeferrableCombo(state)(id);

    if (!promo) {
      return;
    }

    promo.deferred = defer;
  },
  [ADD_GIFT_CARD](state, payload) {
    const { giftCard, customerId } = payload;
    state.cart.giftCardItems = [
      ...state.cart.giftCardItems.filter(gc => gc.id !== giftCard.id),
      { ...giftCard, quantity: 1 },
    ];

    AnalyticsService.track(TrackableEventConstants.ItemAddedToCart, {
      customerId,
      items: [giftCard],
    });
  },
  [REMOVE_GIFT_CARD](state, payload) {
    const { giftCard, customerId } = payload;
    state.cart.giftCardItems = [
      ...state.cart.giftCardItems.filter(gc => gc.id !== giftCard.id),
      { ...giftCard, quantity: 0 },
    ];

    AnalyticsService.track(TrackableEventConstants.ItemRemovedFromCart, {
      customerId,
      items: [giftCard],
    });
  },
  [REMOVE_ALL_GIFT_CARDS](state, id) {
    state.cart.giftCardItems.forEach(lineItem => {
      // eslint-disable-next-line no-param-reassign
      lineItem.quantity = 0;
    });

    AnalyticsService.track(TrackableEventConstants.ItemRemovedFromCart, {
      customerId: id,
      items: state.cart.giftCardItems,
    });
  },
  [SET_SELECTED_TIP](state, payload) {
    const { tipProfileId, tipOptionId, tipAmount } = payload;
    const lineItem = {
      type: TLI_PAIDIN_TYPE,
      quantity: 1,
      tipProfileId,
      tipOptionId,
      tipAmount,
    };
    state.cart.lineItems = [
      ...state.cart.lineItems.filter(li => li.tipProfileId !== tipProfileId),
      lineItem,
    ];
  },
  [REMOVE_SELECTED_TIP](state, tipProfileId) {
    state.cart.lineItems = [...state.cart.lineItems.filter(li => li.tipProfileId !== tipProfileId)];
  },
  [SET_SELECTED_ROUNDUP](state, payload) {
    if (state.selectedRoundUp) {
      // Purge current round up from lineItems
      state.cart.lineItems = [...state.cart.lineItems.filter(li => li?.roundUpId === null)];
    }

    const { roundUp, roundUpAmount } = payload;

    const roundUpLineItem = {
      type: TLI_PAIDIN_TYPE,
      quantity: 1,
      roundUpId: roundUp?.id,
      amount: roundUpAmount,
    };

    state.cart.lineItems = [...state.cart.lineItems, roundUpLineItem];
    state.cart.roundUpTotal = roundUpLineItem.amount;
    state.selectedRoundUp = roundUpLineItem;
  },
  [REMOVE_SELECTED_ROUNDUP](state) {
    state.cart.lineItems = [...state.cart.lineItems.filter(li => li.roundUpId == null)];
    state.selectedRoundUp = {};
    state.cart.roundUpTotal = 0;
  },
  [ADD_SPECIAL_ORDER_ITEM](state, payload) {
    const { description } = payload;

    // Update the line item and add it to the cart
    const line = {
      type: 60,
      description,
      quantity: 1,
    };
    state.cart.lineItems = [
      ...state.cart.lineItems.filter(so => so !== line),
      {
        ...line,
      },
    ];
    state.cart.specialOrderItems = [
      ...state.cart.lineItems.filter(so => so === line && so.instructions),
      {
        ...line,
      },
    ];
  },
  [UPDATE_SPECIAL_ORDER_ITEM](state, payload) {
    const lineItem = payload;

    const specialOrderItems = state.cart.lineItems.filter(li => li.type === 60);
    state.cart.specialOrderItems = specialOrderItems
      .filter(specialOrderItem => specialOrderItem.id === lineItem.id)
      .map(specialOrderItem => {
        return {
          id: specialOrderItem.id,
          description: lineItem.instructions,
          quantity: 1,
        };
      });
    state.cart.lineItems = [
      ...state.cart.lineItems.filter(so => so !== lineItem),
      {
        ...lineItem,
      },
    ];
  },
  [REMOVE_SPECIAL_ORDER_ITEM](state, payload) {
    const lineItem = payload;

    const specialOrderItems = state.cart.lineItems.filter(li => li.type === 60);
    if (specialOrderItems) {
      state.cart.specialOrderItems = specialOrderItems
        .filter(specialOrderItem => specialOrderItem.instructions === lineItem.instructions)
        .map(specialOrderItem => {
          return {
            id: specialOrderItem.id,
            description: specialOrderItem.instructions,
            quantity: 0,
          };
        });
      state.cart.lineItems = [
        ...state.cart.lineItems.filter(so => so !== lineItem),
        {
          ...lineItem,
          quantity: 0,
        },
      ];
    }
  },
  [SET_OUTOFSTOCK_LINEITEM_QUANTITY](state, payload) {
    const { line = {}, quantity = 1 } = payload;
    const itemLineItem = getters.getOutOfStockItemLineItem(state)(line.item, line.variation);

    if (quantity === 0) {
      state.outOfStockLineItems = [
        ...state.outOfStockLineItems.filter(lineItem => lineItem !== itemLineItem),
      ];
    } else if (state.outOfStockLineItems && state.outOfStockLineItems.length > 0) {
      const outOfStockItemIdx = state.outOfStockLineItems.findIndex(
        outOfStockItem => outOfStockItem.item.id === line.item.id,
      );
      // To remove duplicate items in the out of stock items list
      if (outOfStockItemIdx === -1) {
        state.outOfStockLineItems.push(line);
      }
    } else {
      state.outOfStockLineItems = [line];
    }
  },
  [SET_OUTOFSTOCK_LINEITEMS](state, payload) {
    const { lines = [] } = payload;
    state.outOfStockLineItems = [...lines];
  },
  [SET_LOADING_STATUS](state, status) {
    state.loadingStatus = status;
  },
  [SET_CARTS](state, carts) {
    state.carts = carts;
    state.nonEmptyCarts = carts?.filter(
      cart => cart.lineItems?.length || cart.giftCardItems?.length,
    );
  },
  [SET_CART_IN_CARTS](state, cart) {
    setCartInCarts(state, cart);
  },
  [SET_ORDER_TYPES](state, orderTypes) {
    state.orderTypes = orderTypes;
  },
  [SET_CARTS_LOADING_STATUS](state, status) {
    state.cartsLoadingStatus = status;
  },
  [SET_DELIVERY_TOTAL](state, payload) {
    state.cart.deliveryTotal = payload;
  },
  [SET_DELIVERY_INSTRUCTIONS](state, instructions) {
    state.deliveryOptions.dropoffInstructions = instructions;
    state.deliveryOptions = { ...state.deliveryOptions };
  },
  [SET_DELIVERY_CONTACTLESS](state, contactless) {
    state.deliveryOptions.contactless = contactless;
    state.deliveryOptions = { ...state.deliveryOptions };
  },
  [SET_DELIVERY_NOTIFICATIONS](state, sendNotifications) {
    state.deliveryOptions.sendNotifications = sendNotifications;
    state.deliveryOptions = { ...state.deliveryOptions };
  },
  [SET_EBT_SNAP_BALANCE](state, balance) {
    state.ebtSnapBalance = balance;
    state.ebtBalanceTimeStamp = Date.now();
  },
  [SET_EBT_CASH_BALANCE](state, balance) {
    state.ebtCashBalance = balance;
    state.ebtBalanceTimeStamp = Date.now();
  },
  [SET_EBT_BALANCE_TIMESTAMP](state, timestamp) {
    state.ebtBalanceTimeStamp = timestamp;
  },
  [SET_EBT_SNAP_ESTIMATED_BALANCE_ENABLED](state, enabled) {
    state.ebtEstimatedSnapBalanceEnabled = enabled;
  },
  [SET_CART_REPLICATION_STATUS](state, enabled) {
    state.cart.online = enabled;
  },
};

const getSyncPayloadOrderTypeId = payload => {
  if (payload.orderType) {
    if (typeof payload.orderType === 'string') {
      return payload.orderType;
    }
    return payload.orderType.id;
  }
  return null;
};

const isOrderTypeSync = (payload, cart) => {
  const payloadOrderTypeId = getSyncPayloadOrderTypeId(payload);
  return payloadOrderTypeId && payloadOrderTypeId !== cart.orderTypeId && !cart.inStore;
};

const filterOutOfStockItems = lineItems => {
  return lineItems.filter(lineItem => {
    if (!lineItem.item) {
      return true;
    }
    return !lineItem.item.outOfStock || lineItem.item.sellOutOfStock;
  });
};

const removeOutOfStockItemsFromCartLineItems = lineItems => {
  const filtered = filterOutOfStockItems(lineItems);
  for (const lineItem of filtered) {
    if (!lineItem.linkedItems) {
      // eslint-disable-next-line no-continue
      continue;
    }
    lineItem.linkedItems = filterOutOfStockItems(lineItem.linkedItems);
  }
  return filtered;
};

const syncCart = async (vuexState, reference) => {
  const { commit, getters, rootGetters, dispatch, payload } = vuexState;

  const currentCart = await getters.getCartSyncDto;

  let response;
  // If we are syncing a cart that is not the current one, we need a different API endpoint
  if (isOrderTypeSync(payload, currentCart)) {
    const cartsDto = await getters.getCartsSyncDto;
    const orderTypeCart = cartsDto?.find(
      cart => cart.orderTypeId === (payload.orderType.id || payload.orderType),
    );
    response = await axios.post(`api/ot/${orderTypeCart.orderTypeId}/cart`, orderTypeCart);
  } else {
    // To preserve behavior of an existing bug, the client shall only set inStore to true,
    // never to false. This should be changed if we ever reconsider sync.
    if (!currentCart.inStore) {
      currentCart.inStore = null;
    }
    response = await axios.post('api/cart', currentCart);
  }
  handleSyncAlerts(response, dispatch);

  // Determine if changes have occurred during network transmission
  if (getters.getSyncReference !== reference) {
    return;
  }

  const { checkout, ...cart } = response.data;

  if (!getAllowOutOfStockItemsInCart(rootGetters)) {
    dispatch('checkForOutOfStockLineItems', cart.lineItems);
    cart.lineItems = removeOutOfStockItemsFromCartLineItems(cart.lineItems);
  }

  // Only if we are syncing the current cart, update the cart with the new data
  if (!isOrderTypeSync(payload, currentCart)) {
    commit(SET_CHECKOUT, checkout);
    commit(SET_CART, cart);
  }

  if (cart.orderType) {
    if (payload.updateOnlyCurrentCart) {
      commit(SET_CART_IN_CARTS, response.data);
    } else {
      const carts = await fetchCarts();
      commit(SET_CARTS, carts);
    }
  }
};

const actions = {
  async reload({ commit, dispatch, rootState, rootGetters }) {
    commit(SET_SYNCING, true);
    try {
      const response = await axios.get('api/cart');
      const { checkout, ...cart } = response.data;

      if (!cart.inStore && rootState.config.orderTypesEnabled) {
        const carts = await fetchCarts();
        commit(SET_CARTS, carts);
      }

      if (!getAllowOutOfStockItemsInCart(rootGetters)) {
        dispatch('checkForOutOfStockLineItems', cart.lineItems);
        cart.lineItems = removeOutOfStockItemsFromCartLineItems(cart.lineItems);
      }

      commit(SET_CHECKOUT, checkout);
      commit(SET_CART, cart);
    } catch (error) {
      console.error(error);
    } finally {
      commit(SET_SYNCING, false);
      commit(SET_FETCHED, true);
    }
  },

  // Debounced function to delay cart sync so that it only fires a max of once every 0.5s
  defer: debounce(
    ({ dispatch }, payload) => dispatch('sync', { ...payload, immediate: true }),
    CART_DEBOUNCE_TIMER,
  ),

  // Primary method of syncing the cart with optional parameter to force immediate sync
  async sync(
    { commit, getters, rootGetters, dispatch },

    payload = { immediate: false, updateOnlyCurrentCart: false },
  ) {
    // Store a reference in the state so that we can check if the cart has been modified before applying updates from the server
    const reference = uuid();
    commit(SET_SYNC_REFERENCE, reference);

    if (!payload.immediate) {
      dispatch('defer', payload);
      return;
    }
    try {
      commit(SET_SYNCING, true);
      await syncCart({ commit, getters, rootGetters, dispatch, payload }, reference);
    } catch (error) {
      console.error(error);

      if (error?.response?.data?.message) {
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: 'Error',
            message: error?.response?.data?.message,
          },
          timeout: 7000,
        });
      }

      dispatch('reload'); // Reload the cart on failure
    } finally {
      commit(SET_SYNCING, false);
    }
  },
  async cartDrawerCheckout({ commit, getters }) {
    if (!getters.isSyncing) {
      commit(SET_SYNCING, true);
    }
    await CartDrawerService.close();
    const response = await axios.get(`api/ot/${state.cart.orderType}/cart`);
    commit(SET_CART, response.data);
    commit(SET_SYNCING, false);
  },
  checkForOutOfStockLineItems({ commit, dispatch, rootGetters }, lineItems) {
    if (rootGetters['user/isCustomerModeScan']) {
      return;
    }

    if (lineItems && lineItems.length === 0) {
      dispatch('clearOutOfStockItems');
    }

    lineItems.forEach(lineItem => {
      if (lineItem.item && lineItem.item.outOfStock && !lineItem.item.sellOutOfStock) {
        commit(SET_OUTOFSTOCK_LINEITEM_QUANTITY, {
          line: lineItem,
          quantity: 1,
        });
      }
    });
  },
  async setItemQuantity({ commit, dispatch }, payload) {
    // If user changes items in their cart while not on the CO page, make them reconfirm on the 1st stage
    await dispatch('checkout', { newStage: 0, modified: false });
    if (!state.cart.giftCardItems.length) {
      await commit(SET_ITEM_LINEITEM_QUANTITY, payload);

      await dispatch('sync', { immediate: false, orderType: payload?.orderType });
    }
  },
  async removeAllCartItems({ commit, dispatch, rootGetters }) {
    dispatch('setLoadingStatus', true);
    if (state.cart?.lineItems?.length) {
      const specialOrderItems = state.cart?.lineItems?.filter(li => li.type === 60);
      state.cart.specialOrderItems = specialOrderItems.map(specialOrderItem => {
        return {
          id: specialOrderItem.id,
          description: specialOrderItem.instructions,
          quantity: 0,
        };
      });
      await commit(SET_ALL_CART_ITEMS_QUANTITY, { quantity: 0 });
    }
    if (state.cart?.giftCardItems?.length) {
      commit(REMOVE_ALL_GIFT_CARDS, rootGetters['user/getUser']?.sub);
    }
    commit(SET_DELIVERY_TOTAL, null);
    AnalyticsService.track(TrackableEventConstants.ItemRemovedFromCart, {
      customerId: rootGetters['user/getUser']?.sub,
      items: state.cart.lineItems?.filter(lineItem => lineItem.item).map(lineItem => lineItem.item),
    });
    await dispatch('sync', { immediate: true, orderType: state.cart?.orderType });
    dispatch('setLoadingStatus', false);
  },
  async motRemoveAllCartItems({ commit, dispatch, getters, rootGetters }) {
    dispatch('setCartsLoadingStatus', true);
    state.carts?.forEach(cart => {
      if (cart.lineItems?.length) {
        const specialOrderItems = cart?.lineItems.filter(li => li.type === 60);
        // eslint-disable-next-line no-param-reassign
        cart.specialOrderItems = specialOrderItems.map(specialOrderItem => {
          return {
            id: specialOrderItem.id,
            description: specialOrderItem.instructions,
            quantity: 0,
          };
        });
        commit(SET_CART, cart);
        commit(SET_ALL_CART_ITEMS_QUANTITY, { quantity: 0 });
      }
      if (cart.giftCardItems?.length) {
        cart.giftCardItems?.forEach(gc => {
          // eslint-disable-next-line no-param-reassign
          gc.quantity = 0;
        });
        commit(SET_CART, cart);
        dispatch('setCartsLoadingStatus', false);
      }
      commit(SET_DELIVERY_TOTAL, null);

      AnalyticsService.track(TrackableEventConstants.ItemRemovedFromCart, {
        customerId: rootGetters['user/getUser']?.sub,
        items: state.cart.lineItems
          ?.filter(lineItem => lineItem.item)
          .map(lineItem => lineItem.item),
      });
    });
    const cartsDto = await getters.getCartsSyncDto;
    await cartsDto
      .filter(c => c.items.length || c.giftCardItems.length)
      .forEach(cart => {
        axios.post(`api/ot/${cart.orderTypeId}/cart`, cart);
      });
    await dispatch('sync');
    dispatch('setCartsLoadingStatus', false);
  },
  clearOutOfStockItems({ commit }) {
    commit(SET_OUTOFSTOCK_LINEITEMS, []);
  },
  setMtoItem({ commit, dispatch, rootGetters }, payload) {
    const customerId = rootGetters['user/getUser']?.sub;
    commit(SET_MTO_ITEM, { ...payload, customerId });
    dispatch('sync', { immediate: true, orderType: payload?.orderType });
  },
  async addGiftCard({ commit, dispatch, rootGetters }, giftCard) {
    await dispatch('checkout', { newStage: 0, modified: false });
    const customerId = rootGetters['user/getUser']?.sub;
    await commit(ADD_GIFT_CARD, { giftCard, customerId });
    await dispatch('sync');
  },
  async removeGiftCard({ commit, dispatch, rootGetters }, giftCard) {
    const customerId = rootGetters['user/getUser']?.sub;
    await commit(REMOVE_GIFT_CARD, { giftCard, customerId });
    await dispatch('sync');
  },

  setItemLineItemInstructions({ commit }, payload) {
    commit(SET_ITEM_LINEITEM_INSTRUCTIONS, payload);
  },
  setInStore({ commit }, payload) {
    commit(SET_IN_STORE, payload);
  },
  setItemLineItemSubstitutable({ commit }, payload) {
    commit(SET_ITEM_LINEITEM_SUBSTITUTABLE, payload);
  },
  setReservation({ commit }, reservation) {
    commit(SET_RESERVATION_DATE, reservation.date);
    commit(SET_RESERVATION_TIME, reservation.time);
  },
  addCoupon({ state, dispatch }, coupon) {
    if (!state.cart.coupons) {
      state.cart.coupons = [];
    }
    const existingCoupon = state.cart.coupons.find(c => c.code === coupon);

    if (!existingCoupon) {
      state.cart.coupons.push({
        code: coupon,
        remove: false,
      });

      dispatch('sync');
    }
  },
  removeCoupon({ state, dispatch }, coupon) {
    if (state.cart.coupons) {
      let removed = false;

      state.cart.coupons.forEach(c => {
        if (c.code === coupon) {
          // eslint-disable-next-line no-param-reassign
          c.remove = true;
          removed = true;
        }
      });

      if (removed) {
        dispatch('sync');
      }
    }
  },
  setDeferrableCombos({ commit }, payload) {
    commit(SET_DEFERRABLE_COMBOS, payload);
  },
  clearCart({ commit }) {
    commit(SET_CART, {});
  },
  clearCheckout({ commit }) {
    commit(SET_CHECKOUT, {});
  },
  async clearCartAndCheckout({ commit, rootState }) {
    commit(SET_CART, {});
    commit(SET_CHECKOUT, {});

    if (rootState.config.orderTypesEnabled) {
      const carts = await fetchCarts();
      commit(SET_CARTS, carts);
    }
  },
  /**
   * Advances the current stage either directly or by contacting the server first to validate stage data
   * @modified - if stage being left was modified. If so, need to set maxStage too
   */
  async checkout({ commit, dispatch, rootGetters }, { newStage, modified = false }) {
    const { checkout } = state;

    // newStage <= checkout.maxStage: Prevents issues related to going to stage and getting kicked back
    if (newStage <= checkout.maxStage) {
      if (newStage > checkout.maxStageReached) {
        commit(SET_MAX_STAGE_REACHED, newStage);
      } else if (checkout.stage > checkout.maxStageReached) {
        commit(SET_MAX_STAGE_REACHED, checkout.stage);
      }
    }

    if (newStage) {
      AnalyticsService.track(TrackableEventConstants.CheckoutStageChanged, {
        stage: newStage,
        customerId: rootGetters['user/getUser']?.sub,
        lineItems: state.cart.lineItems,
      });
    }
    // If we know the requested checkout stage is valid go directly to it
    if (checkout && newStage <= checkout.maxStage && !modified) {
      commit(SET_CHECKOUT_STAGE, newStage);
      return;
    }

    // If we don't know if the stage is valid then contact the server to validate the current state of the cart
    commit(SET_CHECKOUT_STAGE, newStage);
    await dispatch('sync', { immediate: true, updateOnlyCurrentCart: true });
  },
  async setLoadingStatus({ commit }, status) {
    await commit(SET_LOADING_STATUS, status);
  },
  setSelectedTip({ commit }, payload) {
    commit(SET_SELECTED_TIP, payload);
  },
  removeSelectedTip({ commit }, tipProfileId) {
    commit(REMOVE_SELECTED_TIP, tipProfileId);
  },
  async setSelectedRoundUp({ commit, dispatch }, payload) {
    await commit(SET_SELECTED_ROUNDUP, payload);
    dispatch('sync');
  },
  async removeSelectedRoundUp({ commit, dispatch }) {
    await commit(REMOVE_SELECTED_ROUNDUP);
    dispatch('sync');
  },
  addSpecialOrderItem({ commit, dispatch }, payload) {
    if (!state.cart.specialOrderItems) {
      state.cart.specialOrderItems = [];
    }
    commit(ADD_SPECIAL_ORDER_ITEM, payload);
    dispatch('sync');
  },
  updateSpecialOrderItem({ commit, dispatch }, payload) {
    commit(UPDATE_SPECIAL_ORDER_ITEM, payload);
    dispatch('sync');
  },
  removeSpecialOrderItem({ commit, dispatch }, payload) {
    commit(REMOVE_SPECIAL_ORDER_ITEM, payload);
    dispatch('sync');
  },
  async setCurrentCart({ commit, dispatch }, payload) {
    dispatch('setLoadingStatus', true);
    commit(SET_CART, payload);
    commit(SET_CHECKOUT, payload?.checkout);
    dispatch('setLoadingStatus', false);
  },
  async getCarts({ dispatch, commit }, payload = { loading: true }) {
    try {
      if (payload.loading) {
        await dispatch('setCartsLoadingStatus', true);
      }

      await commit(SET_ORDER_TYPES, this.state.config.orderTypes);
      const carts = await fetchCarts();
      await commit(SET_CARTS, carts);
      await dispatch('setCartsLoadingStatus', false);
    } catch (error) {
      console.error(error);
    }
  },
  setCartsLoadingStatus({ commit }, status) {
    commit(SET_CARTS_LOADING_STATUS, status);
  },
  async setCarts({ commit }, payload) {
    try {
      const response = await axios.post('api/ot/carts', payload);
      await commit(SET_CARTS, response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async setDeliveryTotal({ commit }, payload) {
    try {
      await commit(SET_DELIVERY_TOTAL, payload);
    } catch (error) {
      console.log(error);
    }
  },
  setDeliveryInstructions({ commit }, instructions) {
    commit(SET_DELIVERY_INSTRUCTIONS, instructions);
  },
  setDeliveryContactless({ commit }, contactless) {
    commit(SET_DELIVERY_CONTACTLESS, contactless);
  },
  setSendNotifications({ commit }, sendNotifications) {
    commit(SET_DELIVERY_NOTIFICATIONS, sendNotifications);
  },
  setEbtSnapBalance({ commit }, payload) {
    commit(SET_EBT_SNAP_BALANCE, payload);
  },
  setEbtCashBalance({ commit }, payload) {
    commit(SET_EBT_CASH_BALANCE, payload);
  },
  setEbtBalanceTimestamp({ commit }, payload) {
    commit(SET_EBT_BALANCE_TIMESTAMP, payload);
  },
  setEbtSnapEstimatedBalanceEnabled({ commit }, payload) {
    commit(SET_EBT_SNAP_ESTIMATED_BALANCE_ENABLED, payload);
  },
  setCartReplicationStatus({ commit }, payload) {
    commit(SET_CART_REPLICATION_STATUS, payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
