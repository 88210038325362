import emitter from '@/eventBus';

class CartDrawerService {
  constructor() {
    this.emitter = emitter;
  }

  open() {
    this.emitter.$emit('cartDrawer:open');
  }

  close() {
    this.emitter.$emit('cartDrawer:close');
  }

  toggle() {
    this.emitter.$emit('cartDrawer:toggle');
  }
}

export default new CartDrawerService();
