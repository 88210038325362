<template>
  <div ref="recaptcha" />
</template>
<script>
import emitter from '@/eventBus';
import RecaptchaService from '@/modules/recaptcha/services/recaptcha.service';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WCInvisibleRecaptcha',
  data() {
    return {
      widget: null,
      promise: null,
    };
  },
  async mounted() {
    if (!RecaptchaService.isEnabled()) {
      return;
    }

    try {
      this.widget = RecaptchaService.render(this.$refs.recaptcha, {
        callback: token => emitter.$emit('response', token),
        'expired-callback': () => emitter.$emit('expired'),
        'error-callback': error => emitter.$emit('error', error),
      });
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    execute() {
      if (!RecaptchaService.isEnabled()) {
        return Promise.resolve();
      }

      if (!this.promise) {
        this.promise = new Promise((resolve, reject) => {
          emitter.$once('response', token => resolve(token));
          emitter.$once('expired', () => reject(new Error('Recaptcha has expired')));
          emitter.$once('error', error => reject(error));
        });
      }

      RecaptchaService.execute(this.widget);
      return this.promise;
    },
    reset() {
      if (!RecaptchaService.isEnabled()) {
        return;
      }

      this.promise = null;
      RecaptchaService.reset(this.widget);
    },
  },
});
</script>
