<template>
  <platform-renderer>
    <template v-slot:onDesktop>
      <WCItemModifierQuantityDropdown
        v-if="item.quantities"
        :currentSelectedQty="activeQuantityDescriptor"
        :availableQuantities="item.quantities"
        :name="item.name"
        :isSelected="isSelected"
        :disabled="prohibitAlterations"
        @deselectItem="toggleModifier()"
        @selectQty="setSelectedQuantity($event)"
      />
      <!-- END : Dropdown Qty Based Selection -->
      <!-- START : Quantity Adjustor Based Selection -->
      <WCSimpleQuantityAdjustor
        v-else-if="!isMinMaxQtySame"
        :initialValue="itemQuantity"
        :hasRemove="false"
        :incrementStep="incrementQuantity"
        :maxQty="maximumQuantity"
        :minQty="minimumQuantity"
        :disabled="prohibitAlterations"
        @increment="setItemQuantity"
        @decrement="setItemQuantity"
        @remove="toggleModifier()"
      />
    </template>
    <template v-slot:onMobile>
      <div class="mobile-qty-adjustor w-100">
        <!-- START : Dropdown Qty Based Selection -->
        <div class="d-flex justify-content-end" v-if="item.quantities">
          <WCItemModifierQuantityDropdown
            class="m-1"
            :name="item.name"
            :currentSelectedQty="activeQuantityDescriptor"
            :availableQuantities="item.quantities"
            :isSelected="isSelected"
            :disabled="prohibitAlterations"
            @deselectItem="toggleModifier()"
            @selectQty="setSelectedQuantity($event)"
          />
        </div>
        <!-- END : Dropdown Qty Based Selection -->

        <!-- START : Numeric Qty Based Selection -->
        <div
          v-else
          v-click-outside="hideQtyAdjustor"
          class="d-flex align-items-center justify-content-end mr-2 mt-2"
        >
          <!-- START : Add Item Plus Icon -->
          <!-- Increment button - On clicking plus icon add item to Current Selection -->
          <button
            class="unstyled-btn wc-add-item-icon"
            v-if="!isSelected && !prohibitAlterations"
            @click.stop="toggleModifier()"
            :aria-label="$t('addItem')"
          >
            <font-awesome-icon icon="plus" size="lg" />
          </button>
          <!-- END : Add Item Plus Icon -->

          <!-- START : On Min & Max Qty Same Show Select -->
          <button
            v-else-if="isMinMaxQtySame && !prohibitAlterations"
            class="unstyled-btn wc-add-item-icon"
            :aria-label="$t('remove')"
            @click.stop="toggleModifier()"
          >
            <font-awesome-icon icon="trash" size="lg" />
          </button>
          <!-- END : On Min & Max Qty Same Show Select -->
          <!-- START : Quantity Adjustor -->
          <WCSimpleQuantityAdjustor
            v-else-if="isQtyAdjustorVisible && !prohibitAlterations"
            class="mr-n1 mt-n1"
            :initialValue="itemQuantity"
            :hasRemove="true"
            :incrementStep="incrementQuantity"
            :maxQty="maximumQuantity"
            :minQty="minimumQuantity"
            :disabled="prohibitAlterations"
            @increment="setItemQuantity"
            @decrement="setItemQuantity"
            @remove="toggleModifier()"
          />
          <!-- END : Quantity Adjustor -->

          <!-- START : Selected Quantity Badge -->
          <button
            v-else
            class="quantity-badge unstyled-btn font-size-sm"
            :disabled="prohibitAlterations"
            @click.stop="showHideQtyAdjustor(true)"
          >
            {{ itemQuantity }}
          </button>
          <!-- END : Selected Quantity Badge -->
        </div>
        <!-- END : Numeric Qty Based Selection -->
      </div>
    </template>
  </platform-renderer>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import { BigNumber } from 'bignumber.js';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import WCItemModifierQuantityDropdown from '@/modules/itemModifiers/components/WCItemModifierQuantityDropdown/WCItemModifierQuantityDropdown.vue';
import WCSimpleQuantityAdjustor from '@/components/WCSimpleQuantityAdjustor/WCSimpleQuantityAdjustor.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'WCItemModifierItemQuantityAdjuster',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    PlatformRenderer,
    WCItemModifierQuantityDropdown,
    WCSimpleQuantityAdjustor,
  },
  emits: ['update:activeQuantityDescriptor', 'toggleModifier'],
  props: {
    category: {
      required: true,
      type: Object,
    },
    item: {
      required: true,
      type: Object,
    },
    isSelected: {
      type: Boolean,
    },
    prohibitAlterations: {
      type: Boolean,
      default: false,
    },
    currentAnswer: {
      type: Object,
    },
    activeQuantityDescriptor: {
      type: Object,
    },
  },
  data() {
    return {
      isQtyAdjustorVisible: false,
      initialDropdownQty: null,
      initialSpinnerQty: null,
      isMinMaxQtySame: false,
    };
  },
  created() {
    this.setInitialValue();

    // Check if the min max qunatity equal
    if (this.item.minimum === this.item.maximum) {
      this.isMinMaxQtySame = true;
    }
  },
  methods: {
    /**
     * Method to set initial value for initialDropdownQty, initialSpinnerQty,
     */
    setInitialValue() {
      // Dropdown Selection
      let itemDropdownQty = null;
      if (this.item.quantities) {
        // To set default dropdown qty
        itemDropdownQty = this.item.quantities.find(q => q.defaultItem) || this.item.quantities[0];
        this.initialDropdownQty = itemDropdownQty;
        this.initialSpinnerQty = 0;
      } else {
        this.initialSpinnerQty = new BigNumber(0);
      }
    },

    /**
     * Method to set selected dropdown quantity type
     * @param {object} - selected dropdown quantity
     */
    setSelectedQuantity(selectedQuantity) {
      const spinnerQuantity = new BigNumber((selectedQuantity && selectedQuantity.quantity) || 1);
      if (!this.isSelected) {
        this.toggleModifier();
      }
      this.changed({ spinnerQuantity, selectedQuantity });
    },

    /**
     * Method to set spinner quantity
     * @param {BigNumber} - spinner quantity value
     */
    setItemQuantity(itemQuantity) {
      this.changed({ spinnerQuantity: itemQuantity });
    },

    /**
     * Method to emit updated spinner quantity and selected quantity
     */
    changed({ spinnerQuantity, selectedQuantity } = {}) {
      this.$emit('update:activeQuantityDescriptor', {
        quantity: spinnerQuantity || this.itemQuantity,
        quantityDescriptor: selectedQuantity || this.activeQuantityDescriptor,
      });
    },

    /**
     * Method to show or hide quantity adjustor
     * @param {Boolean} - isShow - true|false
     */
    showHideQtyAdjustor(isShow = true) {
      this.isQtyAdjustorVisible = isShow;
    },

    /**
     * Method to hide quantity adjustor
     */
    hideQtyAdjustor() {
      this.showHideQtyAdjustor(false);
    },

    /**
     * Method to toggle item selection and hide dropdown and quantity dropdown selection
     */
    toggleModifier() {
      this.$emit('toggleModifier');
      this.hideQtyAdjustor();
    },
  },
  computed: {
    ...mapGetters({
      categoryCounts: 'items/getCategoryCounts',
    }),
    /**
     * Method to get maximum allowed item quantity
     */
    maximumQuantity() {
      if (!this.item.countTowardsMaxSelections || !this.category.max) {
        return new BigNumber(this.item.maximum);
      }

      const currentQuantityOfItem = this.currentAnswer
        ? new BigNumber(this.currentAnswer.quantity)
        : new BigNumber(0);
      const currentQuantityOfCategory = this.categoryCounts[this.category.id]
        ? new BigNumber(this.categoryCounts[this.category.id])
        : new BigNumber(0);
      // The categoryCount includes contributions from all items. To determine what other items
      // are contributing, we must subtract our own quantity.
      const currentQuantityOfOtherItemsInThisCategory = currentQuantityOfCategory.minus(
        currentQuantityOfItem,
      );
      const categoryMax = new BigNumber(this.category.max);
      // The maximum quantity imposed by the category limits is equal to the difference between
      // the categoryMax and the contribution of all items other than ourselves.
      const itemMaxByCategory = categoryMax.minus(currentQuantityOfOtherItemsInThisCategory);

      let itemMaxByLimits = new BigNumber(this.item.maximum);
      if (itemMaxByLimits.isZero()) {
        itemMaxByLimits = new BigNumber('Infinity');
      }

      return BigNumber.min(itemMaxByCategory, itemMaxByLimits);
    },

    /**
     * Method to get minimum allowed item quantity
     */
    minimumQuantity() {
      return new BigNumber(this.item.minimum);
    },

    /**
     * Method to get currently selected item quantity value
     */
    itemQuantity() {
      let itemValue = 0;
      // As Quantity is applicable for both description type and numeric qty
      if (this.currentAnswer && this.currentAnswer.quantity) {
        itemValue = new BigNumber(this.currentAnswer.quantity);
      }
      if (!itemValue) {
        itemValue = this.initialSpinnerQty;
      }
      return itemValue;
    },

    /**
     * Method to get quantity increment value
     */
    incrementQuantity() {
      return new BigNumber(this.item.increment || 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

/*----- START : Mobile Qty Adjustor -----*/
.mobile-qty-adjustor {
  /*----- START : Btn Styles -----*/
  .btn {
    background-color: var(--white, $white);
  }
  .btn-outline-primary:hover {
    background-color: var(--primary, $primary);
  }
  /*----- END : Btn Styles -----*/

  /*----- START : Add Item Icon -----*/
  .wc-add-item-icon {
    color: var(--primary, $primary);
    cursor: pointer;
  }
  /*----- END : Add Item Icon -----*/

  /*----- START : Qty Badge -----*/
  .quantity-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.7rem;
    border-radius: 10rem;
    height: rem(32px);
    background-color: var(--primary, $primary);
    color: var(--white, $white);
  }
  /*----- END : Qty Badge -----*/

  /*----- START : Qty Adjustor -----*/
  .qty-adjustor-btn-grp {
    margin-top: $margin-1;
    margin-right: $margin-1;

    .btn {
      border: 1px solid var(--gray-500, $gray-500);
      &.qty {
        min-width: rem(60px);
      }
    }
    @include media-breakpoint-down(md) {
      zoom: 0.75;
      -moz-transform: scale(0.75);
    }
  }
  /*----- END : Qty Adjustor -----*/
}
/*----- END : Mobile Qty Adjustor -----*/
</style>
