<template>
  <textarea
    v-bind="$attrs"
    class="form-control border-dark"
    :class="classes"
    :value="_model"
    @input="e => (_model = e.target.value)"
    :rows="rows"
    v-on="listeners"
  />
</template>

<script>
export default {
  name: 'WCTextarea',
  emits: ['update:modelValue'],
  inject: {
    field: { default: undefined },
  },
  props: {
    modelValue: {},
    rows: {
      type: [Number, String],
      default: 3,
    },
    size: {
      type: String,
      validator: value => ['small', 'default', 'large'].includes(value),
      default: 'default',
    },
    state: {
      type: String,
      validator: value => ['valid', 'invalid'].includes(value),
      default: null,
    },
  },
  computed: {
    listeners() {
      let listeners = {};
      Object.keys(this.$attrs).forEach(key => {
        if (key.startsWith('on') && key !== 'onInput') {
          listeners = { ...listeners, [key]: this.$attrs[key] };
        }
      });
      return listeners;
    },
    _model: {
      get() {
        return this.field ? this.field.$model : this.modelValue;
      },
      set(value) {
        if (this.field) {
          this.field.$model = value;
        }
        this.$emit('update:modelValue', value);
      },
    },
    _state() {
      if (this.field && this.field.$error) {
        return 'invalid';
      }

      return this.state;
    },
    classes() {
      const classes = [];

      switch (this.size) {
        case 'small':
          classes.push('form-control-sm');
          break;
        case 'large':
          classes.push('form-control-lg');
          break;
        default:
          break;
      }

      switch (this._state) {
        case 'valid':
          classes.push('is-valid');
          break;
        case 'invalid':
          classes.push('is-invalid');
          break;
        default:
          break;
      }

      return classes;
    },
  },
};
</script>
