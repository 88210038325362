<template>
  <WCSelect v-model="selected" @update:modelValue="change" :aria-label="$t('categoriesMenu')">
    <option :value="allType.id">
      {{ $t('allCategories') }}
    </option>
    <template v-if="sortedItemGroups">
      <option v-for="itemGroup in sortedItemGroups" :key="itemGroup.id" :value="itemGroup.id">
        {{ itemGroup.name }}
      </option>
    </template>
    <option v-if="viewAll" class="btn-link" :key="view_all">{{ $t('viewAll') }}</option>
  </WCSelect>
</template>

<script>
import WCSelect from '@/modules/forms/components/WCSelect/WCSelect.vue';
import { dynamicSort } from '@/utils';

export default {
  emits: ['change'],
  name: 'WCItemGroupSelector',
  props: {
    itemGroups: {
      required: true,
      type: Array,
    },
    viewAll: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change() {
      this.$emit('change', this.selected);
    },
  },
  components: { WCSelect },
  data() {
    return {
      allType: { id: '-1', name: 'all_type' },
      selected: {},
    };
  },
  created() {
    this.selected = this.allType.id;
    this.change();
  },
  computed: {
    /**
     * Sort item groups based on name in ascending order
     */
    sortedItemGroups() {
      return dynamicSort(this.itemGroups, 'name');
    },
  },
};
</script>
