<template>
  <div class="wc-payment-badges">
    <WCPaymentOptionBadge
      v-for="paymentOption in displayableOptions"
      :key="paymentOption.id"
      :value="paymentOption.name"
      :paymentOption="paymentOption"
    />
  </div>
</template>

<script>
import WCPaymentOptionBadge from '@/components/WCPaymentOptionBadge/WCPaymentOptionBadge.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
    ebtOnly: {
      type: Boolean,
    },
  },
  components: {
    WCPaymentOptionBadge,
  },
  computed: {
    displayableOptions() {
      let specialTenders = this.item.specialTenders;
      if (this.ebtOnly || !this.$configuration.showAllSpecialTenderBadges) {
        // Only display EBT SNAP and TANF by default
        specialTenders = specialTenders?.filter(
          specialTender => specialTender.tenderType === 6 || specialTender.tenderType === 12,
        );
      }

      return specialTenders?.sort((a, b) => a.name - b.name);
    },
  },
});
</script>

<style scoped lang="scss">
.wc-payment-badges {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}
</style>
