class GoogleOptimizeService {
  loaded = false;

  load(googleOptimizeId) {
    if (!this.loaded && googleOptimizeId) {
      this.loaded = new Promise(resolve => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://www.googleoptimize.com/optimize.js?id=${this.googleOptimizeId}`;
        document.head.appendChild(script);
        resolve();
      });
    }

    return this.loaded;
  }
}

export default new GoogleOptimizeService();
