import { createApp } from 'vue';
import RecaptchaService from '@/modules/recaptcha/services/recaptcha.service';
import axios from 'axios';
import App from './App.vue';
import setup from './setup';
import './assets/styles/index.scss';
import { store, SET_CONFIGURATION } from './store';
import ConfigurationService from './services/configuration.service';
import './registerServiceWorker';
import { Configuration } from './types/configuration.d';

axios.interceptors.response.use(async response => {
  if (response.headers['flag-set-cookie']) {
    await store.dispatch('user/setUserFromCookie');
  }
  return response;
});

axios.interceptors.request.use(
  request => {
    // eslint-disable-next-line no-param-reassign
    request.headers.Accept = 'application/vnd.ecrs.webcart-2.0+json;q=1.0, application/json;q=0.9';
    return request;
  },
  error => Promise.reject(error),
);

/**
 * Method to initialize app with configuration
 * @param {Configuration} configuration
 */
const initializeApp = async (configuration: Configuration) => {
  RecaptchaService.configure();
  if (RecaptchaService.isEnabled()) {
    await RecaptchaService.load();
  }
  const app = createApp(App);
  setup(app, configuration);

  const vm = app.mount('#app');

  axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? vm.$configuration.siteUrl : '/';
  await store.dispatch('user/setUserFromCookie');
};
/**
 * Method to set app configuration
 */
const setAppConfiguration = async () => {
  ConfigurationService.load().then(async configuration => {
    store.commit(SET_CONFIGURATION, configuration);
    await initializeApp(configuration);
  });
};
setAppConfiguration();
