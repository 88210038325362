<template>
  <WCSpinner v-if="loader" position="center" size="xl" />
  <WCModal v-else :aria-label="$t('addItemToList')" @dismiss="dismiss('Dismissed')">
    <WCModalHeader class="align-items-baseline">
      <h1 class="font-size-lg">{{ $t('addItemToListTitle') }}</h1>
      <button class="btn btn-link underline-link-from-center" @click="dismiss('Dismissed')">
        {{ $t('cancel') }}
      </button>
    </WCModalHeader>
    <WCModalBody>
      <div class="d-flex align-items-baseline justify-content-between">
        <span class="font-weight-bold">{{ $t('chooseAList') }}</span>
        <button
          class="wc-create-list__btn btn btn-link underline-link-from-center font-weight-bold"
          @click="open"
        >
          {{ $t('createANewList') }}
        </button>
      </div>
      <WCSelect
        class="wc-add-to-list__select"
        v-model="selected"
        :aria-label="$t('createANewList')"
      >
        <option disabled :value="{}">{{ $t('pleaseSelectAList') }}</option>
        <option v-for="list in sortedLists" :key="list.id" :selected="list" :value="list">{{
          list.name
        }}</option>
      </WCSelect>
    </WCModalBody>
    <WCModalFooter>
      <button
        class="wc-modal__footer-btn--single btn btn-primary mx-auto"
        :disabled="isEmptyList"
        @click="add(selected)"
      >
        {{ $t('save') }}
      </button>
    </WCModalFooter>
  </WCModal>
</template>
<script>
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';
import { dynamicSort } from '@/utils';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCSelect from '@/modules/forms/components/WCSelect/WCSelect.vue';
import ToastService from '@/modules/toasts/services/ToastService';
import ModalService from '@/modules/modals/services/modal.service';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import WCAddToListToast from '@/modules/lists/components/WCAddToListToast/WCAddToListToast.vue';
import WCCreateListModal from '@/modules/lists/components/WCCreateListModal/WCCreateListModal.vue';
import ItemAddToListMixin from '@/modules/lists/mixins/ItemAddToListMixin';

export default {
  name: 'WCAddToListModal',
  mixins: [ModalMixin, ItemAddToListMixin],
  components: {
    WCSpinner,
    WCModal,
    WCModalHeader,
    WCModalBody,
    WCSelect,
    WCModalFooter,
  },
  data() {
    return {
      selected: {},
      loader: false,
    };
  },
  props: {
    item: {},
    variation: {
      type: String,
    },
    modalMessage: {
      type: Object,
    },
    items: [],
    disableToast: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      lists: 'lists/getLists',
    }),
    /**
     * Sort lists options in ascending order
     */
    sortedLists() {
      return dynamicSort(this.lists, 'name');
    },

    /**
     * Check if list is selected or empty
     */
    isEmptyList() {
      return (
        this.selected &&
        Object.keys(this.selected).length === 0 &&
        this.selected.constructor === Object
      );
    },
  },
  async created() {
    // To check if the create list modal has stored the newly created list details
    if (!isEmpty(this.modalMessage) && this.modalMessage.id) {
      this.loader = true;
      // To find the newly created list in the vuex state
      const newList = this.lists.find(list => list.id === this.modalMessage.id);
      if (newList) this.selected = newList;
      await this.add(newList || this.modalMessage);
      this.loader = false;
    }
  },
  methods: {
    async open() {
      try {
        await ModalService.open(WCCreateListModal);
      } catch (error) {
        // NOOP
      }
    },
    /**
     * Method to add the item into the selected list
     * @param {Object} selectedList - selected list into which the product will be added.
     */
    async add(selectedList) {
      try {
        this.loader = true;
        let list;
        if (this.items && this.items.length > 0) {
          list = await this.addItemsToList({
            list: selectedList,
            items: this.items,
          });
        } else {
          const shoppingListItem = {
            list: selectedList,
            item: this.item,
          };
          if (this.variation) {
            shoppingListItem.variation = this.variation;
          }
          list = await this.addItemToList(shoppingListItem);
        }
        this.loader = false;
        this.close();
        if (!this.disableToast) {
          ToastService.open(WCAddToListToast, {
            props: {
              variant: 'favorite',
              title: `Item added to ${list.name}`,
              list,
              close: {},
              dismiss: {},
            },
            timeout: 2000,
          });
        }
      } catch (error) {
        this.loader = false;
        ToastService.open(WCAddToListToast, {
          props: {
            variant: 'danger',
            title: 'Error',
            message: error.message,
          },
          timeout: 7000,
        });
      }
    },
  },
};
</script>
