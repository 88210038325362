<template>
  <PlatformRenderer>
    <template v-slot:onDesktop>
      <button v-bind="$attrs" @click="editSpecialOrder(lineItem)" ref="editSpecialOrderBtnRef">
        <div class="ml-2" />
        <font-awesome-icon icon="pencil" size="sm" />
        {{ $t('edit') }}
      </button>
    </template>
    <template v-slot:onMobile>
      <button
        class="btn align-items-center justify-content-center"
        v-bind="$attrs"
        @click="editSpecialOrder(lineItem)"
        :aria-label="$t('edit')"
      >
        <font-awesome-icon icon="pencil" size="sm" />
        {{ $t('edit') }}
      </button>
    </template>
  </PlatformRenderer>
</template>

<script>
import ModalService from '@/modules/modals/services/modal.service';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import WCEditSpecialOrderItemModal from '../WCEditSpecialOrderItemModal/WCEditSpecialOrderItemModal.vue';

export default {
  components: { PlatformRenderer },
  name: 'WCEditSpecialOrderItemButton',
  props: {
    lineItem: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async editSpecialOrder(lineItem) {
      try {
        await ModalService.open(WCEditSpecialOrderItemModal, {
          lineItem,
          editingItem: true,
        });
      } catch (error) {
        console.log(error);
      } finally {
        if (this.$refs.editSpecialOrderBtnRef) this.$refs.editSpecialOrderBtnRef.focus();
      }
    },
  },
};
</script>
