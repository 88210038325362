<template>
  <div>
    <span class="sr-only" :id="`${uniqueId}-menu-label`">{{ dropdownLabel }}</span>
    <div class="dropdown nav-item" v-click-outside="hideDropdown">
      <button
        class="unstyled-btn nav-link h4 btn-primary dropdown-toggle dropdownMenuButton mb-0"
        :class="btnClass"
        data-toggle="dropdown"
        :disabled="disabled"
        :id="`${uniqueId}-dropdown`"
        @click="toggleDropdown"
        @keydown.esc.exact.stop="hideDropdown"
        @keydown.shift.tab="hideDropdown"
        @keydown.up.exact.prevent="initialFocusWithArrowKeys"
        @keydown.down.exact.prevent="initialFocusWithArrowKeys"
        @keydown.tab.exact="focusedIndex = 0"
        aria-haspopup="listbox"
        :aria-labelledby="`${uniqueId}-menu-label ${uniqueId}-dropdown`"
        :aria-expanded="showDropdown ? 'true' : 'false'"
      >
        {{ name }}
      </button>
      <!-- START: Available Options Dropdown -->
      <div
        ref="dropdownMenus"
        class="dropdown-menu"
        :style="dropdownMenusStyle"
        v-if="showDropdown"
        :class="{ show: showDropdown }"
        role="listbox"
        :aria-labelledby="`${uniqueId}-menu-label`"
        :aria-activedescendant="focusedIndex >= 0 ? 'selected-option' : null"
      >
        <button
          v-for="(option, index) in availableOptions"
          :key="option.id"
          :value="currentSelectedOption"
          :class="{
            selected: currentSelectedOption && currentSelectedOption.id == option.id,
            'font-weight-bold': option.isBold,
          }"
          :id="focusedIndex === index ? 'selected-option' : null"
          :aria-selected="true && currentSelectedOption && currentSelectedOption.id == option.id"
          class="dropdown-item btn-sm"
          @click="setSelectedOption(option)"
          @keydown.esc.exact.stop="hideDropdown"
          @keydown.tab.exact="
            index === availableOptions.length - 1 ? hideDropdown() : focusNext(false)
          "
          @keydown.shift.tab.exact="index === 0 ? (focusedIndex = -1) : focusPrevious(false)"
          @keydown.down.exact.prevent="index === availableOptions.length - 1 ? '' : focusNext(true)"
          @keydown.up.exact.prevent="index === 0 ? '' : focusPrevious(true)"
          role="option"
        >
          {{ option.name }}
        </button>
      </div>
      <!-- END : Available Options Dropdown  -->
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'WCCustomSelect',
  props: {
    currentSelectedOption: {
      type: Object,
      default: null,
    },
    availableOptions: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    uniqueIdentifier: {
      type: String,
      required: true,
    },
    /**
     * Brief description or label about dropdown
     */
    dropdownLabel: {
      type: String,
      required: true,
    },
    btnClass: {
      type: String,
    },
    unusableHeight: {
      required: false,
      type: String,
      default: '0px',
    },
  },
  emits: ['selectedOption'],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showDropdown: false,
      focusedIndex: -1,
    };
  },
  computed: {
    /**
     * Unique Id property
     */
    uniqueId() {
      return this.uniqueIdentifier.replace(/\s/g, '-').toLowerCase();
    },
    dropdownMenusStyle() {
      return `max-height: calc(100vh - ${this.unusableHeight}); overflow-y: auto;`;
    },
  },
  methods: {
    /**
     * Method to toggle dropdown options menu
     */
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    /**
     * Method to hide dropdown options menu
     */
    hideDropdown() {
      this.focusedIndex = -1;
      this.showDropdown = false;
    },

    /**
     * Method to set selected option and emit the state to parent
     */
    setSelectedOption(option) {
      this.$emit('selectedOption', option);
      this.hideDropdown();
    },

    /**
     * Method to start initial focus with arrow keys for keyboard accessibility
     */
    initialFocusWithArrowKeys() {
      if (
        this.showDropdown &&
        this.$refs.dropdownMenus?.children &&
        this.$refs.dropdownMenus?.children.length
      ) {
        this.focusedIndex = 0;
        this.$refs.dropdownMenus.children[0].focus();
      }
    },

    /**
     * Method to focus previous item with up or shift+tab keys
     */
    focusPrevious(needFocus) {
      this.focusedIndex -= 1;
      if (needFocus) this.focusItem();
    },

    /**
     * Method to focus next item with down or tab key
     */
    focusNext(needFocus) {
      this.focusedIndex += 1;
      if (needFocus) this.focusItem();
    },

    /**
     * Method to focus item
     */
    focusItem() {
      if (this.$refs.dropdownMenus?.children && this.$refs.dropdownMenus?.children.length)
        this.$refs.dropdownMenus.children[this.focusedIndex].focus();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

/*----- START : Dropdown Item -----*/
.dropdown-item.selected,
.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--secondary-lighter-7, get-color('secondary', 'lighter-7'));
  color: var(--secondary-contrast, get-color-contrast('secondary'));
}
.dropdown-item.selected {
  background-color: var(--secondary, get-color('secondary'));
}
/*----- END : Dropdown Item -----*/
</style>
