import axios from 'axios';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ToastService from '@/modules/toasts/services/ToastService';
import { CREDIT_CARDS, PAYMENT_METHOD_TYPES } from '@/constants/AppConstants';
import { isTokenExpired } from '../utils/WalletUtils';
import FeatureWhitelistMixin from '../../../mixins/FeatureWhitelistMixin';

export default {
  mixins: [FeatureWhitelistMixin],
  data() {
    return {
      wallet: null,
      tokenizeCallback: null,
      continueEnabled: false,
      balanceReloadEnabled: false,
      loading: false,
      error: null,
    };
  },
  methods: {
    async fetchWallet() {
      this.loading = true;
      try {
        const response = await axios.get('api/me/wallet');
        this.handleWalletResponse(response?.data);
      } catch (error) {
        this.error = error;
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    /**
     * Method to handle wallet response
     * @param {Object} response
     */
    handleWalletResponse(response) {
      this.wallet = response;
    },

    async fetchChargeAccountReloadPaymentMethods() {
      try {
        const response = await axios.get('api/ca/reloadMethods');
        this.setChargeAccountReloadPaymentMethodsFromResponse(response);
      } catch (error) {
        this.error = error;
        console.error(error);
      }
    },

    setChargeAccountReloadPaymentMethodsFromResponse(response) {
      const apiResponse = response.data;
      if (apiResponse.code !== 0) {
        console.error(apiResponse.message);
        return;
      }
      const fetchedChargeAccountReloadPaymentMethods = apiResponse.data;
      this.chargeAccountReloadPaymentMethods = fetchedChargeAccountReloadPaymentMethods;
    },

    /**
     * Method to add new wallet card
     */
    async addCard() {
      try {
        this.loading = true;
        const ccInfo = await this.tokenizeCallback();

        // Always send expiry to backend as MM/YY or MMYY
        if (ccInfo?.expiry?.length > 5) {
          if (ccInfo.expiry.includes('/')) {
            ccInfo.expiry = ccInfo.expiry.replace('/', '');
          }
          ccInfo.expiry = ccInfo.expiry.slice(0, 2) + ccInfo.expiry.slice(4);
        }
        const payload = {
          ...ccInfo,
          recaptcha: await this.$refs.recaptcha.execute(),
        };
        const response = await axios.post('api/me/wallet/addToken', payload);
        this.handleWalletResponse(response.data);
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'success',
            title: 'Add Wallet Card',
            message: 'Your Card has been successfully added',
          },
          timeout: 7000,
        });
      } catch (error) {
        this.error = error;
        console.error(error);
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: 'Error',
            message: 'Unable to save your card at this time.',
          },
          timeout: 7000,
        });
        throw error;
      } finally {
        this.loading = false;
        this.continueEnabled = false;
      }
    },

    /**
     * Method to make a card as default card
     * @param {Object} token
     */
    async defaultToken(token) {
      try {
        const response = await axios.post('api/me/wallet/updateDefaultToken', token);
        this.handleWalletResponse(response.data);
      } catch (error) {
        this.error = error;
        console.error(error);
      }
    },

    /**
     * Method to delete card  and display toast on success or failure
     * @param {Object} token
     */
    async deleteToken(token) {
      try {
        const response = await axios.post('api/me/wallet/deleteToken', token);
        this.handleWalletResponse(response.data?.wallet ? response.data?.wallet : response.data);
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'success',
            title: this.$t('deleteCard'),
            message: this.$t('deleteCardSuccess', { finalDigits: token.finalDigits }),
          },
          timeout: 7000,
        });
        return response;
      } catch (error) {
        this.error = error;
        console.error(error);
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: 'Error',
            message: 'Unable to delete your card at this time',
          },
          timeout: 7000,
        });
        return null;
      }
    },

    /**
     * Method to display brand name if brand name is returned from API,
     * If the brand name is empty or returned as 'Other', then display 'Card'
     * @param {String} brand
     * @returns String
     */
    brandDisplayName(brand) {
      if (brand && brand !== 'Other') {
        return brand;
      }
      return 'Card';
    },

    /**
     * Method to get fav icon for credit card
     * @param {String} brand
     * @returns Boolean
     */
    getFAIcon(brand) {
      return CREDIT_CARDS.includes(
        brand
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, '')
          .replace(/\s/g, ''),
      ) && brand !== 'Other'.toLowerCase()
        ? ['fab', `cc-${brand.toLowerCase()}`]
        : ['far', 'credit-card'];
    },
    checkTokenExpiration(token) {
      return isTokenExpired(token);
    },

    chargeAccountDisplayBalanceIsOpenToChargeAmount(account) {
      return (
        this.getChargeAccountDisplayBalance(account) ===
        this.getChargeAccountOpenToChargeAmount(account)
      );
    },

    /**
     * Get a charge account balance as it should be displayed to the user.
     *
     * The balance is how much the shopper owes the merchant.
     * Here we invert this to communicate how much the merchant owes the shopper.
     * @param {Object} account
     * @return {Number}
     */
    getChargeAccountDisplayBalance(account) {
      // Don't return "-0"
      if (account.balance === 0) {
        return 0;
      }
      return -1 * account.balance;
    },

    getChargeAccountOpenToChargeAmount(account) {
      return account.creditLimit - account.balance;
    },

    getChargeAccountReloadButtonText(account) {
      return this.isChargeAccountTraditional(account) ? this.$t('makeAPayment') : this.$t('reload');
    },

    isChargeAccountTraditional(account) {
      return account.creditLimit > 0;
    },

    isChargeAccountDisplayBalanceNegative(account) {
      return this.getChargeAccountDisplayBalance(account) < 0;
    },
  },
  computed: {
    isEbtEnabled() {
      return this.wallet?.ebtPaymentMethod && this.showEbtForCustomer;
    },
    creditTokens() {
      return this.wallet?.tokens?.filter(token => token.tenderType === PAYMENT_METHOD_TYPES.CREDIT);
    },
    ebtTokens() {
      return this.wallet?.tokens?.filter(
        token =>
          token.tenderType === PAYMENT_METHOD_TYPES.EBT_SNAP ||
          token.tenderType === PAYMENT_METHOD_TYPES.EBT_CASH_BENEFITS,
      );
    },
    ebtSnapTokens() {
      return this.wallet?.tokens?.filter(
        token => token.tenderType === PAYMENT_METHOD_TYPES.EBT_SNAP,
      );
    },
  },
};
