<template>
  <div
    class="wc-mobile-subpage-header container-fluid text-primary-contrast px-3 py-2 sticky-header"
    :class="scrollClass"
  >
    <!-- customClick prop - to have custom event on clicking go back icon -->
    <button
      class="unstyled-btn w-25 text-left"
      @click="!customClick ? routeBack() : customEvent()"
      :aria-label="$t('goBack')"
    >
      <font-awesome-icon class="font-size-xl align-middle" icon="arrow-left" />
    </button>
    <h2 class="mx-auto my-auto wc-account-subpage-header__text ">{{ title }}</h2>
    <div class="w-25" />
  </div>
</template>

<script>
import HeaderScrollTransitionMixin from '@/modules/platform/mixins/HeaderScrollTransitionMixin';

export default {
  emits: ['customClickEvent'],
  name: 'WCMobileSubpageHeader',
  mixins: [HeaderScrollTransitionMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    customClick: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * To have custom event on clicking go back
     * - particularly for mobile see more popup
     */
    customEvent() {
      this.$emit('customClickEvent');
    },
    routeBack() {
      if (window?.history?.state?.back == null) {
        this.$router.push({ name: 'Home' });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-mobile-subpage-header {
  display: flex;
  align-items: center;
  background-color: var(--primary, get-color('primary'));
}

.sticky-header {
  padding-top: $pad-2;
  position: fixed;
  top: 50;
  right: 0;
  left: 0;
  z-index: map-get($zindex, 'header');

  @include media-breakpoint-down(sm) {
    &.scrolled-down {
      transform: translateY(-365%);
      transition: all 0.3s ease-in-out;
    }

    &.scrolled-up {
      transform: translateY(0);
      transition: all 0.3s ease-in-out;
    }
  }
}
</style>
