export default {
  "AcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode Scan et Go"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
  "accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro du compte"])},
  "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du compte"])},
  "addAsFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ajouter ", _interpolate(_named("name")), " comme favori"])},
  "addCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des coupons"])},
  "addedSugars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sucres ajoutés"])},
  "addItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un article"])},
  "addItemsToOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des articles à la commande"])},
  "addItemToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter l’article à la liste"])},
  "addItemToListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter cet article à une liste"])},
  "addNewCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle carte"])},
  "addNewList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle liste"])},
  "addNoteAboutItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une note à propos de cet article"])},
  "addPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une méthode de paiement"])},
  "addSelectedToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter la sélection au panier"])},
  "addSpecialInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des instructions spécifiques"])},
  "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au panier"])},
  "addToFavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au favori"])},
  "addToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à la liste"])},
  "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte"])},
  "alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
  "allCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les catégories"])},
  "allOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les commandes"])},
  "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser"])},
  "allowCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser l’utilisation de la caméra"])},
  "allowSubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les substitutions"])},
  "allRightsReserved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("year")), " WebCart - Tous droits réservés"])},
  "alreadyVerifiedYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà vérifié votre compte ?"])},
  "alternateItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles de substitution"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "amountMustBeAtLeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant doit être au moins égal à"])},
  "amountMustBeLessOrEqual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La quantité doit être inférieure ou égale à"])},
  "amountPerServing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité par portion"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
  "applyChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer les modifications"])},
  "areYouSureCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir annuler vos modifications ?"])},
  "areYouSureCancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir annuler votre commande ?"])},
  "areYouSureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer la carte se terminant par *"])},
  "associate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S’associer"])},
  "associateAsCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S’associer en tant que client"])},
  "associationBanner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mode d'association client, Utilisation de WebCart en tant que ", _interpolate(_named("name"))])},
  "assumesNoLiability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n'assume aucune responsabilité pour les inexactitudes ou les déclarations erronées concernant les produits."])},
  "asYouScanItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au fur et à mesure que vous scannez les articles de votre liste, nous les cocherons pour vous"])},
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
  "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilité"])},
  "availableBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde disponible"])},
  "averagePerUnitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix unitaire moyen"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
  "barcodeAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code-barres"])},
  "barcodePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichez ce code-barres à scanner à la caisse"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anniversaire"])},
  "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
  "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fil d'Ariane"])},
  "browseByCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXPLORER PAR CATÉGORIE"])},
  "browseQueryText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("browseText")), "\""])},
  "browseResultQuery": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("browseResult")), "\""])},
  "buyItAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achetez-le à nouveau"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appelez"])},
  "callForPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appelez pour un prix"])},
  "callUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appelez-nous"])},
  "calories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calories"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "cancelEditOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la modification de votre commande"])},
  "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la commande"])},
  "cancelOrderFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons rencontré un problème lors de l’annulation de votre commande. Veuillez rafraîchir la page et réessayer."])},
  "cancelOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande a été annulée."])},
  "cancelQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler ?"])},
  "cannotAddItemToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'ajouter un article au panier"])},
  "cardEnteredInvalidInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de carte que vous avez saisi est invalide ou inactif. Vérifiez le numéro et essayez de le saisir à nouveau."])},
  "cardholderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du titulaire de la carte"])},
  "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de carte"])},
  "cardNumberIsNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de carte n'est pas valide"])},
  "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier"])},
  "cashierApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "cashierApprovalMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
  "categoriesMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu des catégories"])},
  "categoryLimitExceededMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous ne pouvez pas choisir plus de ", _interpolate(_named("limit")), " dans la catégorie ", _interpolate(_named("categoryName")), "."])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe"])},
  "changeYourMind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez changé d'avis ?"])},
  "changeYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer votre mot de passe"])},
  "chargeAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes de débit"])},
  "checkCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier la carte"])},
  "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commander"])},
  "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer en caisse"])},
  "checkShoppingAtCorrectStore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes en train de faire des achats à ", _interpolate(_named("storeName")), ". Assurez-vous qu'il s'agit bien du magasin où vous souhaitez passer votre commande."])},
  "checkTheBoxForReceipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cochez la case pour recevoir vos reçus d'achats en magasin par e-mail."])},
  "checkTheGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cochez les groupes auxquels vous voulez appartenir."])},
  "checkYourBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre solde"])},
  "choose": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choisissez ", _interpolate(_named("max"))])},
  "chooseACategoryMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un menu de catégorie"])},
  "chooseAList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une liste"])},
  "chooseAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un compte"])},
  "chooseAtLeast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choisissez au moins ", _interpolate(_named("min"))])},
  "chooseBetween": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choisissez entre ", _interpolate(_named("min")), " et ", _interpolate(_named("max"))])},
  "chooseDataAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une date et une heure"])},
  "chooseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date"])},
  "chooseDateAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date et l'heure"])},
  "chooseLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la langue"])},
  "chooseQuantityFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la quantité pour"])},
  "chooseTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez l'heure"])},
  "chooseUpTo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choisissez jusqu'à ", _interpolate(_named("max"))])},
  "chooseYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choisissez votre ", _interpolate(_named("category"))])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer"])},
  "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout effacer"])},
  "clearAllText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez les supprimer définitivement ?"])},
  "clearCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vider le panier"])},
  "clearCartFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de vider votre panier"])},
  "clearCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier a été vidé avec succès"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer le filtre"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer les filtres"])},
  "clearOrSaveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez enregistrer ces articles pour plus tard ou les supprimer définitivement ?"])},
  "clickHereToRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici pour vous inscrire"])},
  "clickHereToReregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici pour vous réinscrire"])},
  "clickHereToSendAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici pour en envoyer un autre"])},
  "clickHereToSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici pour vous connecter"])},
  "clickTheVerificationLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le lien de vérification dans l'e-mail pour terminer l'enregistrement de votre compte."])},
  "clickToAddNotesAndSubstitutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour ajouter des notes et des substitutions"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire"])},
  "commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "completeAtCashRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finaliser à une caisse enregistreuse"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalisé"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "continueHereAndEndAcceleratedCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "continueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer les achats"])},
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "couponAndPromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon & Promos"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
  "createANewList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle liste"])},
  "createAPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mot de passe"])},
  "createNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau mot de passe"])},
  "creditCardInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisie de la carte de crédit"])},
  "creditDebitCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte de crédit/débit"])},
  "currentAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(actuelle)"])},
  "currentlySelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection actuelle"])},
  "currentSelections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélections actuelles"])},
  "currentSpecials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions actuelles"])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisé"])},
  "customAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant personnalisé"])},
  "customerAssociation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association de clients"])},
  "customerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARTE CLIENT"])},
  "customerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de client"])},
  "customerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le client"])},
  "customerPrograms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmes des clients"])},
  "customPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prix personnalisé"])},
  "datePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélecteur de date"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour"])},
  "decreaseQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diminution de la quantité"])},
  "decrement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrémenter"])},
  "defaultToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous installer cette application sur votre écran d'accueil pour un accès rapide ?"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "deleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la carte"])},
  "deleteCardSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre carte se terminant par ", _interpolate(_named("finalDigits")), " a été supprimée avec succès"])},
  "deleteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la liste"])},
  "deleteWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le portefeuille"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
  "deviceNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareil non pris en charge"])},
  "didntReceiveEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas reçu l'e-mail ?"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis de non-responsabilité"])},
  "disclaimerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien que nous nous efforcions de garantir l'exactitude des informations sur les produits, les fabricants peuvent parfois modifier leurs listes d'ingrédients. L'emballage et les matériaux réels des produits peuvent contenir des informations supplémentaires et/ou différentes de celles présentées sur notre site Web. Nous vous recommandons de ne pas vous fier uniquement aux informations présentées et de toujours lire les étiquettes, les avertissements et les instructions avant d'utiliser ou de consommer un produit. Pour toute information complémentaire sur un produit, veuillez contacter le fabricant. Le contenu de ce site est fourni à titre de référence et n'est pas destiné à remplacer les conseils d'un médecin, d'un pharmacien ou d'un autre professionnel de la santé agréé. Vous ne devez pas utiliser ces informations comme auto-diagnostic ou pour traiter un problème de santé ou une maladie. Contactez immédiatement votre fournisseur de soins de santé si vous pensez avoir un problème médical. Les informations et déclarations concernant les compléments alimentaires n'ont pas été évaluées par la Food and Drug Administration et ne sont pas destinées à diagnostiquer, traiter, guérir ou prévenir une maladie ou un problème de santé."])},
  "discountAppliedInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La remise est appliquée dans le panier."])},
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remises"])},
  "dollarSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
  "doNotAllowSubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas autoriser les substitutions"])},
  "downloadLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger les journaux"])},
  "drag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["faire glisser"])},
  "dragContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développer le conteneur du produit en le faisant glisser"])},
  "eachAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unité"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "editItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'article"])},
  "editOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la commande"])},
  "editOrderError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec ! Votre commande n'a pas été modifiée."])},
  "editOrderQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la commande ?"])},
  "editOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande est prête à être modifiée. Lorsque vous avez fini d'ajouter ou de supprimer des articles, veuillez passer à nouveau à la caisse"])},
  "editYourNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier votre note"])},
  "eGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte-cadeau électronique"])},
  "eGiftCardBuild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre carte-cadeau électronique"])},
  "eGiftCardCartAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte-cadeau électronique ajoutée au panier"])},
  "eGiftCardCartAddSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une carte-cadeau électronique a été ajoutée avec succès à votre panier"])},
  "eGiftCardCartError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les articles doivent être achetés séparément de la carte-cadeau électronique dans votre panier. Veuillez retirer ou acheter votre carte-cadeau électronique avant de commencer à ajouter des articles à votre panier."])},
  "eGiftCardFormMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez un message concernant cette carte-cadeau électronique (facultatif)"])},
  "eGiftCardModalAlertShoppingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminez vos achats avant d'acheter une carte-cadeau électronique."])},
  "eGiftCardModalAlertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cartes-cadeaux électroniques doivent être achetées séparément des articles de votre panier"])},
  "eGiftCardPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisez notre politique en matière de cartes-cadeaux électroniques"])},
  "eGiftCardPolicyHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique sur les cartes-cadeaux électroniques"])},
  "eGiftCardPurchaseAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cartes-cadeaux électroniques doivent être achetées séparément des articles figurant dans votre panier. Veuillez retirer ou acheter ces articles avant de commencer à créer une carte-cadeau électronique."])},
  "eGiftCardShippingAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cartes-cadeaux électroniques sont livrées par voie électronique (e-mail et/ou texte) ou téléchargées (pour impression). Consultez l'étape 1 pour vérifier la méthode que vous avez choisie"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "emailInUseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse e-mail est déjà associée à un compte."])},
  "emailInUseHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse e-mail est déjà utilisée"])},
  "emailIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse e-mail est requise"])},
  "emailPhoneOrAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail, téléphone, ou numéro de compte"])},
  "emailTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un e-mail à"])},
  "employeeActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions des employés"])},
  "employeeDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord des employés"])},
  "employeeLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion des employés"])},
  "endingIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se terminant par"])},
  "enterAcceleratedCheckOutModeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes actuellement en mode Accelerated Check Out"])},
  "enterInstructionsFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saisissez les instructions spéciales pour votre ", _interpolate(_named("itemName"))])},
  "enterTheWrongEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez saisi une mauvaise adresse e-mail ?"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur(404)"])},
  "errorInAcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur dans le mode «Accelerated Check Out»"])},
  "errorInCancelPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'annulation du paiement"])},
  "errorInPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors du paiement"])},
  "errorScanProductNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le produit scanné non trouvées, veuillez contacter le responsable du magasin"])},
  "exitAcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter le mode «Accelerated Check Out»"])},
  "exitAcceleratedCheckOutModeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "exitAcceleratedCheckOutModeModalBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "exitAcceleratedCheckOutModeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortie du mode «Accelerated Check Out»"])},
  "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développer"])},
  "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration"])},
  "expirationAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp."])},
  "expiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expire"])},
  "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
  "failedPasswordResetError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la réinitialisation du mot de passe"])},
  "failedVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la vérification"])},
  "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["favori"])},
  "featureNotAvailableForDesktopWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonction n'est pas disponible pour les ordinateurs de bureau"])},
  "featureNotAvailableForMobileWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonction n'est pas disponible pour les appareils mobiles"])},
  "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frais"])},
  "fieldIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ est obligatoire"])},
  "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
  "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver"])},
  "findOurStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver notre magasin"])},
  "findStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver un magasin"])},
  "firefoxAndroidToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous installer cette application sur votre écran d'accueil pour un accès rapide ? - Tapez sur l'icône d'accueil, puis sur Ajouter à l'écran d'accueil."])},
  "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "firstNameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prénom est obligatoire"])},
  "forgotYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez oublié votre mot de passe ?"])},
  "forText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["pour ", _interpolate(_named("name"))])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
  "getCodeViaEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevez un code par e-mail."])},
  "giftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte-cadeau"])},
  "giftCardCartAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez une carte-cadeau électronique dans votre panier. Veuillez acheter la carte-cadeau ou la supprimer de votre panier."])},
  "giftCardDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la carte-cadeau électronique"])},
  "giftCardImgAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte-cadeau électronique"])},
  "giftCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes-cadeaux électroniques"])},
  "giftCardWithIdImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Design de la carte cadeau électronique ", _interpolate(_named("id"))])},
  "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "goGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passez au vert avec des reçus sans papier"])},
  "goLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aller à gauche"])},
  "goodThrough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon jusqu’à"])},
  "goRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aller à droite"])},
  "gotIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien compris"])},
  "goToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller au passage en caisse"])},
  "goToHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller au magasin d'origine"])},
  "goToNextMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer au mois suivant"])},
  "goToPreviousMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer au mois précédent"])},
  "goWithPaperless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passez au vert avec des reçus sans papier"])},
  "grandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total général"])},
  "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
  "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez un compte ?"])},
  "haveCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez des coupons ?"])},
  "healthAttributeImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Attribut de santé ", _interpolate(_named("attributeName"))])},
  "hidePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer le mot de passe"])},
  "homePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page d'accueil"])},
  "homeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasin d'accueil"])},
  "homeStoreAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Magasin d'accueil)"])},
  "idRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "idRequiredMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "imHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis ici !"])},
  "importantCartMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message important concernant les articles dans votre panier"])},
  "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans"])},
  "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprend"])},
  "increaseQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augmenter la quantité"])},
  "increment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incrémenter"])},
  "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrédients"])},
  "ingredientsCap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INGRÉDIENTS"])},
  "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "inStoreOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En magasin uniquement"])},
  "inStoreOnlyAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " ne peut être acheté qu'en magasin"])},
  "invalidField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ non valide"])},
  "inYourCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dans votre panier"])},
  "iosShareMessagePart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez sur"])},
  "iosShareMessagePart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l'icône de partage et sélectionnez \"Ajouter à l'écran d'accueil\"."])},
  "iosToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installez cette application sur votre écran d'accueil pour un accès rapide lorsque vous souhaitez refaire des achats."])},
  "isNowOutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n'est plus en stock et a été supprimé de votre panier"])},
  "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["article"])},
  "itemAddedToSaveForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "itemDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'article"])},
  "itemNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes sur l'article"])},
  "itemProhibitAlternativeMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet article ne peut pas être retiré"])},
  "itemProhibited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "itemProhibitedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["article"])},
  "itemTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de l'article"])},
  "itemUnavailableAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " n'est actuellement pas disponible"])},
  "itLooksPickedUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semble que vous ayez déjà récupéré cette commande. Si vous avez des questions ou des préoccupations, veuillez nous appeler."])},
  "joinPromosAndRewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez nos promotions et notre programme de récompenses"])},
  "kilometersAway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km de distance"])},
  "knowYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous connaissez votre mot de passe ?"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "lastNameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom de famille est obligatoire"])},
  "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liste"])},
  "listIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette liste est vide !"])},
  "listNamesCannotExceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les noms des listes ne peuvent pas dépasser 20 caractères"])},
  "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours"])},
  "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
  "locallySourced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet article est d'origine locale"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "loginFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de se connecter"])},
  "loginFormSubheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-bonjour ! Identifiez-vous pour accélérer le passage en caisse et pour pouvoir suivre et visualiser toutes vos commandes."])},
  "loginFormSubheadingAcceleratedCheckOut": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bienvenue sur le site Accelerated Check Out de ", _interpolate(_named("retailer")), " fonctionnant avec le système ECRS"])},
  "loginId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant de connexion"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal"])},
  "makeASelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites une sélection"])},
  "makeHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendre ce site comme votre magasin d'origine"])},
  "manageSavedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérez votre liste d'achats enregistrée"])},
  "manageYourSavedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérez votre liste enregistrée ici"])},
  "memberID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant du membre"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "messageSentFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message n'a pas été envoyé"])},
  "messageSentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message a été envoyé avec succès"])},
  "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deuxième prénom"])},
  "milesAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mi"])},
  "minimumSelectionSatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection minimale satisfaite"])},
  "mobileAcceleratedCheckOutSessionInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
  "moreTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fois de plus."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "nameYourList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nommez votre liste"])},
  "needsMinimumSelection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Doit être une sélection minimale de ", _interpolate(_named("min"))])},
  "needToStartOver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez recommencer ?"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surnom"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "noCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non, Annuler"])},
  "noCancelDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non, Annuler la suppression"])},
  "noCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de cartes à jeton sécurisées dans votre dossier."])},
  "noContactInfoAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune coordonnée disponible."])},
  "noCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez aucun coupon électronique disponible."])},
  "noItemsOnOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun article ne figurait sur cette commande."])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun"])},
  "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune commande n'est actuellement disponible."])},
  "noProductImageAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'image du produit ", _interpolate(_named("name")), " n'est pas disponible"])},
  "noReservationTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune heure de réservation n'est disponible pour ce type de commande."])},
  "noSavedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune carte enregistrée"])},
  "noShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune liste d'achats trouvée"])},
  "noStoresFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun magasin trouvé."])},
  "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disponible"])},
  "notAvailableAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
  "notAvailableOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disponible en ligne"])},
  "noteAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note ajoutée"])},
  "notEntered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aucun ", _interpolate(_named("name")), " n'a été saisi"])},
  "notOnYourList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas sur votre liste"])},
  "notSignedUpYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas encore inscrit ?"])},
  "notYourHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce n'est pas votre magasin d'origine"])},
  "nutrition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutrition"])},
  "nutritionFacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations nutritionnelles"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["des"])},
  "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "okayGotIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK, j’ai compris !"])},
  "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien mot de passe"])},
  "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups !"])},
  "operaBlockedVersionWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application n'est pas prise en charge par Opera version 9 à version 12. Veuillez utiliser d'autres navigateurs."])},
  "operaMiniToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous installer cette application sur votre écran d'accueil pour un accès rapide ? - Tapez sur l'icône plus (+), puis sur Ajouter à l'écran d'accueil."])},
  "optInToAllowInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous pour nous permettre de vous envoyer des informations sur les promotions à venir, de recevoir des coupons, et bien davantage."])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OU"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])},
  "orderCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation de la commande"])},
  "orderCancelledError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec ! Votre commande n'a pas été annulée."])},
  "orderCancelledSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre commande ", _interpolate(_named("orderScanCode")), " a été annulée."])},
  "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la commande"])},
  "orderHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique de la commande"])},
  "orderItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles de la commande"])},
  "orderNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes sur la commande"])},
  "orderNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La commande n'est pas valide"])},
  "orderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande passée"])},
  "ordersInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes en cours"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la commande"])},
  "orderTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de la commande"])},
  "orderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de commande"])},
  "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En rupture de stock"])},
  "outOfStockAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " est actuellement en rupture de stock"])},
  "packOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquet de"])},
  "packSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille du paquet"])},
  "pageNavigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation par page"])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
  "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passé"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "passwordMustInclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe doit inclure"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe est requis"])},
  "passwordRequirementsNotMet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conditions du mot de passe ne sont pas remplies"])},
  "passwordResetError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de valider votre identité en se basant sur les détails du compte fournis."])},
  "passwordValidationCharLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins huit caractères"])},
  "passwordValidationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un chiffre"])},
  "passwordValidationSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un symbole"])},
  "passwordValidationUpperCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins une lettre en MAJUSCULES"])},
  "payLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer plus tard"])},
  "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de paiement"])},
  "payOnYourPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payez sur votre téléphone"])},
  "payTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total du paiement"])},
  "percentDailyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% de la valeur quotidienne*"])},
  "percentDV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le % de la valeur quotidienne (VQ) vous indique la quantité d'un nutriment contenu dans une portion d'aliment qui contribue à une alimentation quotidienne de 2 000 calories par jour. Il est utilisé pour les conseils généraux en matière de nutrition."])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "phoneNumberInUseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre numéro de téléphone est déjà associé à un compte."])},
  "phoneNumberInUseHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre numéro de téléphone est déjà utilisé"])},
  "phoneNumberIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de téléphone est requis"])},
  "phoneOrAppMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone ou appli manquant(e) ?"])},
  "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer la commande"])},
  "pleaseAllowCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez permettre à votre appareil photo de scanner le code-barres"])},
  "pleaseEnterValidAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un montant valide à offrir"])},
  "pleaseEnterYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Veuillez entrer votre ", _interpolate(_named("name"))])},
  "pleaseRegisterAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vous enregistrer à nouveau pour obtenir un nouveau lien."])},
  "pleaseScanNextItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez scanner votre article suivant"])},
  "pleaseSelectAList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une liste"])},
  "pleaseSelectAtLeastOneDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner au moins un mode de livraison"])},
  "pleaseSelectDelDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une date et une heure de livraison"])},
  "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points"])},
  "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préfixe"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
  "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primaire"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer"])},
  "printReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer le reçu"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produit"])},
  "productDescImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Produit ", _interpolate(_named("name")), ", voir la description dans la page"])},
  "productDetailsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page de détails du produit"])},
  "productImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Produit ", _interpolate(_named("brandName")), " ", _interpolate(_named("productName"))])},
  "productImgGalleryThumbnailAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vignette de la galerie du produit ", _interpolate(_named("name"))])},
  "productInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["informations sur le produit"])},
  "productMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produit. Méthode"])},
  "productPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix du produit"])},
  "profileAddressSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur l'adresse"])},
  "profileEmailSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur l'e-mail"])},
  "profileOtherSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres informations"])},
  "profilePersonalSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])},
  "profilePhoneSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le téléphone"])},
  "profileWorkSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le travail"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
  "quantityAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QTÉ"])},
  "quickLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens rapides"])},
  "readyToRedeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt à racheter !"])},
  "receiptLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement du reçu..."])},
  "recipientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du destinataire"])},
  "recipientsEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail du destinataire"])},
  "recipientsPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone du destinataire"])},
  "recommendComplexPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous recommandons de créer un mot de passe complexe, différent de ceux que vous avez utilisés par le passé."])},
  "redeemAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racheter sur"])},
  "redirecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirection..."])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
  "registerAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se réinscrire"])},
  "remainingTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total restant"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
  "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout retirer"])},
  "removeFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Retirer ", _interpolate(_named("name")), " des favoris"])},
  "removeFromCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer du panier"])},
  "removeFromCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer du passage en caisse"])},
  "removeFromFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer du filtre"])},
  "removeFromList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer de la liste"])},
  "removeItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer des articles"])},
  "removeSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer la sélection"])},
  "removeTheItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer les articles"])},
  "replicateNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répliquer maintenant"])},
  "resendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer le courriel"])},
  "resetMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser la méthode"])},
  "resetNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser maintenant"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
  "resetPasswordAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez créé un compte dans le magasin, vous devrez peut-être définir votre mot de passe avant de pouvoir faire vos achats en ligne pour la première fois."])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats"])},
  "returnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'accueil"])},
  "reviewYourItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter vos articles"])},
  "reviewYourOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter vos commandes"])},
  "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récompenses"])},
  "runMobileAuthenticator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancez votre application mobile d'authentification pour obtenir un code à six chiffres"])},
  "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s"])},
  "salePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix de vente"])},
  "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salut"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
  "saveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder la carte"])},
  "savedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes sauvegardées"])},
  "savedForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegardé pour plus tard"])},
  "saveForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder pour plus tard"])},
  "saveItemsForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les articles pour plus tard"])},
  "scanAnItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner un article"])},
  "scanBarcodeCustomerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannez ce code-barres pour utiliser votre carte client"])},
  "scanCustomerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner la carte client (en magasin)"])},
  "scanError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article non trouvé"])},
  "scanItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner un article"])},
  "scanItemInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au fur et à mesure que vous scannez les articles de votre liste, nous les cocherons pour vous"])},
  "scanNextItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez scanner votre article suivant"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
  "searchByZipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par code postal"])},
  "searchCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche de clients..."])},
  "searchFilterCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche d'articles par catégorie"])},
  "searchProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un programme"])},
  "searchResultsFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de la recherche pour"])},
  "securelyTokenizedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes à jeton sécurisées"])},
  "securityCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de sécurité"])},
  "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En voir davantage"])},
  "seePriceAtCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le prix lors du passage en caisse"])},
  "seePriceInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le prix dans le panier"])},
  "selectADesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un modèle"])},
  "selectAnAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un montant"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un pays"])},
  "selectDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le jour"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionné"])},
  "selectedGiftCardImgAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte-cadeau électronique sélectionnée"])},
  "selectedToPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionné pour l’impression"])},
  "selectHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les heures"])},
  "selectImHereButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le bouton \"Je suis ici\" lorsque vous arrivez au magasin, et nous vous apporterons votre commande."])},
  "selectMinutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les minutes"])},
  "selectOneGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez exactement 1 groupe auquel vous voulez appartenir."])},
  "selectOrderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sélectionnez le type de commande"])},
  "selectPaymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la manière dont vous souhaitez payer vos articles"])},
  "selectProductManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le produit manuellement"])},
  "selectQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sélectionnez la quantité"])},
  "selectShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la liste d'achats"])},
  "selectState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un État"])},
  "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'heure"])},
  "sendDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'envoi"])},
  "senderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'expéditeur"])},
  "sendLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer plus tard"])},
  "sendLaterDatePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélecteur de date d'envoi ultérieur"])},
  "sendLaterTimePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélecteur d'heure d'envoi ultérieur"])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le message"])},
  "sendNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer maintenant"])},
  "sendOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le"])},
  "sendUsAMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez-nous un message"])},
  "servingSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille de la portion"])},
  "servingsPerContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portions par conteneur"])},
  "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir le mot de passe"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition"])},
  "shippingProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur d'expédition"])},
  "shipTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire des achats"])},
  "shopByCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter par catégorie"])},
  "shoppingAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter sur"])},
  "shoppingCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier d'achats"])},
  "shoppingCartWithItemsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Panier d'achats avec ", _interpolate(_named("count")), " articles"])},
  "shoppingLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes d'achats"])},
  "shopStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter dans ce magasin"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])},
  "showHidePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher/masquer le mot de passe"])},
  "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichant de"])},
  "showingOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage des commandes"])},
  "showingResultsFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage des résultats pour"])},
  "showPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le mot de passe"])},
  "sideMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu latéral"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
  "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social"])},
  "sorryCouponCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, le code promo que vous avez saisi ne peut pas être appliqué à cette transaction."])},
  "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier"])},
  "sortProductBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier les produits par"])},
  "specialInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions spéciales"])},
  "specialPromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promos spéciales"])},
  "splitTender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre fractionnée"])},
  "stageCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape terminée"])},
  "stageIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape incomplète"])},
  "stageInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape en cours"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État"])},
  "storeLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement du magasin"])},
  "storeLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacements des magasins"])},
  "storeLogoAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo du magasin"])},
  "storeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du magasin"])},
  "streetAddress1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse postale"])},
  "streetAddress2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apt / Suite (facultatif)"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "substitutionAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substitutions autorisées"])},
  "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-total"])},
  "successOrderInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès ! Merci, votre commande est maintenant en cours."])},
  "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suffixe"])},
  "suggestionsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de suggestions"])},
  "suppressReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver les reçus papier"])},
  "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "textTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un texto à"])},
  "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci !"])},
  "thankYouForCreatingAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir créé un compte ! En faisant cela, vous pourrez profiter d'une expérience de paiement plus rapide, du suivi et de la visualisation des commandes passées, et bien davantage."])},
  "timePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélecteur de temps"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poste"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
  "toastInstallBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installer"])},
  "toastNotNowBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas maintenant"])},
  "toastOkBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "toCap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour"])},
  "tokenServerUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le serveur de jetons sécurisé n'est actuellement pas disponible. Veuillez réessayer plus tard."])},
  "topResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEILLEURS RÉSULTATS"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi"])},
  "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "twoFactorVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification à deux facteurs"])},
  "typeListName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le nom de la liste"])},
  "unassociate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désassocier"])},
  "unassociateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez été désassocié avec succès"])},
  "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indisponible"])},
  "upc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code universel des produits (UPC)"])},
  "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de vérification"])},
  "verificationLinkHasExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes désolés, mais ce lien de vérification a expiré."])},
  "verifyingYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de votre compte..."])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout voir"])},
  "viewAllItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tous les articles"])},
  "viewEGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la carte-cadeau électronique"])},
  "viewManageList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir / Gérer la liste"])},
  "viewManageShoppingList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voir ou gérer la liste ", _interpolate(_named("shoppingListName"))])},
  "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir davantage"])},
  "viewNextDateSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la prochaine série de dates disponibles"])},
  "viewNextTimeSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la prochaine série d'heures disponibles"])},
  "viewPrevDateSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la série précédente de dates disponibles"])},
  "viewPrevTimeSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la série précédente d'heures disponibles"])},
  "viewReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le reçu"])},
  "viewStoreLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les emplacements des magasins"])},
  "viewYour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir votre"])},
  "voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon d'achat"])},
  "waitItemsInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendez ! Il y a des articles dans votre panier."])},
  "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portefeuille"])},
  "weCouldntFindWhatYoureLookingFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas trouvé ce que vous recherchez."])},
  "weHaveBeenNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons été informés que vous êtes ici."])},
  "weightedItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "weightedItemMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "weJustSentAVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous venons d'envoyer un lien de vérification à votre e-mail"])},
  "weNeedToSendVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous devons vous envoyer un code de vérification. Comment voulez-vous le recevoir ?"])},
  "wereOnOurWay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes en route"])},
  "whenFinishedEditCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous aurez fini de modifier votre commande, vous devrez à nouveau passer en caisse."])},
  "whenYouArrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À votre arrivée"])},
  "willBeSentOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sera envoyé le"])},
  "wouldYouLikeToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous ajouter ces articles à la commande que vous êtes sur le point de modifier ou les conserver pour plus tard ?"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "yesContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, continuer"])},
  "yesDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, supprimer"])},
  "yesDeleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, supprimer la carte se terminant par *"])},
  "youCanEither": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez soit"])},
  "youCanUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez utiliser"])},
  "youCanUsePromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez utiliser ces promotions maintenant ou les sauvegarder pour plus tard."])},
  "youHave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez"])},
  "youHaveAlreadyNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà réussi à nous informer de votre présence ici. Si votre attente est anormalement longue, veuillez nous appeler."])},
  "yourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte"])},
  "yourCartIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier est vide"])},
  "yourCompletedOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos commandes enregistrées"])},
  "yourCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos eCoupons"])},
  "yourCredentialsMatchMultipleAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos informations d'identification correspondent à plusieurs comptes, veuillez sélectionner le compte sous lequel vous souhaitez vous connecter"])},
  "yourCustomerDiscounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos remises client"])},
  "yourID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre identifiant"])},
  "yourPunchCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos cartes ePunch"])},
  "yourVerificationCodeSentShortly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre code de vérification vous sera envoyé sous peu en utilisant la méthode de contact fournie."])},
  "youTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "zipCodeInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal non valide"])},
  "zipCodeValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal valide"])}
}