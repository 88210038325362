import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-201a3446"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pt-3"
}
const _hoisted_2 = {
  key: 0,
  class: "text-center"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 1,
  class: "d-flex align-items-center w-100"
}
const _hoisted_5 = { "data-testid": "toolTipText" }
const _hoisted_6 = { class: "font-weight-bold" }
const _hoisted_7 = { class: "font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WCSpinner = _resolveComponent("WCSpinner")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_WCToolTip = _resolveComponent("WCToolTip")!

  return (($options.hasEbtTokenInWallet || $options.showSpinner || $data.displayBalances) && !_ctx.isGuest)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($options.showSpinner)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_WCSpinner)
            ]))
          : ($options.hasEbtTokenInWallet)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (!$data.displayBalances || !_ctx.isEbtCacheValid)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($options.toggleDisplayBalances && $options.toggleDisplayBalances(...args)), ["stop"])),
                      class: "btn btn-primary w-100"
                    }, _toDisplayString(_ctx.$t('checkEbtBalance')), 1))
                  : ($data.displayBalances)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("button", {
                          class: _normalizeClass(["btn btn-primary wc-btn-opacity flex-grow-1 align-self-stretch wc-radius-left", { 'wc-mobile-font': _ctx.isMobile, 'w-50': $options.isSnapOrCashUnavaliable }]),
                          disabled: ""
                        }, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('ebtBalance')) + ": ", 1),
                          _createTextVNode(),
                          _createElementVNode("span", null, [
                            _createVNode(_component_font_awesome_icon, {
                              ref: "info-icon",
                              icon: "circle-info",
                              "data-testid": "infoIcon"
                            }, null, 512),
                            _createTextVNode(),
                            _createVNode(_component_WCToolTip, {
                              target: "info-icon",
                              position: "bottom"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$filters.formatTimeStamp(_ctx.getEbtBalanceTimeStamp)), 1)
                              ]),
                              _: 1
                            })
                          ])
                        ], 2),
                        _createTextVNode(),
                        (_ctx.getEbtSnapCachedBalance != null)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              class: _normalizeClass(["btn btn-outline-primary wc-btn-opacity flex-grow-1 align-self-stretch text-dark", {
            'wc-mobile-font': _ctx.isMobile,
            'wc-radius-none': _ctx.getEbtCashCachedBalance != null,
            'wc-radius-right': _ctx.getEbtCashCachedBalance == null,
            'w-50': $options.isSnapOrCashUnavaliable,
          }]),
                              disabled: ""
                            }, [
                              _createElementVNode("span", null, [
                                _createTextVNode(_toDisplayString(_ctx.$t('ebtSnap')) + " ", 1),
                                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$filters.currency(_ctx.getEbtSnapCachedBalance)), 1)
                              ])
                            ], 2))
                          : _createCommentVNode("", true),
                        _createTextVNode(),
                        (_ctx.getEbtCashCachedBalance != null)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 1,
                              class: _normalizeClass(["btn btn-outline-primary wc-btn-opacity flex-grow-1 align-self-stretch wc-radius-right text-dark", { 'wc-mobile-font': _ctx.isMobile, 'w-50': $options.isSnapOrCashUnavaliable }]),
                              disabled: ""
                            }, [
                              _createElementVNode("span", null, [
                                _createTextVNode(_toDisplayString(_ctx.$t('ebtCash')) + " ", 1),
                                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$filters.currency(_ctx.getEbtCashCachedBalance)), 1)
                              ])
                            ], 2))
                          : _createCommentVNode("", true),
                        _createTextVNode(),
                        _createElementVNode("div", null, [
                          _createElementVNode("button", {
                            class: _normalizeClass(["unstyled-btn text-dark", [_ctx.isMobile ? 'wc-mobile-top-right' : 'wc-top-right']]),
                            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($options.closeBalance && $options.closeBalance(...args)), ["stop"]))
                          }, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: "xmark",
                              size: $options.iconSize
                            }, null, 8, ["size"])
                          ], 2)
                        ])
                      ]))
                    : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}