import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76bd5e22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "font-size-xl font-weight-bold" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = {
  class: "font-weight-bold my-2 mx-4",
  "data-testid": "areYouSureLabel"
}
const _hoisted_4 = { class: "mx-1" }
const _hoisted_5 = { class: "font-weight-bold" }
const _hoisted_6 = { class: "m-auto w-100 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_WCModalHeader = _resolveComponent("WCModalHeader")!
  const _component_WCModalBody = _resolveComponent("WCModalBody")!
  const _component_WCModalFooter = _resolveComponent("WCModalFooter")!
  const _component_WCModal = _resolveComponent("WCModal")!

  return (_openBlock(), _createBlock(_component_WCModal, {
    position: "center",
    onDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dismiss(_ctx.MODAL_EVENTS.DISMISS)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_WCModalHeader, { class: "align-items-baseline" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_font_awesome_icon, {
              class: "mr-2",
              icon: ['fa', 'trash']
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('deleteAccount')), 1)
          ])
        ]),
        _: 1
      }),
      _createTextVNode(),
      _createVNode(_component_WCModalBody, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('areYouSureDeleteAccount')), 1),
            _createTextVNode(),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('attention')) + "! ", 1),
              _createTextVNode(),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('requestRemovalDisclaimer')), 1)
            ])
          ])
        ]),
        _: 1
      }),
      _createTextVNode(),
      _createVNode(_component_WCModalFooter, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close(_ctx.MODAL_EVENTS.CONFIRM))),
              class: "btn btn-danger wc-delete-account-button mr-3",
              "data-testid": "yesDeleteLabel"
            }, _toDisplayString(_ctx.$t('yesDelete')), 1),
            _createTextVNode(),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dismiss(_ctx.MODAL_EVENTS.CANCEL))),
              class: "btn btn-outline-danger wc-delete-account-button",
              "data-testid": "noDontDeleteLabel"
            }, _toDisplayString(_ctx.$t('noDontDelete')), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}