<template>
  <transition-group
    name="toast-slide"
    tag="section"
    aria-live="polite"
    aria-atomic="true"
    class="wc-toast-root"
    :class="{ 'wc-scan-n-go-toast': isScanAndGoPage }"
  >
    <div v-for="toast in ToastService.toasts" :key="toast.id">
      <component
        v-bind="toast.props"
        :is="toast.component"
        :data-id="toast.id"
        v-bind:close="toast.close"
        v-bind:dismiss="toast.dismiss"
        v-bind:startTimeout="toast.startTimeout"
        v-on="toast.actions"
      />
    </div>
  </transition-group>
</template>

<script>
import ToastService from '../../services/ToastService';

export default {
  data() {
    return {
      ToastService,
    };
  },
  computed: {
    isScanAndGoPage() {
      return this.$route.meta.isScanAndGoPage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-toast-root {
  z-index: map-get($zindex, 'toasts');
  position: fixed;
  right: rem(15px);
  bottom: 4rem;
  width: calc(100vw - rem(30px));
}
.wc-scan-n-go-toast {
  bottom: rem(15px);
}

/*---- START : Transition effect for Toast ----*/
.toast-slide-enter-active,
.toast-slide-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.toast-slide-enter,
.toast-slide-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
/*---- END : Transition effect for Toast ----*/

@include media-breakpoint-up(sm) {
  .wc-toast-root {
    width: rem(350px);
  }
}

@include media-breakpoint-up(lg) {
  .wc-toast-root {
    bottom: 2rem;
    right: 2rem;
  }
}
</style>
