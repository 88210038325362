import axios from 'axios';
import { MODAL_EVENTS } from '@/constants/EventConstants';
import WCDeleteAccountModal from '@/components/WCDeleteAccountModal/WCDeleteAccountModal.vue';
import ModalService from '@/modules/modals/services/modal.service';
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    isDeleteEnabled() {
      return this.$configuration.allowAccountDeletion;
    },
  },
  methods: {
    async openDeleteAccountModal() {
      try {
        const response = await ModalService.open(WCDeleteAccountModal);
        if (response === MODAL_EVENTS.CONFIRM) {
          await axios.post('/api/auth/requestRemoval');
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
