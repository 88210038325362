import {
  faTwitter,
  faXTwitter,
  faFacebookF,
  faLinkedinIn,
  faYoutube,
  faInstagram,
  faCcVisa,
  faCcAmazonPay,
  faCcAmex,
  faCcApplePay,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcPaypal,
  faCcStripe,
} from '@fortawesome/free-brands-svg-icons';

export default [
  faFacebookF,
  faTwitter,
  faXTwitter,
  faLinkedinIn,
  faYoutube,
  faInstagram,
  faCcVisa,
  faCcAmazonPay,
  faCcAmex,
  faCcApplePay,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcPaypal,
  faCcStripe,
];
