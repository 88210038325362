import { mapActions, mapGetters } from 'vuex';
import { BigNumber } from 'bignumber.js';
import ModalService from '@/modules/modals/services/modal.service';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ToastService from '@/modules/toasts/services/ToastService';
import UserMixin from '@/modules/user/mixins/UserMixin';
import QuantityDisplayMixin from '@/modules/orders/mixins/QuantityDisplayMixin';
import TrackableEventConstants from '@/constants/TrackableEventConstants';
import AnalyticsService from '@/services/analytics.service';
import axios from 'axios';
import CartItemControls from '@/modules/cart/mixins/CartControls/CartItemControls';
import WCStoreSelectModal from '@/components/WCStoreSelectModal/WCStoreSelectModal.vue';

export default {
  props: {
    item: {
      type: Object,
    },
    editingItem: {
      type: Boolean,
    },
    variation: {
      type: String,
    },
    cartInstructions: {
      type: String,
    },
  },
  mixins: [CartItemControls, UserMixin, QuantityDisplayMixin],
  computed: {
    ...mapGetters({
      isSyncing: 'cart/isSyncing',
      isGiftCard: 'cart/isGiftCard',
      getUser: 'user/getUser',
      isCustomerModeScan: 'user/isCustomerModeScan',
      cartsCount: 'cart/getCartsCount',
      nonEmptyCarts: 'cart/nonEmptyCarts',
      cartOrderType: 'cart/getCartOrderType',
      isInStore: 'cart/getInStore',
    }),
  },
  methods: {
    ...mapActions({
      setItemQuantity: 'cart/setItemQuantity',
      getCarts: 'cart/getCarts',
      setCurrentCart: 'cart/setCurrentCart',
      setCartsLoadingStatus: 'cart/setCartsLoadingStatus',
      removeEGiftCard: 'cart/removeGiftCard',
    }),
    /**
     * Get the rounded quantity set by the backend.
     *
     * This quantity can be slightly inaccurate, if the select increment has more decimals than the
     * weightProfile indicates, the extra precision will be rounded off.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {BigNumber}
     */
    getQuantityInCart(item, variation) {
      const lineItem = this.getItemLineItem(item, variation);
      return new BigNumber(lineItem ? lineItem.quantity : 0);
    },

    /**
     * Recover the unrounded quantity based on the rounded quantity in the cart.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {BigNumber}
     */
    recoverUnroundedQuantityFromCart(item, variation) {
      const quantityInCart = this.getQuantityInCart(item, variation);
      const numIncrements = this.getNumQuantityIncrements(item, quantityInCart);
      if (numIncrements.isZero()) {
        return new BigNumber(0);
      }
      const minimumQuantity = this.getMinimumQuantity(item);
      const quantityIncrement = this.getQuantityIncrement(item);
      return minimumQuantity.plus(numIncrements.minus(1).multipliedBy(quantityIncrement));
    },

    /**
     * Get the weighted quantity of the item in the cart, or 0 if unweighted.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {BigNumber}
     */
    getWeightedQuantityInCart(item, variation) {
      const lineItem = this.getItemLineItem(item, variation);
      return new BigNumber(lineItem ? lineItem.weightedItemQuantity : 0);
    },

    /**
     * Get the total quantity of the base item in the cart.
     * @see getTotalBaseQuantity
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {BigNumber}
     */
    getTotalBaseQuantityInCart(item, variation) {
      const quantityInCart = this.recoverUnroundedQuantityFromCart(item, variation);
      const weightedQuantityInCart = this.getWeightedQuantityInCart(item, variation);
      return this.getTotalBaseQuantity(item, quantityInCart, weightedQuantityInCart);
    },

    /**
     * Get the total quantity of the base item, accounting for weightedQuantity and ASC quantity.
     *
     * It is not possible for an item to be an ASC item and a weighted item. ASC items are already
     * "packs" of a defined quantity (weight). Having 2 packs or 1 pack with weighted quantity of
     * 2 is the same and it would be confusing to have both options.
     * @param {Object} item The item.
     * @param {BigNumber} quantity The quantity of the item.
     * @param {BigNumber} weightedQuantity The weightedQuantity of the item.
     * @return {BigNumber}
     */
    getTotalBaseQuantity(item, quantity, weightedQuantity) {
      let totalBaseQuantity = quantity;
      if (this.isSoldByRandomWeight(item)) {
        totalBaseQuantity = totalBaseQuantity.multipliedBy(weightedQuantity);
      }
      if (this.isAdditionalScanCode(item)) {
        totalBaseQuantity = totalBaseQuantity.multipliedBy(item.ascQuantity);
      }
      return totalBaseQuantity;
    },

    /**
     * Get the quantity increment value, based on the type of item.
     * @return {BigNumber}
     */
    getQuantityIncrement(item) {
      if (this.isSoldByAverageWeight(item)) {
        return new BigNumber(item.averageWeight);
      }
      if (this.isSoldByRandomWeight(item)) {
        return new BigNumber(item.weightProfile.selectIncrement);
      }
      return new BigNumber(1);
    },

    /**
     * Get the weighted quantity increment value, based on the type of item.
     *
     * Only random weighted items can be incremented.
     * @param {Object} item The item.
     * @return {BigNumber}
     */
    getWeightedQuantityIncrement(item) {
      if (!this.isSoldByRandomWeight(item)) {
        throw new Error(
          'Weighted quantity increment is not useful for items not sold by random weight',
        );
      }
      return new BigNumber(1);
    },

    /**
     * Get the quantity of an item in the cart to display in the UI.
     * @see getDisplayQuantity
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {string}
     */
    getDisplayQuantityInCart(item, variation) {
      return this.getDisplayQuantity(item, this.recoverUnroundedQuantityFromCart(item, variation));
    },

    /**
     * Returns true if the item is sold by random weight and the weighted quantity adjuster is not hidden.
     * Weighted MTO items always show dual adjusters.
     *
     * @param {Object} item The item.
     * @returns {boolean}
     */
    isSoldByRandomWeightWithDualAdjusters(item) {
      return (
        this.isSoldByRandomWeight(item) &&
        (!this.$configuration.hideWeightedQuantityAdjuster ||
          item.hasModifiers ||
          item.hasLinkedItems)
      );
    },

    /**
     * Get the quantity of an item to display in the UI, based on the type of item.
     * @param {Object} item The item.
     * @param {BigNumber} quantity The quantity to display.
     * @return {string}
     */
    getDisplayQuantity(item, quantity) {
      if (this.isSoldByAverageWeight(item)) {
        return this.getEachCountFromQuantity(item, quantity).toString();
      }
      if (this.isSoldByRandomWeight(item)) {
        return this.getDisplayRandomWeightFromQuantity(item, quantity).toString();
      }
      return quantity.toString();
    },

    /**
     * Get the display quantity for an item sold by random weight, roundng to the profile's decimal
     * places and including the units.
     * @param {Object} item The item.
     * @param {BigNumber} quantity The quantity of `item`.
     * @return {string}
     */
    getDisplayRandomWeightFromQuantity(item, quantity) {
      if (!this.isSoldByRandomWeight(item)) {
        throw new Error(
          'Display Weighted Quantity is not defined for items not sold by random weight',
        );
      }
      const decimals = item.weightProfile.decimals;
      const roundedQuantity = quantity.toFixed(decimals, BigNumber.ROUND_HALF_UP);
      const abbrv = item.weightProfile.abbrv;
      return `${roundedQuantity} ${abbrv}`;
    },

    /**
     * Get the weighted quantity of an item in the cart to display in the UI.
     * @see getDisplayWeightedQuantity
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {string}
     */
    getDisplayWeightedQuantityInCart(item, variation) {
      return this.getDisplayWeightedQuantity(item, this.getWeightedQuantityInCart(item, variation));
    },

    /**
     * Get the weighted quantity of an item to display in the UI.
     *
     * Only random weighted items have a weighted quantity.
     * @param {Object} item The item.
     * @param {BigNumber} quantity The quantity of `item`.
     * @return {string}
     */
    getDisplayWeightedQuantity(item, quantity) {
      if (!this.isSoldByRandomWeight(item)) {
        throw new Error('Weighted Quantity does not exist for items not sold by random weight');
      }
      return quantity.toString();
    },

    /**
     * The minimum quantity of an item that can be added to the cart.
     * @return {BigNumber}
     */
    getMinimumQuantity(item) {
      if (this.isSoldByAverageWeight(item)) {
        return new BigNumber(item.averageWeight);
      }
      if (this.isSoldByRandomWeight(item)) {
        return new BigNumber(item.weightProfile.selectMinimum);
      }
      return new BigNumber(1);
    },

    /**
     * The maximum total base quantity of an item that can be added to the cart.
     * @return {BigNumber}
     */
    getMaximumTotalBaseQuantity(item) {
      if (this.isCustomerModeScan) {
        return new BigNumber('Infinity');
      }

      const departmentMax = this.getDepartmentMax(item);
      const selectMax = this.getSelectMax(item);
      const onHand = this.getOnHand(item);

      if (item.sellOutOfStock) {
        return BigNumber.minimum(departmentMax, selectMax);
      }
      return BigNumber.minimum(departmentMax, selectMax, onHand);
    },

    /**
     * Get the department-set-maximum quantity of an item that can be added to the cart.
     *
     * @param {Object} item The item.
     * @return {BigNumber}
     */
    getDepartmentMax(item) {
      return new BigNumber(item.maxQuantity);
    },

    /**
     * Get the maximum quantity of an item that can be added to the cart based on the weight profile. (Infinity if unweighted or sold by average weight)
     *
     * @param {Object} item The item.
     * @return {BigNumber}
     */
    getSelectMax(item) {
      if (!this.isSoldByWeight(item) || this.isSoldByAverageWeight(item)) {
        return new BigNumber('Infinity');
      }
      const selectIncrement = new BigNumber(item.weightProfile.selectIncrement);
      const selectMinimum = new BigNumber(item.weightProfile.selectMinimum);
      const selectCount = new BigNumber(item.weightProfile.selectCount);
      return selectMinimum.plus(selectIncrement.multipliedBy(selectCount.minus(1)));
    },

    /**
     * Get the on-hand amount of an item, accounting for ASC quantity.
     *
     * @param {Object} item The item.
     * @returns {BigNumber}
     */
    getOnHand(item) {
      const onHand = new BigNumber(item.maxBaseQuantity);
      return onHand;
    },

    /**
     * Get the minimum weighted quantity of an item that can be added to the cart.
     *
     * Only items sold by weight have a useful weighted quantity.
     * @param {Object} item The item.
     * @return {BigNumber}
     */
    getMinimumWeightedQuantity(item) {
      if (this.isSoldByWeight(item)) {
        return new BigNumber(1);
      }
      return new BigNumber('NaN');
    },

    /**
     * Check if a quantity of an item satifies the RACS quantity limits.
     *
     * Considers the entire cart, if `item`'s total quantity were to be `totalBaseQuantity`.
     * @see {@link https://olm.ecrs.com/5-5/?page_id=22186}
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @param {BigNumber} totalBaseQuantity The total base quantity of `item`.
     * @return {boolean}
     */
    isQuantityValidByRacs(item, variation, totalBaseQuantity) {
      const quantityLimit = item.quantityLimitData?.quantityLimit;
      if (!quantityLimit) {
        return true;
      }
      const currentTotalQuantityForVendorGroup = this.getTotalQuantityForVendorGroup(item);
      const currentRacsPackQuantityInCart = this.getRacsPackQuantityInCart(item, variation);
      const candidateRacsPackQuantity = this.getRacsPackQuantity(item, totalBaseQuantity);
      const candidateTotalQuantityForVendorGroup = currentTotalQuantityForVendorGroup
        .minus(currentRacsPackQuantityInCart)
        .plus(candidateRacsPackQuantity);
      return candidateTotalQuantityForVendorGroup.isLessThanOrEqualTo(quantityLimit);
    },

    /**
     * The total quantity of all items in the cart of a given vendor group,
     * accounting for each item's pack quantity.
     * @param {Object} item The item whose vendor group to get the quantity of.
     * @return {BigNumber}
     */
    getTotalQuantityForVendorGroup(item) {
      const lineItems = this.getLineItemsOfType(1);
      const matchVendorGroup = item.quantityLimitData.vendorGroup;
      const lineItemsInVendorGroup = lineItems.filter(
        li => li.item.quantityLimitData?.vendorGroup === matchVendorGroup,
      );
      const totalQuantityForVendorGroup = lineItemsInVendorGroup.reduce(
        (total, li) => total.plus(this.getRacsPackQuantityInCart(li.item, li.variation)),
        new BigNumber(0),
      );
      return totalQuantityForVendorGroup;
    },

    /**
     * Get the pack quantity of an item in the cart.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {BigNumber}
     */
    getRacsPackQuantityInCart(item, variation) {
      return this.getRacsPackQuantity(item, this.getTotalBaseQuantityInCart(item, variation));
    },

    /**
     * Get the pack quantity of an item, given it's quantity.
     * @param {Object} item The item.
     * @param {BigNumber} totalBaseQuantity `item`'s total base quantity.
     * @return {BigNumber}
     */
    getRacsPackQuantity(item, totalBaseQuantity) {
      if (!item.quantityLimitData) {
        return new BigNumber(0);
      }
      return totalBaseQuantity.multipliedBy(item.quantityLimitData.comboPackAmount);
    },

    /**
     * Get the number of quantity increments that have occurred based on a given quantity.
     *
     * The number of increments is the number of times the user would need to consecutively
     * press the add button to achieve the current quantity (beginning with quantity = 0).
     *
     * Equation for quantity given numIncrements:
     *
     * quantity = 0                                                       (numIncrements == 0)
     *
     * quantity = minQuantity + ((numIncrements - 1) * incrementValue)    (numIncrements >= 1)
     *
     * ---
     *
     * Solved for numIncrements, we have:
     *
     * numIncrements = 0                                                  (quantity == 0)
     *
     * numIncrements = ((quantity - minQuantity) / incrementValue) + 1    (quantity > 0)
     *
     * @return {BigNumber}
     */
    getNumQuantityIncrements(item, quantity) {
      if (quantity.isZero()) {
        return new BigNumber(0);
      }
      const minimumQuantity = this.getMinimumQuantity(item);
      const quantityIncrement = this.getQuantityIncrement(item);
      if (quantityIncrement.isLessThanOrEqualTo(0)) {
        return new BigNumber(0);
      }
      return quantity
        .minus(minimumQuantity)
        .dividedBy(quantityIncrement)
        .integerValue(BigNumber.ROUND_HALF_UP)
        .plus(1);
    },

    /**
     * Get an item's next quantity after an increment.
     * @param {Object} item The item.
     * @param {BigNumber} currentQuantity `item`'s current quantity.
     * @return {BigNumber}
     */
    getNextQuantityIncrement(item, currentQuantity) {
      if (currentQuantity.isZero()) {
        return this.getMinimumQuantity(item);
      }
      const quantityIncrement = this.getQuantityIncrement(item);
      return currentQuantity.plus(quantityIncrement);
    },

    /**
     * Get a cart item's next quantity after an increment.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {BigNumber}
     */
    getNextQuantityIncrementInCart(item, variation) {
      const unroundedQuantity = this.recoverUnroundedQuantityFromCart(item, variation);
      return this.getNextQuantityIncrement(item, unroundedQuantity);
    },

    /**
     * Get an item's next quantity after a decrement.
     * @param {Object} item The item.
     * @param {BigNumber} currentQuantity `item`'s current quantity.
     * @return {BigNumber}
     */
    getNextQuantityDecrement(item, currentQuantity) {
      if (currentQuantity.isLessThanOrEqualTo(this.getMinimumQuantity(item))) {
        return new BigNumber(0);
      }
      const quantityIncrement = this.getQuantityIncrement(item);
      return currentQuantity.minus(quantityIncrement);
    },

    /**
     * Get a cart item's next quantity after a decrement.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {BigNumber}
     */
    getNextQuantityDecrementInCart(item, variation) {
      const unroundedQuantity = this.recoverUnroundedQuantityFromCart(item, variation);
      return this.getNextQuantityDecrement(item, unroundedQuantity);
    },

    /**
     * Check if the next quantity increment of an item is allowed.
     * @param {Object} item The item.
     * @param {BigNumber} currentQuantity `item`'s current quantity.
     * @param {BigNumber} currentWeightedQuantity `item`'s current weighted quantity, if applicable.
     * @return {boolean}
     */
    isNextQuantityIncrementValid(item, currentQuantity, currentWeightedQuantity) {
      const nextQuantity = this.getNextQuantityIncrement(item, currentQuantity);
      return this.isQuantityValidByItemLimits(item, nextQuantity, currentWeightedQuantity);
    },

    /**
     * Check if the next quantity increment of an item in the cart is allowed.
     * @see isQuantityValidForCart
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {boolean}
     */
    isNextQuantityIncrementInCartValid(item, variation) {
      const {
        nextQuantity,
        nextWeightedQuantity,
      } = this.getNextQuantityIncrementWithWeightedQuantityInCart(item, variation);
      return this.isQuantityValidForCart(item, variation, nextQuantity, nextWeightedQuantity);
    },

    /**
     * Check if the next quantity decrement of an item is allowed.
     * @param {Object} item The item.
     * @param {BigNumber} currentQuantity `item`'s current quantity.
     * @param {BigNumber} currentWeightedQuantity `item`'s current weighted quantity, if applicable.
     * @return {boolean}
     */
    isNextQuantityDecrementValid(item, currentQuantity, currentWeightedQuantity) {
      const nextQuantity = this.getNextQuantityDecrement(item, currentQuantity);
      return this.isQuantityValidByItemLimits(item, nextQuantity, currentWeightedQuantity);
    },

    /**
     * Check if the next quantity decrement of an item in the cart is allowed.
     * @see isQuantityValidForCart
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {boolean}
     */
    isNextQuantityDecrementInCartValid(item, variation) {
      const nextQuantity = this.getNextQuantityDecrementInCart(item, variation);
      const weightedQuantity = this.getWeightedQuantityInCart(item, variation);
      return this.isQuantityValidForCart(item, variation, nextQuantity, weightedQuantity);
    },

    /**
     * Get an item's next weighted quantity after an increment.
     * @param {Objec} item The item.
     * @param {BigNumber} currentWeightedQuantity `item`'s current weighted quantity.
     * @return {BigNumber}
     */
    getNextWeightedQuantityIncrement(item, currentWeightedQuantity) {
      if (currentWeightedQuantity.isZero()) {
        return this.getMinimumWeightedQuantity(item);
      }
      const weightedQuantityIncrement = this.getWeightedQuantityIncrement(item);
      return currentWeightedQuantity.plus(weightedQuantityIncrement);
    },

    /**
     * Get a cart item's next weighted quantity after an increment.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {BigNumber}
     */
    getNextWeightedQuantityIncrementInCart(item, variation) {
      const weightedQuantity = this.getWeightedQuantityInCart(item, variation);
      return this.getNextWeightedQuantityIncrement(item, weightedQuantity);
    },

    /**
     * Get an item's next weighted quantity after a decrement.
     * @param {Object} item The item.
     * @param {BigNumber} currentWeightedQuantity `item`'s current weighted quantity.
     * @return {BigNumber}
     */
    getNextWeightedQuantityDecrement(item, currentWeightedQuantity) {
      if (currentWeightedQuantity.isLessThanOrEqualTo(this.getMinimumWeightedQuantity(item))) {
        return new BigNumber(0);
      }
      const weightedQuantityIncrement = this.getWeightedQuantityIncrement(item);
      return currentWeightedQuantity.minus(weightedQuantityIncrement);
    },

    /**
     * Get a cart item's next weighted quantity after a decrement.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {BigNumber}
     */
    getNextWeightedQuantityDecrementInCart(item, variation) {
      const weightedQuantity = this.getWeightedQuantityInCart(item, variation);
      return this.getNextWeightedQuantityDecrement(item, weightedQuantity);
    },

    /**
     * Check if the next weighted quantity increment of an item is allowed.
     * @param {Object} item The item.
     * @param {BigNumber} currentQuantity `item`'s current quantity.
     * @param {BigNumber} currentWeightedQuantity `item`'s current weighted quantity.
     * @return {boolean}
     */
    isNextWeightedQuantityIncrementValid(item, currentQuantity, currentWeightedQuantity) {
      const nextWeightedQuantity = this.getNextWeightedQuantityIncrement(
        item,
        currentWeightedQuantity,
      );
      return this.isQuantityValidByItemLimits(item, currentQuantity, nextWeightedQuantity);
    },

    /**
     * Check if the next weighted quantity increment of an item in the cart is allowed.
     * @see isQuantityValidForCart
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {boolean}
     */
    isNextWeightedQuantityIncrementInCartValid(item, variation) {
      const quantity = this.recoverUnroundedQuantityFromCart(item, variation);
      const nextWeightedQuantity = this.getNextWeightedQuantityIncrementInCart(item, variation);
      return this.isQuantityValidForCart(item, variation, quantity, nextWeightedQuantity);
    },

    /**
     * Chick if the next weighted quantity decrement of an item is allowed.
     * @param {Object} item The item.
     * @param {BigNumber} currentQuantity `item`'s current quantity.
     * @param {BigNumber} currentWeightedQuantity `item`'s current weighted quantity.
     * @return {boolean}
     */
    isNextWeightedQuantityDecrementValid(item, currentQuantity, currentWeightedQuantity) {
      const nextWeightedQuantity = this.getNextWeightedQuantityDecrement(
        item,
        currentWeightedQuantity,
      );
      return this.isQuantityValidByItemLimits(item, currentQuantity, nextWeightedQuantity);
    },

    /**
     * Check if the next weighted quantity decrement of an item in the cart is allowed.
     * @see isQuantityValidForCart
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @return {boolean}
     */
    isNextWeightedQuantityDecrementInCartValid(item, variation) {
      const quantity = this.recoverUnroundedQuantityFromCart(item, variation);
      const nextWeightedQuantity = this.getNextWeightedQuantityDecrementInCart(item, variation);
      return this.isQuantityValidForCart(item, variation, quantity, nextWeightedQuantity);
    },

    getNextQuantityIncrementWithWeightedQuantityInCart(item, variation) {
      const currentQuantity = this.recoverUnroundedQuantityFromCart(item, variation);
      const nextQuantity = this.getNextQuantityIncrementInCart(item, variation);
      let nextWeightedQuantity = null;
      if (this.isSoldByRandomWeight(item)) {
        if (currentQuantity.isZero()) {
          nextWeightedQuantity = this.getNextWeightedQuantityIncrementInCart(item, variation);
        } else {
          nextWeightedQuantity = this.getWeightedQuantityInCart(item, variation);
        }
      } else if (this.isSoldByAverageWeight(item)) {
        nextWeightedQuantity = this.getMinimumWeightedQuantity(item);
      }
      return { currentQuantity, nextQuantity, nextWeightedQuantity };
    },

    /**
     * Check if a total base quantity for an item is allowed by its limits.
     *
     * - Cannot be less than zero.
     * - Cannot be in `(0, minimumQuantity]`.
     * - Cannot be greater than `maximumQuantity`.
     * - Quantity must be a valid increment.
     * - Weighted Quantity must be a valid increment.
     * @param {Object} item The item.
     * @param {BigNumber} quantity `item`'s quantity to check.
     * @param {BigNumber} weightedQuantity `item`'s weighted quantity to check, if applicable.
     * @return {boolean}
     */
    isQuantityValidByItemLimits(item, quantity, weightedQuantity) {
      const totalBaseQuantity = this.getTotalBaseQuantity(item, quantity, weightedQuantity);
      if (totalBaseQuantity.isLessThan(0)) {
        return false;
      }
      if (totalBaseQuantity.isZero()) {
        return true;
      }

      const minimumQuantity = this.getMinimumQuantity(item);
      if (totalBaseQuantity.isLessThan(minimumQuantity)) {
        return false;
      }
      const maximumTotalBaseQuantity = this.getMaximumTotalBaseQuantity(item);
      if (totalBaseQuantity.isGreaterThan(maximumTotalBaseQuantity)) {
        return false;
      }

      if (!this.isQuantityValidIncrement(item, quantity)) {
        return false;
      }
      if (!this.isWeightedQuantityValidIncrement(item, weightedQuantity)) {
        return false;
      }

      return true;
    },

    /**
     * Check if a quantity is valid based on the minimum and increment.
     *
     * If the increment is <= 0, no increments are valid.
     * @param {Objet} item The item.
     * @param {BigNumber} quantity `item`'s quantity to check.
     * @return {boolean}
     */
    isQuantityValidIncrement(item, quantity) {
      const minimum = this.getMinimumQuantity(item);
      const increment = this.getQuantityIncrement(item);
      if (increment.isLessThanOrEqualTo(0)) {
        return false;
      }
      return quantity
        .minus(minimum)
        .dividedBy(increment)
        .isInteger();
    },

    /**
     * Check if a weighted quantity is valid based on the minimum and increment.
     *
     * If the item is sold by average weight, only the minimum is allowed,
     * it cannot be incremented or decremented.
     * If the item is not sold by weight, anything is valid.
     * @param {Object} item The item.
     * @param {BigNumber} weightedQuantity `item`'s weighted quantity to check.
     * @return {boolean}
     */
    isWeightedQuantityValidIncrement(item, weightedQuantity) {
      if (this.isSoldByAverageWeight(item)) {
        return weightedQuantity.isEqualTo(this.getMinimumWeightedQuantity(item));
      }
      if (!this.isSoldByRandomWeight(item)) {
        return true;
      }

      const minimum = this.getMinimumWeightedQuantity(item);
      const increment = this.getWeightedQuantityIncrement(item);
      return weightedQuantity
        .minus(minimum)
        .dividedBy(increment)
        .isInteger();
    },

    /**
     * Check if a total base quantity for an item is allowed in the cart.
     *
     * - Must by valid by the item limits.
     * - Must by valid by the RACS quantity limits.
     * @see isQuantityValidByItemLimits
     * @see isQuantityValidByRacs
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @param {BigNumber} quantity `item`'s quantity to check.
     * @param {BigNumber} weightedQuantity `item`'s weighted quantity to check, if applicable.
     * @return {boolean}
     */
    isQuantityValidForCart(item, variation, quantity, weightedQuantity) {
      const totalBaseQuantity = this.getTotalBaseQuantity(item, quantity, weightedQuantity);
      const validByItemLimits = this.isQuantityValidByItemLimits(item, quantity, weightedQuantity);
      const validByRacs = this.isQuantityValidByRacs(item, variation, totalBaseQuantity);
      return validByItemLimits && validByRacs;
    },

    /**
     * Check if an item is in stock.
     * @param {Object} item The item.
     * @return {boolean}
     */
    isInStock(item) {
      // If the item is a service item i.e. maxBaseQuantity is not present, mark the quantity as in stock
      if (item.sellOutOfStock || item.maxBaseQuantity === undefined) {
        return true;
      }
      return this.getOnHand(item).isGreaterThanOrEqualTo(this.getMinimumQuantity(item));
    },

    /**
     * Check if an item is sold in stores only.
     * @return {boolean}
     */
    isInStoreOnly(item) {
      return item.maxQuantity <= 0 || item.actualPrice < 0;
    },

    /**
     * Set a new quantity for an item in the cart as long as it is valid.
     * @param {number|string|BigNumber} quantity New quantity for `item`.
     * @param {number|string|BigNumber} weightedQuantity New weighted quantity for `item`.
     * @param {Object} item The item whose quantity to modify.
     * @param {string} variation `item`'s variation in the cart.
     * @param {Object} priceEmbeddedLineItem `item`'s price embedded data, if applicable.
     */
    async setQuantity(
      quantity,
      weightedQuantity,
      item,
      variation,
      priceEmbeddedLineItem,
      showStoreSelectModal = true,
    ) {
      let newQuantity = new BigNumber(quantity);
      let newWeightedQuantity = new BigNumber(weightedQuantity);
      if (newQuantity.isZero() || newWeightedQuantity.isZero()) {
        newQuantity = new BigNumber(0);
        newWeightedQuantity = new BigNumber(0);
      }
      const newTotalBaseQuantity = this.getTotalBaseQuantity(
        item,
        newQuantity,
        newWeightedQuantity,
      );
      if (!this.isQuantityValidForCart(item, variation, newQuantity, newWeightedQuantity)) {
        throw this.createQuantityError(
          item,
          newQuantity,
          newWeightedQuantity,
          newTotalBaseQuantity,
        );
      }

      if (this.isGiftCard) {
        this.alertGiftCardItem();
        throw new Error('Cannot add Gift Card Item to cart');
      }

      if (this.isFirstItemInCartAtNonHomeStore(newQuantity.toNumber()) && showStoreSelectModal) {
        await this.alertNotShoppingFromHomeStore();
      }

      this.trackAnalyticsNewQuantity(newQuantity.toNumber(), item);

      const itemLineItem = this.getItemLineItem(item, variation);

      await this.setItemQuantity({
        itemLineItem,
        item,
        variation,
        weightedItemQuantity: this.isSoldByWeight(item) ? newWeightedQuantity.toNumber() : null,
        quantity: newQuantity.toNumber(),
        priceEmbeddedLineItem,
        orderType: this.orderType,
      });
    },

    /**
     * Increment the quantity of an item in the cart if possible.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     * @param {Object} priceEmbeddedLineItem `item`'s price embedded data, if applicable.
     * * @param {Boolean} showStoreSelectModal Boolean for showing select store modal.
     */
    async increment(item, variation, priceEmbeddedLineItem, showStoreSelectModal) {
      const {
        currentQuantity,
        nextQuantity,
        nextWeightedQuantity,
      } = this.getNextQuantityIncrementWithWeightedQuantityInCart(item, variation);

      if (!this.isQuantityValidForCart(item, variation, nextQuantity, nextWeightedQuantity)) {
        this.alertMaximumQuantityReached();
        return;
      }

      try {
        await this.setQuantity(
          nextQuantity,
          nextWeightedQuantity,
          item,
          variation,
          priceEmbeddedLineItem,
          showStoreSelectModal,
        );
      } catch (error) {
        console.error(error);
        this.alertFailedToAddToCart();
        return;
      }

      if (currentQuantity.isZero() && !this.isCustomerModeScan) {
        this.alertItemAddedToCart();
      }
      if (!this.isNextQuantityIncrementInCartValid(item, variation)) {
        this.alertMaximumQuantityReached();
      }
    },

    /**
     * Decrement the quantity of an item in the cart if possible.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation.
     * @param {Object} priceEmbeddedLineItem `item`'s price embedded data, if applicable.
     */
    async decrement(item, variation, priceEmbeddedLineItem) {
      const nextQuantity = this.getNextQuantityDecrementInCart(item, variation);
      const weightedQuantity = this.getWeightedQuantityInCart(item, variation);
      await this.setQuantity(
        nextQuantity,
        weightedQuantity,
        item,
        variation,
        priceEmbeddedLineItem,
      );
    },

    /**
     * Increment the weighted quantity of an item in the cart if possible.
     *
     * Weighted Quantity adjusters do not accept price embedded data because
     * they do not exist in ACO, which is the only time price embedded items
     * can show up in WebCart.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation.
     */
    async incrementWeightedQuantity(item, variation) {
      const quantity = this.recoverUnroundedQuantityFromCart(item, variation);
      const nextWeightedQuantity = this.getNextWeightedQuantityIncrementInCart(item, variation);
      if (!this.isQuantityValidForCart(item, variation, quantity, nextWeightedQuantity)) {
        this.alertMaximumQuantityReached();
        return;
      }

      try {
        await this.setQuantity(quantity, nextWeightedQuantity, item, variation, null);
      } catch (error) {
        console.error(error);
        this.alertFailedToAddToCart();
      }
    },

    /**
     * Decrement the weighted quantity of an item in the cart if possible.
     *
     * Weighted Quantity adjusters do not accept price embedded data because
     * they do not exist in ACO, which is the only time price embedded items
     * can show up in WebCart.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in cart.
     */
    async decrementWeightedQuantity(item, variation) {
      const quantity = this.recoverUnroundedQuantityFromCart(item, variation);
      const nextWeightedQuantity = this.getNextWeightedQuantityDecrementInCart(item, variation);
      await this.setQuantity(quantity, nextWeightedQuantity, item, variation, null);
    },

    /**
     * Increment an item with a single unified adjuster.
     *
     * For items sold by random weight, this increments the weighted quantity, unless the weighted quantity adjuster is hidden.
     * For all others, this increments the quantity.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     */
    async incrementCombinedQuantities(item, variation) {
      if (this.isSoldByRandomWeightWithDualAdjusters(item)) {
        this.incrementWeightedQuantity(item, variation);
      } else {
        this.increment(item, variation);
      }
    },

    /**
     * Decrement an item with a single unified adjuster.
     *
     * For items sold by random weight, this decrements the weighted quantity, unless the weighted quantity adjuster is hidden.
     * For all others, this decrements the quantity.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     */
    async decrementCombinedQuantities(item, variation) {
      if (this.isSoldByRandomWeightWithDualAdjusters(item)) {
        this.decrementWeightedQuantity(item, variation);
      } else {
        this.decrement(item, variation);
      }
    },

    /**
     * Get the quantity of an item in the cart to display in the UI for combined adjusters.
     *
     * For items sold by random weight, this is the weighted quantity, unless the weighted quantity adjuster is hidden.
     * For all others, this is the quantity.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation.
     * @return {string}
     */
    getDisplayForCombinedQuantitiesInCart(item, variation) {
      if (this.isSoldByRandomWeightWithDualAdjusters(item)) {
        return this.getDisplayWeightedQuantityInCart(item, variation);
      }
      return this.getDisplayQuantityInCart(item, variation);
    },

    /**
     * Remove the the item from the cart unconditionally.
     * @param {Object} item The item.
     * @param {string} variation `item`'s variation in the cart.
     */
    async remove(item, variation) {
      AnalyticsService.track(TrackableEventConstants.ItemRemovedFromCart, {
        items: [item],
        customerId: this.getUser?.sub,
      });

      if (
        !this.$configuration.orderTypesEnabled ||
        (this.$configuration.orderTypesEnabled && this.isCustomerModeScan)
      ) {
        const itemLineItem = this.getItemLineItem(item, variation);
        await this.setItemQuantity({
          itemLineItem,
          item,
          variation,
          quantity: 0,
          weightedItemQuantity: null,
          orderType: this.orderType,
        });
      }

      if (
        (this.$configuration.orderTypesEnabled && this.lineItemCount() > 0) ||
        (this.$configuration.orderTypesEnabled && this.nonEmptyCarts?.length <= 1)
      ) {
        this.setCartsLoadingStatus(true);
        await this.setItemQuantity({
          item,
          variation,
          quantity: 0,
        });
        await this.getCarts();
        this.setCartsLoadingStatus(false);
      } else if (
        this.$configuration.orderTypesEnabled &&
        this.lineItemCount() === 0 &&
        this.nonEmptyCarts?.length > 1
      ) {
        this.setCartsLoadingStatus(true);
        await this.setItemQuantity({
          item,
          variation,
          quantity: 0,
        });
        const nextCart = this.carts.find(c => c.orderType !== this.cartOrderType);
        const response = await axios.get(`api/ot/${nextCart.orderType}/cart`);
        await this.setCurrentCart(response.data);
        await this.getCarts();
        this.setCartsLoadingStatus(false);
      }
    },

    /**
     * Remove a gift card from the cart.
     * @param {Object} giftCardProfile The gift card to remove.
     */
    async removeGiftCard(giftCardProfile) {
      try {
        await this.removeEGiftCard(giftCardProfile);
      } catch (error) {
        console.log(error);
      } finally {
        if (this.$configuration.orderTypesEnabled) {
          await this.getCarts();
        }
      }
    },

    /**
     * Check if a new quantity is the first item added to an empty cart
     * and the logged in user is not shopping at their home store.
     * @param {BigNumber} newQuantity New quantity of item just added
     * @return {boolean}
     */
    isFirstItemInCartAtNonHomeStore(newQuantity) {
      return (
        this.isEmpty &&
        newQuantity > 0 &&
        this.getUser?.home !== this.$configuration.store.id &&
        this.$configuration.multistore &&
        !this.isCustomerModeScan
      );
    },

    /**
     * Track the new quantity added to cart with the analytics service.
     * @param {Number} newQuantity The new quantity.
     * @param {Object} item The item.
     */
    trackAnalyticsNewQuantity(newQuantity, item) {
      AnalyticsService.track(
        newQuantity > 0
          ? TrackableEventConstants.ItemAddedToCart
          : TrackableEventConstants.ItemRemovedFromCart,
        { items: [item], customerId: this.getUser?.sub },
      );
    },

    /**
     * Create a human-readable Error describing that a quantity is invalid for an item.
     * @param {Object} item The item.
     * @param {BigNumber} quantity The invalid quantity.
     * @param {BigNumber} weightedQuantity The invalid weightedQuanity.
     * @param {BigNumber} totalBaseQuantity The invalid totalBaseQuantity.
     * @return {Error}
     */
    createQuantityError(item, quantity, weightedQuantity, totalBaseQuantity) {
      const quantityDisplay = `Quantity: ${quantity.toString()} (${this.getDisplayQuantity(
        item,
        quantity,
      )}),`;

      let weightedQuantityDisplay = '';
      if (this.isSoldByRandomWeight(item)) {
        weightedQuantityDisplay = ` Weighted Quantity: ${weightedQuantity.toString()} (${this.getDisplayWeightedQuantity(
          item,
          weightedQuantity,
        )}),`;
      }

      const totalBaseQuantityDisplay = `Total Base Quantity: ${totalBaseQuantity.toString()},`;

      const invalidText = `is invalid for item "${item.name}" (${item.id})`;

      return new Error(
        `${quantityDisplay}${weightedQuantityDisplay} ${totalBaseQuantityDisplay} ${invalidText}`,
      );
    },

    /**
     * Alert the user that an item was added to their cart.
     */
    alertItemAddedToCart() {
      ToastService.open(WCSimpleToast, {
        props: {
          variant: 'success',
          title: 'Cart',
          message: 'Item added to your Cart',
        },
        timeout: 2000,
      });
    },

    /**
     * Alert the user that they have reached the maximum quantity of an item that can be selected.
     */
    alertMaximumQuantityReached() {
      ToastService.open(WCSimpleToast, {
        props: {
          variant: 'warning',
          title: 'Inventory',
          message: 'Maximum quantity reached',
        },
        timeout: 7000,
      });
    },

    /**
     * Alert the user that the item failed to be added to the cart for an unknown reason.
     *
     * This should never happen in production, but can be possible in development.
     */
    alertFailedToAddToCart() {
      ToastService.open(WCSimpleToast, {
        props: {
          variant: 'danger',
          title: 'Error',
          message: 'Failed to add the item to your cart',
        },
        timeout: 10000,
      });
    },

    /**
     * Alert the user that they cannot add a gift card item to their cart.
     */
    alertGiftCardItem() {
      ToastService.open(WCSimpleToast, {
        props: {
          variant: 'danger',
          title: 'Cannot Add Item to Cart',
          message: this.$t('giftCardCartAlert'),
        },
        timeout: 7000,
      });
    },

    /**
     * Alert the user that they are not shopping from their home store.
     */
    async alertNotShoppingFromHomeStore() {
      try {
        await ModalService.open(WCStoreSelectModal);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
