<template>
  <WCModal :size="modalSize" position="center" @dismiss="dismiss(MODAL_EVENTS.DISMISS)">
    <WCModalBody :class="{ 'p-0': isMobile }">
      <WCStoreSelectHome
        v-if="!showAllStores"
        :dismiss="dismiss"
        :close="close"
        :isMobile="isMobile"
        @showAllStores="toggleShowAllStores"
      ></WCStoreSelectHome>
      <WCStoreSelectAllStores
        v-else
        :dismiss="dismiss"
        :close="close"
        :isMobile="isMobile"
        @showAllStores="toggleShowAllStores"
      ></WCStoreSelectAllStores>
    </WCModalBody>
  </WCModal>
</template>

<script>
import UserMixin from '@/modules/user/mixins/UserMixin';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import { MODAL_EVENTS } from '@/constants/EventConstants';
import { PlatformMixin } from '@/modules/platform';
import WCStoreSelectHome from './WCStoreSelectHome/WCStoreSelectHome.vue';
import WCStoreSelectAllStores from './WCStoreSelectAllStores/WCStoreSelectAllStores.vue';

export default {
  mixins: [PlatformMixin, ModalMixin, UserMixin],
  components: { WCStoreSelectHome, WCStoreSelectAllStores, WCModal, WCModalBody },
  data() {
    return {
      MODAL_EVENTS,
      showAllStores: false,
    };
  },
  methods: {
    toggleShowAllStores() {
      this.showAllStores = !this.showAllStores;
    },
  },
  computed: {
    modalSize() {
      if (this.showAllStores) {
        return 'large';
      }
      return 'default';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';
</style>
