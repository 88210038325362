<template>
  <WCModal position="center" @dismiss="close(MODAL_EVENTS.DISMISS)">
    <WCModalHeader class="align-items-center">
      <h2>{{ $t('membershipPaymentDue') }}</h2>
      <div class="btn btn-link underline-link-from-center" @click="close(MODAL_EVENTS.DISMISS)">
        <font-awesome-icon icon="xmark" size="2x" />
      </div>
    </WCModalHeader>
    <WCModalBody>
      <div class="text-center d-flex flex-column">
        <font-awesome-icon icon="triangle-exclamation" size="3x" class="w-100 text-warning" />
        <span class="pt-2">{{ $t('membershipPaymentIsDue') }}</span>
        <span class="pt-2">{{ $t('beforeCheckOutPayFees') }}</span>
      </div>
    </WCModalBody>
    <WCModalFooter class="justify-content-around">
      <button @click="close(MODAL_EVENTS.DISMISS)" class="btn btn-outline-primary col-5">
        {{ $t('payLater') }}
      </button>
      <router-link to="/me/coop" @click="close(MODAL_EVENTS.CONFIRM)" class="btn btn-primary col-5">
        {{ $t('payNow') }}
      </router-link>
    </WCModalFooter>
  </WCModal>
</template>

<script>
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import { MODAL_EVENTS } from '@/constants/EventConstants';

export default {
  data() {
    return {
      MODAL_EVENTS,
    };
  },
  components: { WCModal, WCModalHeader, WCModalBody, WCModalFooter },
  mixins: [ModalMixin],
};
</script>
