/**
 * mixin for header scroll Transition
 */
const SCROLL_CLASS = {
  SCROLLED_UP: 'scrolled-up',
  SCROLLED_DOWN: 'scrolled-down',
};

export default {
  data() {
    return {
      lastScrollTop: 0,
      scrollClass: SCROLL_CLASS.SCROLLED_UP,
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    /**
     * Method to debounce Handler based on delay
     * @param {function} method - Handler method
     * @param {Number} delay - Delay
     */
    debounce(method, delay) {
      clearTimeout(method._tId);
      // eslint-disable-next-line no-param-reassign
      method._tId = setTimeout(() => {
        method();
      }, delay);
    },
    /**
     * Method to invoke header scroll handler based on debounce value
     */
    handleScroll() {
      this.debounce(this.handleHeaderOnScroll, 300);
    },
    /**
     * Method to handle Mobile Header Menu Hide/Show based on Scroll
     */
    handleHeaderOnScroll() {
      const scrollTop = window.scrollY;
      const scrollTopBuffer = 70;
      if (scrollTop < this.lastScrollTop) {
        this.scrollClass = SCROLL_CLASS.SCROLLED_UP;
      } else if (scrollTop > scrollTopBuffer) {
        this.scrollClass = SCROLL_CLASS.SCROLLED_DOWN;
      }
      this.lastScrollTop = scrollTop;
    },
  },
};
