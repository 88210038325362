<template>
  <button
    @click="$emit('update:modelValue', id)"
    class="px-2 py-2 rounded border m-2 wc-select-box unstyled-btn"
    :class="[checked ? checkedColor : uncheckedColor]"
    type="button"
    role="radio"
    :aria-checked="checked"
    :data-testid="id"
  >
    <div class="wc-select-box__content d-flex flex-column">
      <slot />
    </div>
  </button>
</template>

<script>
import { v4 as uuid } from 'uuid';

export default {
  name: 'WCSelectBox',
  emits: ['update:modelValue'],
  props: {
    id: {
      type: [String, Object, Number],
      default: () => uuid(),
    },
    modelValue: {
      type: [String, Object, Boolean, Number],
    },
    /* If true, use primary color when selected, otherwise user secondary color */
    primary: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    checked() {
      return this.id === this.modelValue || null;
    },
    checkedColor() {
      return this.primary ? 'text-primary-contrast bg-primary' : 'wc-checked-not-primary';
    },
    uncheckedColor() {
      return this.primary ? 'text-primary border-primary bg-light' : 'wc-unchecked-not-primary';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-select-box__wrapper {
  padding-left: $pad-2;
  padding-right: $pad-2;
}

.wc-select-box {
  cursor: pointer;
}

.wc-select-box__content {
  min-width: rem(80px);
}

.wc-checked-not-primary {
  background-color: var(--gray);
  color: var(--gray-contrast);
}

.wc-unchecked-not-primary {
  background-color: white;
  color: var(--gray);
  border-color: var(--gray) !important;
}
</style>
