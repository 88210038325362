export default {
  props: {
    close: {
      type: Function,
      required: true,
    },
    dismiss: {
      type: Function,
      required: true,
    },
    message: {
      type: String,
      default: 'none',
    },
    variant: {
      type: String,
      validator: value =>
        ['info', 'success', 'warning', 'danger', 'primary', 'secondary', 'light', 'dark'].includes(
          value,
        ),
      default: 'warning',
    },
  },
};
