export default {
  "AcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accelerated Check Out 简易扫描购物模式"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户"])},
  "accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户号码"])},
  "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户设置"])},
  "addAsFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["添加", _interpolate(_named("name")), "作为收藏"])},
  "addCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加优惠券"])},
  "addedSugars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加糖"])},
  "addItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加商品"])},
  "addItemsToOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加商品到订单"])},
  "addItemToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加商品到清单"])},
  "addItemToListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加此商品到一个清单"])},
  "addNewCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加一张新卡"])},
  "addNewList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新清单"])},
  "addNoteAboutItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加有关此商品的备注"])},
  "addPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加一项付款方法"])},
  "addSelectedToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加所选的商品至购物车"])},
  "addSpecialInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加特别指示"])},
  "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加至购物车"])},
  "addToFavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加至收藏"])},
  "addToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加至清单"])},
  "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警报"])},
  "alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["别名"])},
  "allCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有类别"])},
  "allOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有订单"])},
  "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许"])},
  "allowCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许相机权限"])},
  "allowSubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许替代商品"])},
  "allRightsReserved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("year")), " WebCart - 保留所有权利"])},
  "alreadyVerifiedYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已验证您的帐户？"])},
  "alternateItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替代商品"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
  "amountMustBeAtLeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量必须至少为"])},
  "amountMustBeLessOrEqual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量必须少于或等于"])},
  "amountPerServing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每份含量"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用"])},
  "applyChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用更改"])},
  "areYouSureCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要取消更改吗？"])},
  "areYouSureCancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要取消订单吗？"])},
  "areYouSureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除以 * 结尾的片吗"])},
  "associate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关联"])},
  "associateAsCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关联为客户"])},
  "associationBanner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["客户关联模式，使用 WebCart 作为 ", _interpolate(_named("name"))])},
  "assumesNoLiability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对产品的不准确或错误陈述不承担任何责任。"])},
  "asYouScanItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当您扫描清单中的商品时，我们会为您勾选这些商品"])},
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在"])},
  "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用性"])},
  "availableBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用余额"])},
  "averagePerUnitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均单价"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额"])},
  "barcodeAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条码"])},
  "barcodePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在付款处显示此条码以进行扫描"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生日"])},
  "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌"])},
  "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["面包屑"])},
  "browseByCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按类别浏览"])},
  "browseQueryText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("browseText")), "\""])},
  "browseResultQuery": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("browseResult")), "\""])},
  "buyItAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再次购买"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["致电"])},
  "callForPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["致电以获得价格"])},
  "callUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["致电我们"])},
  "calories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡路里"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "cancelEditOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消编辑您的订单"])},
  "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消订单"])},
  "cancelOrderFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们在取消您的订单时遇到了问题。请刷新页面并重试。"])},
  "cancelOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的订单已被取消。"])},
  "cancelQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要取消吗？"])},
  "cannotAddItemToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法将商品添加至购物车"])},
  "cardEnteredInvalidInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您输入的卡号无效或已停止使用。请检查号码并再次尝试输入。"])},
  "cardholderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持卡人姓名"])},
  "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡号"])},
  "cardNumberIsNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡号无效"])},
  "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车"])},
  "cashierApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "cashierApprovalMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
  "categoriesMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别菜单"])},
  "categoryLimitExceededMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您不能在 ", _interpolate(_named("categoryName")), " 个类别中选择超过 ", _interpolate(_named("limit")), " 个。"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改密码"])},
  "changeYourMind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["改变想法了吗？"])},
  "changeYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改您的密码"])},
  "chargeAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改帐户"])},
  "checkCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查卡"])},
  "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结账"])},
  "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结账"])},
  "checkShoppingAtCorrectStore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您正在 ", _interpolate(_named("storeName")), " 购物。请确保这是您要订购的商店。"])},
  "checkTheBoxForReceipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请勾选此框以接收您在店内购买的电子邮件收据。"])},
  "checkTheGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请勾选您要属于的群组。"])},
  "checkYourBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查您的余额"])},
  "choose": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["选择 ", _interpolate(_named("max")), " 个"])},
  "chooseACategoryMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个类别菜单"])},
  "chooseAList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个清单"])},
  "chooseAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个帐户"])},
  "chooseAtLeast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["选择至少 ", _interpolate(_named("min")), " 个"])},
  "chooseBetween": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["选择 ", _interpolate(_named("min")), " 至 ", _interpolate(_named("max")), " 个"])},
  "chooseDataAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个日期和时间"])},
  "chooseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日期"])},
  "chooseDateAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日期和时间"])},
  "chooseLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择语言"])},
  "chooseQuantityFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择商品数量："])},
  "chooseTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择时间"])},
  "chooseUpTo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["选择最多 ", _interpolate(_named("max")), " 个"])},
  "chooseYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["选择您的 ", _interpolate(_named("category"))])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["城市"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除"])},
  "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除全部"])},
  "clearAllText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您想永久删除它们吗？"])},
  "clearCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除购物车"])},
  "clearCartFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法清除您的购物车"])},
  "clearCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功清除您的购物车"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除筛选条件"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除筛选条件"])},
  "clearOrSaveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您想保存这些商品以备日后购买还是永久删除它们？"])},
  "clickHereToRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请点击此处注册"])},
  "clickHereToReregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请点击此处重新注册"])},
  "clickHereToSendAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请点击此处以发送另一项"])},
  "clickHereToSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请点击此处以登录"])},
  "clickTheVerificationLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请点击电子邮件中的验证链接以完成帐户注册。"])},
  "clickToAddNotesAndSubstitutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请点击此处以添加备注和替代商品"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
  "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折叠"])},
  "commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["命令"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司"])},
  "completeAtCashRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在收银台完成"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
  "continueHereAndEndAcceleratedCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "continueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续购物"])},
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版权"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
  "couponAndPromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券和促销"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建帐户"])},
  "createANewList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建一个新清单"])},
  "createAPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建一个密码"])},
  "createNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建一个新密码"])},
  "creditCardInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡输入"])},
  "creditDebitCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡/借记卡"])},
  "currentAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（当前）"])},
  "currentlySelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前选择"])},
  "currentSelections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前选项"])},
  "currentSpecials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前特别推荐"])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义"])},
  "customAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义量"])},
  "customerAssociation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户关联"])},
  "customerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户卡"])},
  "customerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户 ID"])},
  "customerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户信息"])},
  "customerPrograms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户计划"])},
  "customPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义价格"])},
  "datePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期选择器"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
  "decreaseQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["减少量"])},
  "decrement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["递减"])},
  "defaultToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您想在主屏幕上安装此应用程序以便快速访问吗？"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
  "deleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除卡"])},
  "deleteCardSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已成功删除您以 ", _interpolate(_named("finalDigits")), " 结尾的卡"])},
  "deleteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除清单"])},
  "deleteWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除钱包"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货地址"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详情"])},
  "deviceNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不支持此设备"])},
  "didntReceiveEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有收到电子邮件？"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免责声明"])},
  "disclaimerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["虽然我们努力确保产品信息正确无误，但有时制造商可能会更改其成分列表。实际产品包装和材料可能包含比我们网站上显示的更多和/或不同的信息。我们建议您不要仅仅依赖我们所提供的信息，并且建议您在使用或食用产品之前确保阅读标签、警告和说明。有关产品的其他信息，请联系制造商。本网站上的内容仅供参考，不能替代医生、药剂师或其他有执照的医疗保健专业人士的建议。您不应将此信息用作自我诊断或治疗健康问题或疾病。如果您怀疑自己有医疗问题，请立即联系您的医疗保健提供者。有关膳食补充剂的信息和声明尚未经过食品和药物管理局的评估，并且不旨在诊断、治疗、治愈或预防任何疾病或健康状况。"])},
  "discountAppliedInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折扣将在购物车内使用。"])},
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折扣"])},
  "dollarSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
  "doNotAllowSubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不允许替代商品"])},
  "downloadLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载日志"])},
  "drag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拖动"])},
  "dragContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过拖动来展开产品容器"])},
  "eachAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
  "editItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑商品"])},
  "editOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑订单"])},
  "editOrderError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败！您的订单并未编辑。"])},
  "editOrderQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑订单？"])},
  "editOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的订单已准备好进行编辑。完成添加或移除商品后，请再次进行结帐"])},
  "editYourNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑您的备注"])},
  "eGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子礼品卡"])},
  "eGiftCardBuild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["构建您的电子礼品卡"])},
  "eGiftCardCartAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已添加电子礼品卡至购物车"])},
  "eGiftCardCartAddSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功添加一张电子礼品卡至您的购物车"])},
  "eGiftCardCartError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品需要与购物车中的电子礼品卡分开购买。在开始将商品添加至购物车之前，请移除或购买您的电子礼品卡。"])},
  "eGiftCardFormMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加有关此电子礼品卡的消息（非必要）"])},
  "eGiftCardModalAlertShoppingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在购买电子礼品卡之前完成购物。"])},
  "eGiftCardModalAlertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子礼品卡必须与您的购物车商品分开购买"])},
  "eGiftCardPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阅读我们的电子礼品卡政策"])},
  "eGiftCardPolicyHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子礼品卡政策"])},
  "eGiftCardPurchaseAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子礼品卡必须与您的购物车商品分开购买在开始创建电子礼品卡之前，请移除或购买这些商品"])},
  "eGiftCardShippingAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子礼品卡以电子方式（电子邮件和/或短信）或下载（用于打印）交付。请参阅步骤 1 以查看您选择的方法"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件地址"])},
  "emailInUseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的电子邮件地址已与某个帐户关联。"])},
  "emailInUseHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的电子邮件地址已被使用"])},
  "emailIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件为必填项"])},
  "emailPhoneOrAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件、电话或帐户号码"])},
  "emailTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送电子邮件至"])},
  "employeeActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["员工行动"])},
  "employeeDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["员工仪表板"])},
  "employeeLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["员工登录"])},
  "endingIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结尾为"])},
  "enterAcceleratedCheckOutModeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您当前处于 Accelerated Check Out （简易扫描购物）模式"])},
  "enterInstructionsFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["为您的 ", _interpolate(_named("itemName")), " 输入特别指示"])},
  "enterTheWrongEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入错误的电子邮件？"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
  "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error(404)"])},
  "errorInAcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简易扫描购物模式出错"])},
  "errorInCancelPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消付款出错"])},
  "errorInPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款出错"])},
  "errorScanProductNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到扫描的产品信息，请联系店长"])},
  "exitAcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出 Accelerated Check Out"])},
  "exitAcceleratedCheckOutModeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "exitAcceleratedCheckOutModeModalBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "exitAcceleratedCheckOutModeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已退出 Accelerated Check Out 模式"])},
  "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扩展"])},
  "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过期"])},
  "expirationAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过期"])},
  "expiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过期"])},
  "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败"])},
  "failedPasswordResetError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码失败"])},
  "failedVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证失败"])},
  "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏"])},
  "featureNotAvailableForDesktopWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此功能不适用于桌面"])},
  "featureNotAvailableForMobileWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此功能不适用于手机"])},
  "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["费用"])},
  "fieldIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字段为必填项"])},
  "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选条件"])},
  "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寻找"])},
  "findOurStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寻找我们的商店"])},
  "findStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寻找商店"])},
  "firefoxAndroidToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您想在主屏幕上安装此应用程序以便快速访问吗？ - 点击主页图标，然后添加到主屏幕。"])},
  "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一页"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
  "firstNameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字为必填项"])},
  "forgotYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码？"])},
  "forText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["为 ", _interpolate(_named("name"))])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来自"])},
  "getCodeViaEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过电子邮件获取代码。"])},
  "giftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["礼品卡"])},
  "giftCardCartAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的购物车中有一张电子礼品卡。请购买电子礼品卡或将其从您的购物车中移除。"])},
  "giftCardDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子礼品卡详情"])},
  "giftCardImgAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子礼品卡"])},
  "giftCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子礼品卡"])},
  "giftCardWithIdImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["电子礼品卡设计 ", _interpolate(_named("id"))])},
  "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "goGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用无纸化收据实现绿色环保"])},
  "goLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向左走"])},
  "goodThrough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效至"])},
  "goRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向右走"])},
  "gotIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["明白了"])},
  "goToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往结账"])},
  "goToHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往主商店"])},
  "goToNextMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往下一个月"])},
  "goToPreviousMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往上一个月"])},
  "goWithPaperless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用无纸化收据实现绿色环保"])},
  "grandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累计"])},
  "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎"])},
  "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有一个帐户？"])},
  "haveCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有优惠券？"])},
  "healthAttributeImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["健康属性 ", _interpolate(_named("attributeName"))])},
  "hidePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏密码"])},
  "homePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页"])},
  "homeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主商店"])},
  "homeStoreAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（主商店）"])},
  "idRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "idRequiredMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "imHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我在这里！"])},
  "importantCartMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于购物车中商品的重要信息"])},
  "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在"])},
  "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包括"])},
  "increaseQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增加量"])},
  "increment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["递增"])},
  "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成分"])},
  "ingredientsCap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成分"])},
  "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行中"])},
  "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "inStoreOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅限店内"])},
  "inStoreOnlyAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 只能在商店内购买"])},
  "invalidField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效字段"])},
  "inYourCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在您的购物车中"])},
  "iosShareMessagePart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击"])},
  "iosShareMessagePart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共享图标并选择“添加到主屏幕（Add to Home Screen）”。"])},
  "iosToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在您的主屏幕上安装此应用程序以便在您想再次购物时快速访问。"])},
  "isNowOutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在缺货并已从您的购物车中移除"])},
  "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品"])},
  "itemAddedToSaveForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "itemDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品详情"])},
  "itemNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品备注"])},
  "itemProhibitAlternativeMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法移除此项目"])},
  "itemProhibited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "itemProhibitedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品"])},
  "itemTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品总计"])},
  "itemUnavailableAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 目前缺货"])},
  "itLooksPickedUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您似乎已经提取了此订单中的商品。如果您有任何问题或疑虑，请致电我们"])},
  "joinPromosAndRewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请参加我们的促销和奖励计划"])},
  "kilometersAway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公里距离"])},
  "knowYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["知道您的密码？"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
  "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后一页"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓氏"])},
  "lastNameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓氏是必填项"])},
  "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["领英"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清单"])},
  "listIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此清单是空的！"])},
  "listNamesCannotExceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清单名称不能超过 20 个字符"])},
  "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清单"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载中"])},
  "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本地"])},
  "locallySourced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此商品为本地采购"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地点"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "loginFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录失败"])},
  "loginFormSubheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎您回来！请登录以加快结帐速度，并能够跟踪和查看您过去的订单。"])},
  "loginFormSubheadingAcceleratedCheckOut": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["欢迎使用由 ECRS 提供技术支持的 ", _interpolate(_named("retailer")), " Accelerated Check Out"])},
  "loginId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录 ID"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
  "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主"])},
  "makeASelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选一项"])},
  "makeHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将此商店设置为您的主商店"])},
  "manageSavedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理您保存的购物清单"])},
  "manageYourSavedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在此处管理您保存的清单"])},
  "memberID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会员 ID"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息"])},
  "messageSentFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的消息尚未发送"])},
  "messageSentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的信息已成功发送"])},
  "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中间名"])},
  "milesAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mi"])},
  "minimumSelectionSatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已满足最低选择量"])},
  "mobileAcceleratedCheckOutSessionInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "moreTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多次。"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
  "nameYourList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["命名您的清单"])},
  "needsMinimumSelection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["需要最少 ", _interpolate(_named("min")), " 个选择"])},
  "needToStartOver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要重新开始？"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一页"])},
  "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昵称"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不"])},
  "noCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不，取消"])},
  "noCancelDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不，取消删除"])},
  "noCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您没有存档任何安全令牌化的卡。"])},
  "noContactInfoAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可用的联系信息。"])},
  "noCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您没有任何可用的电子优惠券"])},
  "noItemsOnOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此订单上没有任何商品。"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有"])},
  "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前没有可用的订单。"])},
  "noProductImageAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["没有 ", _interpolate(_named("name")), " 的产品图片"])},
  "noReservationTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此订单类型没有可用的预订时间。"])},
  "noSavedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有已保存的信用卡"])},
  "noShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有找到购物清单"])},
  "noStoresFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有找到商店。"])},
  "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可用"])},
  "notAvailableAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
  "notAvailableOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可在网上购买"])},
  "noteAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已添加备注"])},
  "notEntered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["没有输入 ", _interpolate(_named("name"))])},
  "notOnYourList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不在您的清单上"])},
  "notSignedUpYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还没注册？"])},
  "notYourHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这不是您的主商店"])},
  "nutrition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营养"])},
  "nutritionFacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营养表"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["之"])},
  "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "okayGotIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好的，明白了！"])},
  "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旧密码"])},
  "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["哎呀！"])},
  "operaBlockedVersionWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opera 版本 9 至 12 不支持此应用程序。请使用其他浏览器。"])},
  "operaMiniToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您想在主屏幕上安装此应用程序以便快速访问吗？ - 请点击加号图标然后添加到主屏幕。"])},
  "optInToAllowInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择加入以允许我们向您发送有关即将进行的促销活动的信息、接收优惠券等。"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单"])},
  "orderCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消订单"])},
  "orderCancelledError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败！您的订单未被取消。"])},
  "orderCancelledSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的订单 ", _interpolate(_named("orderScanCode")), " 已被取消。"])},
  "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单详情"])},
  "orderHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单历史"])},
  "orderItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单商品"])},
  "orderNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单备注"])},
  "orderNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单无效"])},
  "orderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已下单"])},
  "ordersInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单进行中"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单状态"])},
  "orderTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单总计"])},
  "orderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单类型"])},
  "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺货"])},
  "outOfStockAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 目前缺货"])},
  "packOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每包装有"])},
  "packSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包装大小"])},
  "pageNavigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["页面导航"])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["页"])},
  "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已通过"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
  "passwordMustInclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的密码必须包括"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码是必填项"])},
  "passwordRequirementsNotMet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未满足密码要求"])},
  "passwordResetError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法根据提供的帐户详细信息验证您的身份。"])},
  "passwordValidationCharLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少八个字符"])},
  "passwordValidationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少一个数字"])},
  "passwordValidationSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少一个符号"])},
  "passwordValidationUpperCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少有一个大写字母"])},
  "payLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以后支付"])},
  "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付方法"])},
  "payOnYourPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在您的手机上支付"])},
  "payTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付总计"])},
  "percentDailyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日值百分比*"])},
  "percentDV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日值百分比 (DV) 告诉您一份食物中的营养素对日常饮食的贡献程度；使用每天 2000 卡路里以制定一般营养建议。"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
  "phoneNumberInUseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的电话号码已与某个帐户关联。"])},
  "phoneNumberInUseHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的电话号码已被使用"])},
  "phoneNumberIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码为必填项"])},
  "phoneOrAppMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有电话或应用程序？"])},
  "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下单"])},
  "pleaseAllowCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请让您的相机扫描条形码"])},
  "pleaseEnterValidAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效支付金额"])},
  "pleaseEnterYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["请输入你的 ", _interpolate(_named("name"))])},
  "pleaseRegisterAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请重新注册以获取新链接。"])},
  "pleaseScanNextItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请扫描您的下一个商品"])},
  "pleaseSelectAList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择一个清单"])},
  "pleaseSelectAtLeastOneDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择至少一种交货方式"])},
  "pleaseSelectDelDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择交货日期和时间"])},
  "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["积分"])},
  "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字首"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预览"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一页"])},
  "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打印"])},
  "printReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打印收据"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品"])},
  "productDescImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 产品，见页面描述"])},
  "productDetailsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品详情页面"])},
  "productImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("brandName")), " ", _interpolate(_named("productName")), " 产品"])},
  "productImgGalleryThumbnailAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 产品图库缩略图"])},
  "productInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品信息"])},
  "productMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品。方法"])},
  "productPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品价格"])},
  "profileAddressSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址信息"])},
  "profileEmailSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件信息"])},
  "profileOtherSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他信息"])},
  "profilePersonalSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人信息"])},
  "profilePhoneSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话信息"])},
  "profileWorkSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工资信息"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
  "quantityAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QTY"])},
  "quickLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Links"])},
  "readyToRedeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["准备使用积分！"])},
  "receiptLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收据加载中..."])},
  "recipientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收货人姓名"])},
  "recipientsEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收货人的电子邮件地址"])},
  "recipientsPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收货人的电话号码"])},
  "recommendComplexPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们建议您创建一个与您过去使用的密码不同的复杂密码。"])},
  "redeemAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["积分使用在"])},
  "redirecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新定向……"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地区"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
  "registerAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再次注册"])},
  "remainingTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剩余总计"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除"])},
  "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除全部"])},
  "removeFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["从收藏中移除 ", _interpolate(_named("name"))])},
  "removeFromCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从购物车中移除"])},
  "removeFromCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从结账中移除"])},
  "removeFromFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从筛选条件中移除"])},
  "removeFromList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从清单中移除"])},
  "removeItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除商品"])},
  "removeSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除已选择项"])},
  "removeTheItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除商品"])},
  "replicateNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即复制"])},
  "resendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重发电子邮件"])},
  "resetMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置方法"])},
  "resetNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即重置"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码"])},
  "resetPasswordAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您在商店中创建了帐户，则第一次在线购物时可能需要设置密码才能在线购物"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结果"])},
  "returnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回主页"])},
  "reviewYourItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看您的商品"])},
  "reviewYourOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看您的订单"])},
  "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奖励"])},
  "runMobileAuthenticator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运行您的移动验证器应用程序以获取六位数代码"])},
  "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s"])},
  "salePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售价格"])},
  "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您好"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "saveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存此卡"])},
  "savedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已保存卡"])},
  "savedForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已保存以便以后使用"])},
  "saveForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存以便以后使用"])},
  "saveItemsForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存商品以便以后购买"])},
  "scanAnItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请扫描一个商品"])},
  "scanBarcodeCustomerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请扫描此条码以使用您的客户卡"])},
  "scanCustomerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扫描客户卡（店内）"])},
  "scanError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到商品"])},
  "scanItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请扫描一个商品"])},
  "scanItemInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当您扫描清单中的商品时，我们会为您勾选这些商品"])},
  "scanNextItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请扫描您的下一个商品"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
  "searchByZipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按邮政编码搜索"])},
  "searchCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索客户……"])},
  "searchFilterCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索类别商品"])},
  "searchProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索计划"])},
  "searchResultsFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索结果"])},
  "securelyTokenizedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全令牌化卡"])},
  "securityCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全代码"])},
  "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
  "seePriceAtCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看结账价格"])},
  "seePriceInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看购物车价格"])},
  "selectADesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个设计"])},
  "selectAnAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个数量"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个国家"])},
  "selectDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日子"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已选择"])},
  "selectedGiftCardImgAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已选择的电子礼品卡"])},
  "selectedToPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已选择以打印"])},
  "selectHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择小时"])},
  "selectImHereButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当您到达商店时请选择“I’m here”（我在这里）按钮，我们会将您订购的商品带出来给您。"])},
  "selectMinutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择分钟"])},
  "selectOneGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请准确选择您要所属的 1 个群组。"])},
  "selectOrderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择订单类型"])},
  "selectPaymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择您希望如何支付您的商品"])},
  "selectProductManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手动选择产品"])},
  "selectQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择数量"])},
  "selectShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择购物清单"])},
  "selectState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个州"])},
  "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择时间"])},
  "sendDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日期"])},
  "senderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送者名字"])},
  "sendLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以后发送"])},
  "sendLaterDatePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以后发送日期选择器"])},
  "sendLaterTimePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以后发送时间选择器"])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送信息"])},
  "sendNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即发送"])},
  "sendOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送于"])},
  "sendUsAMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向我们发送一项信息"])},
  "servingSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["份量大小"])},
  "servingsPerContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每容器份量数"])},
  "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运送"])},
  "shippingProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运送服务提供商"])},
  "shipTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运送至"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物"])},
  "shopByCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按类别购物"])},
  "shoppingAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物地点"])},
  "shoppingCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车"])},
  "shoppingCartWithItemsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["购物车有 ", _interpolate(_named("count")), " 件商品"])},
  "shoppingLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物清单"])},
  "shopStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在此商店购物"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示"])},
  "showHidePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示/隐藏密码"])},
  "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在显示"])},
  "showingOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示订单"])},
  "showingResultsFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示结果"])},
  "showPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示密码"])},
  "sideMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["侧菜单"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大小"])},
  "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社交"])},
  "sorryCouponCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抱歉，您输入的优惠券代码不能用于此次交易。"])},
  "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序"])},
  "sortProductBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序产品按"])},
  "specialInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特别指示"])},
  "specialPromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特别促销"])},
  "splitTender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SplitTender"])},
  "stageCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阶段已完成"])},
  "stageIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阶段未完成"])},
  "stageInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阶段进行中"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["州"])},
  "storeLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店地点"])},
  "storeLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店地点"])},
  "storeLogoAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店商标"])},
  "storeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店名称"])},
  "streetAddress1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["街道地址"])},
  "streetAddress2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公寓好/套房号（非必要）"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
  "substitutionAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许替代商品"])},
  "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小计"])},
  "successOrderInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功！谢谢，您的订单正在处理中。"])},
  "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字尾"])},
  "suggestionsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建议清单"])},
  "suppressReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭纸质收据"])},
  "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["短信"])},
  "textTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送短信至"])},
  "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["谢谢！"])},
  "thankYouForCreatingAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感谢您创建帐户！通过创建帐户，您将能够享受更快的结账体验、跟踪和查看过去的订单等。"])},
  "timePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间选择器"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["职衔"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至"])},
  "toastInstallBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装"])},
  "toastNotNowBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂不创建"])},
  "toastOkBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确定"])},
  "toCap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至"])},
  "tokenServerUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全令牌服务器当前不可用。请稍后再试。"])},
  "topResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳结果"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
  "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追踪"])},
  "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推特"])},
  "twoFactorVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["两因素验证"])},
  "typeListName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请键入清单名称"])},
  "unassociate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消关联"])},
  "unassociateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已成功取消关联"])},
  "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可用"])},
  "upc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPC"])},
  "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码"])},
  "verificationLinkHasExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很抱歉，此验证链接已过期"])},
  "verifyingYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在验证您的帐户……"])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看全部"])},
  "viewAllItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看全部商品"])},
  "viewEGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看电子礼品卡"])},
  "viewManageList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看/管理清单"])},
  "viewManageShoppingList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["查看或管理 ", _interpolate(_named("shoppingListName")), " 清单"])},
  "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
  "viewNextDateSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看下一组可用日期"])},
  "viewNextTimeSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看下一组可用时间"])},
  "viewPrevDateSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看上一组可用日期"])},
  "viewPrevTimeSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看上一组可用时间"])},
  "viewReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看收据"])},
  "viewStoreLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看商店地址"])},
  "viewYour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看您的"])},
  "voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代金券"])},
  "waitItemsInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等一下！您的购物车中有商品。"])},
  "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱包"])},
  "weCouldntFindWhatYoureLookingFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们找不到您要找的东西。"])},
  "weHaveBeenNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们已收到通知，您已到达此处。"])},
  "weightedItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "weightedItemMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "weJustSentAVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们刚刚向您的电子邮件发送了验证链接"])},
  "weNeedToSendVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们需要向您发送验证码。您希望如何接收验证码？"])},
  "wereOnOurWay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们正在路上"])},
  "whenFinishedEditCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成订单编辑后，您需要再次继续结帐"])},
  "whenYouArrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当你到达"])},
  "willBeSentOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将发送与"])},
  "wouldYouLikeToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您想将这些项目添加到您将要编辑的订单中还是将其保存以备后用？"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
  "yesContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是，继续"])},
  "yesDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是，删除"])},
  "yesDeleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是，删除以 * 结尾的卡片"])},
  "youCanEither": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以"])},
  "youCanUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以使用"])},
  "youCanUsePromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以立即使用这些促销或将其保存以备后用。"])},
  "youHave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您有"])},
  "youHaveAlreadyNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已经成功通知我们您已来到这里。如果您遇到异常长时间的延迟，请致电我们"])},
  "yourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的帐户"])},
  "yourCartIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的购物车是空的"])},
  "yourCompletedOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已完成的订单"])},
  "yourCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的电子优惠券"])},
  "yourCredentialsMatchMultipleAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的凭据匹配多个帐户，请选择以哪个帐户登录"])},
  "yourCustomerDiscounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的客户折扣"])},
  "yourID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的 ID"])},
  "yourPunchCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的 ePunch 电子积分卡"])},
  "yourVerificationCodeSentShortly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的验证码将很快通过提供的联系方式发送。"])},
  "youTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮政编码"])},
  "zipCodeInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮政编码无效"])},
  "zipCodeValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮政编码有效"])}
}