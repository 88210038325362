<template>
  <div>
    <platform-renderer>
      <template v-slot:onMobile>
        <div class="mobile-item-modifier">
          <!-- START : Current Selections Wrap -->
          <div class="selections-wrap pb-3">
            <!-- START : Selections Content -->
            <WCItemModifierAnswers :item="item" @clearAll="$emit('clearAll')" />
            <!-- END : Selections Content -->

            <!-- START : Add Special Instructions -->
            <div class="d-flex justify-content-end mx-3 mb-2">
              <button
                type="button"
                class="unstyled-btn text-primary font-size-lg font-weight-bold"
                @click="addInstructions()"
                data-testid="addInstructionsButton"
              >
                <font-awesome-icon icon="plus" class="font-size-base" />
                {{ $t('addSpecialInstructions') }}
              </button>
            </div>
            <!-- END : Add Special Instructions -->
            <!-- START : Selection Footer -->
            <div class="selections__footer d-flex justify-content-between align-items-center mx-3">
              <!-- START : Selection Total Price -->
              <div class="font-size-lg font-weight-bold" data-testid="selectionTotal">
                {{ $t('total') }}:
                <span class="ml-3">
                  {{ $filters.currency(calculateSelectionTotal) }}
                </span>
              </div>
              <!-- END : Selection Total Price -->
              <!-- START : Slot for Add to Cart Button -->
              <slot name="addToCartBtn"></slot>
              <!-- END : Slot for Add to Cart Button -->
            </div>
            <!-- END : Selection Footer -->
          </div>
          <!-- END : Current Selections Wrap -->
          <!-- START : Customize Items Collapse Group -->
          <WCCollapseCardGroup
            :count="categories.length"
            :initialActiveIdx="1"
            :oneActiveCard="true"
            :hasBorderRadius="false"
          >
            <WCCollapseCard
              class="w-100"
              bodyClass="p-0 mb-3"
              :isCollapsed="activeCategory.id !== -2"
              @onCollapseOrExpand="setActiveCategory({ id: -2 })"
              openIcon="chevron-down"
              closeIcon="chevron-up"
              data-testid="quantityWeightCollapseCard"
            >
              <template v-slot:headerTitle>
                <WCItemModifierQuantity
                  class="wc-category nav-item font-weight-bold"
                  :class="{
                    'wc-category-active': activeCategory.id === -2,
                    'wc-category-inactive': activeCategory.id !== -2,
                  }"
                  :item="item"
                />
              </template>
              <template v-slot:cardBody>
                <WCItemModifierQuantityEntry
                  :itemModifiers="itemModifiers"
                  :item="item"
                  :lineItem="lineItem"
                />
              </template>
            </WCCollapseCard>
            <!-- START : Category Item Collapse Card -->
            <WCCollapseCard
              class="w-100"
              bodyClass="p-0 mb-3"
              :isCollapsed="activeCategory.id !== category.id"
              v-for="category in categories"
              :key="category.id"
              @onCollapseOrExpand="setActiveCategory(category)"
              openIcon="chevron-down"
              closeIcon="chevron-up"
            >
              <template v-slot:headerTitle>
                <WCItemModifierCategory
                  :modelValue="category"
                  :category="category"
                  :selectedAnswers="getSelectedItemsByCategory(category)"
                  class="wc-category nav-item font-weight-bold"
                  :class="{
                    'wc-category-active': activeCategory.id === category.id,
                    'wc-category-inactive': activeCategory.id !== category.id,
                  }"
                  criteriaIconColor="red"
                />
              </template>
              <template v-slot:cardBody>
                <WCItemModifierItemGroup
                  :category="category"
                  :selectedAnswers="getSelectedItemsByCategory(category)"
                />
              </template>
            </WCCollapseCard>
            <!-- END : Category Item Collapse Card -->
            <!-- START : Linked Item Group Collapse Card -->
            <WCCollapseCard
              class="w-100"
              bodyClass="p-0 mb-3"
              :isCollapsed="activeCategory.id !== 1"
              v-if="itemModifiers.linkedItems"
              @onCollapseOrExpand="setActiveCategory({ id: 1 })"
              openIcon="chevron-down"
              closeIcon="chevron-up"
            >
              <template v-slot:headerTitle>
                <WCItemModifierLinkedItems
                  v-if="itemModifiers.linkedItems"
                  :linkedItems="itemModifiers.linkedItems"
                  class="wc-category nav-item font-weight-bold"
                  :class="{
                    'wc-category-active': activeCategory.id === 1,
                    'wc-category-inactive': activeCategory.id !== 1,
                  }"
                />
              </template>
              <template v-slot:cardBody>
                <WCItemModifierLinkedItemsGroup
                  :itemModifiers="itemModifiers"
                  :aria-labelledby="
                    constructCategoryId(
                      itemModifiers.linkedItems.name,
                      itemModifiers.linkedItems.id,
                    ) + 'tab'
                  "
                />
              </template>
            </WCCollapseCard>
            <!-- END : Linked Item Group Collapse Card -->
          </WCCollapseCardGroup>
          <!-- END : Customize Now Items Collapse Group -->
        </div>
      </template>
      <template v-slot:onDesktop>
        <div class="row wc-item-modifier-dialog">
          <section class="col-3">
            <WCItemModifierCategoryGroup
              :categories="categories"
              :itemModifiers="itemModifiers"
              :modelValue="activeCategory"
              @update:modelValue="setActiveCategory($event)"
              :itemName="item.name"
              :item="item"
            />

            <b>
              {{ $t('needToStartOver') }}
              <button
                class="btn btn-link underline-link-from-center"
                @click.prevent="$emit('clearAll')"
              >
                {{ $t('clearAll') }}
              </button>
            </b>
          </section>
          <section class="col-6">
            <div class="tab-content" id="wc-tabContent">
              <div
                v-for="category in categories"
                :key="category.id"
                class="tab-pane fade"
                :id="constructCategoryId(category.name, category.id) + 'tabpanel'"
                :class="{
                  show: activeCategory.id === category.id,
                  active: activeCategory.id === category.id,
                }"
                role="tabpanel"
                :aria-labelledby="constructCategoryId(category.name, category.id) + 'tab'"
                tabindex="0"
              >
                <WCItemModifierItemGroup
                  :category="category"
                  :selectedAnswers="getSelectedItemsByCategory(category)"
                />
              </div>
              <div
                class="tab-pane fade"
                role="tabpanel"
                :class="{
                  show: activeCategory.id === -1,
                  active: activeCategory.id === -1,
                }"
                id="special-instructions-tabpanel"
                aria-labelledby="special-instructions-tab"
                tabindex="0"
              >
                <WCItemModifierInstructionsEntry
                  :item="item"
                  :instructions="instructions"
                  @input="$emit('input', $event)"
                />
              </div>
              <div
                class="tab-pane fade"
                role="tabpanel"
                :class="{
                  show: activeCategory.id === -2,
                  active: activeCategory.id === -2,
                }"
                id="modifier-quantity-tabpanel"
                aria-labelledby="modifier-quantity-tab"
                tabindex="0"
              >
                <WCItemModifierQuantityEntry
                  :itemModifiers="itemModifiers"
                  :item="item"
                  :lineItem="lineItem"
                />
              </div>
              <div
                class="tab-pane fade"
                role="tabpanel"
                v-if="itemModifiers.linkedItems"
                :class="{
                  show: activeCategory.id === 1,
                  active: activeCategory.id === 1,
                }"
                :aria-labelledby="
                  constructCategoryId(
                    itemModifiers.linkedItems.name,
                    itemModifiers.linkedItems.id,
                  ) + 'tab'
                "
                :id="
                  constructCategoryId(
                    itemModifiers.linkedItems.name,
                    itemModifiers.linkedItems.id,
                  ) + 'tabpanel'
                "
                tabindex="0"
              >
                <WCItemModifierLinkedItemsGroup
                  :itemModifiers="itemModifiers"
                  :aria-labelledby="
                    constructCategoryId(
                      itemModifiers.linkedItems.name,
                      itemModifiers.linkedItems.id,
                    ) + 'tab'
                  "
                />
              </div>
            </div>
          </section>
          <section class="col-3 d-flex flex-column">
            <WCItemModifierAnswers :item="item" />
          </section>
          <div class="col-12 d-flex justify-content-end" data-testid="selectionTotal">
            <b>
              {{ $t('total') }}
            </b>
            <span class="ml-3">
              {{ $filters.currency(calculateSelectionTotal) }}
            </span>
          </div>
        </div>
      </template>
    </platform-renderer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { BigNumber } from 'bignumber.js';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import WCItemModifierItemGroup from '@/modules/itemModifiers/components/WCItemModifierItemGroup/WCItemModifierItemGroup.vue';
import WCItemModifierCategoryGroup from '@/modules/itemModifiers/components/WCItemModifierCategoryGroup/WCItemModifierCategoryGroup.vue';
import WCItemModifierCategory from '@/modules/itemModifiers/components/WCItemModifierCategory/WCItemModifierCategory.vue';
import WCItemModifierLinkedItems from '@/modules/itemModifiers/components/WCItemModifierLinkedItems/WCItemModifierLinkedItems.vue';
import WCItemModifierAnswers from '@/modules/itemModifiers/components/WCItemModifierAnswers/WCItemModifierAnswers.vue';
import ItemModifierMixin from '@/modules/itemModifiers/mixins/ItemModifierMixin';
import ItemModifierQuantityMixin from '@/modules/itemModifiers/mixins/ItemModifierQuantityMixin';
import CartControls from '@/modules/cart/mixins/CartControls/CartControls';
import WCItemModifierInstructionsEntry from '@/modules/itemModifiers/components/WCItemModifierInstructionsEntry/WCItemModifierInstructionsEntry.vue';
import WCItemModifierQuantity from '@/modules/itemModifiers/components/WCItemModifierQuantity/WCItemModifierQuantity.vue';
import WCItemModifierQuantityEntry from '@/modules/itemModifiers/components/WCItemModifierQuantityEntry/WCItemModifierQuantityEntry.vue';
import ModalService from '@/modules/modals/services/modal.service';
import WCInstructionsModal from '@/modules/checkout/components/WCInstructionsModal/WCInstructionsModal.vue';
import WCItemModifierLinkedItemsGroup from '@/modules/itemModifiers/components/WCItemModifierLinkedItemsGroup/WCItemModifierLinkedItemsGroup.vue';
import WCCollapseCardGroup from '@/components/WCCollapseCardGroup/WCCollapseCardGroup.vue';
import WCCollapseCard from '@/components/WCCollapseCard/WCCollapseCard.vue';

export default {
  components: {
    WCItemModifierItemGroup,
    WCItemModifierCategoryGroup,
    WCItemModifierAnswers,
    WCItemModifierInstructionsEntry,
    WCItemModifierQuantity,
    WCItemModifierQuantityEntry,
    WCItemModifierLinkedItemsGroup,
    PlatformRenderer,
    WCCollapseCardGroup,
    WCCollapseCard,
    WCItemModifierCategory,
    WCItemModifierLinkedItems,
  },
  name: 'WCItemModifier',
  mixins: [ItemModifierMixin, CartControls, ItemModifierQuantityMixin],
  props: {
    lineItem: {},
    instructions: {},
    isEditing: {
      type: Boolean,
    },
    orderType: {},
  },
  emits: ['clearAll', 'input'],
  created() {
    const activeCategory = { id: -2 };
    this.setActiveCategory(activeCategory);
  },
  methods: {
    ...mapMutations({
      setActiveCategory: 'items/SET_ACTIVE_CATEGORY',
    }),
    ...mapActions({
      setMtoItemTotal: 'items/setMtoItemTotal',
    }),
    setTotal(total) {
      this.setMtoItemTotal(total);
    },
    /**
     * Method to get selected items in the category
     */
    getSelectedItemsByCategory(category) {
      const active = [];

      Object.values(this.currentAnswers).forEach(a => {
        if (a.quantity > 0) {
          category.items.forEach(i => {
            if (a.id === i.id) {
              active.push(a);
            }
          });
        }
      });

      return active;
    },
    /**
     * Method to open and store special instructions
     */
    async addInstructions() {
      try {
        const instructionsModalResponse = await ModalService.open(WCInstructionsModal, {
          lineItem: this.item,
          saveBtnLabel: 'save',
          initialInstructions: this.instructions,
        });
        this.$emit('input', instructionsModalResponse?.instructions);
      } catch (error) {
        console.error(error);
      }
    },
  },

  computed: {
    ...mapGetters({ activeCategory: 'items/getActiveCategory' }),
    calculateSelectionTotal() {
      const baseTotal = new BigNumber(this.item.actualPrice).multipliedBy(this.basePriceMultiplier);
      const addOnTotal = Object.values(this.currentAnswers).reduce(
        (a, b) => a.plus(new BigNumber(b.priceModifier).multipliedBy(this.addOnPriceMultiplier)),
        new BigNumber(0),
      );
      const itemTotal = baseTotal.plus(addOnTotal);
      this.setTotal(itemTotal.toNumber());
      return itemTotal.toNumber();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-item-modifier-dialog {
  min-height: 30rem;
}

/*----- START : Mobile Item Modifier Wrap -----*/
.mobile-item-modifier {
  .selections-wrap {
    border-bottom: 2px solid var(--secondary, $secondary);
  }
  .item-modifier-tab__header {
    border-bottom: 2px solid var(--gray-200, $gray-200);
  }
  :deep(.fade:not(.show)) {
    display: none;
  }
}
/*----- END : Mobile Item Modifier Wrap -----*/
</style>
