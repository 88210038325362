<template>
  <div class="card border-0 wc-cartdrawer__ordercard--wrapper">
    <div
      class="container-fluid justify-content-between card-header wc-cartdrawer__ordercard--header align-items-center"
    >
      <WCCartDrawerSubtotal
        :cart="cart"
        :walletEbtTokens="walletEbtTokens"
        :loadingWallet="loadingWallet"
        class="text-primary"
      />
      <WCCartDrawerCheckoutButton class="wc-cartdrawer__ordercard--checkout" ref="checkoutButton" />
    </div>
    <WCCartDrawerItems :cart="cart" class="card-body" />
  </div>
</template>

<script>
import WCCartDrawerItems from '@/modules/cart/components/WCCartDrawerItems/WCCartDrawerItems.vue';
import WCCartDrawerCheckoutButton from '@/modules/cart/components/WCCartDrawerCheckoutButton/WCCartDrawerCheckoutButton.vue';
import { mapGetters } from 'vuex';
import WCCartDrawerSubtotal from '../WCCartDrawerOrderType/components/WCCartDrawerSubtotal.vue';

export default {
  components: { WCCartDrawerItems, WCCartDrawerCheckoutButton, WCCartDrawerSubtotal },
  props: {
    cart: {
      type: Object,
    },
    walletEbtTokens: {
      type: Array,
    },
    loadingWallet: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isFetched: 'cart/isFetched',
      isScanAndGoMode: 'user/isCustomerModeScan',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';
.wc-cartdrawer__ordercard--header {
  display: flex;
  background-color: var(--secondary-lighter-7);
}

@include media-breakpoint-down(md) {
  .wc-cartdrawer__ordercard--header {
    display: grid;
    gap: 0.5rem;
  }

  .wc-cartdrawer__ordercard--checkout {
    width: 100%;
    grid-column: span 2;
  }
}
</style>
