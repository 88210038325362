import AlertService from '@/modules/alerts/services/AlertService';
import WCMembershipPaymentDueAlert from '@/modules/membership/components/WCMembershipPaymentDueAlert/WCMembershipPaymentDueAlert.vue';
import WCMembershipPaymentDueModal from '@/modules/membership/components/WCMembershipPaymentDueModal/WCMembershipPaymentDueModal.vue';
import ModalService from '@/modules/modals/services/modal.service';

interface State {
  feeAlertDisplayed: boolean;
}

const state: State = {
  feeAlertDisplayed: false,
};

const getters = {
  isFeeAlertDisplayed(state: State) {
    return state.feeAlertDisplayed;
  },
};

export const SET_FEE_ALERT_DISPLAYED = 'SET_FEE_ALERT_DISPLAYED';

const mutations = {
  [SET_FEE_ALERT_DISPLAYED](state: State, feeAlertDisplayed: boolean) {
    state.feeAlertDisplayed = feeAlertDisplayed;
  },
};

const actions = {
  async displayFeeAlert({ state, commit }) {
    if (state.feeAlertDisplayed) {
      return;
    }

    await ModalService.open(WCMembershipPaymentDueModal);
    AlertService.show(WCMembershipPaymentDueAlert, {
      props: { variant: 'danger' },
      timeout: 0,
    });
    commit(SET_FEE_ALERT_DISPLAYED, true);
  },
  hideFeeAlert({ commit }) {
    commit(SET_FEE_ALERT_DISPLAYED, false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
