import { createRouter, createWebHistory } from 'vue-router';
import { store } from '@/store';
import routeGuardAccess from './route-guards/route-guards';
import routes from './routes/routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach(routeGuardAccess);

function getOrderTypeNameById(categoryId, configuration) {
  const selectedCategory = configuration.orderTypes.find(orderType => orderType.id === categoryId);
  if (selectedCategory === undefined) {
    return 'Shopping by Order Type';
  }
  return selectedCategory.name;
}

export function getRoutePageTitle(to, configuration) {
  let pageTitle = to.meta?.title ? to.meta.title : to.name;
  if (pageTitle?.includes('Shopping by Order Type') && configuration.orderTypesEnabled) {
    pageTitle = getOrderTypeNameById(to.params.id, configuration);
  }
  pageTitle = pageTitle ? `- ${pageTitle}` : '';
  return `${configuration.store.name} ${pageTitle}`;
}
router.afterEach(to => {
  document.title = getRoutePageTitle(to, store.getters.getConfiguration);
});

export default router;
