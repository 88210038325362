<template>
  <WCModal>
    <WCModalHeader class="align-items-baseline" @dismiss="dismiss('Dismissed')">
      <h3>{{ $t('createANewList') }}</h3>
      <button class="btn btn-link underline-link-from-center" @click="dismiss('Dismissed')">
        {{ $t('cancel') }}
      </button>
    </WCModalHeader>
    <WCModalBody>
      <div class="col-12">
        <form class="align-items-center pt-2" @submit.prevent="addList">
          <label class="mb-0 font-weight-bold">{{ $t('nameYourList') }}</label>
          <WCInput class="form-control mt-2" type="text" v-model="form.name" />
        </form>
      </div>
    </WCModalBody>
    <WCModalFooter>
      <button
        class="wc-modal__footer-btn--single btn btn-primary mx-auto"
        type="submit"
        @click="addList"
      >
        {{ $t('save') }}
      </button>
    </WCModalFooter>
  </WCModal>
</template>
<script>
import { mapActions } from 'vuex';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import WCInput from '@/modules/forms/components/WCInput/WCInput.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ToastService from '@/modules/toasts/services/ToastService';

export default {
  name: 'WCCreateListModal',
  mixins: [ModalMixin],
  components: { WCModal, WCModalHeader, WCModalBody, WCInput, WCModalFooter },
  data() {
    return {
      form: {
        name: '',
      },
    };
  },
  methods: {
    ...mapActions({
      add: 'lists/add',
    }),
    async addList() {
      try {
        const selectedList = (await this.add(this.form)) || {};
        this.form = {};
        this.close(selectedList);
      } catch (error) {
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: 'Error',
            message: error.message,
          },
          timeout: 7000,
        });
      }
    },
  },
};
</script>
