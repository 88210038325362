<template>
  <div>
    <div class="container-fluid">
      <div class="row py-3 px-3 wc-mobile-search align-items-center justify-content-between">
        <button :aria-label="$t('goBack')" @click="$router.go(-1)" class="unstyled-btn">
          <font-awesome-icon
            class="font-size-xl text-dark mr-1"
            icon="arrow-left"
            :aria-label="$t('goBack')"
          />
        </button>
        <div class="search--input" :class="{ 'focusable-area': hasFocus }">
          <WCInputGroup class="w-100">
            <template v-slot:left>
              <button
                @click="submitSearch(searchInput)"
                class="btn wc-search-button"
                :aria-label="$t('search')"
              >
                <font-awesome-icon icon="magnifying-glass" />
              </button>
            </template>
            <div
              class="wc-search-area"
              role="combobox"
              :aria-label="$t('search')"
              :aria-expanded="showSuggestions ? 'true' : 'false'"
              :aria-owns="showSuggestions ? 'search-listbox' : null"
            >
              <WCInput
                class="wc-search-box border-dark"
                :class="{ 'search-border': !isClearSearchAvailable }"
                ref="searchInput"
                @focusin="focusInSearch(searchInput)"
                @focusout="hideModal"
                v-model="searchInput"
                @update:modelValue="fetchSuggestions"
                :placeholder="$t('search')"
                @keydown.enter="submitSearch(searchInput)"
                @keydown.down="handleKeyDown()"
                @keydown.up="handleKeyUp()"
                @keydown.esc="handleEscapeKey()"
                :aria-label="$t('search')"
                :aria-controls="showSuggestions ? 'search-listbox' : null"
                :aria-activedescendant="
                  activeSuggestionIndex !== -1 ? 'selected-search-option' : null
                "
                aria-autocomplete="list"
              />
            </div>

            <template v-slot:right
              ><button
                class="btn wc-clear-button"
                @click="onClickClearIcon"
                v-if="isClearSearchAvailable"
                :aria-label="$t('clear')"
              >
                <font-awesome-icon :icon="['fa', 'xmark']" />
              </button>
            </template>
          </WCInputGroup>
        </div>
      </div>
      <div class="row wc-search-area">
        <div
          class="dropdown-menu show"
          v-if="showSuggestions && suggestions && suggestions.length && searchInput"
          role="listbox"
          id="search-listbox"
        >
          <div class="pl-4 wc-results-header">{{ $t('topResults') }}</div>

          <div
            class="wrapper"
            v-for="(suggestion, index) in suggestions"
            :key="index"
            :value="suggestion"
            @keydown.enter="submitSearch(suggestion.name)"
            @mousedown="submitSearch(suggestion.name)"
            @mousemove="activeSuggestionIndex = index"
            role="option"
            :id="activeSuggestionIndex === index && 'selected-search-option'"
            :aria-selected="true && activeSuggestionIndex === index"
          >
            <span
              class="wc-suggestion dropdown-item"
              :class="{ active: activeSuggestionIndex === index }"
            >
              {{ suggestion.name }}

              <span
                @mousedown.stop="submitSearch(suggestion.name, suggestion.department)"
                @keydown.enter="submitSearch(suggestion.name, suggestion.department)"
                class="wc-department dropdown-item"
                :class="{ active: activeSuggestionIndex === index }"
                v-if="index === 0 && suggestion.department"
              >
                {{ $t('in') }} {{ suggestion.department }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WCInputGroup from '@/modules/forms/components/WCInputGroup/WCInputGroup.vue';
import WCInput from '@/modules/forms/components/WCInput/WCInput.vue';
import SearchComboBoxMixin from '@/mixins/SearchComboBoxMixin';

export default {
  name: 'WCMobileSearchHeader',
  components: { WCInputGroup, WCInput },
  mixins: [SearchComboBoxMixin],
  computed: {
    isClearSearchAvailable() {
      return this.searchInput.length > 0;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/_tools.scss';
@import '~@/assets/styles/_settings.scss';

.wc-search-area {
  position: relative;
  flex: 1 1 auto;

  .wc-search-box {
    border-radius: 0px;
    border-color: var(--light-darker-5);
    border-left: none;
    padding: $pad-1;

    &.form-control:focus {
      box-shadow: none;
    }

    .form-group-sm .form-control {
      font-size: $font-size-base;
    }
  }

  .dropdown-menu {
    right: 0;
    border: none;
    padding: 0 0 $pad-2;
    margin: 0;
  }

  .wc-suggestion {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /** On change default bootstrap colour to the secondary brand colour **/
  .dropdown-item {
    &.active,
    &:active,
    &:hover,
    &:focus {
      background-color: var(--secondary-lighter-7, get-color('secondary', 'lighter-7'));
      color: var(--secondary-contrast, get-color-contrast('secondary'));
    }
  }

  .wc-department {
    font-size: $font-size-xs;
    color: var(--gray);
    padding: 0 0 $pad-2 $pad-3;
    &.active {
      color: inherit;
    }
    &:hover {
      font-weight: 600;
    }
  }
}
.wc-mobile-search {
  background: var(--light-darker-2);
  flex-wrap: nowrap;
}
.wc-search-button {
  color: var(--light-darker-6);
  background: var(--white);
  border-color: var(--dark, $dark);
  border-right: none;
  border-radius: 10px 0px 0px 10px;
}
.wc-clear-button {
  color: var(--light-darker-6);
  background: var(--white);
  border-color: var(--dark, $dark);
  border-left: none;
  border-radius: 0px 10px 10px 0px;
}
.wc-results-header {
  background: var(--secondary-lighter-6, get-color('secondary', 'lighter-6'));
  font-weight: 700;
  font-size: $font-size-sm;
  height: 3rem;
  padding-top: $pad-3 * 0.8;
}
.search--input {
  width: 90%;
  &.focusable-area {
    border-radius: 10px;
  }
}
.search-border {
  border-radius: 0 10px 10px 0 !important;
  border-color: var(--light-darker-5);
}
</style>
