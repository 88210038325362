<template>
  <div class="modal-footer">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
