<template>
  <div
    class="modal d-block"
    :id="`modal-${id}`"
    tabindex="-1"
    role="dialog"
    @keydown="checkKeyEvent"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document" :class="classes">
      <div class="modal-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['dismiss'],
  props: {
    id: {
      type: [Number, String],
      default: () => uuid(),
    },
    size: {
      validator: value => ['small', 'default', 'large', 'xl'].includes(value),
      type: String,
      default: 'default',
    },
    position: {
      type: String,
    },
  },
  mounted() {
    const modal = document.querySelector(`#modal-${this.id}`);
    // Focus tabindex on modal div by default
    if (modal) {
      modal.focus();
    }
  },
  methods: {
    checkKeyEvent(event) {
      const focusableList = document.querySelectorAll(
        `#modal-${this.id} button:not(:disabled), #modal-${this.id} [href], #modal-${this.id} input, #modal-${this.id} select, #modal-${this.id} textarea, #modal-${this.id} [tabindex]:not([tabindex="-1"])`,
      );
      // escape early if only 1 or no elements to focus
      if (focusableList.length < 2 && event.key === 'Tab') {
        event.preventDefault();
        return;
      }
      const last = focusableList.length - 1;
      if (event.key === 'Tab' && event.shiftKey === false && event.target === focusableList[last]) {
        event.preventDefault();
        focusableList[0].focus();
      } else if (
        event.key === 'Tab' &&
        event.shiftKey === true &&
        event.target === focusableList[0]
      ) {
        event.preventDefault();
        focusableList[last].focus();
      } else if (event.key === 'Escape') {
        this.$emit('dismiss');
      }
    },
  },
  computed: {
    classes() {
      const classes = [];
      switch (this.position) {
        case 'center':
          classes.push('modal-dialog-centered');
          break;
        default:
          break;
      }
      switch (this.size) {
        case 'small':
          classes.push('modal-sm');
          break;
        case 'large':
          classes.push('modal-lg');
          break;
        case 'xl':
          classes.push('modal-xl');
          break;
        default:
          break;
      }

      return classes;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

/* Small devices (landscape phones, less than 768px) */
@include media-breakpoint-down(sm) {
  .modal-dialog {
    position: absolute;
    bottom: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
  .modal-dialog-centered.modal-dialog-scrollable {
    justify-content: flex-end;
  }
}

.modal-lg {
  max-width: rem(700px);
}
</style>
