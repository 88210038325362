import emitter from '@/eventBus';

class SpinnerService {
  constructor() {
    this.emitter = emitter;
  }

  open() {
    this.emitter.$emit('loader:open');
  }

  close() {
    this.emitter.$emit('loader:close');
  }
}

export default new SpinnerService();
