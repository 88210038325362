<template>
  <WCSpinner v-if="!fetched || !updated" position="center" size="xl" />
  <FocusLoop v-else>
    <WCModal
      size="xl"
      @dismiss="dismiss('Dismissed')"
      :aria-label="$t('customizeYourItem', { itemName: item.name })"
    >
      <WCModalHeader>
        <h3 class="my-2">{{ $t('customizeYourItem', { itemName: item.name }) }}</h3>
        <button @click="closeDialog" class="btn btn-link text-muted underline-link-from-center">
          {{ $t('cancel') }} <font-awesome-icon icon="xmark" size="lg" class="ml-2" />
        </button>
      </WCModalHeader>
      <WCModalBody>
        <WCItemModifier
          @input="instructions = $event"
          :itemModifiers="itemModifiers"
          :item="item"
          :lineItem="lineItem"
          :instructions="instructions"
          :isEditing="editingItem"
          :orderType="orderType"
          @clearAll="clearSelected"
        />
      </WCModalBody>
      <WCModalFooter>
        <WCCurrentOrderTypeBadge v-if="orderType" :orderType="orderType" />
        <!-- START : Add to list button -->
        <WCAddToListButton
          v-if="hasAddToList"
          :isFavoriteMTOItem="favoriteMTOItem"
          :item="item"
          :btnVarient="addToListBtnType"
          :editingItem="editingItem"
          :itemModifiers="itemModifiers"
          :instructions="instructions"
          :disabled="!allValid"
          @close="closeDialog"
        />
        <!-- END : Add to list button -->
        <WCItemModifierAddToCartButton
          v-else
          :lineItem="lineItem"
          :item="item"
          :editingItem="editingItem"
          :itemModifiers="itemModifiers"
          :instructions="instructions"
          :orderType="orderType"
          @click="closeDialog"
        />
      </WCModalFooter>
    </WCModal>
  </FocusLoop>
</template>

<script>
import { FocusLoop } from '@vue-a11y/focus-loop';
import WCItemModifier from '@/modules/itemModifiers/components/WCItemModifier/WCItemModifier.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import ItemModifierRootMixin from '@/modules/itemModifiers/mixins/ItemModifierRootMixin';
import WCItemModifierAddToCartButton from '@/modules/itemModifiers/components/WCItemModifierAddToCartButton/WCItemModifierAddToCartButton.vue';
import WCAddToListButton from '@/modules/lists/components/WCAddToListButton/WCAddToListButton.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import WCCurrentOrderTypeBadge from '@/modules/orderTypes/components/WCCurrentOrderTypeBadge/WCCurrentOrderTypeBadge.vue';

export default {
  components: {
    FocusLoop,
    WCSpinner,
    WCModalHeader,
    WCModalBody,
    WCModalFooter,
    WCModal,
    WCItemModifier,
    WCItemModifierAddToCartButton,
    WCAddToListButton,
    WCCurrentOrderTypeBadge,
  },
  mixins: [ModalMixin, ItemModifierRootMixin],
  methods: {
    closeDialog() {
      this.dismiss('Dismissed');
    },
  },
};
</script>

<style scoped></style>
