<template>
  <footer class="container-lg p-3" role="contentinfo">
    <div class="wc-top-footer" v-if="isNotAnEmployee">
      <nav aria-labelledby="company-section">
        <h3 id="company-section">{{ $t('company') }}</h3>
        <ul class="unstyled-list">
          <li v-if="showContactUsPage">
            <router-link class="underline-link-from-center" to="/contact">{{
              $t('contactUs')
            }}</router-link>
          </li>
          <li>
            <router-link class="underline-link-from-center" to="/select-store">{{
              isMultiStore ? $t('findStore') : $t('findOurStore')
            }}</router-link>
          </li>
        </ul>
      </nav>

      <nav aria-labelledby="quicklinks-section">
        <h3 id="quicklinks-section">{{ $t('quickLinks') }}</h3>
        <ul class="unstyled-list">
          <li v-for="cs in $configuration.customSources" :key="cs.id">
            <router-link class="underline-link-from-center" :to="`/cs/${cs.id}`">{{
              cs.label
            }}</router-link>
          </li>
          <li v-if="$configuration.allowEGiftCards">
            <router-link class="underline-link-from-center" :to="giftCardUrl">{{
              $t('giftCards')
            }}</router-link>
          </li>
          <li v-if="isEbtEnabled">
            <router-link class="underline-link-from-center" to="/me/wallet">{{
              $t('checkEbtBalance')
            }}</router-link>
          </li>
        </ul>
      </nav>

      <nav aria-labelledby="accounts-section">
        <h3 id="accounts-section">{{ $t('account') }}</h3>
        <ul class="unstyled-list">
          <li>
            <router-link v-if="!isLoggedIn" class="underline-link-from-center" to="/login">{{
              $t('signIn')
            }}</router-link>
          </li>
          <li>
            <router-link
              class="underline-link-from-center"
              v-if="$configuration.allowCustomerAdd && !isLoggedIn"
              to="/register"
              >{{ $t('register') }}</router-link
            >
          </li>
          <li>
            <router-link class="underline-link-from-center" to="/me/orders">{{
              $t('orderHistory')
            }}</router-link>
          </li>
          <li>
            <router-link class="underline-link-from-center" to="/employee/login">{{
              $t('employeeLogin')
            }}</router-link>
          </li>
        </ul>
      </nav>

      <nav aria-labelledby="social-section" v-if="$configuration.socialNetworksEnabled">
        <h3 id="social-section">{{ $t('social') }}</h3>
        <ul class="unstyled-list">
          <li v-for="(socialProvider, si) in $configuration.socialProviders" :key="si">
            <a
              v-if="socialProvider.provider === 'facebook'"
              class="underline-link-from-center"
              :href="`https://www.facebook.com/${socialProvider.userKey}`"
              target="_blank"
              rel="noopener"
            >
              {{ $t('facebook') }}</a
            >
            <a
              v-if="socialProvider.provider === 'twitter'"
              class="underline-link-from-center"
              :href="`https://x.com/${socialProvider.userKey}`"
              target="_blank"
              rel="noopener"
            >
              {{ $t('twitter') }}</a
            >
            <a
              v-if="socialProvider.provider === 'youtube'"
              class="underline-link-from-center"
              :href="`https://youtube.com/${socialProvider.userKey}`"
              target="_blank"
              rel="noopener"
            >
              {{ $t('youTube') }}</a
            >
            <a
              v-if="socialProvider.provider === 'linkedin'"
              class="underline-link-from-center"
              :href="`https://www.linkedin.com/company/${socialProvider.userKey}`"
              target="_blank"
              rel="noopener"
            >
              {{ $t('linkedIn') }}</a
            >
            <a
              v-if="socialProvider.provider === 'instagram'"
              class="underline-link-from-center"
              :href="`https://www.instagram.com/${socialProvider.userKey}`"
              target="_blank"
              rel="noopener"
            >
              {{ $t('instagram') }}</a
            >
          </li>
        </ul>
      </nav>
    </div>

    <hr />

    <div class="wc-bottom-footer" role="list">
      <span role="listitem"
        ><font-awesome-icon :icon="['far', 'copyright']" :title="$t('copyright')" />
        {{ $t('allRightsReserved', { year: currentYear }) }}</span
      >
      <span role="listitem" v-if="locationEmail && locationEmail.email">
        <a
          :href="'mailto:' + locationEmail + '?subject=Customer question'"
          class="underline-link-from-center"
        >
          {{ locationEmail }}</a
        >
      </span>
      <span role="listitem" v-if="locationPhone && locationPhone.phone">
        <a :href="`tel:${locationPhone}`" class="underline-link-from-center">
          {{ locationPhone }}
        </a>
      </span>
      <span
        role="listitem"
        v-if="locationAddress && (locationAddress.location || locationAddress.webcart)"
      >
        <a :href="mapStoreAddress" class="underline-link-from-center text-dark" target="_blank">{{
          locationAddress
        }}</a></span
      >
    </div>
  </footer>
</template>

<script>
import { getOneLineAddress } from '@/utils';
import ContactUsMixin from '@/mixins/ContactUsMixin';
import { MAP_CONSTANTS } from '@/constants/AppConstants';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';
import GiftCardMixin from '@/modules/giftcard/mixins/GiftCardMixin';
import UserMixin from '@/modules/user/mixins/UserMixin';

export default {
  mixins: [EmployeeMixin, GiftCardMixin, UserMixin, ContactUsMixin],
  computed: {
    /**
     * Address link for Google api
     */
    mapStoreAddress() {
      return `${MAP_CONSTANTS.GOOGLE_MAP_API}?q=${this.oneLineAddress}`;
    },
    /**
     * Method to return current year
     */
    currentYear() {
      return new Date().getFullYear();
    },
    /**
     * Address for Google maps api
     */
    oneLineAddress() {
      return getOneLineAddress(this.locationAddress);
    },
    isMultiStore() {
      return this.$configuration.multistore;
    },
    isLoggedIn() {
      return this.isCustomer || this.isEmployee;
    },
    isEbtEnabled() {
      return (
        this.isLoggedIn &&
        this.$configuration.thirdPartyServiceProfiles?.filter(
          profile => profile.thirdPartyServiceProvider?.type === 2,
        )?.length
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

footer {
  margin-top: auto;

  a {
    color: var(--dark, $dark);
    background-image: linear-gradient(var(--dark, $dark), var(--dark, $dark));
  }
}

.wc-top-footer,
.wc-bottom-footer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.625rem;
}

.wc-top-footer > nav,
.wc-bottom-footer > span {
  padding-left: $pad-4;
}

.unstyled-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
</style>
