export default {
  "AcceleratedCheckOutFeatureUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "AcceleratedCheckOutFeatureUnavailableSubText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "AcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo Escanear e Ir"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de cuenta"])},
  "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes de la cuenta"])},
  "addAsFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Agregar ", _interpolate(_named("name")), " como favorito"])},
  "addCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar cupones"])},
  "addedSugars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar azúcares"])},
  "addItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar artículo"])},
  "addItemsToOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar artículos al pedido"])},
  "addItemToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar artículos a la lista"])},
  "addItemToListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar este artículo a una lista"])},
  "addNewCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar a una nueva tarjeta"])},
  "addNewList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar lista nueva"])},
  "addNoteAboutItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar una nota acerca de este artículo"])},
  "addPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un método de pago"])},
  "addSelectedToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar elemento seleccionado al Carrito"])},
  "addSelectedToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "addSpecialInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar instrucciones especiales"])},
  "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar al carrito"])},
  "addToFavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar a favoritos"])},
  "addToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar a la lista"])},
  "addWalletCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "addWalletCardFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "addWalletCardSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerta"])},
  "alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
  "allCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las categorías"])},
  "allOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los pedidos"])},
  "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir"])},
  "allowCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir permiso de la cámara"])},
  "allowSubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir sustituciones"])},
  "allRightsReserved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("year")), " WebCart - Todos los derechos reservados"])},
  "alreadyVerifiedYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ya verificó su cuenta?"])},
  "alternateItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artículos alternativos"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
  "amountMustBeAtLeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cantidad debe ser al menos"])},
  "amountMustBeLessOrEqual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cantidad debe ser menor que o igual a"])},
  "amountPerServing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad por ración"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar"])},
  "applyChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar cambios"])},
  "areYouSureCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea cancelar sus cambios?"])},
  "areYouSureCancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea cancelar su pedido?"])},
  "areYouSureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea borrar el final de la tarjeta en *"])},
  "associate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asociado"])},
  "associateAsCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asociado como cliente"])},
  "associationBanner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Modo Asociación del Cliente, usando WebCart como ", _interpolate(_named("name"))])},
  "assumesNoLiability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no asume responsabilidad por inexactitudes o errores acerca de los productos."])},
  "asYouScanItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mientras explora los artículos de su lista, los seleccionaremos para usted"])},
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
  "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilidad"])},
  "availableBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo disponible"])},
  "averagePerUnitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio de precio por unidad"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
  "barcodeAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de barras"])},
  "barcodePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar código de barras para escanear en el registro"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
  "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marca"])},
  "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migas de pan"])},
  "browseByCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAVEGAR POR CATEGORÍA"])},
  "browseQueryText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("browseText")), "\""])},
  "browseResultQuery": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("browseResult")), "\""])},
  "buyItAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar de nuevo"])},
  "buyItAgainItemsUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llamar"])},
  "callForPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llamar por un precio"])},
  "callUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llámenos"])},
  "calories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calorías"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "cancelEditOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar la edición de su pedido"])},
  "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar pedido"])},
  "cancelOrderFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detectamos un problema cancelando su pedido. Por favor, refresque la página e intente de nuevo."])},
  "cancelOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su pedido ha sido cancelado."])},
  "cancelQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cancelar?"])},
  "cannotAddItemToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede agregar un artículo al carrito"])},
  "cardEnteredInvalidInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de tarjeta que introdujo está inactivo o inválido. Compruebe el número y trate de introducirlo de nuevo."])},
  "cardholderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del titular de la tarjeta"])},
  "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de tarjeta"])},
  "cardNumberIsNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de tarjeta no es válido"])},
  "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrito"])},
  "cashierApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "cashierApprovalMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías"])},
  "categoriesMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menú de categorías"])},
  "categoryLimitExceededMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usted no puede elegir más de ", _interpolate(_named("limit")), " en la categoría ", _interpolate(_named("categoryName"))])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
  "changeYourMind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cambia de parecer?"])},
  "changeYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar su contraseña"])},
  "chargeAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas de cargo"])},
  "checkCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprobar tarjeta"])},
  "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
  "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
  "checkShoppingAtCorrectStore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usted está comprando en ", _interpolate(_named("storeName")), ". Asegúrese de que esta es la tienda en la que quiere hacer el pedido."])},
  "checkTheBoxForReceipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque la casilla para recibir sus recibos de compras en la tienda por correo electrónico."])},
  "checkTheGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque los grupos a los que quiere pertenecer."])},
  "checkYourBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compruebe su saldo"])},
  "choose": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elija ", _interpolate(_named("max"))])},
  "chooseACategoryMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija un menú de categoría"])},
  "chooseAList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija una lista"])},
  "chooseAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija una cuenta"])},
  "chooseAtLeast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elija al menos ", _interpolate(_named("min"))])},
  "chooseBetween": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elija entre ", _interpolate(_named("min")), " y ", _interpolate(_named("max"))])},
  "chooseDataAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija fecha y hora"])},
  "chooseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija fecha"])},
  "chooseDateAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija fecha y hora"])},
  "chooseLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija idioma"])},
  "chooseQuantityFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija cantidad para"])},
  "chooseTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija hora"])},
  "chooseUpTo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elija hasta ", _interpolate(_named("max"))])},
  "chooseYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elija su ", _interpolate(_named("category"))])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar"])},
  "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar todo"])},
  "clearAllText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Le gustaría quitarlos permanentemente?"])},
  "clearCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaciar carrito"])},
  "clearCartFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pudo vaciar su carrito"])},
  "clearCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su carrito ha sido vaciado exitosamente"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar filtro"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar filtros"])},
  "clearOrSaveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Le gustaría guardar estos artículos para después o quitarlos permanentemente?"])},
  "clickHereToRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic aquí para registrar"])},
  "clickHereToReregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic aquí para volver a registrar"])},
  "clickHereToSendAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic aquí para enviar otro"])},
  "clickHereToSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic aquí para iniciar sesión"])},
  "clickTheVerificationLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic en el enlace de verificación en el correo electrónico para terminar de registrar su cuenta."])},
  "clickToAddNotesAndSubstitutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic para agregar notas y sustituciones"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
  "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colapso"])},
  "commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Órdenes"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compañía"])},
  "completeAtCashRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar en una caja registradora"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacte con nosotros"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "continueHereAndEndAcceleratedCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "continueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar comprando"])},
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derechos de autor"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
  "couponAndPromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cupón y promociones"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
  "createANewList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una nueva lista"])},
  "createAPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una contraseña"])},
  "createNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una nueva contraseña"])},
  "creditCardInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de la tarjeta de crédito"])},
  "creditDebitCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de crédito/débito"])},
  "currentAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(actual)"])},
  "currentlySelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente seleccionada"])},
  "currentSelections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciones actuales"])},
  "currentSpecials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especiales actuales"])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar"])},
  "customAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar cantidad"])},
  "customerAssociation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asociación de clientes"])},
  "customerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TARJETA DEL CLIENTE"])},
  "customerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del Cliente"])},
  "customerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del Cliente"])},
  "customerPrograms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programas del cliente"])},
  "customPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar precio"])},
  "datePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selector de fecha"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día"])},
  "decreaseQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disminuir cantidad"])},
  "decrement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disminución"])},
  "defaultToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Le gustaría instalar esta aplicación en su pantalla de inicio para un acceso rápido?"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
  "deleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar tarjeta"])},
  "deleteCardSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Su tarjeta que termina en ", _interpolate(_named("finalDigits")), " ha sido borrada satisfactoriamente"])},
  "deleteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar lista"])},
  "deleteWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar monedero"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de entrega"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
  "deviceNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositivo sin soporte"])},
  "didntReceiveEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No recibió el correo electrónico?"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exención de responsabilidad"])},
  "disclaimerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mientras trabajamos para asegurar que la información del producto es correcta, en ocasiones los fabricantes pueden alterar sus listas de ingredientes. Los materiales y empaquetamientos de productos reales pueden contener más y/o información diferente que la que aparece en nuestro sitio web. Recomendamos que no solo se base en la información presentada y que siempre lea las etiquetas, advertencias e instrucciones antes de usar o consumir un producto. Para información adicional acerca de un producto, por favor contacte con el fabricante. El contenido en este sitio es para propósitos de referencia y no está diseñado para sustituir los consejos de un médico, farmacéutico u otro profesional autorizado de atención médica. Usted no debería usar esta información como un autodiagnóstico o para tratar un problema de salud o enfermedad. Contacte con su proveedor de atención médica de inmediato si sospecha que tiene un problema médico. La información y afirmaciones relacionados con suplementos dietéticos no han sido evaluadas por la Administración de Alimentos y Medicamentos, y no tienen la intención de diagnosticar, tratar, curar o evitar ninguna enfermedad o afección de salud."])},
  "discountAppliedInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento aplicado en el carrito."])},
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuentos"])},
  "dollarSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizado"])},
  "doNotAllowSubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No permita sustituciones"])},
  "downloadLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar registros"])},
  "drag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arrastrar"])},
  "dragContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expandir recipiente del producto arrastrando"])},
  "eachAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ea"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "editItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar elemento"])},
  "editOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar pedido"])},
  "editOrderError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Falló! Su pedido no fue editado."])},
  "editOrderQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Editar pedido?"])},
  "editOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su pedido está listo para ser editado. Cuando termine de agregar o quitar artículos, por favor proceda con el pago de nuevo"])},
  "editYourNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edite su nota"])},
  "eGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta E-Gift (regalo electrónico)"])},
  "eGiftCardBuild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrolle su tarjeta E-Gift"])},
  "eGiftCardCartAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta E-Gift agregada al carrito"])},
  "eGiftCardCartAddSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una tarjeta E-Gift ha sido agregada satisfactoriamente a su carrito"])},
  "eGiftCardCartError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los artículos deben ser comprados por separado de la tarjeta E-Gift en su carrito. Por favor, retire o compre su tarjeta E-Gift antes de empezar a agregar artículos a su carrito."])},
  "eGiftCardFormMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir un mensaje acerca de esta tarjeta E-Gift (opcional)"])},
  "eGiftCardModalAlertShoppingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termine de hacer la compra antes de adquirir una tarjeta E-Gift."])},
  "eGiftCardModalAlertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las tarjetas E-Gift deben ser compradas por separado de los artículos de su carrito"])},
  "eGiftCardPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lea nuestra política de tarjeta E-Gift"])},
  "eGiftCardPolicyHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de la tarjeta E-Gift"])},
  "eGiftCardPurchaseAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las tarjetas E-Gift deben ser compradas por separado de los artículos en su carrito. Por favor, retire o compre esos artículos antes de que empiece a crear una tarjeta E-Gift."])},
  "eGiftCardShippingAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las tarjetas E-Gift son entregadas electrónicamente (correo electrónico y/o texto) o descargadas (para imprimir). Vea el Paso 1 para revisar el método que seleccionó"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico"])},
  "emailInUseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su dirección de correo electrónico ya está asociada con una cuenta."])},
  "emailInUseHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su dirección de correo electrónico ya está en uso"])},
  "emailIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere un correo electrónico"])},
  "emailPhoneOrAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico, teléfono o número de cuenta"])},
  "emailTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico a"])},
  "employeeActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones del empleado"])},
  "employeeDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablero del empleado"])},
  "employeeLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión del empleado"])},
  "endingIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finalizando en"])},
  "enterAcceleratedCheckOutModeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente, usted está en modo Accelerated Check Out (Escanear e Ir)"])},
  "enterInstructionsFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Introduzca instrucciones especiales para su ", _interpolate(_named("itemName"))])},
  "enterTheWrongEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Introdujo correo electrónico equivocado?"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error(404)"])},
  "errorInAcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error en modo escanear e ir"])},
  "errorInCancelPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error en cancelar pago"])},
  "errorInPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error en pago"])},
  "errorScanProductNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró información de producto escaneado, por favor contacte con el gerente de la tienda."])},
  "exitAcceleratedCheckOutMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir de Escanear e Ir"])},
  "exitAcceleratedCheckOutModeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "exitAcceleratedCheckOutModeModalBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "exitAcceleratedCheckOutModeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salió del modo Escanear e Ir"])},
  "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expandir"])},
  "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiración"])},
  "expirationAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp."])},
  "expiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expirando"])},
  "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falló"])},
  "failedPasswordResetError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falló en restablecer contraseña"])},
  "failedVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falló verificación"])},
  "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["favorito"])},
  "featureNotAvailableForDesktopWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta característica no está disponible para computadora de mesa"])},
  "featureNotAvailableForMobileWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta característica no está disponible para teléfono móvil"])},
  "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cuota"])},
  "fieldIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere un campo"])},
  "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
  "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrar"])},
  "findOurStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrar nuestra Tienda"])},
  "findStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrar una Tienda"])},
  "firefoxAndroidToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Le gustaría instalar esta aplicación en su pantalla de inicio para un acceso rápido? - Toque en el ícono de inicio y luego Agregue a la Pantalla de Inicio."])},
  "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primero"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer nombre"])},
  "firstNameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere primer nombre"])},
  "forgotYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidó su contraseña?"])},
  "forText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["para ", _interpolate(_named("name"))])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde"])},
  "getCodeViaEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtener un código por correo electrónico."])},
  "giftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de regalo"])},
  "giftCardCartAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted tiene una tarjeta E-Gift en su carrito. Por favor, compre la Tarjeta E-Gift o quítela de su carrito."])},
  "giftCardDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la tarjeta E-Gift"])},
  "giftCardImgAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta E-Gift (regalo electrónico)"])},
  "giftCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas E-Gift"])},
  "giftCardWithIdImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diseño de la tarjeta E-Gift ", _interpolate(_named("id"))])},
  "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
  "goGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apueste a lo ecológico con recibos sin papel"])},
  "goLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vaya a la izquierda"])},
  "goodThrough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válido hasta"])},
  "goRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vaya a la derecha"])},
  "gotIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entendido"])},
  "goToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya a Pago"])},
  "goToHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya a la tienda en el hogar"])},
  "goToNextMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya al siguiente mes"])},
  "goToPreviousMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya al mes anterior"])},
  "goWithPaperless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apueste a lo ecológico con recibos sin papel"])},
  "grandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gran total"])},
  "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido"])},
  "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tiene una cuenta?"])},
  "haveCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tiene cupones?"])},
  "healthAttributeImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Atributo de salud ", _interpolate(_named("attributeName"))])},
  "hidePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar contraseña"])},
  "homePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página de inicio"])},
  "homeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienda en el hogar"])},
  "homeStoreAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Tienda en el hogar)"])},
  "idRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "idRequiredMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "imHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Estoy aquí!"])},
  "importantCartMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje importante acerca de artículos en su carrito"])},
  "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En"])},
  "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluye"])},
  "increaseQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incrementar cantidad"])},
  "increment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incrementar"])},
  "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingredientes"])},
  "ingredientsCap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INGREDIENTES"])},
  "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En progreso"])},
  "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "inStoreOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo en la tienda"])},
  "inStoreOnlyAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " solo puede comprarse en la tienda"])},
  "invalidField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo inválido"])},
  "inYourCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en su carrito"])},
  "iosShareMessagePart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toque en"])},
  "iosShareMessagePart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comparta ícono y seleccione “Agregar a Pantalla de Inicio”."])},
  "iosToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instale esta aplicación en su pantalla de inicio para acceso rápido cuando desee comprar de nuevo."])},
  "isNowOutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ahora está fuera de existencias disponibles y ha sido quitado de su carrito"])},
  "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["artículo"])},
  "itemAddedToSaveForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "itemDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del artículo"])},
  "itemNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas del artículo"])},
  "itemProhibitAlternativeMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto no se puede quitar"])},
  "itemProhibited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "itemProhibitedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["artículo"])},
  "itemTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total artículos"])},
  "itemUnavailableAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " actualmente no está disponible"])},
  "itLooksPickedUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que ya ha recogido este pedido. Si tiene preguntas o inquietudes, por favor llámenos"])},
  "joinPromosAndRewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participe en nuestro programa de promociones y premios"])},
  "kilometersAway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km de distancia"])},
  "knowYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Conoce su contraseña?"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
  "lastNameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere apellido"])},
  "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lista"])},
  "listIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esta lista está vacía!"])},
  "listNamesCannotExceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los nombres de la lista no pueden exceder de 20 caracteres"])},
  "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listas"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando"])},
  "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
  "locallySourced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este artículo se obtiene localmente"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugar"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión"])},
  "loginFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falló en iniciar sesión"])},
  "loginFormSubheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Bienvenido de nuevo! Inicie sesión para un pago más rápido y poder seguir y ver sus pedidos pasados."])},
  "loginFormSubheadingAcceleratedCheckOut": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bienvenido a Accelerated Check Out (Escanear e Ir) de ", _interpolate(_named("retailer")), " impulsado por ECRS"])},
  "loginId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de inicio de sesión"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal"])},
  "makeASelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer una selección"])},
  "makeHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer que esta sea su tienda en el hogar"])},
  "manageSavedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maneje su lista de compra guardada"])},
  "manageYourSavedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maneje aquí su lista guardada"])},
  "memberID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de miembro"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
  "messageSentFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su mensaje no ha sido enviado"])},
  "messageSentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su mensaje ha sido enviado satisfactoriamente"])},
  "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo nombre"])},
  "milesAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mi"])},
  "minimumSelectionSatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección mínima satisfecha"])},
  "mobileAcceleratedCheckOutSessionInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
  "moreTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["más veces."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "nameYourList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre su lista"])},
  "needsMinimumSelection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Necesita selección mínima de ", _interpolate(_named("min"))])},
  "needToStartOver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Necesita empezar de nuevo?"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
  "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apodo"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "noCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Cancelar"])},
  "noCancelDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Cancelar Borrar"])},
  "noCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted no tiene en archivo ninguna tarjeta convertida en token de forma segura."])},
  "noContactInfoAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay disponible información de contacto."])},
  "noCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted no tiene ningunos eCoupons (cupones electrónicos) disponibles"])},
  "noCustomerAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "noDeleteMyItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "noItemsOnOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No había artículos en este Pedido."])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ninguno"])},
  "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay pedidos actualmente disponibles."])},
  "noProductImageAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " imagen de producto no disponible"])},
  "noRemoveMyItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "noReservationTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay disponibles tiempos de reserva para este tipo de pedido."])},
  "noSavedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay tarjetas guardadas"])},
  "noSearchResultsFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "noShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró lista de Compra"])},
  "noStoresFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron tiendas."])},
  "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No está disponible"])},
  "notAvailableAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
  "notAvailableOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No está disponible en línea"])},
  "noteAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota agregada"])},
  "notEntered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No se introdujo ningún ", _interpolate(_named("name"))])},
  "notOnYourList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No está en su lista"])},
  "notSignedUpYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No ha iniciado sesión todavía?"])},
  "notYourHomeStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta no es su tienda en el hogar"])},
  "nutrition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutrición"])},
  "nutritionFacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de nutrición"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "okayGotIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Bien, entendido!"])},
  "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vieja contraseña"])},
  "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Vaya!"])},
  "operaBlockedVersionWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La aplicación no tiene soporte en la versión 9 hasta versión 12 de Opera. Por favor, use otros navegadores."])},
  "operaMiniToastMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Le gustaría instalar esta aplicación en su pantalla de inicio para un acceso rápido? - Toque en el ícono más y luego Agregue a la Pantalla de Inicio."])},
  "optInToAllowInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt-in (adhesión) para enviarle a usted información sobre venideras promociones, recibir cupones y más."])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido"])},
  "orderCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceló el Pedido"])},
  "orderCancelledError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Falló! Su pedido no fue cancelado."])},
  "orderCancelledSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Su pedido ", _interpolate(_named("orderScanCode")), " ha sido cancelado."])},
  "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del pedido"])},
  "orderHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de pedidos"])},
  "orderItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artículos del pedido"])},
  "orderNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas del pedido"])},
  "orderNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pedido no es válido"])},
  "orderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido colocado"])},
  "ordersInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos en progreso"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condición del pedido"])},
  "orderTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total del pedido"])},
  "orderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de pedido"])},
  "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No está en existencias"])},
  "outOfStockAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " está actualmente fuera de existencias"])},
  "packOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquete de"])},
  "packSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño del paquete"])},
  "pageNavigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegación de página"])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Páginas"])},
  "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasó"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
  "passwordMustInclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su contraseña debe incluir"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere contraseña"])},
  "passwordRequirementsNotMet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se cumplieron requisitos de contraseña"])},
  "passwordResetError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo validar su identidad basado en los detalles de cuenta proporcionados."])},
  "passwordValidationCharLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos ocho caracteres."])},
  "passwordValidationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos un número"])},
  "passwordValidationSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos un símbolo"])},
  "passwordValidationUpperCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos una letra MAYÚSCULA"])},
  "payAtRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "payLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar después"])},
  "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pago"])},
  "payOnYourPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pague con su teléfono"])},
  "payTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total a pagar"])},
  "percentDailyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% de valor diario*"])},
  "percentDV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El % de valor diario (DV) le dice a usted cuánto de un nutriente en una porción de comida contribuye a una dieta diaria de 2,000 calorías al día para consejos de nutrición general."])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
  "phoneNumberInUseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su número de teléfono ya está asociado con una cuenta."])},
  "phoneNumberInUseHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su número de teléfono ya está en uso"])},
  "phoneNumberIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere número de teléfono"])},
  "phoneOrAppMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Falta teléfono o aplicación?"])},
  "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocar Pedido"])},
  "pleaseAllowCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, permita que su cámara escanee el código de barras"])},
  "pleaseEnterValidAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca una cantidad válida a licitar"])},
  "pleaseEnterYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Por favor, introduzca su ", _interpolate(_named("name"))])},
  "pleaseRegisterAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, registre de nuevo para obtener un nuevo enlace."])},
  "pleaseScanNextItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, escanee su siguiente artículo"])},
  "pleaseSelectAList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, seleccione una lista"])},
  "pleaseSelectAtLeastOneDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, seleccione al menos un método para la entrega"])},
  "pleaseSelectDelDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, seleccione una fecha y hora de entrega"])},
  "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puntos"])},
  "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefijo"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
  "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primario"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimir"])},
  "printReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimir recibo"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["producto"])},
  "productDescImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " Producto, vea descripción en la página"])},
  "productDetailsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página de detalles del producto"])},
  "productImgAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("brandName")), " ", _interpolate(_named("productName")), " Producto"])},
  "productImgGalleryThumbnailAltText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " Galería de productos en miniatura"])},
  "productInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["información del producto"])},
  "productMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["producto. Método"])},
  "productPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio del producto"])},
  "profileAddressSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de dirección"])},
  "profileEmailSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de correo electrónico"])},
  "profileOtherSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otra información"])},
  "profilePersonalSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información personal"])},
  "profilePhoneSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de teléfono"])},
  "profileWorkSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de trabajo"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
  "quantityAbbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTD"])},
  "quickLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlaces rápidos"])},
  "readyToRedeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Listo para canjear!"])},
  "receiptLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando recibo..."])},
  "recipientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de beneficiario"])},
  "recipientsEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico del beneficiario"])},
  "recipientsPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número telefónico del beneficiario"])},
  "recommendComplexPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendamos a usted crear una contraseña compleja que sea diferente a una contraseña que haya usado en el pasado."])},
  "redeemAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canjear en"])},
  "redirecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redireccionando..."])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar"])},
  "registerAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar de nuevo"])},
  "remainingTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total restante"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar"])},
  "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar todo"])},
  "removeCartAcceleratedCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "removeCartAcceleratedCheckOutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "removeFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Quitar ", _interpolate(_named("name")), " de favoritos"])},
  "removeFromCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar del carrito"])},
  "removeFromCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar de Pago"])},
  "removeFromFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar del filtro"])},
  "removeFromList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar de la lista"])},
  "removeGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "removeGiftCardCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "removeItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar artículos"])},
  "removeSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar lo seleccionado"])},
  "removeTheItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar los artículos"])},
  "replicateNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replicar ahora"])},
  "resendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar correo electrónico"])},
  "resetMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer método"])},
  "resetNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer ahora"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer contraseña"])},
  "resetPasswordAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si usted creó una cuenta en la tienda, puede tener que restablecer su contraseña para comprar en línea por primera vez"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados"])},
  "returnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresar a Inicio"])},
  "reviewYourItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revise sus artículo"])},
  "reviewYourOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revise sus pedidos"])},
  "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premios"])},
  "runMobileAuthenticator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecute la aplicación autenticador móvil para obtener un código de seis dígitos"])},
  "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s"])},
  "salePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de venta"])},
  "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "saveAllItemsForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "saveAllItemsToListText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "saveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de ahorro"])},
  "savedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas guardadas"])},
  "savedForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardado para después"])},
  "saveForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar para después"])},
  "saveItemsForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar artículos para después"])},
  "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "scanAnItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanear un artículo"])},
  "scanBarcodeCustomerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanear este código de barras para su usar su tarjeta de cliente"])},
  "scanCustomerCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanear tarjeta del cliente (en la tienda)"])},
  "scanError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artículo no encontrado"])},
  "scanItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanear un artículo"])},
  "scanItemInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mientras explora los artículos de su lista, los seleccionaremos para usted"])},
  "scanNextItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, escanee su siguiente artículo"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búsqueda"])},
  "searchByZipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por código postal"])},
  "searchCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar clientes..."])},
  "searchFilterCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar artículos por categoría"])},
  "searchProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programa de búsqueda"])},
  "searchResultsFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar resultados por"])},
  "securelyTokenizedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas tokenizadas de forma segura"])},
  "securityCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de seguridad"])},
  "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
  "seePriceAtCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver precio en Compra"])},
  "seePriceInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver precio en carrito"])},
  "selectADesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar un diseño"])},
  "selectAnAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar una cantidad"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar un país"])},
  "selectDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar día"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionado"])},
  "selectedGiftCardImgAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta E-Gift seleccionada"])},
  "selectedToPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionado para Imprimir"])},
  "selectHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar horas"])},
  "selectImHereButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el botón “Estoy aquí” cuando llegue a la tienda, y sacaremos su pedido."])},
  "selectMinutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar minutos"])},
  "selectOneGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione exactamente 1 grupo al que quiere pertenecer."])},
  "selectOrderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seleccione tipo de pedido"])},
  "selectPaymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione cómo le gustaría pagar sus artículos"])},
  "selectProductManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el producto manualmente"])},
  "selectQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seleccione cantidad"])},
  "selectShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione lista de compra"])},
  "selectState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un Estado"])},
  "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione Hora"])},
  "sendDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar fecha"])},
  "senderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del remitente"])},
  "sendLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar después"])},
  "sendLaterDatePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar después seleccionador de fecha"])},
  "sendLaterTimePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar después seleccionador de hora"])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar mensaje"])},
  "sendNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar ahora"])},
  "sendOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar en"])},
  "sendUsAMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviarnos un mensaje"])},
  "servingSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño de porción"])},
  "servingsPerContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porciones por recipiente"])},
  "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establecer contraseña"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envío"])},
  "shippingProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedor de envío"])},
  "shipTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar a"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
  "shopByCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar por categoría"])},
  "shoppingAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprando en"])},
  "shoppingCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrito de compra"])},
  "shoppingCartWithItemsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Carrito de compra con ", _interpolate(_named("count")), " artículos"])},
  "shoppingLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listas de compra"])},
  "shopStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar en esta tienda"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar"])},
  "showHidePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostar/ocultar contraseña"])},
  "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrando"])},
  "showingOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrando pedidos"])},
  "showingResultsFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrando resultados para"])},
  "showPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar contraseña"])},
  "sideMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menú lateral"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño"])},
  "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social"])},
  "sorryCouponCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, el código de cupón que usted introdujo no se puede aplicar a esta transacción."])},
  "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificar"])},
  "sortProductBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificar productos por"])},
  "specialInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucciones especiales"])},
  "specialPromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promociones especiales"])},
  "splitTender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licitación dividida"])},
  "stageCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etapa completada"])},
  "stageIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etapa incompleta"])},
  "stageInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etapa en progreso"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "storeLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugar de la tienda"])},
  "storeLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugares de las tiendas"])},
  "storeLogoAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotipo de la tienda"])},
  "storeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la tienda"])},
  "streetAddress1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
  "streetAddress2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apt/Suite (opcional)"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunto"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "substitutionAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sustitución permitida"])},
  "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
  "successOrderInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Éxito! Gracias, su pedido ahora está en progreso."])},
  "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sufijo"])},
  "suggestionsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de sugerencias"])},
  "suppressReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar recibos de papel"])},
  "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "textTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar texto a"])},
  "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Gracias!"])},
  "thankYouForCreatingAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Gracias por crear una cuenta! Al hacer esto, usted podrá disfrutar de una experiencia de pago más rápida, siguiendo y viendo pedidos pasados, y más."])},
  "timePicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selector de hora"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para"])},
  "toastInstallBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalar"])},
  "toastNotNowBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ahora"])},
  "toastOkBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien"])},
  "toCap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para"])},
  "tokenServerUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El servidor de token seguro no está disponible actualmente. Por favor, intente de nuevo después."])},
  "topResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESULTADOS MÁS IMPORTANTES"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento"])},
  "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "twoFactorVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de dos factores"])},
  "typeListName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribir lista de nombres"])},
  "unassociate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafiliar"])},
  "unassociateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted ha sido desafiliado satisfactoriamente"])},
  "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No disponible"])},
  "upc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPC"])},
  "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de verificación"])},
  "verificationLinkHasExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, pero este enlace de verificación ha expirado"])},
  "verifyingYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificando su cuenta..."])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todo"])},
  "viewAllItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos los artículos"])},
  "viewEGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Tarjeta E-Gift"])},
  "viewItemList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "viewManageList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver / Manejar Lista"])},
  "viewManageShoppingList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ver o manejar lista ", _interpolate(_named("shoppingListName"))])},
  "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
  "viewNextDateSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver siguiente grupo de fechas disponibles"])},
  "viewNextTimeSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver siguiente grupo de horas disponibles"])},
  "viewPrevDateSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver anterior grupo de fechas disponibles"])},
  "viewPrevTimeSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver anterior grupo de horas disponibles"])},
  "viewReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver recibo"])},
  "viewStoreLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver lugares de las tiendas"])},
  "viewYour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver su"])},
  "voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprobante"])},
  "waitItemsInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Espere! Hay artículos en su carrito."])},
  "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monedero"])},
  "weCouldntFindWhatYoureLookingFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pudimos encontrar lo que estaba buscando."])},
  "weHaveBeenNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemos sido notificados de que usted está aquí."])},
  "weightedItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "weightedItemMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "weJustSentAVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acabamos de enviar un enlace de verificación a su correo electrónico."])},
  "weNeedToSendVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesitamos enviar a usted un código de verificación. ¿Cómo le gustaría recibirlo?"])},
  "wereOnOurWay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos en camino"])},
  "whenFinishedEditCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando termine de editar su pedido, necesitaremos continuar con el pago de nuevo"])},
  "whenYouArrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando llegue"])},
  "willBeSentOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se enviará en"])},
  "wouldYouLikeToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Le gustaría agregar estos artículos al pedido que está a punto de editar o guardar para después?"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
  "yesContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, Continuar"])},
  "yesDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, Borrar"])},
  "yesDeleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, Borrar la tarjeta que termina en *"])},
  "yesMoveMyItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "youCanEither": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted puede"])},
  "youCanUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted puede usar"])},
  "youCanUsePromos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted puede usar estas promociones ahora o guardarlas para después."])},
  "youHave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted ha"])},
  "youHaveAlreadyNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted ya nos ha notificado satisfactoriamente que está aquí. Si está experimentando un retraso inusualmente largo, por favor llámenos"])},
  "yourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su Cuenta"])},
  "yourCartIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su carrito está vacío"])},
  "yourCompletedOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus Pedidos Completados"])},
  "yourCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus eCoupons (cupones electrónicos)"])},
  "yourCredentialsMatchMultipleAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus credenciales coinciden con múltiples cuentas, por favor seleccione la cuenta para iniciar sesión como"])},
  "yourCustomerDiscounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus descuentos de cliente"])},
  "yourID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su ID"])},
  "yourPunchCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus tarjetas ePunch"])},
  "yourVerificationCodeSentShortly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su código de verificación será enviado dentro de poco usando el método de contacto proporcionado."])},
  "youTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
  "zipCodeInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal inválido"])},
  "zipCodeValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal válido"])}
}