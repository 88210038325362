<template>
  <div class="form-group">
    <slot></slot>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'WCFormGroup',
  props: {
    field: Object,
  },
  provide() {
    return { field: computed(() => this.field) };
  },
};
</script>
