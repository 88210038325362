import { library } from '@fortawesome/fontawesome-svg-core';
import FreeSolidIcons from './free/icons-solid';
import FreeRegularIcons from './free/icons-regular';
import FreeBrandIcons from './free/icons-brand';
import ProSolidIcons from './pro/icons-solid';
import ProRegularIcons from './pro/icons-regular';
import ProLightIcons from './pro/icons-light';
import ProDuotoneIcons from './pro/icons-duotone';

export default () => {
  FreeSolidIcons.forEach(icon => library.add(icon));
  FreeRegularIcons.forEach(icon => library.add(icon));
  FreeBrandIcons.forEach(icon => library.add(icon));
  ProSolidIcons.forEach(icon => library.add(icon));
  ProRegularIcons.forEach(icon => library.add(icon));
  ProLightIcons.forEach(icon => library.add(icon));
  ProDuotoneIcons.forEach(icon => library.add(icon));
};
