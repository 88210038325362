<template>
  <button
    @click="specialInstructions()"
    class="unstyled-btn nav-link w-100"
    data-toggle="tab"
    role="tab"
    id="special-instructions-tab"
  >
    {{ $t('specialInstructions') }}
  </button>
</template>

<script>
export default {
  emits: ['specialInstructionsSelected'],
  methods: {
    specialInstructions() {
      this.$emit('specialInstructionsSelected');
    },
  },
};
</script>

<style scoped></style>
