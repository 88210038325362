<template>
  <div class="item-row" ref="rowElement" v-if="coupons.length > 0">
    <div v-if="loadFailedError">{{ loadFailedError }}</div>
    <div v-if="!loadFailedError" class="navbar navbar-light item-row-heading">
      <h2 class="content-left mb-0">{{ $t('digitalCoupons') }}</h2>
      <router-link
        v-if="totalCount > coupons.length"
        :to="viewMoreLink"
        class="content-right underline-link-from-center"
      >
        {{ $t('viewMore') }}
      </router-link>
    </div>
    <template v-if="!loadFailedError">
      <div v-if="loading" class="wc-item-row__spinner-wrapper d-flex">
        <WCSpinner class="mx-auto align-self-center" size="xl" />
      </div>
      <template v-if="!loading">
        <PlatformRenderer>
          <template v-slot:onDesktop>
            <WCProductCardGroup class="item-row-wrapper" :style="gridColumnsForCoupons" role="list">
              <WCCouponCard
                v-for="coupon in desktopCoupons"
                :key="coupon.id"
                :coupon="coupon"
                role="listitem"
              />
            </WCProductCardGroup>
          </template>
          <template v-slot:onMobile>
            <WCCarousel class="wc-carousel--negate-container py-2 d-flex" :isSlider="true">
              <Splide
                :options="{ perPage: itemPerPage, arrows: false, gap: '1rem' }"
                class="container-fluid pb-4"
                role="list"
              >
                <SplideSlide
                  v-for="coupon in coupons"
                  :key="coupon.id"
                  class="wc-coupon-card-slide"
                >
                  <WCCouponCard
                    :coupon="coupon"
                    class="wc-card__wrapper--fixed-size"
                    role="listitem"
                  ></WCCouponCard>
                </SplideSlide>
              </Splide>
            </WCCarousel>
          </template>
        </PlatformRenderer>
      </template>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import WCCouponCard from '@/components/WCCouponCard/WCCouponCard.vue';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import WCCarousel from '@/components/WCCarousel/WCCarousel.vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { PlatformMixin } from '@/modules/platform';
import WCProductCardGroup from '../WCProductCardGroup/WCProductCardGroup.vue';

export default {
  components: {
    WCSpinner,
    PlatformRenderer,
    WCCouponCard,
    WCProductCardGroup,
    WCCarousel,
    Splide,
    SplideSlide,
  },
  data() {
    return {
      loading: false,
      loadFailedError: null,
      totalCount: -1,
      rowWidthPx: 0,
      coupons: [],
    };
  },
  mixins: [PlatformMixin],
  created() {
    this.updateData();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    coupons() {
      this.$nextTick(this.handleResize);
    },
  },
  methods: {
    handleResize() {
      this.rowWidthPx = this.$refs.rowElement ? this.$refs.rowElement.clientWidth : 0;
    },
    updateData() {
      this.loading = true;
      this.loadFailedError = null;
      this.fetchCoupons();
      this.loading = false;
    },
    async fetchCoupons() {
      try {
        const response = await axios.post('api/cpb/', {});
        if (response.data) {
          this.coupons = response.data.items;
          this.totalCount = response.data.totalCount;
        }
      } catch (error) {
        this.loading = false;
        this.loadFailedError = error;
        console.error(error);
      }
    },
  },
  computed: {
    viewMoreLink() {
      return `/cpb`;
    },
    itemPerPage() {
      return this.isMediumDevice ? this.desktopCouponsPerPage : 2;
    },
    desktopCoupons() {
      return this.coupons.slice(0, this.desktopCouponsPerPage);
    },
    desktopCouponsPerPage() {
      const targetCardWidthPx = '333';
      const targetItemCount = Math.floor(this.rowWidthPx / targetCardWidthPx);
      return Math.min(Math.max(targetItemCount, 4), 7);
    },
    gridColumnsForCoupons() {
      return { 'grid-template-columns': `repeat(${this.desktopCouponsPerPage}, 1fr)` };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-item-row__spinner-wrapper {
  height: rem(200px);
}

.wc-carousel {
  margin-left: -$container-gutter;
  margin-right: -$container-gutter;
}

.wc-coupon-card-slide {
  min-width: 0%;
}

.item-row {
  .item-row-heading {
    width: 100%;
    margin-bottom: $margin-2;
    padding: $pad-2 * 1.25 0 $pad-2 * 1.25;
    border-radius: 4px;
  }
  .item-row-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: rem(10px);
  }
}
</style>
